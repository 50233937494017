export function formatUserSkillsFromAlgolia(skills, highlightResult, appliedFilters) {
  const emptySkills = { highlight: [], normal: [] };

  return !Array.isArray(skills)
    ? emptySkills
    : skills.reduce((acc, skill, i) => {
      const hasHighlightedResult = highlightResult && highlightResult.skills && highlightResult.skills[i].matchLevel !== 'none';
      const hasMatchInFilters = appliedFilters && appliedFilters.skills && !!appliedFilters.skills
        .filter((s) => s.toLowerCase().trim() === skill.toLowerCase().trim()).length;

      hasHighlightedResult || hasMatchInFilters
        ? acc.highlight.push(skill)
        : acc.normal.push(skill);

      return acc;
    }, emptySkills);
}
