export const QUESTION_NAME = {
  ACCOUNT: 'account',
  FEEDBACK: 'feedback',
  FEELING: 'feeling',
  VISIT: 'visit',
};

export const SURVEY_SLUG = {
  ACTIVE_GENERAL_FEEDBACK: 'active_general_feedback',
  PASSIVE_GENERAL_FEEDBACK: 'passive_general_feedback',
};
