import PropTypes from 'prop-types';
import React from 'react';

import LazyImage from '../../../../reusable_components/LazyImage';
import defaultStyles from './card_image.css';

const CardImage = ({
  captureScroll,
  classList,
  component,
  fit,
  linkTo,
  loadBuffer,
  queryParams,
  ratio,
  shape,
  style,
  url,
  width,
}) => {
  const styles = { ...defaultStyles, ...classList };

  return (
    <div className={styles.container} style={{ width, ...style }}>
      {component // turn this into an Image component in sub_components
        ? component
        : (
          <a href={linkTo}>
            <LazyImage
              captureScroll={captureScroll}
              className={shape === 'circle' ? styles.circleImage : styles.image}
              fit={fit}
              loadBuffer={loadBuffer}
              queryParams={queryParams}
              ratio={ratio || '1:1'}
              src={url || ''}
              width={width}
            />
          </a>
          )}
    </div>
  );
};

CardImage.propTypes = {
  captureScroll: PropTypes.bool,
  classList: PropTypes.shape({ container: PropTypes.string }),
  component: PropTypes.element,
  fit: PropTypes.string,
  linkTo: PropTypes.string,
  loadBuffer: PropTypes.number,
  queryParams: PropTypes.object,
  ratio: PropTypes.string,
  shape: PropTypes.string,
  style: PropTypes.object,
  url: PropTypes.string,
  width: PropTypes.number.isRequired,
};

CardImage.defaultProps = {
  captureScroll: false,
  classList: {},
  component: null,
  fit: 'min',
  linkTo: '',
  loadBuffer: 0,
  queryParams: {},
  ratio: '1:1',
  shape: 'square',
  style: {},
  url: '',
};

export default CardImage;
