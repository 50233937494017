import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../../client/icon';
import AnalyticsLink from '../../../../../client/link/AnalyticsLink';

import { isBlank } from '../../../../../utility/types';

import { NEWS_ARTICLE_CARD } from '../../../../../services/keen/events/linkConstants';

import barStyles from '../../../../../client/projects/similar_projects/similar_projects_bar.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const LatestArticlesBar = ({ records }) => {
  if (isBlank(records)) return null;
  const record = records[0];

  return (
    <div className={`${layout.flexCenterItems} ${utilStyles.overflowFlexHack}`}>
      <AnalyticsLink
        className={`${barStyles.container} ${typography.link} ${utilStyles.overflowFlexHack}`}
        item={record}
        linkType={NEWS_ARTICLE_CARD}
        location="latest_articles_bar"
      >
        <div className={barStyles.text}>
          <h5 className={typography.h5}>Next article</h5>
          <div className={`${typography.bodyS} ${typography.bold} ${barStyles.link}`}>
            {record.title}
          </div>
        </div>
        <div className={layout.marginLeft15}>
          <Icon name="arrow-right" />
        </div>
      </AnalyticsLink>
    </div>
  );
};

LatestArticlesBar.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

LatestArticlesBar.defaultProps = { records: null };

export default LatestArticlesBar;
