/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import LinkList from '../link_list';

import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './mobile_nav.css';

const _shouldRenderDropdown = (item, currentDropdownKey) => item.hasOwnProperty('internalTarget') && (item.internalTarget === currentDropdownKey);

const _dropdownListView = (item, switchPanelOrDropdown, currentDropdownKey) => (
  <span
    className={`${typography.link} ${utilStyles.cursorPointer} ${styles.internalCategory} ${(currentDropdownKey === item.internalTarget) ? typography.bold : ''}`}
    onClick={(e) => switchPanelOrDropdown(e, 'dropdown', item.internalTarget)}
  >
    {item.name}
    <Icon className={(currentDropdownKey === item.internalTarget) ? styles.dropdownArrowRotate : styles.dropdownArrow} name="arrow-right" size="12" />
  </span>
);

const _externalLinkView = (item, trackLinkClick) => (
  <a
    className={typography.link}
    href={item.url}
    onClick={(e) => trackLinkClick({ e, type: 'categories' })}
  >
    {item.name}
  </a>
);

const _internalPanelView = (item, switchPanelOrDropdown) => (
  <span
    className={`${typography.link} ${utilStyles.cursorPointer} ${styles.internalCategory}`}
    onClick={(e) => switchPanelOrDropdown(e, 'panel', item.internalTarget)}
  >
    {item.name}
    <Icon name="arrow-right" size="12" />
  </span>
);

const _panelOrDropdownView = (item, currentDropdownKey, switchPanelOrDropdown, trackLinkClick) => (
  // If internalTarget exists, could be a panel (drilldown) or dropdown. Dropdowns will have an additional dropdownList.
  item.hasOwnProperty('internalTarget')
    ? item.hasOwnProperty('dropdownList')
      ? _dropdownListView(item, switchPanelOrDropdown, currentDropdownKey)
      : _internalPanelView(item, switchPanelOrDropdown)
    : _externalLinkView(item, trackLinkClick)
);

const Categories = ({ currentDropdownKey, items, switchPanelOrDropdown, trackLinkClick }) => (
  <div className={`${utilStyles.borderBottom} ${utilStyles.borderTop}`}>
    {items.map((item, i) => (
      <div key={i} className={styles.row}>
        {_panelOrDropdownView(item, currentDropdownKey, switchPanelOrDropdown, trackLinkClick)}
        {_shouldRenderDropdown(item, currentDropdownKey)
        && (
          <LinkList
            items={item.dropdownList.items}
            trackLinkClick={trackLinkClick}
            viewAllLink={item.dropdownList.viewAllLink}
          />
        )}
      </div>
    ))}
  </div>
);

Categories.propTypes = {
  currentDropdownKey: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    dropdownList: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      })).isRequired,
      viewAllLink: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
    internalTarget: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  })).isRequired,
  switchPanelOrDropdown: PropTypes.func.isRequired,
  trackLinkClick: PropTypes.func.isRequired,
};

Categories.defaultProps = { currentDropdownKey: null };

export default Categories;
