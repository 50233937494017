import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../client/icon';
import DropdownList from './DropdownList';
import SimpleSelect from '../../../client/form_components/simple_select';

import algoliaService from '../../../services/algolia';
import errorHandler from '../../../services/error_handler';
import queryStringToNormalized from '../../../services/algolia/converters/queryStringToNormalized';
import { filterSearchQuery } from '../../../services/algolia/filterSearchQuery';
import { storeSearchQuery } from '../../../services/keen/searchTracker';
import { windowInnerWidth, windowLocationRedirect, windowLocationSearch } from '../../../services/window';
import clickOutsideListener from '../../../utility/clickOutsideListener';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './search_input.css';

const CATEGORY_OPTS = [
  {
    default: true,
    label: 'Projects',
    value: 'projects',
  },
  {
    label: 'News',
    value: 'news_articles',
  },
  {
    label: 'Channels',
    value: 'channels',
  },
  {
    label: 'Contests',
    value: 'contests',
  },
  {
    label: 'Events',
    value: 'events',
  },
  {
    label: 'People',
    value: 'users',
  },
  {
    label: 'Products',
    value: 'parts',
  },
  {
    label: 'Videos',
    value: 'videos',
  },
];
const MOBILE_BREAKPOINT = 768;
const REDIRECT_SEARCH_PATH = '/search';

// NOTE: This is the main search input in the navbar. It has special logic when its mounted on the /search endpoint.
class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: CATEGORY_OPTS,
      inputFocused: false,
      selectedCategory: 'projects',
      suggestions: [],
      value: '',
    };

    this.dismissMobileView = this.dismissMobileView.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.focusFromDispatch = this.focusFromDispatch.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onCategorySelect = this.onCategorySelect.bind(this);

    this._clickHandler = null;

    // Refs.
    this.root;
    this.input;
  }

  /**
   * Lifecycle Methods
   */
  componentDidMount() {
    // Set up listeners
    this._clickHandler = clickOutsideListener(this.root, (e) => {
      if (this.state.inputFocused) this.handleInputBlur(e);
    },
    true,
    );
    algoliaService.getChannel().subscribe('queryUpdate', this.handleQueryUpdate);
    window.addEventListener('focus:ReactSearchInput', this.focusFromDispatch);

    // Handle route init logic.
    if (this.props.onSearchPath) {
      this._initializeFromSearchParams();
    }
  }

  componentWillUnmount() {
    if (this._clickHandler) this._clickHandler.remove();
    window.removeEventListener('focus:ReactSearchInput', this.focusFromDispatch);
  }

  /**
   * Initializers
   */
  _initializeFromSearchParams() {
    const queryString = windowLocationSearch.get().substring(1);
    const normalized = queryStringToNormalized(queryString);
    const query = normalized.query;

    algoliaService.enableHistoryPushState();

    if (query && query.length) {
      algoliaService.createSuggestion(query);
      this.setState({ value: query });
    }

    // Honor any search params.
    return algoliaService.initializeWithEffects(normalized, this.props.channelId)
      .then(() => algoliaService.getChannel().publish('working', false))
      .catch((err) => {
        algoliaService.getChannel().publish('working', false);
        errorHandler('Search input _initializeFromSearchParams', err);
      });
  }

  /**
   * Methods
   */
  dismissMobileView() {
    this.setState({ inputFocused: false, suggestions: [] }, () => {
      if (this.input) this.input.blur();
      this._setRootShowFlex(false);
      this._setDocumentNoScroll(false);
    });
  }

  fetchSuggestions(query) {
    return algoliaService.getSuggestions(query)
      .then((suggestions) => this.setState({ suggestions }))
      .catch((err) => errorHandler('fetchSuggestions', err));
  }

  focusFromDispatch() {
    if (this._isInMobileView()) {
      if (this.state.inputFocused) {
        this.dismissMobileView();
      } else {
        // When the input is hidden, we need to show it before we can focus it.
        if (!this.props.onSearchPath) {
          this._setRootShowFlex(true);
        }
        if (this.input) {
          this.input.focus();
        }
      }
    }
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 /* ENTER */) {
      this._handleReturnKey(e);
    } else if (e.keyCode === 27 /* ESC */ || e.keyCode === 9 /* TAB */) {
      e.preventDefault();
      this.handleInputBlur();
    } else if (this.dropdownlist && ((e.keyCode === 40 /* DOWN */ || e.keyCode === 38) /* UP */)) {
      e.preventDefault();
      this.dropdownlist.handleKeyDown(e);
    }
  }

  _handleReturnKey(e) {
    if (this.dropdownlist && this.dropdownlist.isDropdownItemFocused()) {
      this.dropdownlist.handleKeyDown(e);
    } else {
      this.handleQuery();
    }
  }

  handleOnChange(e) {
    let value = e.target.value;

    if (value.length > 255) {
      value = value.slice(0, 255);
    }

    if (value && value.length > 0 && value.split(' ').join('').length > 0) {
      this.fetchSuggestions(value);
      this.setState({ value }, () => this._handleNoScroll());
    } else {
      this.setState({ value, suggestions: [] }, () => this._handleNoScroll());
    }
  }

  handleQuery(query = this.state.value, suggestion = null) {
    this.handleInputBlur();

    // Everywhere except /search
    if (this.props.onSearchPath === false) return this._redirectWithQuery(query);

    // Logic for /search
    if (this._isInMobileView()) this.dismissMobileView();
    const queryMap = algoliaService.processQueryMap({ query });

    if (queryMap.query !== this.state.value) this.setState({ value: queryMap.query });

    if (queryMap.query.length > 0) algoliaService.createSuggestion(queryMap.query);

    return algoliaService.searchWithEffects(queryMap, suggestion, true)
      .catch((err) => errorHandler('handleQuery', err));
  }

  handleQueryUpdate(query = '') {
    if (this.state.value !== query) {
      this.setState({ value: query });
    }
  }

  _redirectWithQuery(query) {
    const cleanQuery = filterSearchQuery(query);
    storeSearchQuery(cleanQuery);
    windowLocationRedirect(algoliaService.getRedirectPath(encodeURIComponent(cleanQuery), REDIRECT_SEARCH_PATH, this.state.selectedCategory));
  }

  handleInputBlur() {
    this.setState({
      inputFocused: false,
      suggestions: [],
    }, () => {
      if (this.input) this.input.blur();
    });
  }

  // TODO: Revisit focus and blur logic
  onInputFocus() {
    this.setState({ inputFocused: true });

    if (this._isInMobileView()) {
      this._setRootShowFlex(true);
      this._handleNoScroll();
    }

    if (this.state.value.length > 0) {
      this.handleOnChange({ target: { value: this.state.value } });
    }
  }

  onCategorySelect(category) {
    this.setState({ selectedCategory: category.value }, () => {
      if (this.input) this.input.focus();
    });
  }

  /**
   * Helpers
   */
  _handleNoScroll() {
    if (!this._isInMobileView()) return;

    if (!this.props.onSearchPath) {
      this._setDocumentNoScroll(true);
    } else if (this.props.onSearchPath) {
      // On search path enable the suggestions dropdown only if we have an input value.
      if (this.state.value.length > 0) {
        this._setDocumentNoScroll(true);
      } else {
        this._setDocumentNoScroll(false);
      }
    }
  }

  _isInMobileView() {
    return windowInnerWidth() < MOBILE_BREAKPOINT;
  }

  _setRootShowFlex(doAdd) {
    if (!this.root) return;

    if (doAdd && !this.root.classList.contains('show-flex')) {
      this.root.classList.add('show-flex');
    } else if (!doAdd && this.root.classList.contains('show-flex')) {
      this.root.classList.remove('show-flex');
    }
  }

  // TODO: move this into a service/utility?
  _setDocumentNoScroll(doAdd) {
    if (!document || !document.body) return;

    if (doAdd && !document.body.classList.contains('no-scroll')) {
      document.body.classList.add('no-scroll');
    } else if (!doAdd && document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll');
    }
  }

  _shouldShowDismissX() {
    if (this.props.onSearchPath) {
      if (
        (this.state.inputFocused && this.state.suggestions.length <= 0 && this.state.value.length <= 0)
        || (this.state.inputFocused === false)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  _getContainerStyles() {
    // These styles are in a media query and are for mobile views only.
    if (this.props.onSearchPath) {
      return (this.state.value.length > 0 && this.state.inputFocused) ? `${styles.onSearchPath} ${styles.viewPortHeightMax}` : styles.onSearchPath;
    } else {
      return styles.viewPortHeightMax;
    }
  }

  render() {
    return (
      <div
        ref={(el) => this.root = el}
        className={`${styles.container} ${this._getContainerStyles()}`}
        id="react-search"
      >
        <div className={`
          ${styles.inputContainer}
          ${this.state.inputFocused ? styles.inputFocused : ''}
          ${this.state.suggestions.length > 0 ? styles.listOpen : ''}
          `.trim()}
        >
          <input
            ref={(el) => this.input = el}
            className={styles.input}
            id="searchInput"
            onChange={this.handleOnChange}
            onFocus={this.onInputFocus}
            onKeyDown={this.handleKeyDown}
            placeholder="Search"
            type="text"
            value={this.state.value}
          />

          {!this.props.onSearchPath
          && (
            <SimpleSelect
              classList={{
                label: `${typography.fontWeightNormal} ${layout.borderNone}`,
                root: `${layout.flexCenterItems} ${layout.alignSelfCenter}`,
              }}
              onSelection={this.onCategorySelect}
              options={this.state.categories}
            />
          )}

          <div className={styles.searchIconWrapper} onClick={() => this.handleQuery()} style={{ marginLeft: 15 }}>
            <Icon className={styles.searchIcon} name="search" />
          </div>

          {this.state.suggestions && this.state.suggestions.length > 0
          && (
            <DropdownList
              ref={(el) => this.dropdownlist = el}
              currentQuery={this.state.value}
              dismiss={() => this.setState({ suggestions: [] })}
              onSelect={(option) => this.setState({ value: option.value.query }, () => this.handleQuery(option.value.query, option.value))}
              options={this.state.suggestions}
              templateFn={(option) => (<span dangerouslySetInnerHTML={{ __html: option.label }} />)}
            />
          )}
        </div>
        <span
          className={`${styles.dismiss} ${this._shouldShowDismissX() ? '' : styles.hideDismiss}`}
          onClick={() => this.dismissMobileView()}
        >
          &times;
        </span>
      </div>
    );
  }
}

SearchInput.propTypes = {
  channelId: PropTypes.number,
  onSearchPath: PropTypes.bool.isRequired,
};

SearchInput.defaultProps = { channelId: null };

export default SearchInput;
