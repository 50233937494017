import request from 'superagent';
import OAuth from '../services/oauth';

export function updateUserProfile(form) {
  return OAuth.apiRequest(
    request
      .patch(`${OAuth.getApiPath()}/private/users/update_me`)
      .send(form),
    true,
  );
}
