import { Map } from 'immutable';
import searchIndex from '../searchIndex';
import { graphMutateWithoutUser } from '../../../requests/graphql';
import { getInObj } from '../../../utility/accessors';
import { escapeDoubleQuotes, pluralize } from '../../../utility/formatters';
import errorHandler from '../../error_handler';

export default class Suggestions {
  constructor() {
    this.suggestions = Map();
    this.previouslyCreated = Map();
  }

  create(q, currentIndex = 'projects') {
    if (!q || !q.length) return;
    if (this.previouslyCreated.has(q)) return;

    return searchIndex({ index: currentIndex, query: q })
      .then((res) => {
        if (res.hits && res.hits.length > 0) {
          this.previouslyCreated = this.previouslyCreated.set(q, true);

          return graphMutateWithoutUser({ t: 'create_search_query' }, { query: escapeDoubleQuotes(q) });
        }

        return Promise.resolve();
      })
      .catch((err) => errorHandler('Suggestions create', err));
  }

  get(q) {
    return new Promise((resolve, reject) => {
      if (this.suggestions.has(q)) {
        return resolve(this.suggestions.get(q));
      }

      return searchIndex({ index: 'suggestions', query: q })
        .then((res) => {
          const sugg = res.hits.map((hit) => ({
            label: (getInObj(['_highlightResult', 'query', 'value'], hit) || hit.query),
            value: { ...hit, category: pluralize(hit.category).toLowerCase() },
          }));

          this.suggestions = this.suggestions.set(q, sugg);
          resolve(sugg);
        })
        .catch((err) => reject(err));
    });
  }

  set(q, suggestions = []) {
    this.suggestions = this.suggestions.set(q, suggestions);
  }
}
