import { getInObj } from '../../../../utility/accessors';

export default function channels({
  avatar_url,
  _highlightResult,
  id,
  name,
  members_count,
  pitch,
  projects_count,
  url,
}) {
  return {
    body: {
      htmlString: getInObj(['pitch', 'value'], _highlightResult),
      plainText: pitch,
    },
    id: id,
    image: {
      linkTo: url,
      loadBuffer: 100,
      fit: 'fillmax',
      queryParams: { bg: 'fff' },
      url: avatar_url,
      width: 75,
    },
    stats: [
      { metric: members_count, text: 'Member' },
      { metric: projects_count, text: 'Project' },
    ],
    subTitle: [],
    title: {
      text: name,
      linkTo: url,
    },
  };
}
