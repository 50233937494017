/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from './header';

import Router, { history, initCurrentPath, transition } from '../../client/reusable_components/Router';
import SeoHandler from '../../services/seo_handler';

import errorHandler from '../../services/error_handler';
import smoothScroll from '../../client/utils/smoothScroll';
import { cleanPreAndPostSlashes, transitionWithSearch } from '../../client/reusable_components/Router/history';
import { clickEventHasModifierKey } from '../../utility/events';
import { graphQuery } from '../../requests/graphql';
import { getConfigForChannelType } from './channelPageConfig';

import { NOOP_HREF } from '../../constants/links';

import utilStyles from '../../styles/global_ui/util.css';

class ChannelPage extends Component {
  constructor(props) {
    super(props);

    const initPath = initCurrentPath(props.pathHelpers.fullPath, props.pathHelpers.basePath);

    this.state = {
      basePath: props.pathHelpers.basePath,
      currentHistoryData: null,
      currentPath: initPath,
      currentUser: props.blankUser,
      initPath: initPath,
      initialized: false,
      isWorking: false,
      userPermissions: {
        canManage: false,
        channelRole: null,
      },
    };

    this.getAdminLink = this.getAdminLink.bind(this);
    this.handleProductTransition = this.handleProductTransition.bind(this);
    this.handleProjectsTransition = this.handleProjectsTransition.bind(this);

    this.config = getConfigForChannelType(props.channel_type);
    this.seoHandler = new SeoHandler({ config: this.config.seoConfig });
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._init();
  }

  /**
   * Initializers
   */
  _init() {
    return graphQuery({ t: 'current_user_for_channel' }, { channel_id: this.props.channel.id })
      .then(({ user }) => {
        this.setState({
          currentUser: user,
          initialized: true,
          userPermissions: {
            canManage: user.can_manage_channel,
            channelRole: user.channel_role,
          },
        });
      })
      .catch((err) => {
        errorHandler('ChannelPage _init', err);
        this.setState({ initialized: true });
      });
  }

  /**
   * Methods
   */
  getAdminLink(label) {
    const item = this.props.channel.admin_menu.find((menuItem) => menuItem.label === label);

    return item ? item.url : NOOP_HREF;
  }

  handleProductTransition(route, partSlug, state = {}, search = '', event = null) {
    const map = {
      projects: (partSlug) => `/products/${partSlug}`,
      specs: (partSlug) => `/products/${partSlug}/specs`,
    };

    if (map.hasOwnProperty(route) && partSlug && partSlug.length) {
      const path = map[route](partSlug);

      if (event && clickEventHasModifierKey(event)) {
        const url = `${this.state.basePath}${path}`;
        window.open(url, '_blank');
      } else {
        transitionWithSearch({ path, history, search, state });
      }
    }
  }

  handleProjectsTransition(path, state = {}) {
    const parts = path.split('?');
    const search = this._processQueryStringForSort(parts[1]);
    transitionWithSearch({ path: parts[0], history, search, state });
  }

  /**
   * Helpers
   */
  _getRouteProps() {
    return {
      basePath: this.state.basePath,
      channel: this.props.channel,
      channel_type: this.props.channel_type,
      currentHistoryData: this.state.currentHistoryData,
      currentUser: this.state.currentUser,
      discussion_props: this.props.discussion_props,
      filters: this.props.filters,
      getAdminLink: this.getAdminLink,
      handleProductTransition: this.handleProductTransition,
      handleProjectsTransition: this.handleProjectsTransition,
      initialized: this.state.initialized,
      pathHelpers: this.props.pathHelpers,
      seoHandler: this.seoHandler,
      transition,
      userPermissions: this.state.userPermissions,
    };
  }

  _onRouterUpdate(pathData, action) {
    const historyData = { ...pathData, action };
    const cleanedPath = cleanPreAndPostSlashes(pathData.pathname);
    if (cleanedPath !== this.state.currentPath) {
      this.setState({ currentPath: cleanedPath, currentHistoryData: historyData }, () => this._postRouterUpdateHandler(pathData));
    } else {
      this.setState({ currentHistoryData: historyData });
    }
  }

  _postRouterUpdateHandler(pathData) {
    if (pathData.state && pathData.state.scrollTo) {
      setTimeout(() => {
        smoothScroll(document.getElementById(pathData.state.scrollTo));
      }, 50);
    }
  }

  _processQueryStringForSort(query = '') {
    if (!query.length) return 'sort=trending';

    return query.split('&').reduce((acc, part, i, list) => {
      // Quick return when we don't need to operate further.
      if (acc.hasSort === true) return acc;

      // Replace the search query and do nothing.
      if (part.split('=')[0] === 'sort') {
        acc.hasSort = true;
        acc.queryParts = query.split('&');

        return acc;
      }

      // Append a default sort if none found.
      acc.queryParts = (i === list.length - 1) ? acc.queryParts.concat([part, 'sort=trending']) : acc.queryParts.concat(part);

      return acc;
    }, { hasSort: false, queryParts: [] }).queryParts.join('&');
  }

  /**
   * Views
   */
  _getRoutes() {
    if (!this.state.initialized) return this.config.routes.filter((r) => !r.hasOwnProperty('deferred'));

    return this.config.routes.filter((r) => r.hasOwnProperty('deferred') ? r.deferred(this.state.userPermissions) : r);
  }

  _getHeader() {
    return (
      <Header
        admin_menu={this.props.channel.admin_menu}
        currentPath={this.state.currentPath}
        initPath={this.state.initPath}
        initialized={this.state.initialized}
        isWorking={this.state.isWorking}
        model={this.props.channel}
        routes={this._getRoutes()}
        transition={(route) => transition(history, route)}
        userPermissions={this.state.userPermissions}
      />
    );
  }

  render() {
    return (
      <div className={utilStyles.posRelative}>
        <Router
          basePath={this.state.basePath}
          initPath={this.state.initPath}
          onUpdate={(pathData, action) => this._onRouterUpdate(pathData, action)}
        >
          {/* need to pre-build this flat array of children in order to appease the Router component */}
          {React.Children.toArray(
            [this._getHeader()].concat(this.config.getRouteComponents(this._getRouteProps())),
          )}
        </Router>
      </div>
    );
  }
}

ChannelPage.propTypes = {
  channel: PropTypes.shape({
    about: PropTypes.string,
    admin_menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    call_to_action: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
    home_sections: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })),
    id: PropTypes.number.isRequired,
    logo_url: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    user_name: PropTypes.string.isRequired, // Used for Navbar links
    websites: PropTypes.shape({ website_link: PropTypes.string }),
  }).isRequired,
  channel_type: PropTypes.oneOf(['community', 'platform', 'topic']).isRequired,
  discussion_props: PropTypes.shape({
    origin: PropTypes.shape({
      admin_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  filters: PropTypes.shape({
    product_filters: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired,
    })),
    project_filters: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired,
    })),
  }).isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

ChannelPage.defaultProps = { home_sections: [] };

export default ChannelPage;
