const KNOWN_PARAMS = {
  mpn: (mpn) => mpn,
  part_id: (id) => parseInt(id),
  page: (page) => parseInt(page, 10),
  per_page: (per_page) => parseInt(per_page, 10),
  platform_id: (id) => parseInt(id),
  platform_user_name: (platform_user_name) => platform_user_name,
  slug: (slug) => slug,
};

const KNOWN_SORTS = ['alpha_by_name', 'owned', 'recent', 'used'];

const composeQueryParameters = (queryMap) => {
  const params = filterQueryParameters(queryMap, KNOWN_PARAMS);
  const sort = getSort(queryMap);

  return {
    ...params,
    ...sort,
  };
};

const filterQueryParameters = (queryMap, map) => {
  const keys = Object.keys(queryMap);

  if (!keys.length) return {};

  return keys.reduce((acc, key) => {
    if (map[key]) {
      acc[key] = map[key](queryMap[key]);
    }

    return acc;
  }, {});
};

function getSort(queryMap) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!queryMap.hasOwnProperty('sort') || !KNOWN_SORTS.includes(queryMap.sort)) return {};

  return { sort: queryMap.sort.toUpperCase() };
}

export default function queryBuilder(queryMap) {
  return new Promise((resolve, reject) => {
    resolve(composeQueryParameters(queryMap));
  });
}
