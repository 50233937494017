import React from 'react';
import styles from './horizontal_part_card.css';

const DummyHorizontalPartCard = ({ className }) => (
  <div className={`${styles.dummyContainer} ${className}`}>
    <div className={styles.imageContainer}>
      <div className={styles.dummyImage}></div>
    </div>
    <div className={styles.content}>
      <div className={styles.dummyHeader}></div>
      <div className={styles.dummyDescription}></div>
      <div className={styles.dummyButtons}></div>
    </div>
  </div>
);

export default DummyHorizontalPartCard;
