import PropTypes from 'prop-types';
import React from 'react';

function allowRedirect(e, onClick) {
  if (
    e.ctrlKey
    || e.shiftKey
    || e.metaKey
    || (e.button && e.button === 1)
  ) return;

  e.preventDefault();
  onClick();
}

const LinkToTab = ({ className, children, onClick, href }) => (
  <a className={className} href={href} onClick={(e) => allowRedirect(e, onClick)}>
    {children}
  </a>
);

LinkToTab.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

LinkToTab.defaultProps = {
  className: '',
  href: 'javascript:void(0)',
  onClick: () => {},
};

export default LinkToTab;
