import React from 'react';
import PropTypes from 'prop-types';

// HOC
import adAnalyticsTracker from '../wrappers/adAnalyticsTracker';

import Image from '../../image';
import { adFAQLink } from '../../../constants/links';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './news_gallery_ad.css';

const NewsGalleryAd = ({ ad, forwardedRef, initialized, onClick, onImageLoad }) => {
  if (!initialized || ad === null) return null;
  const { client, cover_image, link, text } = ad;

  return (
    <div ref={forwardedRef} className={styles.container}>
      <div className={styles.imageContainer}>
        <a
          href={link}
          onClick={() => onClick(ad)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            className={`${styles.standardImage} ${utilStyles.borderRadius}`}
            lazy={true}
            onLoad={() => onImageLoad(ad)}
            ratio="16:9"
            setStyle={false}
            src={cover_image.url}
            width={270}
          />
          <Image
            className={`${styles.mobileImage} ${utilStyles.borderRadius}`}
            lazy={true}
            onLoad={() => onImageLoad(ad)}
            ratio="1:1"
            setStyle={false}
            src={cover_image.url}
            width={270}
          />
        </a>
      </div>

      <div className={`${styles.textContainer} ${layout.flexColumn}`}>
        <a
          className={`${typography.bodyM} ${typography.bold} ${typography.breakWord} ${typography.link} ${typography.linkCharcoal}`}
          href={link}
          onClick={() => onClick(ad)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {text}
        </a>
        <div className={`${styles.subTextContainer} ${layout.flexJustifySpaceBetween} ${typography.bodyS}`}>
          <div>
            Sponsored by
            {client}
          </div>
          <div className={`${styles.linkContainer}`}>
            <a
              className={`${typography.link} ${typography.textRight}`}
              href={adFAQLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Ad
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

NewsGalleryAd.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ad_type: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  initialized: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onImageLoad: PropTypes.func.isRequired,
};

NewsGalleryAd.defaultProps = { ad: null };

export default adAnalyticsTracker(NewsGalleryAd);
