// DEPRECATED: 02-14-2023
// Note: Used in 'RecommendedResults' component, which shows on project search. Doesn't look like algolia ever provides event reccs.
import React from 'react';
import PropTypes from 'prop-types';

import { Body, SubTitle, Title } from '../../sub_components';

import { timestampToPrettyDate } from '../../../../../utility/time';

import styles from './vertical.css';

const Event = ({ body, dateString, image, subTitle, title, location }) => {
  const dayOfMonth = timestampToPrettyDate(dateString, { day: 'numeric' }, false, null);
  const month = timestampToPrettyDate(dateString, { month: 'short' }, false, null);

  return (
    <div className={styles.eventCard}>
      <div className={styles.eventDate}>
        <span>{month}</span>
        <div>{dayOfMonth}</div>
      </div>
      <div>
        <Title {...title} />
        <SubTitle content={subTitle} />
        <Body>
          {location}
        </Body>
      </div>
    </div>
  );
};

Event.propTypes = {
  body: PropTypes.shape({
    component: PropTypes.element,
    htmlString: PropTypes.string,
    type: PropTypes.string,
  }),
  id: PropTypes.string,
  image: PropTypes.shape({
    linkTo: PropTypes.string,
    shape: PropTypes.string,
    url: PropTypes.string,
  }),
  subTitle: PropTypes.arrayOf(PropTypes.shape({
    linkTo: PropTypes.string,
    text: PropTypes.string,
  })),
  title: PropTypes.shape({
    linkTo: PropTypes.string,
    text: PropTypes.string,
  }),
};

Event.defaultProps = {}; // fill out default props

export default Event;
