function updateDialogRecord(id, record) {
  if (this.state.dialog && this.state.dialog.props) {
    this.setState({
      dialog: {
        ...this.state.dialog,
        props: {
          ...this.state.dialog.props,
          records: this.state.dialog.props.records.map((r) => r.id === id ? record : r),
        },
      },
    });
  }
}

function updatePopoverProps(id, resource, record) {
  const compoundKey = this._compoundKey(id, resource);

  if (this.state.popover.id && this.state.popover.id === compoundKey) {
    this.setState({
      popover: {
        ...this.state.popover,
        props: {
          ...this.state.popover.props,
          ...record,
        },
      },
    });
  }
}

function updateProfileStats(store, path, createOrDeleteBool, resource) {
  const profileStats = store.get('profileStats');
  store.setIn(path, createOrDeleteBool ? profileStats[resource] + 1 : Math.max(profileStats[resource] - 1, 0));
  this.forceUpdate();
}

export function updateCommunityFollowing(id, resource, path, createOrDeleteBool, store) {
  const record = store.getIn(path);

  if (!record.stats) return;

  const updatedRecord = {
    ...record,
    stats: { ...record.stats, members: createOrDeleteBool ? record.stats.members + 1 : Math.max(record.stats.members - 1, 0) },
  };

  store.setIn(path, updatedRecord);
  updateDialogRecord.call(this, id, updatedRecord);
  updatePopoverProps.call(this, id, resource, updatedRecord);
}

export function updateProjectRespects(hid, resource, path, createOrDeleteBool, store) {
  const record = store.getIn(['allProjects', hid]);
  const updatedRecord = {
    ...record,
    stats: { ...record.stats, respects: createOrDeleteBool ? record.stats.respects + 1 : Math.max(record.stats.respects - 1, 0) },
  };

  store.setIn(path, updatedRecord);
  store.setIn(['allProjects', hid], updatedRecord);

  updateDialogRecord.call(this, hid, updatedRecord);
  updatePopoverProps.call(this, hid, resource, updatedRecord);
  this.forceUpdate();
}

export function updateUserFollowers(path, createOrDeleteBool, store) {
  updateProfileStats.call(this, store, path, createOrDeleteBool, 'followers');

  if (store.getIn(['followers', 'records']).length > 0) {
    const currentUserId = this.state.currentUser.id;
    createOrDeleteBool
      ? this.fetchUser(currentUserId, ['followers', 'records'], store)
      : store.deleteIn(['followers', 'records', ['id', currentUserId]]);
  }
}

export function updateUserFollowing(id, path, createOrDeleteBool, store) {
  updateProfileStats.call(this, store, path, createOrDeleteBool, 'following');
  const isStorePopulated = store.getIn(['following', 'records']).length > 0;

  if (isStorePopulated && this.state.dialog.resource !== 'following') {
    createOrDeleteBool
      ? this.fetchUser(id, ['following', 'records'], store)
      : store.deleteIn(['following', 'records', ['id', id]]);
  }
}
