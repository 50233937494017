import React from 'react';

import LazyImage from '../../../client/reusable_components/LazyImage';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './channels.css';

const c = {
  section: `${layout.flexColumn} ${layout.gutter30}`,
  headingP: `${typography.bodyM} ${typography.staticCharcoal}`,
  listWrapper: `${layout.flexJustifySpaceBetween} ${typography.staticCharcoal} ${styles.listsWrapper}`,
  p: `${typography.bodyM} ${typography.bold} ${typography.staticCharcoal} ${layout.marginBottom10}`,
  ul: `${typography.bodyM} ${typography.staticCharcoal}`,
  imageWrapper: `${layout.flexJustifySpaceBetween} ${layout.flexCenterItems} ${layout.gutter30} ${styles.imageWrapper}`,
  image: `${util.bgTransparent} ${layout.heightAuto}`,
};

const Channels = () => (
  <section className={c.section}>
    <p className={c.headingP}>
      Platform and Community Hubs are the quickest and most effective way to begin engaging the global Hackster community around products or shared interests within your own branded space. Post announcements, tutorials from your team, engineering resources, or any other content of your choosing. Think of a hub as your dedicated page on Hackster.io. The best part? Hubs are 100% free and 100% self-managed. There&apos;s no need to go through a Hackster admin to promote your latest content or directly engage with developers.
    </p>
    <div className={c.listWrapper}>
      <div>
        <p className={c.p}>Hub Features and Capabilities:</p>
        <ul className={c.ul}>
          <li>Home tab with customizable sections to highlight content</li>
          <li>Discussion board</li>
          <li>Products catalog</li>
          <li>Project gallery</li>
          <li>Intuitive admin interface</li>
        </ul>
      </div>
      <div>
        <p className={c.p}>Why Start a Hub?</p>
        <ul className={c.ul}>
          <li>Create a branded channel for your platform or interest group</li>
          <li>Connect with your users and members</li>
          <li>Grow your follower base</li>
          <li>Gain insights on what developers are doing with your products</li>
        </ul>
      </div>
    </div>
    <div className={c.imageWrapper}>
      <LazyImage
        alt="NXP hover games contest"
        classList={{ root: c.image }}
        fit="fill"
        ratio="690:712"
        src="https://hackster.imgix.net/static/marketing/content/edge_impulse.png"
        width={345}
      />
      <LazyImage
        alt="Example of branded channels on Hackster.io"
        classList={{ root: c.image }}
        fit="fill"
        ratio="1180:652"
        src="https://hackster.imgix.net/static/marketing/content/branded_channels_cards.png"
        width={600}
      />
    </div>
  </section>
);

export default Channels;
