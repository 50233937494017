import React, { useCallback, useState } from 'react';

import EventCardHorizontalSmall from '../../client/cards/event_card/EventCardHorizontalSmall.js';
import GridList from '../../client/wrappers/grid_list';
import LazyComponent from '../../client/wrappers/lazy_component';
import errorHandler from '../../services/error_handler';
import { graphQuery } from '../../requests/graphql.js';

import gridStyles from '../../styles/global_ui/grid.css';

const EventSection = () => {
  const [events, setEvents] = useState();

  const eventReveal = useCallback(() => {
    // TODO: we don't have a way to determine most active?
    graphQuery({ t: 'events_with_simple_pagination' }, { by_approved: true, per_page: 2, sort: 'START_DESC' })
      .then(({ events }) => setEvents(events))
      .catch((err) => errorHandler('eventReveal', err));
  }, []);

  return (
    <LazyComponent onReveal={eventReveal}>
      <GridList
        ItemComponent={EventCardHorizontalSmall}
        className={`${gridStyles.guttersH15_sm} ${gridStyles.guttersV15_sm}`}
        gutterSize={15}
        maxCols={2}
        records={events?.records}
      />
    </LazyComponent>
  );
};

export default EventSection;
