import PropTypes from 'prop-types';
import React from 'react';

import RadioGroup from '../../../client/form_components/inputs/radio_group';

import { isBlacklistedWhitelabelIndex, indexes, indexToLabel, whitelabelIndexes } from '../algoliaIndexes';
import { isNumber } from '../../../utility/types';

import inputStyles from '../../../styles/global_ui/inputs.css';
import utilStyles from '../../../styles/global_ui/util.css';

function getButtons(channelId) {
  const buttons = isNumber(channelId)
    ? whitelabelIndexes.filter((index) => !isBlacklistedWhitelabelIndex(channelId, index))
    : indexes;

  return buttons.map((index) => ({ label: indexToLabel(index), value: index }));
}

const IndexSetting = ({ channelId, disabled, index, mobile, onChange }) => (
  <section>
    <header className={`${inputStyles.label} ${disabled ? utilStyles.disabled : ''}`}>
      Show results for
    </header>
    <RadioGroup
      buttons={getButtons(channelId)}
      disabled={disabled}
      name={`index-${mobile ? 'mobile' : 'desktop'}`}
      onChange={onChange}
      value={index}
    />
  </section>
);

IndexSetting.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  onChange: PropTypes.func,
};

IndexSetting.defaultProps = {
  disabled: false,
  mobile: false,
  onChange: () => {},
};

export default IndexSetting;
