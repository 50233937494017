import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../link/AnalyticsLink';
import LazyBackgroundImage from '../../../image/LazyBackgroundImage';

import { getInObj } from '../../../../utility/accessors';
import { paddingClassForRatio } from '../baseCardHelpers';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './base_card_vertical.css';

// NOTE: The reason to conditionally render here is to reduce the amount of elements on the page.
const _getImageView = (props) => getInObj(['mobile_image', 'url'], props.item) !== null ? WithMobileImageView(props) : DefaultImageView(props);

const DefaultImageView = ({ imageAspectRatio, item }) => (
  <LazyBackgroundImage
    alt={item.title}
    classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}` }}
    ratio={imageAspectRatio}
    setStyle={false}
    src={getInObj(['image', 'url'], item)}
    width={270}
  />
);

const WithMobileImageView = ({ imageAspectRatio, item }) => (
  <Fragment>
    {/* Mobile image view */}
    <div className={layout.hiddenSmallUp}>
      <LazyBackgroundImage
        alt={item.title}
        classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}` }}
        ratio="1:1"
        setStyle={false}
        src={getInObj(['mobile_image', 'url'], item)}
        width={270}
      />
    </div>

    {/* Default view */}
    <div className={layout.hiddenSmallDown}>
      <DefaultImageView imageAspectRatio={imageAspectRatio} item={item} />
    </div>
  </Fragment>
);

const BaseCardVertical = ({ className, item, imageAspectRatio, linkProps, renderAfterImage, renderBody, responsive }) => (
  <div className={`${responsive ? styles.cardResponsive : ''} ${layout.flexColumn} ${layout.fullWidth} ${className}`}>
    <div className={`${responsive ? styles.imageResponsive : ''} ${layout.fullWidth} ${utilStyles.absolutePlaceholderParent} ${paddingClassForRatio(imageAspectRatio)}`}>
      <AnalyticsLink item={item} {...linkProps}>
        {_getImageView({ imageAspectRatio, item })}
      </AnalyticsLink>
      {renderAfterImage()}
    </div>
    <div className={`${responsive ? styles.textResponsive : ''} ${layout.marginTop22}`}>
      {renderBody()}
    </div>
  </div>
);

BaseCardVertical.propTypes = {
  className: PropTypes.string,
  imageAspectRatio: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.shape({ url: PropTypes.string }),
    mobile_image: PropTypes.shape({ url: PropTypes.string }),
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
  linkProps: PropTypes.shape({ linkType: PropTypes.string.isRequired }).isRequired,
  renderAfterImage: PropTypes.func,
  renderBody: PropTypes.func,
  responsive: PropTypes.bool,
};

BaseCardVertical.defaultProps = {
  className: '',
  imageAspectRatio: '4:3',
  renderAfterImage: () => null,
  renderBody: () => null,
  responsive: false,
};

export default BaseCardVertical;
