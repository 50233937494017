import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DummyPlatformMemberList from '../../../client/user/platform_member_list/DummyPlatformMemberList';
import PlatformMemberList from '../../../client/user/platform_member_list';
import GraphQLPaginatedList from '../../../client/wrappers/graph_ql/GraphQLPaginatedList';
import GraphQLMembersService from '../../../services/graphql/members_service';

import { FILTERS } from './filters';

import layoutStyles from '../../../styles/global_ui/layout.css';

class Members extends Component {
  constructor(props) {
    super(props);

    this.handleQuery = this.handleQuery.bind(this);
    this.graphQLService = new GraphQLMembersService({ history: props.history, type: props.graphQLServiceType });
  }

  handleQuery() {
    this.props.seoHandler(this.props.path);
  }

  render() {
    return (
      <div className={layoutStyles.container}>
        <div className={`${layoutStyles.wrapper1170} ${layoutStyles.fullScreenHeight}`}>
          <GraphQLPaginatedList
            currentHistoryData={this.props.currentHistoryData}
            filters={FILTERS}
            graphQLArguments={this.props.graphQLArguments}
            graphQLService={this.graphQLService}
            listComponent={PlatformMemberList}
            listProps={{}}
            loaderComponent={DummyPlatformMemberList}
            propagateQuery={this.handleQuery}
          />
        </div>
      </div>
    );
  }
}

Members.propTypes = {
  currentHistoryData: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  graphQLArguments: PropTypes.object,
  graphQLServiceType: PropTypes.string.isRequired,
  history: PropTypes.object, // Router history
  path: PropTypes.string.isRequired,
  seoHandler: PropTypes.func.isRequired,
};

Members.defaultProps = {
  currentHistoryData: null,
  graphQLArguments: {},
  history: {},
};

export default Members;
