import React from 'react';
import PropTypes from 'prop-types';

import isTouchDevice from '../../../utility/isTouchDevice';

// TODO: get new edit icon
import Pencil from '../../reusable_components/icons/Pencil';

import styles from '../project_edit_card/project_edit_card.css';

const ActionsOverlay = ({ editLink }) => (
  <div className={isTouchDevice() ? styles.actionsOverlayAlwaysOn : styles.actionsOverlay}>
    <div className={styles.transparency} />
    <div className={styles.actions}>
      <a className={styles.action} href={editLink}>
        <Pencil className={styles.buttonIcon} />
      </a>
    </div>
  </div>
);

ActionsOverlay.propTypes = { editLink: PropTypes.string.isRequired };

export default ActionsOverlay;
