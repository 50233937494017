import React from 'react';
import PropTypes from 'prop-types';

import FilterLabel from './Filters/FilterLabel';
import RadioGroup from '../../../client/form_components/inputs/radio_group';

const sortOptions = {
  channels: [
    { label: 'Relevance', value: 'relevance' },
    { label: 'A-Z', value: 'a_z' },
    { label: 'Last updated', value: 'last_updated' },
    { label: 'Most projects', value: 'most_projects' },
    // {label: 'Most members', value: 'most_members'},
  ],
  contests: null,
  events: [
    { label: 'Relevance', value: 'relevance' },
    { label: 'Earliest first', value: 'earliest_first' },
    { label: 'Latest first', value: 'latest_first' },
  ],
  news_articles: [
    { label: 'Relevance', value: 'relevance' },
    { label: 'Most recent', value: 'most_recent' },
    { label: 'Most respected', value: 'most_respected' },
  ],
  parts: null,
  projects: [
    { label: 'Relevance', value: 'relevance' },
    { label: 'Most recent', value: 'most_recent' },
    // {label: 'Most popular', value: 'most_popular'},
    { label: 'Most respected', value: 'most_respected' },
    { label: 'Trending', value: 'trending' },
  ],
  users: null,
  videos: [
    { label: 'Relevance', value: 'relevance' },
    { label: 'View count', value: 'most_popular' },
  ],
};

const Sort = ({ disabled, index, mobile, onChange, sort }) => {
  if (!sortOptions[index]) return null;

  return (
    <RadioGroup
      buttons={sortOptions[index]}
      disabled={disabled}
      label={<FilterLabel disabled={disabled} label="Sort by" />}
      name={`sort-${mobile ? 'mobile' : 'desktop'}`}
      onChange={onChange}
      value={sort}
    />
  );
};

Sort.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  onChange: PropTypes.func,
  sort: PropTypes.string.isRequired,
};

Sort.defaultProps = {
  disabled: false,
  mobile: false,
  onChange: () => {},
};

export default Sort;
