import PropTypes from 'prop-types';
import React from 'react';

import HorizontalCard from '../../../client/cards/search/components/Horizontal';
import RecommendedResults from './RecommendedResults';

import * as contentMap from './contentTypes';
import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';

const List = ({
  algoliaIndex,
  appliedFilters,
  currentQuery,
  insertItems,
  insertIndex,
  isWhitelabel,
  results,
  recommendedIndex,
  setIndex,
}) => {
  const list = insertItems.length
    ? [...results.slice(0, insertIndex), insertItems, ...results.slice(insertIndex)]
    : results;

  return (
    <ul className={`${layout.marginBottom30} ${utilStyles.ulReset}`}>
      {list.map((result, i) => (
        <li key={`${i}-${result.id}`} className={layout.marginTop30}>
          {Array.isArray(result)
            ? (
              <RecommendedResults
                currentQuery={currentQuery}
                recommendedIndex={recommendedIndex}
                results={result}
                setIndex={setIndex}
              />
              )
            : <HorizontalCard {...contentMap[algoliaIndex](result, { appliedFilters, isWhitelabel })} />}
        </li>
      ))}
    </ul>
  );
};

export default List;

List.propTypes = {
  algoliaIndex: PropTypes.string.isRequired,
  appliedFilters: PropTypes.object,
  currentQuery: PropTypes.string.isRequired,
  insertIndex: PropTypes.number.isRequired,
  insertItems: PropTypes.array,
  isWhitelabel: PropTypes.bool,
  recommendedIndex: PropTypes.string,
  results: PropTypes.array,
  setIndex: PropTypes.func.isRequired,
};

List.defaultProps = {
  appliedFilters: {},
  insertItems: [],
  isWhitelabel: false,
  results: [],
  recommendedIndex: '',
};
