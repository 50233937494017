import { DEFAULT_PAGE_DESCRIPTION, DEFAULT_PAGE_TITLE } from '../../constants/pageMetadata';

const CONFIG = {
  '/ideas': (challenge) => ({
    title: `${challenge.name} ideas`,
    meta_desc: DEFAULT_PAGE_DESCRIPTION,
    pageType: 'challenge_ideas#index',
    entity_id: challenge.id,
  }),
  '/ideas/*': (idea) => ({
    title: DEFAULT_PAGE_TITLE,
    meta_desc: DEFAULT_PAGE_DESCRIPTION,
    pageType: 'challenge_ideas#show',
    entity_id: idea.id,
  }),
};

const seoConfig = (path) => CONFIG[path];

export default seoConfig;
