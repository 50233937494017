import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ButtonDropdown from '../../../client/buttons/button_dropdown';
import Dropdown from './Dropdown';
import Icon from '../../../client/icon';
import OnboardingNotification from './OnboardingNotification';
import TooltipTarget from '../../../client/reusable_components/TooltipTarget';

import currentUserService from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';
import keenService from '../../../services/keen/main';
import notificationsStore from '../../../services/notifications_store';
import { graphMutate } from '../../../requests/graphql';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './notification_dropdown.css';

const blankStore = { metadata: {}, records: {} };

// NOTE: All the async code here should be rewritten. The Promises are poorly handled, even at the NotificationStore level.
class NotificationDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.resetNewCount = this.resetNewCount.bind(this);

    const notifications = notificationsStore.has('notifications')
      ? notificationsStore.get('notifications')
      : blankStore;

    this.state = {
      newCount: props.newNotificationsCount,
      notifications,
      showDropdown: false,
      showOnboarding: false,
    };

    this.updateSub = notificationsStore.getChannel().subscribe('notifications.markRead.#', this.handleChange);
  }

  componentDidMount() {
    currentUserService.getCurrentOnboardingStatus()
      .then((isComplete) => this.setState({ showOnboarding: !isComplete }))
      .catch((err) => errorHandler(err));
  }

  componentWillUnmount() {
    this.updateSub.unsubscribe();
  }

  // When the Icon is cliked, fetched the first 10 records and reset the Counter.
  fetchNotifications() {
    return notificationsStore.fetchPage(1, 'FOR_UNREAD')
      .then(() => {
        this.setState({ notifications: notificationsStore.get('notifications') });

        return this.resetNewCount();
      })
      .catch((err) => errorHandler(err));
  }

  handleChange() {
    const notifications = notificationsStore.get('notifications') || blankStore;
    this.setState({ notifications });
  }

  resetNewCount() {
    if (this.state.newCount === 0) return Promise.resolve();

    const oldCount = this.state.newCount;
    this.setState({ newCount: 0 });

    return graphMutate({ t: 'update_notifications_reset' })
      .then((res) => {})
      .catch((err) => {
        this.setState({ newCount: oldCount });
        errorHandler('resetNewCount Error:', err);
      });
  }

  handleToggle(showDropdown) {
    if (showDropdown) {
      this.fetchNotifications();
      keenService.recordEvent({ eventName: 'Clicked link' }, { href: '', location: 'topnav', type: 'notifications-icon', value: 'Bell icon' });
    }
    this.setState({ showDropdown });
  }

  /**
   * Views
   */
  _getButton() {
    const { newCount, showDropdown, showOnboarding } = this.state;
    const badgeCount = showOnboarding ? (newCount + 1) : newCount;

    return (
      <TooltipTarget classList={{ tooltipInner: typography.lineHeight1 }} offsetY={6} tooltip={showDropdown ? null : 'Notifications'}>
        <div className={`${styles.icon}`}>
          <Icon name="notification" size="20" />
          {badgeCount > 0 && <div className={styles.badge}>{badgeCount.toString()}</div>}
        </div>
      </TooltipTarget>
    );
  }

  _getDropdownBody() {
    const { notifications, showOnboarding } = this.state;
    const { currentUserId } = this.props;

    return (
      <div>
        {showOnboarding && <OnboardingNotification />}
        <Dropdown
          currentUserId={currentUserId}
          isLoading={!Array.isArray(notifications.records[notificationsStore.getPageKey(1)])}
          markAllRead={notificationsStore.markAllRead}
          markRead={notificationsStore.markRead}
          notifications={notifications.records[notificationsStore.getPageKey(1)] || []}
        />
      </div>
    );
  }

  render() {
    return (
      <ButtonDropdown
        alignRight={true}
        classList={{ button: buttonStyles.blank, dropdown: styles.dropdown, wrapper: typography.lineHeight0 }}
        handleToggle={this.handleToggle}
        label={this._getButton()}
        targetWillUnmount={true}
      >
        {this.state.showDropdown && this._getDropdownBody()}
      </ButtonDropdown>
    );
  }
}

NotificationDropdown.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  newNotificationsCount: PropTypes.number,
};

NotificationDropdown.defaultProps = { newNotificationsCount: 0 };

export default NotificationDropdown;
