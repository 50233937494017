import React from 'react';
import PropTypes from 'prop-types';

import NewsArticleFeaturedCard from '../../../client/cards/news_article/featured_card';

import styles from './news_home_page.css';

const FeaturedSection = ({ records }) => (
  <div className={styles.featuredSection}>
    {records.map((record, i) => (
      <NewsArticleFeaturedCard
        key={record.id}
        article={record}
        isHero={i === 0}
        location={`news_article_featured_card-${i}`}
      />
    ))}
  </div>
);

FeaturedSection.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    summary: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

FeaturedSection.defaultProps = {};

export default FeaturedSection;
