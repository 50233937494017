import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

/* Based off CSSTransition (http://reactcommunity.org/react-transition-group/css-transition)
 * except using style prop instead of CSS classes. This is for transitions where
 * we might want to dynamically set style based on current conditions, or if we want to
 * customize/tweak default style values, but not write a whole new set of CSS classes
 * for each variation.
 * TODO: set CSS classes AND style for each state?
 * Note: the child of this Transition component must make use of the style prop for it to work
 */
class StyleTransition extends Component {
  static contextTypes = { transitionGroup: PropTypes.object };

  constructor(props, context) {
    super(props, context);

    this.state = { style: props.getStyle(null, this._initStatus(props, context)) };

    this.onEnter = this.onEnter.bind(this);
    this.onEntering = this.onEntering.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.onExit = this.onExit.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  // https://github.com/reactjs/react-transition-group/blob/master/src/Transition.js
  _initStatus(props, context) {
    const parentGroup = context.transitionGroup;

    // In the context of a TransitionGroup all enters are really appears
    const appear = parentGroup && !parentGroup.isMounting ? props.enter : props.appear;

    if (props.in) {
      if (appear) {
        return EXITED;
      } else {
        return ENTERED;
      }
    } else {
      if (props.unmountOnExit || props.mountOnEnter) {
        return UNMOUNTED;
      } else {
        return EXITED;
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onEnter(node, appearing) {
    this._safelySetState({ style: this.props.getStyle(node, ENTER, appearing) });

    if (this.props.onEnter) {
      this.props.onEnter(node, appearing);
    }
  }

  onEntering(node, appearing) {
    this._forceReflow(node);
    this._safelySetState({ style: this.props.getStyle(node, ENTERING, appearing) });

    if (this.props.onEntering) {
      this.props.onEntering(node, appearing);
    }
  }

  onEntered(node, appearing) {
    this._safelySetState({ style: this.props.getStyle(node, ENTERED, appearing) });

    if (this.props.onEntered) {
      this.props.onEntered(node, appearing);
    }
  }

  onExit(node) {
    this._safelySetState({ style: this.props.getStyle(node, EXIT) });

    if (this.props.onExit) {
      this.props.onExit(node);
    }
  }

  onExiting(node) {
    this._forceReflow(node);
    this._safelySetState({ style: this.props.getStyle(node, EXITING) });

    if (this.props.onExiting) {
      this.props.onExiting(node);
    }
  }

  onExited(node) {
    this._safelySetState({ style: this.props.getStyle(node, EXITED) });
    if (this.props.onExited) {
      this.props.onExited(node);
    }
  }

  _forceReflow(node) {
    // Trick used by CSSTransition (https://github.com/reactjs/react-transition-group/blob/master/src/CSSTransition.js)
    return node && node.scrollTop;
  }

  _safelySetState(state, cb) {
    if (this._isMounted) this.setState(state, cb);
  }

  render() {
    const {getStyle, ...transitionProps} = this.props; // eslint-disable-line

    return (
      <Transition
        {...transitionProps}
        onEnter={this.onEnter}
        onEntered={this.onEntered}
        onEntering={this.onEntering}
        onExit={this.onExit}
        onExited={this.onExited}
        onExiting={this.onExiting}
        style={this.state.style}
      />
    );
  }
}

StyleTransition.propTypes = {
  ...Transition.propTypes,
  getStyle: PropTypes.func.isRequired,
};

StyleTransition.defaultProps = Transition.defaultProps;

export default StyleTransition;
export const ENTER = 'enter';
export const ENTERING = 'entering';
export const ENTERED = 'entered';
export const EXIT = 'exit';
export const EXITING = 'exiting';
export const EXITED = 'exited';
export const UNMOUNTED = 'unmounted';
