export const FILTERS = [
  {
    key: 'sort',
    options: [
      {
        label: 'Alphabetically',
        value: 'alpha',
      },
      {
        default: true,
        label: 'Most active',
        value: null,
      },
      {
        label: 'Most followers',
        value: 'followers',
      },
      {
        label: 'Most projects',
        value: 'projects',
      },
      {
        label: 'Most recently joined',
        value: 'recent',
      },
    ],
  },
];
