import React from 'react';
import { happy } from './rating_svgs';

const RobotHappy = () => (
  <div
    dangerouslySetInnerHTML={{ __html: happy }}
  />
);

export default RobotHappy;
