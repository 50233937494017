import PropTypes from 'prop-types';
import React from 'react';

import ContestsList from './ContestsList';
import EventsList from './EventsList';
import List from './List';
import NoResults from './NoResults';
import ResultsHeader from './ResultsHeader';
import VideosList from './VideosList';

import algoliaService from '../../../services/algolia';

import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';
import typography from '../../../styles/global_ui/typography.css';

const DEFAULT_INDEX = 'projects';
const INSERT_RECOMMENDED_RESULTS_ON = 4;

function setIndex(index) {
  algoliaService.publishEvent('setIndex', index);
}

const _getListView = (props) => {
  if (props.algoliaIndex === 'contests') return (<ContestsList results={props.results} />);
  if (props.algoliaIndex === 'events') return (<EventsList results={props.results} />);
  if (props.algoliaIndex === 'videos') return (<VideosList results={props.results} />);

  return (
    <List
      algoliaIndex={props.algoliaIndex}
      appliedFilters={props.appliedFilters}
      currentQuery={props.currentQuery}
      insertIndex={INSERT_RECOMMENDED_RESULTS_ON}
      insertItems={props.insertItems}
      isWhitelabel={props.isWhitelabel}
      recommendedIndex={props.recommendedIndex}
      results={props.results}
      setIndex={setIndex}
    />
  );
};

const _getResultsView = (props) => {
  if (props.loading) return null;
  if (props.totalRecords) return _getListView(props);

  return (
    <NoResults
      algoliaIndex={props.algoliaIndex}
      currentQuery={props.currentQuery}
      isWhitelabel={props.isWhitelabel}
      setIndex={setIndex}
    />
  );
};

const _processRecommendations = ({ recommendedResults, algoliaIndex }) => {
  if (!recommendedResults || algoliaIndex !== DEFAULT_INDEX) {
    return { recommendedIndex: null, insertItems: [] };
  }

  return {
    recommendedIndex: recommendedResults.index || null,
    insertItems: recommendedResults.results || [],
  };
};

const ResultsList = (props) => {
  const appliedFilters = props.settings.filters || {};
  const { recommendedIndex, insertItems } = _processRecommendations(props);

  return (
    <div className={`${layout.posRelative} ${utilStyles.bgWhite} ${typography.bodyM}`}>
      <ResultsHeader
        algoliaIndex={props.algoliaIndex}
        appliedFilters={appliedFilters}
        currentQuery={props.currentQuery}
        isWhitelabel={props.isWhitelabel}
        loading={props.loading}
        toggleFilterPanel={props.toggleFilterPanel}
        totalRecords={props.totalRecords}
      />
      {_getResultsView({ ...props, appliedFilters, insertItems, recommendedIndex })}
    </div>
  );
};

ResultsList.propTypes = {
  algoliaIndex: PropTypes.string.isRequired,
  currentQuery: PropTypes.string.isRequired,
  isWhitelabel: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  pageKey: PropTypes.string.isRequired,
  recommendedResults: PropTypes.shape({
    index: PropTypes.string,
    results: PropTypes.array,
  }),
  results: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  toggleFilterPanel: PropTypes.func.isRequired,
  totalRecords: PropTypes.number,
};

ResultsList.defaultProps = {
  algoliaIndex: '',
  isWhitelabel: false,
  loading: false,
  recommendedResults: {},
};

export default ResultsList;
