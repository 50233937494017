import errorHandler from '../../services/error_handler';
import postal from 'postal';
import { graphQueryWithUser, graphMutate } from '../../requests/graphql';

class NotificationsStore {
  constructor() {
    this.initStore = this.initStore.bind(this);

    this.fetchPage = this.fetchPage.bind(this);
    this.markAllRead = this.markAllRead.bind(this);
    this.markRead = this.markRead.bind(this);

    this.__refresh__ = this.__refresh__.bind(this);
    this.__setProperty__ = this.__setProperty__.bind(this);

    this.channel = postal.channel('notifications');
    this._initializedAt = null; // for pagination simplicity for now, don't ever fetch any notifications newer than initialization
    this._isInitialized = false;
    this.store = {
      notifications: {
        metadata: {},
        records: {},
      },
    };
  }

  /*****************************************************************************
 * Initialize
 ****************************************************************************/
  initStore() {
    if (!this._isInitialized) {
      this._isInitialized = true;
      this._initializedAt = new Date().toISOString();
    }
  }

  /*****************************************************************************
 * Requests
 ****************************************************************************/
  fetchPage(pageNum, query) {
    const pageKey = this.getPageKey(pageNum);
    if (Array.isArray(this.store.notifications.records[pageKey])) {
      return Promise.resolve();
    }

    // TODO: The created_before argument is likely used because we are caching the notifications in memory as well as handling the
    // delayed matview that was deprecated. If we rewrite this store, remove the page caching and the argument from the requests.
    // Make sure to look into the Dropdown request as well when / if this changes.
    return graphQueryWithUser({ t: 'get_notifications' }, {
      page: pageNum,
      per_page: 10,
      created_before: this._initializedAt,
      query: query,
    })
      .then((res) => {
        this.store = {
          ...this.store,
          notifications: {
            metadata: { ...res.notifications.metadata },
            records: {
              ...this.store.notifications.records,
              [pageKey]: res.notifications.records,
            },
          },
        };
        this.initStore();
        this._storeChanged();
      })
      .catch((err) => Promise.reject(err));
  }

  markAllRead() {
    const oldStore = this.store; // save to catch optimistic loading error
    const oldRecords = oldStore.notifications.records;

    const newRecords = Object.keys(oldRecords).reduce((acc, key) => (
      { ...acc, [key]: oldRecords[key].map((record) => ({ ...record, read: true })) }
    ), {});

    this.store = {
      ...oldStore,
      notifications: {
        ...oldStore.notifications,
        records: newRecords,
      },
    };

    this._storeChanged('notifications.markRead.all.optimistic');

    return graphMutate({ t: 'update_notifications_read' }, { receipt_ids: [], all: true, read: true })
      .then((res) => {
        this._storeChanged('notifications.markRead.all.success');
      })
      .catch((err) => {
        this.store = oldStore;
        this._storeChanged('notifications.markRead.all.failure');
        errorHandler(err);
      });
  }

  markRead(receipt_id, isRead = true) {
    const oldStore = this.store; // save to catch optimistic loading error

    const records = this.store.notifications.records;
    const newRecords = Object.keys(records).reduce((acc, key) => ({
      ...acc,
      [key]: records[key].map((n) => n.receipt_id === receipt_id ? { ...n, read: isRead } : n),
    }), {});

    this.store = {
      ...oldStore,
      notifications: {
        ...oldStore.notifications,
        records: newRecords,
      },
    };

    // optimistically signal update to UI
    this._storeChanged('notifications.markRead.single.optimistic', { receipt_id });

    return graphMutate({ t: 'update_notifications_read' }, { receipt_ids: [receipt_id], read: true })
      .then((res) => {
        this._storeChanged('notifications.markRead.single.success', { receipt_id }); // so NewNotifications can update if it's mounted
      })
      .catch((err) => {
        this.store = oldStore;
        this._storeChanged('notifications.markRead.single.failure', { receipt_id });
        errorHandler(err);
      });
  }

  /*****************************************************************************
 * Getters
 ****************************************************************************/
  get(resource) {
    return this.has(resource) ? this.store[resource] : null;
  }

  getChannel() {
    return this.channel;
  }

  getPageKey(pageNum) {
    return `page_${pageNum}`;
  }

  getStore() {
    return this.store;
  }

  has(resource) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return this.store.hasOwnProperty(resource);
  }

  initializedAt() {
    return this._initializedAt;
  }

  isInitialized() {
    return this._isInitialized;
  }

  _storeChanged(topic = 'notifications.changed', changes = {}) {
    this.channel.publish(topic, changes);
  }

  /** Test helpers. */
  __refresh__() {
    this.channel = postal.channel('notifications');
    this._isInitialized = false;
    this._initializedAt = null;
    this.store = {
      notifications: {
        metadata: {},
        records: {},
      },
    };
  }

  __setProperty__(property, value) {
    this[property] = value;
  }
}

const notificationsStore = new NotificationsStore();

export default notificationsStore;
