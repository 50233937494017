import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const FilterLabel = ({ count, className, disabled, label, limit }) => (
  <span className={`${className} ${layout.flexJustifySpaceBetween} ${typography.bodyM} ${typography.bold} ${disabled ? utilStyles.disabled : ''}`}>
    {label}
    {limit
    && <span className={typography.bodyS}>{count === limit ? 'limit reached' : `(limit ${limit})`}</span>}
  </span>
);

FilterLabel.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  limit: PropTypes.number,
};

FilterLabel.defaultProps = {
  className: '',
  count: null,
  disabled: false,
  limit: null,
};

export default FilterLabel;
