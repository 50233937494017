import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../../client/link';
import Icon from '../../../../../client/icon';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';

import {
  // type
  ENTRY,
  IDEA,
  // status: idea/entry
  APPROVED,
  DRAFT,
  PENDING_REVIEW,
  REJECTED,
  // status: entry only
  INCOMPLETE,
  RESUBMITTED,
  LOST,
  WON,
  WON_OTHER_CONTEST,
} from '../../../constants';

const _getTooltipProjectIncomplete = () => (
  'Your project is missing one or more requirements. Please update it and resubmit.'
);

const _getTooltipProjectRejected = () => (
  'Your project is ineligible for submission.' // TODO: verify copy
);

const _getTooltipProjectSubmitted = (resubmitted) => (
  `We received your ${resubmitted ? 'resubmitted ' : ''}project. We will review it to determine if it meets our guidelines and decide if it is a prize winner.`
);

const _getTooltipProjectResult = (lost) => (
  `Your project has ${lost ? 'not ' : ''}won a prize${lost ? '.' : '!'}`
);

const _getTooltipIdeaResult = (rejected) => (
  `Your idea was ${rejected ? 'not ' : ''}chosen${rejected ? '.' : '!'}`
);

const _getToolTipWonOtherContest = () => (
  'Your entry was automatically disqualified because it won another contest.'
);

const _getTooltipCopy = (item, type) => {
  switch (item.status) {
    case APPROVED:
      return type === ENTRY ? _getTooltipProjectSubmitted(false) : _getTooltipIdeaResult(false);
    case INCOMPLETE:
      return _getTooltipProjectIncomplete();
    case LOST:
      return _getTooltipProjectResult(true);
    case REJECTED:
      return type === ENTRY ? _getTooltipProjectRejected() : _getTooltipIdeaResult(true);
    case PENDING_REVIEW:
      return type === ENTRY
        ? _getTooltipProjectSubmitted(false)
        : 'We received your idea. We will review it to determine if it meets our guidelines and will be selected.';
    case RESUBMITTED:
      return _getTooltipProjectSubmitted(true);
    case WON:
      return _getTooltipProjectResult(false);
    case WON_OTHER_CONTEST:
      return _getToolTipWonOtherContest();
    case DRAFT:
    default:
      return '';
  }
};

const SubmissionStatusTooltip = ({ item, type, faqPath }) => (
  <div>
    <div>
      {_getTooltipCopy(item, type)}
      <Link className={layout.marginLeft5} color="White" href={faqPath} target="_blank">
        <Icon className={typography.iconBaselineFix} name="external-link" />
      </Link>
    </div>
    {!!item.moderator_feedback
    && <div className={layout.marginTop5}>{item.moderator_feedback}</div>}
  </div>
);

SubmissionStatusTooltip.propTypes = {
  faqPath: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.shape({ // ENTRY
      can_be_resubmitted: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      is_prize_address_present: PropTypes.bool,
      moderator_feedback: PropTypes.string,
      prize_requires_shipping: PropTypes.bool,
      project: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        edit_url: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      status: PropTypes.string.isRequired,
      submit_url: PropTypes.string,
      updated_at: PropTypes.string.isRequired,
    }),
    PropTypes.shape({ // IDEA
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ]),
  type: PropTypes.oneOf([ENTRY, IDEA]),
};

SubmissionStatusTooltip.defaultProps = { type: IDEA };

export default SubmissionStatusTooltip;
