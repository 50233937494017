import React from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../anchor';
import Button from '../base';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';

const JumboButton = ({ anchor, icon, text, ...buttonProps }) => {
  const ButtonComponent = anchor ? AnchorButton : Button;

  return (
    <ButtonComponent {...buttonProps} size="jumbo">
      <div className={buttonStyles.jumboInner}>
        <div className={buttonStyles.jumboIcon}>{icon}</div>
        <div className={layout.marginTop5}>{text}</div>
      </div>
    </ButtonComponent>
  );
};

JumboButton.propTypes = {
  anchor: PropTypes.bool,
  icon: PropTypes.element,
  text: PropTypes.string,
};

JumboButton.defaultProps = { anchor: false };

export default JumboButton;
