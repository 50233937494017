import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../../client/reusable_components/Dialog';
import FormSelect from '../../../client/form_components/selects/form_select/index.js';
import SuggestTagForm from './suggest_tag_form';

import { preventSubmit, appendAdditionalInputs, createDiffArray, createValidationErrors } from './utils/helpers';
import { fetchSearchResults } from './utils/requests';
import { formatOptions, formatSearchInput, initOptions } from './utils/formatting';

import layout from '../../../styles/global_ui/layout.css';

const PUBLISHED_STATES = ['approved', 'approved_after_edits', 'approved_after_review', 'machine_approved', 'pending_review'];

// TODO: Move file to client/form_components/rails.

export default class CategoryTagDropdown extends Component {
  constructor(props) {
    super(props);

    const { selected, markedForDeletion } = initOptions(props.tags);

    this.state = {
      showDialog: false,
      selectedTags: selected,
      validationError: false,
    };

    this.dismissDialog = this.dismissDialog.bind(this);
    this.generateHiddenFields = this.generateHiddenFields.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handlePreventSubmit = this.handlePreventSubmit.bind(this);
    this.summonDialog = this.summonDialog.bind(this);

    // refs
    this.tagReference = [...selected, ...markedForDeletion];
  }

  componentDidMount() {
    if (window && window.addEventListener) {
      window.addEventListener('submit', this.handlePreventSubmit);
    }
  }

  componentWillUnmount() {
    if (window && window.removeEventListener) {
      window.removeEventListener(this.handlePreventSubmit);
    }
  }

  dismissDialog() {
    this.setState({ showDialog: false });
  }

  getSearchResults(input) {
    const searchInput = formatSearchInput(input);

    return fetchSearchResults(searchInput)
      .then((data) => {
        const { hits } = data;
        const formattedOptions = formatOptions(hits, true);

        return { options: formattedOptions };
      })
      .catch((err) => console.error('Fetch search error: ', err));
  }

  generateHiddenFields() {
    const current = this.state.selectedTags.reduce((memo, s) => ({ ...memo, [s.value]: s }), {});
    const previous = this.tagReference.reduce((memo, s) => ({ ...memo, [s.value]: s }), {});
    const updates = createDiffArray(current, previous);
    appendAdditionalInputs(updates);
  }

  handlePreventSubmit(event) {
    if (this.state.validationError) {
      preventSubmit(event);
    } else if (document && document.body) {
      this.generateHiddenFields();
    }
  }

  handleSelection(selections) {
    const isPublicProject = PUBLISHED_STATES.includes(this.props.project_state);
    this.setState({
      selectedTags: selections,
      validationError: createValidationErrors(selections, isPublicProject),
    });
  }

  summonDialog() {
    this.setState({ showDialog: true });
  }

  _getErrors() {
    const errors = this.state.validationError ? [...this.props.errors, this.state.validationError] : this.props.errors;
    if (!errors || (errors && errors.length === 0)) return null;

    return errors.join(', ');
  }

  _getHelperText() {
    return (
      <span className="help-block">
        <span>
          Select up to five additional
          {' '}
          <strong>topic</strong>
          {' '}
          or
          {' '}
          <strong>technology</strong>
          {' '}
          tags to categorize your project.
          {' '}
        </span>
        {this.props.creatable
          ? <strong>You have the ability to create new tags. </strong>
          : (
            <span>
              <a href="javascript:void(0)" onClick={this.summonDialog}>Suggest a new tag</a>
              {' '}
              if we don&apos;t already have it, or
              {' '}
            </span>
            )}
        <a href="http://help.hackster.io/knowledgebase/posting-a-project/learn-more-about-tags">Learn more about tags.</a>
      </span>
    );
  }

  render() {
    return (
      <div>
        <div className={`form-group ${layout.marginBottom0} ${(this.props.errors && this.props.errors.length) ? 'has-error' : ''}`} id="category-tag-select">
          <div className="col-sm-4">
            <label className="string optional control-label" htmlFor="project_category">Tags</label>
          </div>

          <div className="col-sm-8">
            <div className="input-group" style={{ width: '100%' }}>
              <FormSelect
                asyncOpts={{
                  initOnMount: false,
                  request: this.getSearchResults,
                }}
                creatableOpts={{ creatable: this.props.creatable }}
                errors={this._getErrors()}
                helperText={this._getHelperText()}
                onSelectedChange={this.handleSelection}
                placeholder="Start typing to search"
                searchOpts={{
                  multiLimit: 5,
                  rule: 'default',
                }}
                theme="bootstrap"
                type="multi"
                value={this.state.selectedTags}
              />
            </div>
          </div>
        </div>
        <Dialog dismiss={this.dismissDialog} open={this.state.showDialog}>
          <SuggestTagForm dismiss={this.dismissDialog} project_id={this.props.project_id} />
        </Dialog>
      </div>
    );
  }
}

CategoryTagDropdown.propTypes = {
  creatable: PropTypes.bool,
  errors: PropTypes.array,
  project_id: PropTypes.number.isRequired,
  project_state: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marked_for_deletion: PropTypes.bool,
    name: PropTypes.string,
    projects_count: PropTypes.number,
    tagging_cue_id: PropTypes.number,
  })),
};

CategoryTagDropdown.defaultProps = {
  creatable: false,
  errors: [],
  tags: [],
};
