import React from 'react';
import PropTypes from 'prop-types';

import UserRelationButton from '../../../server/user/relation_button';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';

const buttonClass = `${buttonStyles.md} ${buttonStyles.secondary} ${layout.marginRight10}`;

const AddToInterestsButton = ({ partId }) => (
  <UserRelationButton
    config={{ className: [buttonClass, buttonClass] }}
    id={partId}
    type="part"
  />
);

AddToInterestsButton.propTypes = { partId: PropTypes.number.isRequired };

AddToInterestsButton.defaultProps = {};

export default AddToInterestsButton;
