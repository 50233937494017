import React from 'react';

function getPath(className, direction, fill = '#000000') {
  switch (direction) {
    case 'left':
      return <path className={className} d="M0.0750266426,7.375 C0.175062166,7.575 0.275097689,7.675 0.375133213,7.775 L6.17719357,13.575 C6.37726462,13.775 6.77740671,13.975 7.07751328,13.975 C7.37761985,13.975 7.67772642,13.875 7.97783299,13.575 C8.47801061,13.075 8.47801061,12.275 7.97783299,11.775 L4.37655415,8.175 L12.8795736,8.175 C13.5798223,8.175 14.0799999,7.575 14.0799999,6.975 C14.0799999,6.375 13.4797868,5.775 12.8795736,5.775 L4.37655415,5.775 L7.97783299,2.175 C8.47801061,1.675 8.47801061,0.875 7.97783299,0.375 C7.47765538,-0.125 6.67737119,-0.125 6.17719357,0.375 L0.375133213,6.175 C0.275097689,6.275 0.175062166,6.475 0.0750266426,6.575 C-0.0250088809,6.675 -0.0250088809,7.075 0.0750266426,7.375 L0.0750266426,7.375 Z" fill={fill} fillRule="evenodd" id="Shape" stroke="none"></path>;

    case 'right':
      return <path className={className} d="M1.20000003,8.375 L9.70000026,8.375 L6.10000017,11.975 C5.60000015,12.475 5.60000015,13.275 6.10000017,13.775 C6.30000017,13.975 6.70000018,14.175 7.00000019,14.175 C7.3000002,14.175 7.60000021,14.075 7.90000021,13.775 L13.7000004,7.975 C13.8000004,7.875 13.9000004,7.675 14.0000004,7.575 C14.1000004,7.375 14.1000004,7.275 14.1000004,7.075 C14.1000004,6.875 14.1000004,6.775 14.0000004,6.575 C13.9000004,6.375 13.8000004,6.275 13.7000004,6.175 L7.90000021,0.375 C7.4000002,-0.125 6.60000018,-0.125 6.10000017,0.375 C5.60000015,0.875 5.60000015,1.675 6.10000017,2.175 L9.70000026,5.775 L1.20000003,5.775 C0.500000014,5.775 0,6.375 0,6.975 C0,7.575 0.500000014,8.375 1.20000003,8.375 L1.20000003,8.375 Z" fill={fill} fillRule="evenodd" id="Shape" stroke="none"></path>;

    case 'up':
      return <path className={className} d="M2.375,7.975 L5.975,4.375 L5.975,12.875 C5.975,13.575 6.575,14.075 7.175,14.075 C7.775,14.075 8.375,13.475 8.375,12.875 L8.375,4.375 L11.975,7.975 C12.175,8.175 12.575,8.375 12.875,8.375 C13.175,8.375 13.475,8.275 13.775,7.975 C14.275,7.475 14.275,6.675 13.775,6.175 L7.975,0.375 C7.875,0.275 7.675,0.175 7.575,0.075 C7.275,-0.025 6.975,-0.025 6.575,0.075 C6.375,0.175 6.275,0.275 6.175,0.375 L0.375,6.175 C-0.125,6.675 -0.125,7.475 0.375,7.975 C0.875,8.475 1.875,8.475 2.375,7.975 L2.375,7.975 Z" fill={fill} fillRule="evenodd" id="Shape" stroke="none"></path>;

    case 'down':
    default:
      return <path className={className} d="M5.975,13.7 C6.075,13.8 6.275,13.9 6.375,14 C6.575,14.1 6.675,14.1 6.875,14.1 C7.075,14.1 7.175,14.1 7.375,14 C7.575,13.9 7.675,13.8 7.775,13.7 L13.575,7.9 C14.075,7.4 14.075,6.6 13.575,6.1 C13.075,5.6 12.275,5.6 11.775,6.1 L8.175,9.7 L8.175,1.2 C8.175,0.5 7.575,0 6.975,0 C6.375,0 5.775,0.6 5.775,1.2 L5.775,9.7 L2.175,6.1 C1.675,5.6 0.875,5.6 0.375,6.1 C-0.125,6.6 -0.125,7.4 0.375,7.9 L5.975,13.7 L5.975,13.7 Z" fill={fill} fillRule="evenodd" id="Shape" stroke="none"></path>;
  }
}

const SoftArrow = ({ className, direction, fill, height = '15', width = '15' }) => (
  <svg height={`${height}px`} version="1.1" viewBox="0 0 15 15" width={`${width}px`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    {getPath(className, direction, fill)}
  </svg>
);

export default SoftArrow;
