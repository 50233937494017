import PropTypes from 'prop-types';
import React from 'react';

import { Body, CardImage, Stats, SubTitle, Tags, Title } from '../../sub_components';
import styles from './horizontal.css';

const HorizontalCard = ({ body, image, stats, subTitle, title, tags }) => (
  <div className={styles.card}>
    <div className={styles.imageContainer}>
      <CardImage {...image} />
    </div>
    <div className={styles.content}>
      <Title {...title} />
      <SubTitle content={subTitle} />
      <Body body={body} />
      <Tags {...tags} />
      <Stats stats={stats} />
    </div>
  </div>
);

HorizontalCard.propTypes = {
  body: PropTypes.shape({
    htmlString: PropTypes.string,
    component: PropTypes.element,
    type: PropTypes.string,
  }),
  image: PropTypes.shape({
    linkTo: PropTypes.string,
    url: PropTypes.string,
    shape: PropTypes.string,
  }),
  stats: PropTypes.arrayOf(PropTypes.shape({
    metric: PropTypes.number,
    text: PropTypes.string,
  })),
  subTitle: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    linkTo: PropTypes.string,
  })),
  tags: PropTypes.shape({
    highlight: PropTypes.array,
    normal: PropTypes.array,
  }),
  title: PropTypes.shape({
    text: PropTypes.string,
    linkTo: PropTypes.string,
  }),
};

HorizontalCard.defaultProps = {
  body: {},
  image: {},
  stats: [],
  subTitle: [],
  tags: {},
  title: {},
};

export default HorizontalCard;
