import React from 'react';
import PropTypes from 'prop-types';

import DummyBaseCardVertical from '../../../../client/cards/base_card/vertical/DummyBaseCardVertical';
import GridList from '../../../../client/wrappers/grid_list';
import NewsArticleCard from '../../../../client/cards/news_article';
import PaginatedList from '../../../../client/wrappers/paginated_list';

import keenService from '../../../../services/keen/main';
import { getClickedButtonArgs } from '../../../../services/keen/events/eventTypeTemplates';

const ArticlesList = ({
  articles,
  className,
  handlePaginatorClick,
  gutterSize,
  horizontal,
  injectableConfig,
  isBusy,
  location,
  maxCols,
  onUserProfile,
}) => (
  <PaginatedList
    buttonText="Show more"
    disabled={isBusy}
    onPaginatorClick={(e) => {
      keenService.reportEventWithObj(getClickedButtonArgs({ location }, e));
      handlePaginatorClick(e);
    }}
    paginator={{
      currentPage: articles.metadata.current_page,
      nextPage: articles.metadata.next_page,
      prevPage: articles.metadata.prev_page,
    }}
    type="button"
  >
    <GridList
      DummyComponent={() => <DummyBaseCardVertical imageAspectRatio="16:9" responsive={!onUserProfile} />}
      ItemComponent={NewsArticleCard}
      className={className}
      gutterSize={gutterSize}
      horizontal={horizontal}
      injectableConfig={injectableConfig}
      isLoading={isBusy}
      itemKey="article"
      itemProps={{ location, onUserProfile }}
      loadingIndicator="dummy"
      maxCols={maxCols}
      records={articles.records}
      validationFn={() => true} // always render list, so dummy cards render
    />
  </PaginatedList>
);

ArticlesList.propTypes = {
  articles: PropTypes.shape({
    metadata: PropTypes.shape({
      current_page: PropTypes.number,
      next_page: PropTypes.number,
      per_page: PropTypes.number,
      prev_page: PropTypes.number,
    }),
    records: PropTypes.array,
  }).isRequired,
  className: PropTypes.string,
  gutterSize: PropTypes.number,
  handlePaginatorClick: PropTypes.func,
  horizontal: PropTypes.bool,
  injectableConfig: PropTypes.shape({
    component: PropTypes.func,
    index: PropTypes.number,
    props: PropTypes.shape({
      ad: PropTypes.object,
      initialized: PropTypes.bool,
    }),
  }),
  isBusy: PropTypes.bool,
  location: PropTypes.string,
  maxCols: PropTypes.number,
  onUserProfile: PropTypes.bool,
};

ArticlesList.defaultProps = {
  className: '',
  gutterSize: 15,
  handlePaginatorClick: () => {},
  horizontal: false,
  injectableConfig: null,
  isBusy: false,
  location: '',
  maxCols: 4,
  onUserProfile: false,
};

export default ArticlesList;
