import GraphQLBaseService from '../base_service';
import queryBuilder from './query_builder';
import { graphQuery } from '../../../requests/graphql';
import { getUrlSegmentsAfterUrlPart } from '../../algolia/converters';

const DEFAULT_QUERY = () => ({ t: 'platform_products' });
const DEFAULT_RESOLVER_KEY = 'products';

export default class GraphQLPartsService extends GraphQLBaseService {
  constructor({ history, queryString, resolverKey } = {}) {
    super({ history, queryBuilder, queryString: (queryString || DEFAULT_QUERY), resolverKey: (resolverKey || DEFAULT_RESOLVER_KEY) });
  }

  getPartSlugFromWindowLocation() {
    return new Promise((resolve, reject) => getUrlSegmentsAfterUrlPart('products')
      .then((segments) => resolve(segments[0]))
      .catch((err) => reject(err)));
  }

  // NOTE: Use this only when the resolver expects an Aloglia shape as its repsonse, for now.
  // Apps using this method: [PlatformsPage]
  searchWithNoEffects(queryMap, params, recordsCount = 0) {
    return new Promise((resolve, reject) => this._translateQueryMap(queryMap, params)
      .then((vars) => graphQuery(this.graphQLQueryString(), vars))
      .then((response) => {
        const currentQuery = response[this.resolverKey];

        resolve({
          hits: currentQuery.records,
          nbHits: this._translateMetadata(currentQuery, recordsCount),
        });
      })
      .catch((err) => reject(err)));
  }

  searchWithNoEffectsForSingleRecord(queryMap, params) {
    return new Promise((resolve, reject) => this._translateQueryMap(queryMap, params)
      .then((vars) => graphQuery(this.graphQLQueryString(), vars))
      .then((response) => {
        resolve({ part: response[this.resolverKey] });
      })
      .catch((err) => reject(err)));
  }

  _translateMetadata(currentQuery, recordsCount) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    if (!currentQuery.hasOwnProperty('metadata')) return 0;

    if (currentQuery.metadata.next_page && currentQuery.metadata.next_page === null) {
      return recordsCount;
    } else if (typeof currentQuery.per_page === 'number') {
      return currentQuery.per_page + recordsCount;
    } else {
      return 0;
    }
  }

  _translateQueryMap(queryMap, params) {
    return new Promise((resolve, reject) => {
      const vars = { ...queryMap, ...params };
      const qlVars = queryMap.qlVars || {};

      return queryBuilder(vars)
        .then((query) => resolve({ ...query, ...qlVars }))
        .catch((err) => reject(err));
    });
  }
}
