import React from 'react';
import PropTypes from 'prop-types';

import { EVENT_CARD } from '../../../services/keen/events/linkConstants';

import EventCardText from './EventCardText';
import BaseCardHorizontalSmall from '../base_card/horizontal/BaseCardHorizontalSmall';

const EventCardHorizontalSmall = ({ item, itemIndex, overrideTracking }) => (
  <BaseCardHorizontalSmall
    item={{ ...item, url: item.link }}
    linkProps={{
      isExternal: true,
      itemIndex,
      linkType: EVENT_CARD,
      overrideTracking,
    }}
    renderBody={() => (
      <EventCardText
        item={item}
        itemIndex={itemIndex}
        overrideTracking={overrideTracking}
        size="L"
      />
    )}
  />
);

EventCardHorizontalSmall.propTypes = {
  item: PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    imageRight: PropTypes.bool,
    link: PropTypes.string.isRequired,
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    start_date: PropTypes.string.isRequired,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  itemIndex: PropTypes.number,
  overrideTracking: PropTypes.func,
};

EventCardHorizontalSmall.defaultProps = {
  itemIndex: null,
  overrideTracking: null,
};

export default EventCardHorizontalSmall;
