import React from 'react';
import PropTypes from 'prop-types';

import TextImageSection from './TextImageSection';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './marketing.css';

const c = {
  center: typography.textCenter,
  number: `${typography.h1Responsive} ${layout.marginBottom0} ${typography.hackster}`,
  text: `${typography.bodyM} ${typography.staticCharcoal}`,
  section: `${layout.paddingBottom30} ${util.borderBottom}`,
  header: `${typography.h4} ${typography.staticCharcoal} ${layout.marginBottom22}`,
  reach: `${layout.flexJustifySpaceAround} ${layout.gutter22} ${styles.reach}`,
  imageHeader: `${typography.textCenter} ${typography.bodyM} ${typography.staticCharcoal} ${typography.bold} ${layout.marginBottom22} ${styles.imageHeader}`,
  brandsWrapper: styles.brandsWrapper,
};

const Reach = ({ number, text }) => (
  <div className={c.center}>
    <p className={c.number}>{number}</p>
    <p className={c.text}>{text}</p>
  </div>
);

Reach.propTypes = {
  number: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const MonthlyReach = () => (
  <>
    <section className={c.section}>
      <h4 className={c.header}>Monthly Average Reach</h4>
      <div className={c.reach}>
        <Reach number="550,000+" text="Monthly Hackster news readers" />
        <Reach number="8,000,000+" text="Monthly social media impressions" />
        <Reach number="6,000,000+" text="Monthly website views" />
      </div>
    </section>
    <TextImageSection
      content={
        <p className={c.text}>Our team of creators and network of community contributors set the bar high for content that always puts the developer first. The result? Authentic, relatable, and informative content that your audiences WANT to see in a variety of formats including project tutorials, deep dives, videos, article series, demos, unboxings, white papers, and more.</p>
      }
      images={[
        { alt: 'Screenshot of unboxing video showcasing Raspberry Pi Pico W', ratio: '578:324', src: 'pico_w_unboxing', width: 289 },
      ]}
      title="Engaging Content Creation"
    />

    <TextImageSection
      content={<p className={c.text}>We keep our eyes on the prize — getting eyes on your ads. Each ad campaign is optimized based on personas, regions, or topics and placed in premium Hackster-owned properties and channels.</p>}
      images={[
        { alt: 'Example Meta post for Anker', ratio: '228:410', src: 'anker_ad', width: 114 },
        { alt: 'Example X post for AMD Space Webinar', ratio: '348:410', src: 'space_webinar', width: 174 },
      ]}
      title="Targeting Advertising"
    />

    <TextImageSection
      classList={{ imageWrapper: c.brandsWrapper }}
      content={<p className={c.text}>Hackster News is a go-to source for hardware news, from the biggest launches to niche happenings in the industry with more than 550,000 monthly readers. Our writing staff is made up of technical contributors across a spectrum of specialties who generate articles that are not only read by our audience but also reach and are picked up by peer publications.</p>}
      imageHeader={<p className={c.imageHeader}>Featured on</p>}
      images={[
        { alt: 'Logo for TC', ratio: '88:44', src: 'tc', width: 44 },
        { alt: 'Logo for TNW', ratio: '170:44', src: 'tnw', width: 85 },
        { alt: 'Logo for Fast Company', ratio: '224:34', src: 'fast_company', width: 112 },
        { alt: 'Logo for Digital Trends', ratio: '212:38', src: 'digital_trends', width: 106 },
        { alt: 'Logo for Gizmodo', ratio: '208:30', src: 'gizmodo', width: 104 },
        { alt: 'Logo for Forbes', ratio: '140:36', src: 'forbes', width: 70 },
        { alt: 'Logo for Engadget', ratio: '170:38', src: 'engadget', width: 85 },
        { alt: 'Logo for Fortune', ratio: '160:36', src: 'fortune', width: 80 },
      ]}
      title="Hackster News"
    />
  </>
);

export default MonthlyReach;
