import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ChannelHome from '../channel_page/home';
import currentUserService from '../../services/current_user';
import urlService from '../../services/url_service';
import errorHandler from '../../services/error_handler';

class WhitelabelPage extends Component {
  constructor(props) {
    super(props);

    this.state = { userPermissions: { canManage: false } };

    urlService.setRootPath(props.pathHelpers.rootPath);
  }

  componentDidMount() {
    this._fetchUserPermissions();
  }

  _fetchUserPermissions() {
    const platformId = this.props.platform.id;

    return currentUserService.fetchPermissions([
      {
        can_manage_platform: {
          key: platformId,
          ql: {
            query: { t: 'get_platform_permissions_for_current_user' },
            variables: { platform_id: platformId },
          },
        },
      },
    ])
      .then(({ can_manage_platform = {} }) => {
        this.setState({ userPermissions: { canManage: !!can_manage_platform[platformId] } });
      })
      .catch((err) => errorHandler('WhitelabelPage _fetchUserPermissions', err));
  }

  render() {
    return (
      <div>
        <ChannelHome
          canManage={this.state.userPermissions.canManage}
          homeSections={this.props.platform.home_sections}
          path=""
          renderForWhitelabel={true}
          seoHandler={() => {}}
          transition={(e, path) => {}}
          viewAllBuilderArg={() => ({ type: 'whitelabel', props: { platform: this.props.platform, rootPath: this.props.pathHelpers.rootPath } })}
        />
      </div>
    );
  }
}

WhitelabelPage.propTypes = {
  pathHelpers: PropTypes.shape({ rootPath: PropTypes.string.isRequired }).isRequired,
  platform: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
};

WhitelabelPage.defaultProps = {};

export default WhitelabelPage;
