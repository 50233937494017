import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ContentPlaceholder from '../../../../../client/reusable_components/ContentPlaceholder';
import ExpandableCard from '../../list/ExpandableCard';
import LazyImage from '../../../../../client/reusable_components/LazyImage';
import HorizontalScroll from '../../../../../client/wrappers/horizontal_scroll';
import CardFade from './CardFade';

import { formatNumberWithCommas, pluralize } from '../../../../../utility/formatters';

import buttonStyles from '../../../../../styles/global_ui/buttons.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import profileStyles from '../profile.css';
import listStyles from '../../list/list.css';
import styles from './tools.css';

function _projectByIdOrNull(projectId, allProjects) {
  return (projectId && allProjects[projectId.toString()]) || null;
}

class Tools extends Component {
  constructor(props) {
    super(props);
    this.fetchProjects = this.fetchProjects.bind(this);
    this.handleExpandingCard = this.handleExpandingCard.bind(this);
    this.state = { expandedCard: null };
  }

  fetchProjects(toolId) {
    return this.props.fetchProjectsForResource(
      'tool_projects',
      ['tools', 'records', ['id', toolId], 'projects'],
      { author_id: this.props.profile.id, part_id: toolId },
      false,
    );
  }

  handleExpandingCard(tool, expandedCard, isExpanding) {
    this.props.blockHoverForResource(isExpanding);

    if (isExpanding) {
      const hids = tool.projects.records.reduce((acc, record) => !this.props.allProjects[record.hid] ? acc.concat(record.hid) : acc, []);

      this.setState({ expandedCard });
      if (hids.length) this.props.fetchProjects(hids);
    }
  }

  render() {
    const metadata = this.props.tools.metadata;
    const records = this.props.inDialog ? this.props.tools.records : this.props.tools.records.slice(0, 5);

    return (
      <section className={styles.tools}>
        {!this.props.inDialog
        && (
          <header className={profileStyles.sectionHeader}>
            <span className={`${typography.bodyM} ${typography.bold}`}>Products</span>
            <span className={`${typography.bodyM} ${profileStyles.count}`}>{`${formatNumberWithCommas(metadata.total_records)}`}</span>
            {metadata.total_records > 0 && (
              <a className={layout.marginLeftAuto} href="/onboarding/interests?filter=products">+ Add products</a>
            )}
          </header>
        )}
        {metadata.total_records === 0
          ? (
            <ContentPlaceholder
              isProfileOwner={this.props.isProfileOwner}
              templateArgs={{ name: this.props.profile.name }}
              type="no-tools"
            />
            )
          : (
            <div className={listStyles.list}>
              {records.map((tool) => (
                <ExpandableCard
                  key={tool.id}
                  dropdown={(
                    <HorizontalScroll
                      fetchMore={() => this.fetchProjects(tool.id)}
                      recordsCount={tool.projects.records.length}
                      totalRecordsCount={tool.projects.metadata.total_records}
                    >
                      {tool.projects.records.map((project) => (
                        <CardFade
                          key={project.hid || project.id}
                          config={{ controlledRespects: true }}
                          imageProps={{
                            captureScroll: true,
                            setStyle: true,
                          }}
                          project={this.props.allProjects[project.hid] || _projectByIdOrNull(project.id, this.props.allProjects)}
                          toggleRespect={this.props.toggleRespect}
                        />
                      ))}
                    </HorizontalScroll>
                  )}
                  expandedCard={this.state.expandedCard}
                  expanding={this.handleExpandingCard.bind(this, tool)}
                  info={`${tool.projects.metadata.total_records} ${pluralize('project', tool.projects.metadata.total_records)}`}
                  isExpandable={tool.projects.records.length > 0}
                  link={tool.url}
                  title={tool.name}
                  uuid={`${tool.id}`}
                >
                  {tool.projects.records.length > 0
                  && (
                    <div className={styles.toolsGallery}>
                      {tool.projects.records.slice(0, 5).map((project, i) => (
                        <LazyImage
                          key={i}
                          className={styles.toolsImage}
                          onMouseLeave={() => this.props.killPopover(project.hid, 'project', 50)}
                          onMouseOver={(e) => this.props.triggerPopover(['allProjects', project.hid], project.hid, 'project', e.target, 'bottom', [], { targetOffset: 15 }, this.props.inDialog)}
                          ratio="4:3"
                          src={project.cover_image_url}
                          width={48}
                        />
                      ))}
                    </div>
                  )}
                </ExpandableCard>
              ))}
            </div>
            )}
        {!this.props.inDialog && metadata.total_records > 5
        && (
          <button
            className={`${buttonStyles.sm} ${buttonStyles.secondary} ${buttonStyles.pill} ${layout.flexAlignSelfEnd} ${layout.marginTop10}`}
            onClick={() => this.props.openDialog('tools')}
          >
            View all
          </button>
        )}
      </section>
    );
  }
}

Tools.propTypes = {
  allProjects: PropTypes.object.isRequired,
  blockHoverForResource: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  fetchProjectsForResource: PropTypes.func.isRequired,
  inDialog: PropTypes.bool,
  isProfileOwner: PropTypes.bool,
  killPopover: PropTypes.func.isRequired,
  openDialog: PropTypes.func,
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  toggleRespect: PropTypes.func.isRequired,
  tools: PropTypes.shape({
    metadata: PropTypes.shape({
      currentPage: PropTypes.number,
      per_page: PropTypes.number,
      total_pages: PropTypes.number,
      total_records: PropTypes.number,
    }),
    records: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      projects: PropTypes.shape({
        records: PropTypes.arrayOf(PropTypes.shape({
          cover_image_url: PropTypes.string,
          hid: PropTypes.string,
        })),
        metadata: PropTypes.object,
      }),
      stats: PropTypes.shape({ projects: PropTypes.number }),
    })),
  }).isRequired,
  triggerPopover: PropTypes.func.isRequired,
};

Tools.defaultProps = {
  inDialog: false,
  isProfileOwner: false,
  openDialog: () => {},
};

export default Tools;
