import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../../../reusable_components/Router/Loader';
import PageHeader from '../../page_header';
import ProjectsSection from '../../projects_section';

import layout from '../../../../styles/global_ui/layout.css';

const ProjectsRoot = ({ currentUser, handleReorder, projects, toggleRespect }) => {
  const loading = !(projects.drafts && projects.public && projects.guest);

  const numProjects = loading
    ? null
    : ['drafts', 'public', 'guest'].reduce((memo, category) => (
        memo + projects[category].metadata.total_records
      ), 0);

  return (
    <div className={layout.container}>
      <div className={layout.wrapper960}>
        <PageHeader type="projects" typeArgs={{ category: 'all', numProjects }} />
        {loading
          ? <Loader />
          : ['drafts', 'public', 'guest'].map((category) => (
            <ProjectsSection
              key={category}
              cardBorder={true}
              category={category}
              currentUser={currentUser}
              handleReorder={handleReorder}
              projects={projects[category].records}
              toggleRespect={(id, createOrDeleteBool) => toggleRespect(id, createOrDeleteBool, category)}
              totalRecords={projects[category].metadata.total_records}
            />
            ))}
      </div>
    </div>
  );
};

ProjectsRoot.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }),
  projects: PropTypes.shape({
    drafts: PropTypes.shape ({
      records: PropTypes.array,
      metadata: PropTypes.object,
    }),
    public: PropTypes.shape ({
      records: PropTypes.array,
      metadata: PropTypes.object,
    }),
    guest: PropTypes.shape ({
      records: PropTypes.array,
      metadata: PropTypes.object,
    }),
  }),
};

ProjectsRoot.defaultProps = {
  currentUser: {},
  projects: {
    drafts: {
      records: [],
      metadata: { total_records: 0 },
    },
    public: {
      records: [],
      metadata: { total_records: 0 },
    },
    guest: {
      records: [],
      metadata: { total_records: 0 },
    },
  },
};

export default ProjectsRoot;
