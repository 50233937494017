import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import style from './title.css';

export const c = {
  container: `${layout.flexColumn} ${layout.flexCenterItems} ${typography.textCenter} ${style.header}`,
  title: typography.h1Responsive,
  subText: `${layout.margin0} ${typography.h3Responsive} ${typography.fontWeightNormal} ${typography.lineHeight1} ${typography.textCenter}`,
};

const Title = ({ header, subText }) => (
  <header className={c.container}>
    <h1 className={c.title}>{header}</h1>
    <h2 className={c.subText}>{subText}</h2>
  </header>
);

Title.propTypes = {
  header: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
};

export default Title;
