import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProductWrapper from './ProductWrapper';
import validator from 'validator';

import styles from './product.css';

class ProductSpecs extends Component {
  constructor(props) {
    super(props);

    this.state = { part: null };
  }

  _updateSEOHeaders(part) {
    this.props.seoHandler(this.props.path, part);
  }

  render() {
    return (
      <ProductWrapper
        graphQLArguments={this.props.graphQLArguments}
        history={this.props.history}
        path={this.props.path}
        platformId={this.props.platformId}
        propagatePart={(part) => this.setState({ part }, () => this._updateSEOHeaders(part))}
        transition={this.props.transition}
      >
        {this.state.part && this.state.part.html_description
        && (
          <div
            dangerouslySetInnerHTML={{ __html: validator.unescape(this.state.part.html_description) }}
            className={styles.description}
          />
        )}
      </ProductWrapper>
    );
  }
}

ProductSpecs.propTypes = {
  graphQLArguments: PropTypes.shape({ platform_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  history: PropTypes.object, // Router history
  path: PropTypes.string.isRequired,
  platformId: PropTypes.number.isRequired,
  seoHandler: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};

ProductSpecs.defaultProps = { history: {} };

export default ProductSpecs;
