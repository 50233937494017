/* eslint-disable react/jsx-key */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';
import Button from '../../../../client/buttons/base';
import Checkbox from '../../../../client/form_components/checkboxes/custom';
import FormSelect from '../../../../client/form_components/selects/form_select';
import ImageUploader from '../../../../client/form_components/image_uploaders/form_image_uploader';
import Link from '../../../../client/link';
import RadioGroup from '../../../../client/form_components/inputs/radio_group';

import { graphMutate } from '../../../../requests/graphql';

import { buildFields } from './config';
import { getErrorHelperView } from '../../../../client/form_components/templates';
import { getTypeForFormSelect } from '../../../../graphql/challenges/enums';
import { getErrorForField, getFieldValuesAsObject, setStateOrError, validateFields } from '../../../../utility/forms';
import { isBlank } from '../../../../utility/types';
import { windowLocationRedirect } from '../../../../services/window';

import errorHandler from '../../../../services/error_handler';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './submit_form.css';

const CHECKBOX_LABEL = 'I have reviewed my submission and made sure it meets all the requirements above.';
const HARDWARE_INFO = 'Trying to submit an idea for free hardware? Ideas submitted in project format will not be considered. ';

class ContestEntrySubmitForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      fields: buildFields(props),
      isBusy: false,
      serverError: null,
    };

    this.getErrorForField = getErrorForField.bind(this);
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setStateOrError = setStateOrError.bind(this);
    this.validate = validateFields.bind(this);
  }

  /**
   * Methods
   */
  handleSubmit() {
    const validated = this.validate();

    if (validated) {
      return this._submitChallengeEntry();
    }
  }

  /**
   * Requests
   */
  _submitChallengeEntry() {
    this.setState({ isBusy: true, serverError: null });

    return graphMutate({ t: 'submit_contest_entry' }, this._formatEntrySubmissionOut())
      .then(() => this._redirectToContest())
      .catch((err) => {
        this.setState({ isBusy: false, serverError: 'There was an issue saving your entry, please try again.' });
        errorHandler('ContestEntrySubmitForm _submitChallengeEntry', err);
      });
  }

  /**
   * Helpers
   */
  _formatEntrySubmissionOut() {
    const fields = this.getFieldValuesAsObject();
    const keys = Object.keys(fields);
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    const prize_category_ids = fields.hasOwnProperty('prize_category_ids') ? { prize_category_ids: fields.prize_category_ids } : {};
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    const fullName = fields.hasOwnProperty('full_name') ? { user_full_name: fields.full_name } : {};

    const questions = keys.filter((k) => k.includes('question')).reduce((acc, key) => acc.concat({ answer: fields[key], id: this.state.fields[key].id }), []);

    const submissionQuestions = questions.length > 0 ? { submission_questions: questions } : {};
    return {
      id: this.props.challenge_entry.id,
      ...prize_category_ids,
      ...fullName,
      ...submissionQuestions,
    };
  }

  _formatOptions(list) {
    return list.map((item) => {
      if (typeof item === 'string') return { value: item, label: item };

      return item;
    });
  }

  _redirectToContest() {
    windowLocationRedirect(this.props.challenge.url);
  }

  /**
   * Views
   */
  _getErrorMsgForForm() {
    const errorKeys = Object.keys(this.state.errors).filter((e) => e !== 'agree');
    if (errorKeys.length > 0) {
      return 'There seems to be errors on the form. Please fill out the required fields.';
    } else if (this.state.serverError) {
      return this.state.serverError;
    }

    return null;
  }

  _getOptionalInputsView() {
    return React.Children.toArray(
      Object.keys(this.state.fields)
        .filter((k) => !['agree'].includes(k))
        .reduce((acc, key) => (acc.concat(this._getViewForInputType(this.state.fields[key], key))), []),
    );
  }

  _getViewForInputType(field, key) {
    switch (field.inputType) {
      case 'multi':
      case 'select':
        return this._getFormSelectView(field, key);
      case 'radio':
        return this._getFormRadioView(field, key);
      case 'image':
        return this._getFormImageView(field, key);
      default:
        return this._getBasicFormInputView(field, key, field.inputType);
    }
  }

  _getBasicFormInputView(field, key, element) {
    return (
      <div key={`vf${key}`}>
        <BasicFormInput
          element={element}
          errors={this.getErrorForField(key)}
          label={field.label}
          onChange={(e) => this.setStateOrError(null, key, e.target.value)}
          type={element}
          value={field.value}
        />
      </div>
    );
  }

  _getFormRadioView(field, key, element) {
    const handleChange = (v) => {
      const opt = { label: v.target.value, value: v.target.value };
      this.setStateOrError(null, key, opt.value);
    };
    return (
      <div id={`vf${key}`}>
        <RadioGroup
          buttons={this._formatOptions(field.options)}
          errors={this.getErrorForField(key)}
          label={field.label}
          onChange={handleChange}
          value={field.value}
        />
      </div>
    );
  }

  _getFormImageView(field, key, element) {
    return (
      <div id={`vf${key}`}>
        <ImageUploader
          allowRemoteURL
          classList={{ inputButtonsWrapper: `${layout.flexColumn} ${layout.gutter10}` }}
          errors={this.getErrorForField(key)}
          imageData={{ url: field.value }}
          label={field.label}
          propagateUpload={(e) => this.setStateOrError(null, key, e.url)}
        />
      </div>
    );
  }

  _getFormSelectView(field, key) {
    const handleSelectChange = (optObj) => {
      const isMulti = field.inputType === 'multi';

      const value = isMulti ? optObj.map(({ value }) => value) : optObj?.value;
      this.setStateOrError(null, key, value);
    };

    return (
      <div id={`vf${key}`}>
        <FormSelect
          errors={this.getErrorForField(key)}
          label={field.label}
          onSelectedChange={handleSelectChange}
          options={field.options}
          placeholder={field.placeholder}
          type={getTypeForFormSelect(field.inputType)}
          value={field.value}
        />
      </div>
    );
  }

  _getRequirementsList() {
    const reqs = [
      <li>
        Your project must meet all the
        {' '}
        <a href={`${this.props.challenge.rules_url}#requirements`} rel="noreferrer" target="_blank">submission requirements</a>
        .
      </li>,
      <li>
        Your project must make use of
        {' '}
        <span className={typography.bold}>{this.props.challenge.technology_requirement}</span>
        .
      </li>,
      <li>
        Any licensed content (open source or otherwise) used in your submission must properly credit original authors as well as follow the original license terms.
        {' '}
        <a href="/copyright-guidelines" target="_blank">Read more</a>
        .
      </li>,
      <li>After submitting, you are still able to make updates to your entry until the contest deadline.</li>,
    ];

    return this.props.challenge.technology_requirement ? reqs : [reqs[0], reqs[2], reqs[3]];
  }

  _getSubmissionForm() {
    return (
      <div>
        {this._getOptionalInputsView()}
        <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom10}`}>Submission requirements</p>
        <p className={`${typography.bodyM} ${layout.marginBottom15}`}>Review your submission to make sure it meets all the following requirements. Projects that fail to meet the requirements will be disqualified.</p>
        <ol className={`${typography.ol} ${typography.bodyM} ${layout.marginBottom30}`}>
          {React.Children.toArray(this._getRequirementsList())}
        </ol>

        <div className={layout.marginBottom30} id="vfagree">
          <Checkbox
            error={this.getErrorForField('agree')}
            isChecked={this.state.fields.agree.value}
            label={CHECKBOX_LABEL}
            onChange={(value) => this.setStateOrError(null, 'agree', value)}
          />
          {getErrorHelperView(this.getErrorForField('agree'))}
        </div>

        <div>
          <Button
            disabled={this.state.isBusy}
            onClick={this.handleSubmit}
            size="lg"
          >
            Submit project
          </Button>
          <Button
            colorStyle="cancel"
            disabled={this.state.isBusy}
            onClick={() => this._redirectToContest()}
            size="lg"
          >
            Cancel
          </Button>
          {getErrorHelperView(this._getErrorMsgForForm())}
        </div>
      </div>
    );
  }

  _getChecklistView() {
    const { edit_url, missing_fields } = this.props.challenge_entry;

    return (
      <div>
        <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom10}`}>Submission requirements</p>
        <p className={`${typography.bodyM} ${layout.marginBottom15}`}>Please ensure that the following fields are filled before submitting:</p>
        <ul className={`${typography.ul} ${typography.bodyM} ${layout.marginBottom30}`}>
          {missing_fields.map((field) => (<li key={field} className={typography.li}>{field}</li>))}
        </ul>
        <div>
          <a className={`${buttonStyles.lg}`} href={edit_url}>
            Add missing fields
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={`${layout.marginLeft10} ${layout.marginRight10}`}>
        <h3 className={`${typography.h3} ${layout.marginBottom15}`}>{this.props.challenge.name}</h3>
        {this.props.challenge.free_hardware_applications_open
        && (
          <div className={`${typography.bodyM} ${layout.marginBottom15} ${styles.info}`}>
            {HARDWARE_INFO}
            <Link color="Blue" href={this.props.challenge.ideas_url}>Fill out an application here.</Link>
          </div>
        )}
        {!isBlank(this.props.challenge_entry.missing_fields)
          ? this._getChecklistView()
          : this._getSubmissionForm()}
      </div>
    );
  }
}

ContestEntrySubmitForm.propTypes = {
  challenge: PropTypes.shape({
    free_hardware_applications_open: PropTypes.bool.isRequired,
    ideas_url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rules_url: PropTypes.string.isRequired,
    submission_categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    submission_questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      input_type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string),
      required: PropTypes.bool.isRequired,
    })),
    technology_requirement: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
  challenge_entry: PropTypes.shape({
    edit_url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    missing_fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  user: PropTypes.shape({ has_full_name: PropTypes.bool.isRequired }).isRequired,
};

export default ContestEntrySubmitForm;
