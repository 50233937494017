import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './idea_form.css';

const ChallengeFAQ = ({ hardware }) => (
  <div className={styles.faqContainer}>
    <p
      className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom10}`}
    >
      Frequently Asked Questions
    </p>

    <div className={`${styles.faqBox} ${typography.bodyM}`}>
      <div className={layout.marginBottom30}>
        <strong>Will I be able to compete in this challenge if my proposal is not selected?</strong>
        <p className={layout.marginTop10}>
          Only official contenders are able to submit solutions to this challenge. Please take your time and submit a thorough proposal in order to be considered.
        </p>
      </div>

      <div className={layout.marginBottom30}>
        <strong>What kind of proposals are more likely to be accepted?</strong>
        <p className={layout.marginTop10}>
          We encourage you to write a comprehensive plan to demonstrate your
          ability to deliver the solution on time. For instance, your technical
          abilities & skills; your project portfolio; and any additional
          resources you might have to support your build. Include visuals,
          images, videos, or any other supportive media to enrich your proposal.
        </p>
      </div>

      <div className={layout.marginBottom30}>
        <strong>
          Do I have to submit a project entry if I am selected to be a
          contender?
        </strong>
        <p className={layout.marginTop10}>
          Once you are an official contender, you will be entering into a
          legally binding agreement with Hackster to deliver a solution based on
          your proposal. Contenders who receive free hardware and do not finish
          the challenge may be ineligible for future challenges and competitions
          unless the hardware is returned. If you receive free hardware and
          become unable to finish, please contact us at
          {' '}
          <a
            href="mailto:contests@hackster.io"
            rel="noopener nofollow noreferrer"
            target="_blank"
          >
            contests@hackster.io
          </a>
          {' '}
          and let us know as soon as possible. We may choose to arrange for the
          hardware to be shipped to another proposal.
        </p>
      </div>

      <div className={layout.marginBottom30}>
        <strong>How many contender slots are available?</strong>
        <p className={layout.marginTop10}>
          {`${hardware.remaining} are available.`}
        </p>
      </div>

      <div>
        <strong>How many ideas can I submit?</strong>
        <p className={layout.marginTop10}>
          Each user is allowed to submit one idea.
        </p>
      </div>
    </div>
  </div>
);

ChallengeFAQ.propTypes = {
  hardware: PropTypes.shape({ remaining: PropTypes.number.isRequired }).isRequired,
  supportEmail: PropTypes.string.isRequired,
};

export default ChallengeFAQ;
