import PropTypes from 'prop-types';
import React from 'react';

import Awards from './Awards';
import Articles from './Articles';
import Communities from './Communities';
import Projects from './Projects';
import Tools from './tools';
import UserInfo from './UserInfo';

import layout from '../../../../styles/global_ui/layout.css';
import styles from './profile.css';

const Profile = ({
  blockHoverForResource,
  fetchProjects,
  fetchProjectsForResource,
  history,
  isProfileOwner,
  isWhiteLabel,
  killPopover,
  openDialog,
  profile,
  store,
  toggleRespect,
  triggerPopover,
}) => (
  <div>
    <div className={layout.container}>
      <div className={layout.wrapper1170}>
        <UserInfo isProfileOwner={isProfileOwner} profile={profile} />
        {profile.challenge_prizes.length > 0
        && <Awards awards={profile.challenge_prizes} />}
        <Projects
          history={history}
          isProfileOwner={isProfileOwner}
          profile={{ id: profile.id, name: profile.name }}
          projects={store.get('projects')}
          toggleRespect={(hid, createOrDeleteBool) => toggleRespect(hid, 'project', ['projects', 'records', ['hid', hid]], createOrDeleteBool, 'project_respects')}
        />
        {(!isWhiteLabel && profile.news_role)
        && (
          <Articles
            articles={store.get('articles')}
            history={history}
            isProfileOwner={isProfileOwner}
            profile={{ ...profile }}
          />
        )}
        <div className={styles.toolsAndCommunities}>
          <Tools
            allProjects={store.get('allProjects')}
            blockHoverForResource={(shouldBlock) => blockHoverForResource('tools', shouldBlock)}
            fetchProjects={fetchProjects.bind(this, store)}
            fetchProjectsForResource={fetchProjectsForResource.bind(this, store)}
            isProfileOwner={isProfileOwner}
            killPopover={killPopover}
            openDialog={openDialog.bind(this, store)}
            profile={{ id: profile.id, name: profile.name }}
            toggleRespect={(hid, createOrDeleteBool) => toggleRespect(hid, 'project', ['projects', 'records', ['hid', hid]], createOrDeleteBool, 'project_respects')}
            tools={store.get('tools')}
            triggerPopover={triggerPopover.bind(this, store, 'tools')}
          />
          {!isWhiteLabel
          && (
            <Communities
              communities={store.get('communities')}
              isProfileOwner={isProfileOwner}
              killPopover={killPopover}
              openDialog={openDialog.bind(this, store)}
              profile={{ name: profile.name }}
              triggerPopover={triggerPopover.bind(this, store, 'communities')}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

Profile.propTypes = {
  blockHoverForResource: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  fetchProjectsForResource: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
  isWhiteLabel: PropTypes.bool.isRequired,
  killPopover: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    available_for_hire: PropTypes.bool,
    avatar_url: PropTypes.string,
    bio: PropTypes.string,
    challenge_prizes: PropTypes.arrayOf(PropTypes.shape({
      category: PropTypes.shape({ id: PropTypes.number }),
      challenge: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      }),
      icon_urls: PropTypes.shape({
        x1: PropTypes.string,
        x2: PropTypes.string,
      }),
      id: PropTypes.number,
      position_name: PropTypes.string,
    })),
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    email: PropTypes.string,
    hourly_rate: PropTypes.number,
    id: PropTypes.number,
    interest: PropTypes.array,
    name: PropTypes.string,
    news_role: PropTypes.oneOf(['admin', 'author', 'editor']),
    skills: PropTypes.array,
    state: PropTypes.string,
    stats: PropTypes.shape({
      followers: PropTypes.number,
      following: PropTypes.number,
      projects: PropTypes.number,
      reputation: PropTypes.number,
    }),
    user_name: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
  setMessage: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  toggleRespect: PropTypes.func.isRequired,
  triggerPopover: PropTypes.func.isRequired,
};

export default Profile;
