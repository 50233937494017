import { formatNumberWithCommas } from '../../../utility/formatters';

const CONFIG = {
  '/': (platform) => ({
    title: `${platform.name} Community - Hackster.io`,
    meta_desc: `Learn about ${platform.name} and get inspiration from other developers. Find out where to buy, how to get started, project ideas, tutorials and tips & tricks. Discover all of ${platform.name}'s products and specs. Ask questions on the discussion board.`,
    pageType: 'platforms#show',
    entity_id: platform.id,
    canonical: platform.url,
  }),
  '/analytics': (platform) => ({
    title: `${platform.name}'s analytics - Hackster.io`,
    meta_desc: `Analytics for ${platform.name}.`,
    pageType: 'platforms#analytics',
    entity_id: platform.id,
  }),
  '/discussion': (platform) => ({
    title: `${platform.name} Discussion - Hackster.io`,
    meta_desc: `Discuss with other ${platform.name} developers to find answers to your questions! Join ${formatNumberWithCommas(platform.stats.members)} hardware developers who follow ${platform.name}.`,
    pageType: 'platforms#discussion',
    entity_id: platform.id,
    canonical: platform.url + '/discussion',
  }),
  '/members': (platform) => ({
    title: `${platform.name} Developers - Hackster.io`,
    meta_desc: `Join ${formatNumberWithCommas(platform.stats.members)} hardware developers in ${platform.name}'s community on Hackster.io.`,
    pageType: 'platforms#members',
    entity_id: platform.id,
    canonical: platform.url + '/members',
  }),
  '/projects': (platform) => ({
    title: `${platform.stats.projects > 4 ? `${formatNumberWithCommas(platform.stats.projects)} ` : ''}${platform.name} Projects & Tutorials for Beginners and Up - Hackster.io`,
    meta_desc: `Explore ${platform.stats.projects > 4 ? `${formatNumberWithCommas(platform.stats.projects)} ` : ''}${platform.name} projects and tutorials with instructions, code and schematics. New projects for beginners and up posted every day. Get inspired with ideas and build your own.`,
    pageType: 'platforms#projects',
    entity_id: platform.id,
    canonical: platform.url + '/projects',
  }),
  '/products': (platform) => ({
    title: `${platform.name} Products - Hackster.io`,
    meta_desc: `Discover all the ${platform.name} products, where to buy, and their related hardware projects.`,
    pageType: 'platforms/parts#index',
    entity_id: platform.id,
    canonical: platform.url + '/products',
  }),
  '/products/*': ({ part, platform }) => ({
    title: `${part.stats.projects > 4 ? `${formatNumberWithCommas(part.stats.projects)} ` : ''}${part.name.includes(platform.name) ? part.name : `${platform.name} ${part.name}`} Projects & Tutorials for Beginners and Up - Hackster.io`,
    meta_desc: `Explore ${part.stats.projects > 4 ? `${formatNumberWithCommas(part.stats.projects)} ` : ''}${part.name.includes(platform.name) ? part.name : `${platform.name} ${part.name}`} projects and tutorials with instructions, code and schematics. New projects for beginners and up posted every day. Get inspired with ideas and build your own.`,
    pageType: 'platforms/parts#show',
    entity_id: part.id,
    canonical: part.url,
  }),
  '/products/*/specs': ({ part, platform }) => ({
    title: `${part.name.includes(platform.name) ? part.name : `${platform.name} ${part.name}`} Specifications and Features - Hackster.io`,
    meta_desc: `What is ${part.name.includes(platform.name) ? part.name : `${platform.name} ${part.name}`} and how does it work? Where can I buy it?`,
    pageType: 'platforms/parts#specs',
    entity_id: part.id,
    canonical: part.url + '/specs',
  }),
};

const seoConfig = (path) => CONFIG[path];

export default seoConfig;
