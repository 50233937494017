import PropTypes from 'prop-types';
import React from 'react';

import styles from './tags.css';

const Tags = ({ count, highlight, normal }) => {
  if (
    count === 0
    || !Array.isArray(highlight) || !Array.isArray(normal)
    || (highlight.length + normal.length) === 0
  ) return null;

  return (
    <div className={styles.tags}>
      {highlight.slice(0, count).map((tag, i) => (
        <div key={i} className={styles.tagHighlight}>{tag}</div>
      ))}
      {normal.slice(0, Math.max(count - highlight.length, 0)).map((tag, i) => (
        <div key={i} className={styles.tag}>{tag}</div>
      ))}
    </div>
  );
};

Tags.propTypes = {
  count: PropTypes.number,
  highlight: PropTypes.array,
  normal: PropTypes.array,
};

Tags.defaultProps = {
  count: 10,
  highlight: [],
  normal: [],
};

export default Tags;
