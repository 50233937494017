import { formatNumberWithCommas } from '../../../utility/formatters';

const CONFIG = {
  '/': (topic) => ({
    title: `${topic.name} Community - Hackster.io`,
    meta_desc: `Learn about ${topic.name} and get inspiration from other developers. Find out how to get started, what to buy, project ideas, tutorials and tips & tricks. Ask questions on the discussion board.`,
    pageType: 'topics#show',
    entity_id: topic.id,
    canonical: topic.url,
  }),
  '/discussion': (topic) => ({
    title: `${topic.name} Discussion - Hackster.io`,
    meta_desc: `Discuss with other ${topic.name} developers to find answers to your questions! Join ${formatNumberWithCommas(topic.stats.members)} hardware developers who follow ${topic.name}.`,
    pageType: 'topics#discussion',
    entity_id: topic.id,
    canonical: topic.url + '/discussion',
  }),
  '/members': (topic) => ({
    title: `${topic.name} Developers`,
    meta_desc: `Join ${formatNumberWithCommas(topic.stats.members)} hardware developers in the ${topic.name} Community on Hackster.io.`,
    pageType: 'topics#members',
    entity_id: topic.id,
    canonical: topic.url + '/members',
  }),
  '/projects': (topic) => ({
    title: `${topic.stats.projects > 4 ? `${formatNumberWithCommas(topic.stats.projects)} ` : ''}${topic.name} Projects & Tutorials for Beginners and Up `,
    meta_desc: `Explore ${topic.stats.projects > 4 ? `${formatNumberWithCommas(topic.stats.projects)} ` : ''}${topic.name} projects and tutorials with instructions, code and schematics. New projects for beginners and up posted every day. Get inspired with ideas and build your own.`,
    pageType: 'topics#projects',
    entity_id: topic.id,
    canonical: topic.url + '/projects',
  }),
};

export default (path) => CONFIG[path];
