import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../client/icon';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './progress_bar.css';

const ProgressBar = ({ classList, iconSize, steps }) => (
  <ul
    className={`${layout.flexJustifySpaceBetween} ${util.ulReset} ${layout.marginAuto} ${typography.lineHeight0} ${layout.fullWidth} ${layout.gutter2} ${classList?.root}`}
  >
    {steps.map(({ href, name: step, isActive, isDisabled, isLast, isComplete, onClick }) => (
      <li key={step} className={isLast ? layout.widthFit : layout.fullWidth}>
        <div className={`${layout.flexJustifySpaceBetween} ${layout.flexCenterItems} ${layout.gutter2} ${layout.fullWidth}`}>
          <div
            className={`${util.circle} ${isDisabled ? '' : util.cursorPointer}
             ${isComplete ? styles.completedDot : styles.uncompletedDot}
             ${isActive ? styles.currentDot : ''}
          `}
          >
            <a
              aria-disabled={isDisabled}
              aria-label={step}
              className={`${typography.lineHeight0} ${styles.link}`}
              href={isDisabled ? null : href}
              onClick={onClick}
              role="link"
            >
              {isComplete
                ? (
                  <Icon
                    className={`${typography.white} ${styles.icon}`}
                    name="checkmark-bold"
                    size={iconSize}
                  />
                  )
                : (
                  <div style={{ width: `${iconSize}px`, height: `${iconSize}px` }} />
                  )}
            </a>
          </div>
          {!isLast && <div className={`${layout.flexCenterCenter} ${util.borderRadiusSmall} ${isComplete ? styles.completedBar : styles.uncompletedBar}`} />}
        </div>
      </li>
    ))}
  </ul>
);

ProgressBar.propTypes = {
  classList: PropTypes.shape({ root: PropTypes.string }),
  iconSize: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  })).isRequired,
};

export default ProgressBar;
