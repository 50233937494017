import React from 'react';

import btnStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './notification_dropdown.css';

const OnboardingNotification = () => (
  <div className={styles.onboarding}>
    <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom15} ${typography.lineHeight1}`}>You&apos;re almost done!</p>
    <a
      className={`${btnStyles.md}`}
      href="/onboarding"
    >
      Finish setting up your account
    </a>
  </div>
);

export default OnboardingNotification;
