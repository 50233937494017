import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../../../client/icon';
import QuestionMarkTooltip from '../../../../../client/tooltips/question_mark';
import SubmissionStatusTooltip from './SubmissionStatusTooltip';

import colors from '../../../../../styles/global_ui/colors.css';
import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import styles from './submission_card.css';

import {
  // type
  ENTRY,
  IDEA,
  // status: idea/entry
  APPROVED,
  DRAFT,
  PENDING_REVIEW,
  REJECTED,
  // status: entry only
  INCOMPLETE,
  RESUBMITTED,
  LOST,
  WON,
  WON_OTHER_CONTEST,
} from '../../../constants';

const _getDisqualifiedStatusProps = () => ({
  backgroundColor: 'var(--errorBG)',
  iconColor: 'var(--errorToWhite)',
  icon: 'exclamation',
  text: 'Disqualified',
});

const _getLosingStatusProps = (type, contestType) => ({
  backgroundColor: colors['v-asphalt'],
  text: type === ENTRY ? 'Non-winning submission' : contestType === 'challenge' ? 'Proposal not approved' : 'Not awarded hardware',
});

const _getIncompleteStatusProps = (rejected) => ({
  backgroundColor: 'var(--errorBG)',
  iconColor: 'var(--errorToWhite)',
  icon: 'exclamation',
  text: rejected ? 'Not eligible' : 'Missing requirements',
});

const _getSubmittedStatusProps = (resubmitted) => ({
  backgroundColor: 'var(--successBG)',
  iconColor: 'var(--successToWhite)',
  icon: 'checkmark',
  text: resubmitted ? 'Resubmitted' : 'Submitted',
});
const _getWinningStatusProps = (type, contestType) => ({
  backgroundColor: 'var(--successBG)',
  text: type === ENTRY ? 'Winning submission' : contestType === 'challenge' ? 'Proposal accepted' : 'Awarded hardware',
});

const _getStatusProps = (item, type, contestType) => {
  switch (item.status) {
    case APPROVED:
      return type === ENTRY
        ? _getSubmittedStatusProps()
        : _getWinningStatusProps(type, contestType);
    case INCOMPLETE:
      return _getIncompleteStatusProps();
    case LOST:
      return _getLosingStatusProps(type, contestType);
    case PENDING_REVIEW:
      return _getSubmittedStatusProps();
    case REJECTED:
      return type === ENTRY
        ? _getIncompleteStatusProps(true)
        : _getLosingStatusProps(type, contestType);
    case RESUBMITTED:
      return _getSubmittedStatusProps(true);
    case WON:
      return _getWinningStatusProps(type, contestType);
    case WON_OTHER_CONTEST:
      return _getDisqualifiedStatusProps();
    case DRAFT:
    default:
      return null;
  }
};

const SubmissionStatus = ({ closeTooltip, contestType, faqPath, item, summonTooltip, type }) => {
  const statusProps = _getStatusProps(item, type, contestType);
  if (!statusProps) return null;

  return (
    <div className={`${layout.marginTop10} ${layout.flexCenterItems}`}>
      <div
        className={`${styles.statusPill} ${typography.textWithIcon}`}
        style={{ background: statusProps.backgroundColor }}
      >
        {statusProps.icon && (
          <Icon
            className={layout.marginRight5}
            name={statusProps.icon}
            size="12"
            style={{ color: statusProps.iconColor }}
          />
        )}
        <span className={`${typography.bodyS} ${typography.graphite} `}>
          {statusProps.text}
        </span>
      </div>
      <div className={layout.marginLeft5} style={{ lineHeight: 0 }}>
        <QuestionMarkTooltip
          position="bottom"
          theme="dark"
          tooltip={<SubmissionStatusTooltip faqPath={faqPath} item={item} type={type} />}
        />
      </div>
    </div>
  );
};

SubmissionStatus.propTypes = {
  closeTooltip: PropTypes.func,
  contestType: PropTypes.oneOf(['challenge', 'competition']),
  faqPath: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.shape({ // ENTRY
      can_be_resubmitted: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      is_prize_address_present: PropTypes.bool,
      moderator_feedback: PropTypes.string,
      prize_requires_shipping: PropTypes.bool,
      project: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        edit_url: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      status: PropTypes.string.isRequired,
      submit_url: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    PropTypes.shape({ // IDEA
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updated_at: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
  ]),
  summonTooltip: PropTypes.func,
  type: PropTypes.oneOf([ENTRY, IDEA]).isRequired,
};

SubmissionStatus.defaultProps = {
  closeTooltip: () => {},
  summonTooltip: () => {},
};

export default SubmissionStatus;
