import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Notification from './Notification';

import fadeIn from '../../../../styles/transitions/fade_in_not_out.css';
import overlayStyles from '../../../../styles/common-overlay.css';
import slideDown from '../../../../styles/transitions/notification_transitions.css';
import utilStyles from '../../../../styles/global_ui/util.css';

function createList({ currentUser, markRead, notifications }) {
  return notifications.map((notification) => (
    <Notification key={notification.receipt_id} currentUser={currentUser} markRead={markRead} notification={notification} />
  ));
}

function transitionFullList(props) {
  return (
    <TransitionGroup>
      <CSSTransition key={props.pageKey} appear={true} classNames={fadeIn} timeout={{ enter: 350, exit: 20 }}>
        <ul className={`${utilStyles.ulReset} ${utilStyles.posRelative}`}>
          {createList(props)}
          {props.loading && props.notifications.length > 0
          && <div className={overlayStyles.overlay} />}
        </ul>
      </CSSTransition>
    </TransitionGroup>
  );
}

function transitionIndividualItems({ currentUser, markRead, notifications }) {
  return (
    <TransitionGroup className={utilStyles.ulReset} component="ul">
      {notifications.map((notification) => (
        <CSSTransition key={notification.receipt_id} classNames={slideDown} timeout={350}>
          <Notification currentUser={currentUser} markRead={markRead} notification={notification} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}

const NotificationsList = (props) => (
  <div className={props.className}>
    {props.individualTransitions ? transitionIndividualItems(props) : transitionFullList(props)}
  </div>
);

NotificationsList.propTypes = {
  className: PropTypes.string,
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  individualTransitions: PropTypes.bool,
  loading: PropTypes.bool,
  markRead: PropTypes.func,
  notifications: PropTypes.array.isRequired,
  pageKey: PropTypes.string,
};

NotificationsList.defaultProps = {
  className: '',
  individualTransitions: false,
  loading: false,
  markRead: () => Promise.resolve(),
  pageKey: '',
};

export default NotificationsList;
