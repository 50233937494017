export function setStore(store, projects) {
  ['drafts', 'public', 'guest'].forEach((category) => {
    if (projects[category]) {
      store.set(`projects/${category}`, { projects: { [category]: projects[category] } });
    }
  });
}

export function updateRecords(updated, oldProjects, category = 'public', startIndex = 0) {
  const newRecords = oldProjects[category].records.slice(0, startIndex)
    .concat(updated)
    .concat(oldProjects[category].records.slice(startIndex + updated.length));

  return {
    ...oldProjects,
    [category]: {
      ...oldProjects[category],
      records: newRecords,
    },
  };
}
