import { capitalize, unsnakeString } from '../../utility/formatters';

const capitalizeWordsAndAddCommas = (str) => (
  // TODO: This formatter needs a little work. We'll want to make sure things like (Ai -> AI) are full caps + add commas to lists (i.e. space, planets, and stars v.s. space and planets)
  // Alternative would be to have a giant
  str.split(' ')
    .map((word) => (word === 'and' || word === 'or') ? word : capitalize(word))
    .join(' ')
);

const capitalizeWords = (str) => (
  str.split(' ')
    .map((word) => (word === 'and' || word === 'or') ? word : capitalize(word))
    .join(' ')
);

export const objectToOptions = (object) => (
  Object.entries(object).map(([value, label]) => ({ label, value }))
);

export const jobOptsFormatter = (jobOpts) => (
  jobOpts.map((opt) => (
    {
      label: unsnakeString(opt, capitalizeWords),
      value: opt,
    }),
  )
);

export const skillLevelOptsFormatter = (skillLevelOpts) => (
  skillLevelOpts.map((opt) => (
    {
      label: opt === 'hardcore' ? 'Expert' : capitalize(opt),
      value: opt,
    }),
  )
);
