import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AdsSection from './AdsSection';
import ArticlesList from '../shared/articles_list';
import FeaturedSection from './FeaturedSection';
import NewsGalleryAd from '../../../client/ads/news_gallery/NewsGalleryAd';
import HorizontalScrollBox from '../../../client/wrappers/horizontal_scroll_box';
import MoreArticlesSection from './MoreArticlesSection';
import SimpleSelect from '../../../client/form_components/simple_select';
import SponsoredSection from './SponsoredSection';
import TrendingSection from './TrendingSection';

import createHistory from '../../../client/reusable_components/Router/history';

import { graphQuery } from '../../../requests/graphql';
import { NEWS_HOME_PAGE } from '../../../graphql/ads/enums';
import { PUBLISHED_SORT, PUBLISHED_STATUS, TRENDING_DESC_SORT } from '../../../graphql/news/enums';

import AdsService from '../../../services/ads';
import errorHandler from '../../../services/error_handler';
import keenService from '../../../services/keen/main';
import { getInObj } from '../../../utility/accessors';
import { filterObject } from '../../../utility/filters';
import { mapifyStringQuery, mapToStringQuery } from '../../../utility/converters';

import { SIMPLE_PAGINATION } from '../../../constants/pagination';

import gridStyles from '../../../styles/global_ui/grid.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './news_home_page.css';
import truncateArticlesForCenterColumn from '../home_edit_page/truncateArticlesForCenterColumn';

const INJECTABLE_INDEX = 3;
const TOPIC_QUERY_PARAM = 'topic';
const DEFAULT_TOPIC_ARTICLES_STATE = {
  pagination: SIMPLE_PAGINATION,
  records: [],
};

class NewsHomePage extends Component {
  constructor(props) {
    super(props);

    this.history = createHistory(props.path_helpers.basePath, props.path_helpers.fullPath);
    this.state = {
      ads: {},
      adsInitialized: false,
      currentHistoryData: this.history.location,
      isBusy: false,
      latest: truncateArticlesForCenterColumn(props.latest),
      timeFromNowSync: 0, // If the "latest" section gets hidden then re-rendered, its "timeFromNow" values will drift apart from the corresponding article cards in the "more articles" section. this flag lets us rerender the "more articles" section to sync up the displayed timestamps
      trending: null,
      ...this._initRecordsAndPagination(props.topic_articles, this.history.location),
    };

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSearchQueryUpdate = this.handleSearchQueryUpdate.bind(this);

    this.adsService = new AdsService(NEWS_HOME_PAGE);
  }

  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
    if (!this._doesInitialTopicArticlesAndQueryParamMatch()) this._fetchRecordsForTopic();
    this._fetchAds();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Initializers
   */
  _initRecordsAndPagination(topic_articles, currentHistoryData) {
    const topic = this._getCurrentTopicFromCurrentHistory(currentHistoryData.search);

    // Expects the topic parameter to match the buckets key.
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    /* eslint-disable-next-line no-prototype-builtins, @stylistic/no-mixed-operators */
    if (!topic_articles || !topic || topic_articles && !topic_articles.hasOwnProperty(topic)) return DEFAULT_TOPIC_ARTICLES_STATE;

    const data = topic_articles[topic];

    return {
      pagination: data.metadata,
      records: data.records,
    };
  }

  /**
   * Methods
   */
  handleLocationChange(pathData, action) {
    this.setState({
      currentHistoryData: { ...pathData, action },
      records: [],
    }, () => {
      this._fetchRecordsForTopic();
      keenService.reportVirtualPageView();
    });
  }

  handleSearchQueryUpdate(queryMap) {
    const currentQueryMap = mapifyStringQuery(this.state.currentHistoryData.search);
    const search = mapToStringQuery(filterObject({ ...currentQueryMap, ...queryMap }));
    this.history.push({ search });
  }

  /**
   * Helpers
   */
  _doesCurrentTopicExistInTopicFilters() {
    const topicSlug = this._getCurrentTopicFromCurrentHistory();

    return this.props.topic_filters.findIndex((tf) => tf.value === topicSlug) > -1;
  }

  _doesInitialTopicArticlesAndQueryParamMatch() {
    const topic = this._getCurrentTopicFromCurrentHistory();

    if ((!this.props.topic_articles && !topic) // Home page
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      || (this.props.topic_articles && this.props.topic_articles.hasOwnProperty(topic))) {
      return true;
    }
    // Also handles last case where topic_articles exists but the topic does not.
    return false;
  }

  _fetchAds() {
    return this._getAds()
      .then((ads) => {
        this.setState({
          ads: ads,
          adsInitialized: true,
        });
      })
      .catch((err) => errorHandler('NewsHomePage _fetchAds', err));
  }

  _fetchFirstTwoPaginatedArticlePages() {
    this.setState({ isBusy: true });

    const firstRequestArgs = { ...this._getPaginatedArticlesArgs(), offset: 0, page: 1, per_page: 24 };
    const secondRequestArgs = { ...this._getPaginatedArticlesArgs(), offset: 12, page: 2, per_page: 12 };

    return Promise.all([
      this._getPaginatedArticles(firstRequestArgs),
      this._getPaginatedArticles(secondRequestArgs),
    ])
      .then((results) => {
        this.setState({
          isBusy: false,
          pagination: results[1].metadata,
          records: results[0].records.concat(results[1].records),
        });
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        errorHandler('NewsHomePage _fetchFirstTwoPaginatedArticlePages:', err);
      });
  }

  // Handles location history changes.
  _fetchRecordsForTopic() {
    if (!this._doesCurrentTopicExistInTopicFilters()) return this._resetTopicArticlesState();
    this._fetchPaginatedArticles();
  }

  // Handles more button pagination.
  _fetchMoreRecordsForTopic() {
    // This means we're on the initial page which was server rendered. Those initial records are cached and may become stale.
    // To remedy this, we're going to make 2 requests silently to get the fresh records to avoid duplicates.
    return this.state.pagination.current_page === 1 ? this._fetchFirstTwoPaginatedArticlePages() : this._fetchPaginatedArticles();
  }

  _fetchPaginatedArticles() {
    this.setState({ isBusy: true });

    return this._getPaginatedArticles()
      .then((articles) => {
        this.setState({
          isBusy: false,
          pagination: articles.metadata,
          records: !this.state.records.length ? articles.records : this.state.records.concat(articles.records),
        });
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        errorHandler('NewsHomePage _fetchPaginatedArticles:', err);
      });
  }

  _fetchPaginatedArticlesWithAds() {
    this.setState({ isBusy: true });

    return this._getAds()
      .then((ads) => Promise.all([ads, this._getPaginatedArticles(this._fetchPaginatedArticlesWithAdsArgs(ads))]))
      .then(([ads, articles]) => {
        this.setState({
          ads: ads,
          adsInitialized: true,
          isBusy: false,
          pagination: articles.metadata,
          records: !this.state.records.length ? articles.records : this.state.records.concat(articles.records),
        });
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        errorHandler('NewsHomePage _fetchPaginatedArticlesWithAds:', err);
      });
  }

  _fetchPaginatedArticlesWithAdsArgs(ads) {
    const hasGalleryAd = getInObj(['gallery'], ads) !== null;
    const initialRequest = this.state.records.length === 0;

    const offset = initialRequest ? 0 : (hasGalleryAd ? -1 : 0);
    const per_page = initialRequest && hasGalleryAd ? 11 : 12;

    return { ...this._getPaginatedArticlesArgs(), offset, per_page };
  }

  _getInjectableConfigForMoreArticlesSection() {
    if (this.state.adsInitialized && getInObj(['gallery'], this.state.ads) === null) return null;

    return {
      component: NewsGalleryAd,
      index: INJECTABLE_INDEX,
      props: { ad: this.state.ads.gallery, initialized: this.state.adsInitialized },
    };
  }

  _getTopicFilterOptions() {
    const topic = this._getCurrentTopicFromCurrentHistory();

    return [{ default: true, label: 'All', value: null }].concat(this.props.topic_filters).map((opt) => (
      (topic === opt.value) ? { ...opt, active: true, disabled: true } : opt
    ));
  }

  _getTopicIdFromTopicSlug(queryMap) {
    const slug = getInObj([TOPIC_QUERY_PARAM], queryMap);
    if (!slug) return null;

    const topic = this.props.topic_filters.find((tf) => tf.value === slug);

    return topic ? topic.id : null;
  }

  _getCurrentTopicFromCurrentHistory(historyQuery = this.state.currentHistoryData.search) {
    return getInObj([TOPIC_QUERY_PARAM], mapifyStringQuery(historyQuery));
  }

  _resetTopicArticlesState() {
    if (this.state.records.length > 0) {
      this.setState({ ...DEFAULT_TOPIC_ARTICLES_STATE });
    }
  }

  /**
   * Requests
   */
  _getAds() {
    return this.state.adsInitialized ? Promise.resolve(this.state.ads) : this.adsService.getAdsForPage();
  }

  _getPaginatedArticles(queryArgs = this._getPaginatedArticlesArgs()) {
    return new Promise((resolve, reject) => graphQuery({ t: 'news_articles_simple_pagination' }, queryArgs)
      .then(({ articles }) => resolve(articles))
      .catch((err) => reject(err)));
  }

  _getPaginatedArticlesArgs() {
    const queryMap = mapifyStringQuery(this.state.currentHistoryData.search);
    const topic_id = this._getTopicIdFromTopicSlug(queryMap);

    const by_topic_id = topic_id ? { by_topic_id: topic_id } : {};

    return {
      ...by_topic_id,
      ...this._getPaginationArgsForRequest(topic_id),
      by_sponsored: false,
      by_status_type: PUBLISHED_STATUS,
      sort: PUBLISHED_SORT,
    };
  }

  _getPaginationArgsForRequest(topic_id) {
    const initialRequest = this.state.records.length === 0;
    // NOTE: this assumes that the paginator will do its job and not render when next_page is null.
    const page = initialRequest ? {} : { page: this.state.pagination.next_page };

    return topic_id
      ? { ...page, offset: initialRequest ? 0 : 12, per_page: initialRequest ? 24 : 12 }
      : { ...page, per_page: 12 };
  }

  _getTrendingArticles() {
    return graphQuery({ t: 'news_articles_light' }, {
      by_sponsored: false,
      by_status_type: PUBLISHED_STATUS,
      limit: 7,
      sort: TRENDING_DESC_SORT,
    })
      .then(({ articles }) => this.setState({ trending: truncateArticlesForCenterColumn(articles) }))
      .catch((err) => errorHandler('NewsHomePage _getTrendingArticles:', err));
  }

  /**
   * Views
   */
  _getMainView() {
    return this._doesCurrentTopicExistInTopicFilters() ? this._getArticlesListView() : this._getDefaultView();
  }

  _getDefaultView() {
    return (
      <Fragment>
        <div className={layout.container}>
          <div className={layout.wrapper1170}>
            <div className={layout.flexJustifyCenter}>
              <div className={styles.mainContentWrapper}>
                <div className={styles.aboveTheFoldWrapper}>
                  <FeaturedSection records={this.props.featured} />
                  <TrendingSection
                    getTrendingRecords={() => this._getTrendingArticles()}
                    latest={this.state.latest}
                    onLatestClick={() => this.setState({ timeFromNowSync: this.state.timeFromNowSync + 1 })}
                    trending={this.state.trending}
                  />
                  <AdsSection
                    ad_urls={this.props.ad_urls}
                    ads={this.state.ads}
                    adsInitialized={this.state.adsInitialized}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SponsoredSection records={this.props.sponsored} />
        <div className={`${layout.fullWidth} ${layout.flexJustifyCenter} ${layout.padding015}`}>
          <div className={`${layout.wrapper1170} ${layout.marginBottom60}`}>
            <MoreArticlesSection
              key={this.state.timeFromNowSync}
              getArticles={() => this._fetchPaginatedArticlesWithAds()}
              injectableConfig={this._getInjectableConfigForMoreArticlesSection()}
              isBusy={this.state.isBusy}
              pagination={this.state.pagination}
              records={this.state.records}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  _getArticlesListView() {
    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          <ArticlesList
            articles={{ records: this.state.records, metadata: this.state.pagination }}
            className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
            gutterSize={15}
            handlePaginatorClick={() => this._fetchMoreRecordsForTopic()}
            isBusy={this.state.isBusy}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={`${layout.fullWidth} ${styles.container}`}>
        <div className={`${layout.fullWidth} ${layout.flexJustifyCenter} ${layout.paddingTop30} ${layout.paddingLeft15} ${layout.paddingRight15}`}>
          <div className={`${layout.wrapper1170} ${utilStyles.borderBottom}`}>
            <h1 className={`${typography.h1} ${layout.marginBottom15}`}>News</h1>
            <HorizontalScrollBox>
              <SimpleSelect
                onSelection={(opt) => this.handleSearchQueryUpdate({ [TOPIC_QUERY_PARAM]: opt.value, page: null })}
                options={this._getTopicFilterOptions()}
                tabbedViewUnderline={false}
                view="tabbed"
              />
            </HorizontalScrollBox>
          </div>
        </div>
        {this._getMainView()}
      </div>
    );
  }
}

NewsHomePage.propTypes = {
  ad_urls: PropTypes.shape({
    banner: PropTypes.shape({
      href: PropTypes.string.isRequired,
      mobile_url: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  featured: PropTypes.arrayOf(PropTypes.object).isRequired,
  latest: PropTypes.arrayOf(PropTypes.object).isRequired,
  path_helpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
  sponsored: PropTypes.arrayOf(PropTypes.shape({})),
  topic_articles: PropTypes.objectOf( // The nested key will map to the topic value dynamically
    PropTypes.shape({
      metadata: PropTypes.object,
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  topic_filters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

NewsHomePage.defaultProps = { topic_articles: null };

export default NewsHomePage;
