import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../client/buttons/base';
import FormSelect from '../../../../client/form_components/selects/form_select';

import { isRequired } from '../../../../services/validation/validators';
import { getErrorHelperView } from '../../../../client/form_components/templates';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './side_panel.css';

const FIELD_ERROR = 'Please select a project to add.';

class AddProjectPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      value: '',
    };

    this.validate = this.validate.bind(this);
  }

  validate() {
    const error = isRequired(this.state.value, FIELD_ERROR);

    if (error) {
      this.setState({ error });
    } else {
      this.props.createEntry(this.state.value.value);
    }
  }

  render() {
    return (
      <div className={styles.panel}>
        <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom10}`}>Add existing project</p>
        <div className={layout.marginBottom15}>
          <FormSelect
            asyncOpts={{
              initOnMount: true,
              request: this.props.getUserProjects,
              resolver: (res) => ({ options: res.map((p) => ({ label: p.name, value: p.id })) }),
            }}
            onSelectedChange={(opt) => this.setState({ value: opt })}
            placeholder="Select a project"
            value={this.state.value}
          />
          {getErrorHelperView(this.state.error)}
        </div>
        <div>
          <Button disabled={this.props.isBusy} onClick={this.validate}>
            Add project
          </Button>
          <Button colorStyle="cancel" disabled={this.props.isBusy} onClick={this.props.dismiss}>
            Cancel
          </Button>
          {getErrorHelperView(this.props.serverError)}
        </div>
      </div>
    );
  }
}

AddProjectPanel.propTypes = {
  createEntry: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  serverError: PropTypes.string,
};

AddProjectPanel.defaultProps = { serverError: null };

export default AddProjectPanel;
