import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../client/icon';

import algoliaService from '../../../services/algolia';
import errorHandler from '../../../services/error_handler';
import queryStringToNormalized from '../../../services/algolia/converters/queryStringToNormalized';
import urlService from '../../../services/url_service';
import { filterSearchQuery } from '../../../services/algolia/filterSearchQuery';
import { storeSearchQuery } from '../../../services/keen/searchTracker';
import { stripExcessWhiteSpace } from '../../../utility/formatters';
import { windowLocationPathname, windowLocationRedirect, windowLocationSearch } from '../../../services/window';

import styles from './whitelabel_input.css';

const SEARCH_PATH = '/search';

class WhitelabelSearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
      value: '',
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleQueryUpdate = this.handleQueryUpdate.bind(this);

    // instance variables
    this.onSearchPath;

    // Refs.
    this.input;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this.onSearchPath = this._isOnSearchPath();

    if (this.onSearchPath) {
      this._initializeFromSearchParams();
    } else {
      // This is for slow connections where server rendering will enable the input before the client JS loads.
      if (this.input && this.input.value && this.input.value.length > 0) {
        this.handleOnChange({ target: { value: stripExcessWhiteSpace(this.input.value) } });
      }
    }

    algoliaService.getChannel().subscribe('queryUpdate', this.handleQueryUpdate);
  }

  /**
   * Initializers
   */
  _initializeFromSearchParams() {
    const queryString = windowLocationSearch.get().substring(1);
    const normalized = queryStringToNormalized(queryString);
    const query = normalized.query;

    algoliaService.enableHistoryPushState();

    // Note: createSuggestion will fire a request to the projects index to calculate Suggestions.
    if (query && query.length) {
      algoliaService.createSuggestion(query);
      this.setState({ value: query });
    }

    // Honor any search params.
    return algoliaService.initializeWithEffects(normalized, this.props.channelId)
      .then(() => algoliaService.getChannel().publish('working', false))
      .catch((err) => {
        algoliaService.getChannel().publish('working', false);
        errorHandler('WhitelabelSearchInput _initializeFromSearchParams', err);
      });
  }

  /**
   * Methods
   */
  handleKeyDown(e) {
    if (e.keyCode === 13 /* ENTER */) {
      this.handleQuery();
    }
  }

  handleOnChange(e) {
    let value = e.target.value;

    if (value.length > 255) {
      value = value.slice(0, 255);
    }

    this.setState({ value });
  }

  handleQuery(query = this.state.value, suggestion = null) {
    if (this.onSearchPath === false) {
      return this._redirectWithQuery(query);
    }

    const queryMap = algoliaService.processQueryMap({ query });

    if (queryMap.query.length > 0) {
      algoliaService.createSuggestion(queryMap.query);
    }

    if (queryMap.query !== this.state.value) {
      this.setState({ value: queryMap.query });
    }

    return algoliaService.searchWithEffects(queryMap, suggestion, true)
      .catch((err) => errorHandler('handleQuery', err));
  }

  handleQueryUpdate(query = '') {
    if (this.state.value !== query) {
      this.setState({ value: query });
    }
  }

  /**
   * Helpers
   */
  _isOnSearchPath() {
    return windowLocationPathname.get() === urlService.url(SEARCH_PATH);
  }

  _redirectWithQuery(query) {
    const cleanQuery = filterSearchQuery(query);
    storeSearchQuery(cleanQuery);
    windowLocationRedirect(algoliaService.getRedirectPath(query, urlService.url(SEARCH_PATH)));
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <div className={styles.searchIconWrapper} onClick={() => this.handleQuery()}>
            <Icon name="search" size="16" />
          </div>
          <input
            ref={(el) => this.input = el}
            className={styles.input}
            onChange={this.handleOnChange}
            onKeyDown={this.handleKeyDown}
            placeholder={this.props.placeholder}
            type="text"
            value={this.state.value}
          />
        </div>
      </div>
    );
  }
}

WhitelabelSearchInput.propTypes = {
  channelId: PropTypes.number,
  placeholder: PropTypes.string,
};

WhitelabelSearchInput.defaultProps = {
  channelId: null,
  placeholder: 'Search',
};

export default WhitelabelSearchInput;
