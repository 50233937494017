import KeenService from './index';
import errorHandler from '../error_handler';
import { deleteItem, getItem, setItem } from '../local_storage';

const keenService = new KeenService();
const LS_KEY = 'hck.kn.sq';

// sanity check. if the stored search term isn't the same as the current one, it's leftover from something unrelated
function validateStoredQuery(storedQuery, currentQuery) {
  return !!storedQuery && currentQuery === storedQuery;
}

export function storeSearchQuery(query) {
  // pass search term across redirect so we know it came from a non-search page
  setItem(LS_KEY, query);
}

export function trackSearchEvent({ filters, index, query, sort } = {}, { page, hitsPerPage, nbPages, nbHits } = {}) {
  const storedQuery = getItem(LS_KEY);
  deleteItem(LS_KEY);

  const search_type = validateStoredQuery(storedQuery, query) ? 'simple' : 'advanced';

  const customProps = {
    filters,
    pagination: {
      current_page: page,
      per_page: hitsPerPage,
      total_pages: nbPages,
      total_records: nbHits,
    },
    query,
    result_type: index,
    search_type,
    sort,
  };

  return keenService.recordEvent({ eventName: 'Searched' }, customProps)
    .catch((err) => errorHandler(err));
}
