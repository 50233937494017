import React from 'react';
import PropTypes from 'prop-types';

import ActionsOverlay from './ActionsOverlay';
import ProjectCard from '../project_card';

import styles from './project_edit_card.css';

const ProjectEditCard = ({ attributes, cardBorder, isTouchDevice, listeners, item, sortable, toggleRespect }) => (
  <div className={`${styles.item} ${styles.overlayParent}`}>
    <ProjectCard
      cardBorder={cardBorder}
      enableOverlay={false}
      project={item}
      toggleRespect={toggleRespect}
    />
    <ActionsOverlay
      attributes={attributes}
      isTouchDevice={isTouchDevice}
      listeners={listeners}
      project={item}
      sortable={sortable}
    />
  </div>
);

ProjectEditCard.propTypes = {
  attributes: PropTypes.object,
  cardBorder: PropTypes.bool,
  isTouchDevice: PropTypes.bool,
  item: PropTypes.shape({
    cover_image_url: PropTypes.string,
    difficulty: PropTypes.string,
    edit_url: PropTypes.string,
    hid: PropTypes.string,
    name: PropTypes.string,
    one_liner: PropTypes.string,
    stats: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  listeners: PropTypes.object,
  sortable: PropTypes.bool,
  toggleRespect: PropTypes.func,
};

ProjectEditCard.defaultProps = {
  attributes: {},
  cardBorder: false,
  isTouchDevice: false,
  listeners: {},
  orderable: true,
  toggleRespect: () => Promise.resolve(),
};

export default ProjectEditCard;
