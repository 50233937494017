import React from 'react';
import PropTypes from 'prop-types';
import urlService from '../../../../services/url_service';
import defaultStyles from './breadcrumbs.css';

const Breadcrumbs = ({ links, classList }) => {
  const styles = { ...defaultStyles, ...classList };

  return (
    <div className={styles.linkContainer}>
      {links.map((link, i, list) => (
        <span key={i} className={styles.link}>
          {
            link.href
              ? <a href={urlService.url(link.href)}>{link.text}</a>
              : link.callback && !link.isCurrentView
                ? <a href="javascript:void(0);" onClick={() => link.callback()}>{link.text}</a>
                : link.isCurrentView
                  ? <span className={styles.current}>{link.text}</span>
                  : <span>{link.text}</span>
          }
          {i !== list.length - 1 && <span className={styles.arrow}>&gt;</span>}
        </span>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  classList: PropTypes.shape({
    linkContainer: PropTypes.string,
    link: PropTypes.string,
    arrow: PropTypes.string,
    current: PropTypes.string,
  }),
  links: PropTypes.array, // link object => { text: '', href: '' }
};
Breadcrumbs.defaultProps = {
  classList: {},
  links: [],
};

export default Breadcrumbs;
