import PropTypes from 'prop-types';
import React from 'react';
import sanitizer from 'sanitizer';
import { isObject } from '../../../../../utility/types';

import styles from './body.css';

const bodyTypes = {
  htmlString: (htmlString) => (<div dangerouslySetInnerHTML={{ __html: sanitizer.sanitize(htmlString) }} className={styles.body} />),
  component: (component) => (<div className={styles.body}>{component}</div>),
};

function getBody(body) {
  if (!isObject(body) || !Object.keys(body).length) return null;

  return Object.keys(bodyTypes).reduce((acc, key) => {
    const content = body[key];

    return body[key] && !acc ? bodyTypes[key](content) : acc;
  }, null);
}

const Body = ({ body, children }) => {
  if (children) return <div className={styles.body}>{children}</div>;

  return getBody(body);
};

Body.propTypes = {
  body: PropTypes.shape({
    component: PropTypes.element,
    htmlString: PropTypes.string,
  }),
};

Body.defaultProps = { body: null };

export default Body;
