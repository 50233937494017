import React from 'react';
import PropTypes from 'prop-types';

// TODO: get new icons for below
import MoveArrows from '../../reusable_components/icons/MoveArrows';
import Pencil from '../../reusable_components/icons/Pencil';

import { getProjectLink, isEditable } from '../project_card/helpers';

import styles from './project_edit_card.css';

const DragHandle = ({ attributes, listeners }) => (
  <div {...attributes} {...listeners} className={styles.action}>
    <MoveArrows className={styles.buttonIcon} />
  </div>
);

const ActionsOverlay = ({ attributes, isTouchDevice, listeners, project, sortable }) => (
  <div className={isTouchDevice ? styles.actionsOverlayAlwaysOn : styles.actionsOverlay}>
    <a
      className={styles.transparency}
      href={getProjectLink(project, 'url')}
    />
    <div className={styles.actions}>
      {sortable && <DragHandle attributes={attributes} listeners={listeners} />}
      {isEditable(project)
      && (
        <a className={styles.action} href={project.edit_url}>
          <Pencil className={styles.buttonIcon} />
        </a>
      )}
    </div>
  </div>
);

ActionsOverlay.propTypes = {
  attributes: PropTypes.object,
  isTouchDevice: PropTypes.bool,
  listeners: PropTypes.object,
  project: PropTypes.shape({
    edit_url: PropTypes.string,
    project_type: PropTypes.string,
    type_label: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  sortable: PropTypes.bool,
};

ActionsOverlay.defaultProps = {
  attributes: {},
  isTouchDevice: false,
  listeners: {},
  sortable: true,
};

export default ActionsOverlay;
