import components from './components';
import { renderReact } from './renderReact';

/**
 * renderReact(<KEY>, <CODE>):
 * renderReact will use the key to find any mounted components in the dom and hydrate them.  Hypernova renders script tags below the mounting
 * elements which hold the props for its matching component.  If a component doesn't exist on the page (no matching dom nodes), renderReact will
 * skip over any mounting logic.
 */
export default function renderHypernovaClient() {
  for (const component in components) {
    renderReact(component, components[component]);
  }
}
