import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../../client/reusable_components/Accordion/Accordion';
import Contests from './AccordionContent/Contests';
import Channels from './AccordionContent/Channels';
import DevEngagement from './AccordionContent/DevEngagement';
import Events from './AccordionContent/Events';
import LazyImage from '../../client/reusable_components/LazyImage';
import MonthlyReach from './AccordionContent/Marketing';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import util from '../../styles/global_ui/util.css';
import styles from './business.css';

const c = {
  title: `${layout.flexCenterCenter} ${layout.gutter30} ${styles.accordionTitle}`,
  image: `${util.bgTransparent} ${layout.flexCenterCenter} ${styles.accordionIcon}`,
  section: `${layout.wrapper1170} ${layout.flexColumnCenterCenter} ${typography.textCenter} ${styles.expertise}`,
  header: `${typography.h3Responsive} ${typography.bold} ${typography.hackster} ${layout.margin0} ${layout.marginBottom10}`,
  subHeading: `${typography.h1Responsive} ${typography.staticCharcoal} ${layout.marginBottom30}`,
  accordionWrapper: `${layout.flexColumn} ${layout.fullWidth} ${layout.gutter15} ${typography.textLeft}`,
  accordion: `${layout.flexColumn} ${styles.accordion}`,
  accordionDetails: `${util.bgStaticWhite} ${typography.staticCharcoal}`,
};

const Title = ({ iconSrc, title }) => (
  <span className={c.title}>
    <LazyImage
      alt={`icon for ${iconSrc}`}
      classList={{ root: c.image }}
      fit="fill"
      ratio="1:1"
      src={`https://hackster.imgix.net/static/marketing/icons/${iconSrc}.png`}
      width={73}
    />
    <span>{title}</span>
  </span>
);

Title.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const toggleGroup = 'businessExpertise';

const Expertise = () => (
  <section className={c.section}>
    <h2 className={c.header}>Our Expertise</h2>
    <h3 className={c.subHeading}>What can we do for you?</h3>
    <div className={c.accordionWrapper}>
      <Accordion
        classList={{ content: c.accordion, root: c.accordionDetails }}
        group={toggleGroup}
        title={<Title iconSrc="marketing_advertising" title="Marketing and Advertising" />}
      >
        <MonthlyReach />
      </Accordion>
      <Accordion
        classList={{ content: c.accordion, root: c.accordionDetails }}
        group={toggleGroup}
        title={<Title iconSrc="engineering_contests" title="Engineering Contest" />}
      >
        <Contests />
      </Accordion>
      <Accordion
        classList={{ content: c.accordion, root: c.accordionDetails }}
        group={toggleGroup}
        title={<Title iconSrc="branded_channels" title="Branded Channels" />}
      >
        <Channels />
      </Accordion>
      <Accordion
        classList={{ content: c.accordion, root: c.accordionDetails }}
        group={toggleGroup}
        title={<Title iconSrc="virtual_event_hosting" title="Virtual Event Hosting" />}
      >
        <Events />
      </Accordion>
      <Accordion
        classList={{ content: c.accordion, root: c.accordionDetails }}
        group={toggleGroup}
        title={<Title iconSrc="bespoke" title="Bespoke Developer Engagement Programs" />}
      >
        <DevEngagement />
      </Accordion>
    </div>
  </section>

);

export default Expertise;
