import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../reusable_components/LazyImage';

import { getProjectsCount } from '../../bookmarks/helpers';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const ListCard = ({ list, imageURLs }) => (
  <div className={layout.fullWidth}>
    <a href={list.url}>
      <div className={`${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding4_3}`}>
        {list.cover_image_url
          ? (
            <LazyImage
              alt={list.name}
              className={`${utilStyles.border} ${utilStyles.borderRadius} ${utilStyles.absolutePlaceholderChild}`}
              ratio="4:3"
              setStyle={false}
              src={list.cover_image_url}
              width={270}
            />
            )
          : (
            <img
              alt={list.name}
              className={`${utilStyles.border} ${utilStyles.borderRadius} ${utilStyles.absolutePlaceholderChild}`}
              src={imageURLs.bookmarkCoverDefault.x1}
              srcSet={`${imageURLs.bookmarkCoverDefault.x1} 1x, ${imageURLs.bookmarkCoverDefault.x2} 2x`}
            />
            )}
      </div>
    </a>
    <div className={`${typography.bodyL} ${typography.bold} ${typography.textWithIcon} ${typography.overflowBreakWord} ${layout.marginTop10} ${layout.fullWidth}`}>
      <a className={`${typography.linkCharcoal} ${layout.fullWidth}`} href={list.url}>
        {list.name}
      </a>
    </div>
    <div className={typography.bodyM}>{getProjectsCount(list.projects_count)}</div>
  </div>
);

ListCard.propTypes = {
  imageURLs: PropTypes.shape({
    bookmarkCoverDefault: PropTypes.shape({
      x1: PropTypes.string.isRequired,
      x2: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  list: PropTypes.shape({
    cover_image_url: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    private: PropTypes.bool.isRequired,
    projects_count: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default ListCard;
