import React from 'react';

import { summonLoginPanel } from '../../../utility/dispatchers';

import typography from '../../../styles/global_ui/typography.css';
import styles from './mobile_nav.css';

function summonAuth(type = 'login') {
  summonLoginPanel({
    detail: {
      state: { currentPanel: type },
      source: 'hamburgernav',
    },
  });
}

const AuthButtons = () => (
  <div className={styles.rowCenter}>
    <div className={typography.bodyL}>
      <a
        className={`${typography.link} ${typography.bold}`}
        href="javascript:void(0)"
        onClick={() => summonAuth('signup')}
      >
        Sign up
      </a>
      {' or '}
      <a
        className={`${typography.link} ${typography.bold}`}
        href="javascript:void(0)"
        onClick={() => summonAuth('login')}
      >
        log in
      </a>
    </div>
  </div>
);

export default AuthButtons;
