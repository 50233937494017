import React from 'react';
import PropTypes from 'prop-types';

import TooltipTarget from '../../../reusable_components/TooltipTarget';

import buildMessage from './buildMessage';

import { timestampToPrettyDate, timestampToRelativeTime } from '../../../../utility/time';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './notifications.css';

const Notification = ({ currentUser, markRead, notification }) => {
  const onClick = () => markRead(notification.receipt_id);

  const msg = buildMessage({
    currentUserId: currentUser.id,
    event: notification.event,
    fields: notification.message_fields,
    onClick,
    type: notification.notifiable_type,
  });

  if (!msg) return null;

  return (
    <li className={notification.read ? styles.listItem : styles.unreadItem}>
      <div className={styles.itemInner}>
        <div className={typography.bodyS}>
          <div>{msg}</div>
          <div className={typography.pebble} title={timestampToPrettyDate(notification.created_at)}>{timestampToRelativeTime(notification.created_at)}</div>
        </div>
        <div className={styles.buttonContainer}>
          {notification.read
            ? <div className={styles.markReadPlaceholder} /> /* Placeholder so text doesn't jump */
            : (
              <TooltipTarget offsetY={-2.5} position="left" tooltip="Mark as Read">
                <button className={`${buttonStyles.blank} ${styles.markRead} ${utilStyles.circle}`} onClick={onClick} />
              </TooltipTarget>
              )}
        </div>
      </div>
    </li>
  );
};

Notification.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  markRead: PropTypes.func,
  notification: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    event: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    message_fields: PropTypes.object.isRequired,
    notifiable_type: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    receipt_id: PropTypes.number.isRequired,
  }).isRequired,
};

Notification.defaultProps = { markRead: () => Promise.resolve() };

export default Notification;
