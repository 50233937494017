import React from 'react';

import TextImageSection from './TextImageSection';

import typography from '../../../styles/global_ui/typography.css';
import styles from './events.css';

const c = {
  text: `${typography.bodyM} ${typography.staticCharcoal}`,
  sectionRoot: styles.sectionRoot,
};

const Events = () => (
  <>
    <p className={c.text}>
      Host your next event with Hackster to expand your guest list and make logistics a breeze! You bring the content and speakers; we&apos;ll invite all our friends (and a few new faces too) to the party, create a relaxed and engaging atmosphere, and do all the heavy lifting.
    </p>
    <TextImageSection
      classList={{ root: c.sectionRoot }}
      content={(
        <ul className={c.text}>
          <li>Webinars</li>
          <li>Half-day workshops</li>
          <li>Single or multi-day seminars</li>
        </ul>
      )}
      images={[
        { alt: 'Infineon and Hackster.io event webinar', ratio: '516:258', src: 'infineon_event', width: 258 },
        { alt: 'Intel event: The secret behind AI Optimization', ratio: '402:258', src: 'intel_event', width: 201 },
      ]}
      title="What Types of Events Can Hackster Host?"
    />

    <TextImageSection
      classList={{ root: c.sectionRoot }}
      content={(
        <ul className={c.text}>
          <li>Dedicated project manager</li>
          <li>Online virtual hosting</li>
          <li>Event moderator</li>
          <li>Custom landing page</li>
          <li>Lead capture form</li>
          <li>Registration management and email reminders</li>
          <li>Recorded sessions</li>
          <li>Marketing and promotion</li>
          <li>Post-event reporting</li>
          <li>Completion certificates for participants</li>
        </ul>
      )}
      images={[
        { alt: 'Make: Hangout and Nerdout event', ratio: '1:1', src: 'make_event', width: 237 },
        { alt: 'DevAcademy event with Nordic', ratio: '448:474', src: 'nordic_event', width: 224 },
      ]}
      title="Turn-key Event Logistics"
    />
  </>
);

export default Events;
