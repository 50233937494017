const CONFIG = {
  '/': (user) => ({
    title: `${user.name} - Hackster.io`,
    pageType: 'users#show',
    entity_id: user.id,
  }),
  '/projects': (user) => ({
    title: `${user.name}'s Projects - Hackster.io`,
    pageType: 'users#projects',
    entity_id: user.id,
  }),
};

const seoConfig = (path) => CONFIG[path];
export default seoConfig;
