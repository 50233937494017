import React from 'react';
import PropTypes from 'prop-types';

import ActionsOverlay from './ActionsOverlay';
import BaseCardVertical from '../base_card/vertical/BaseCardVertical';
import EventCardText from './EventCardText';

import { EVENT_CARD } from '../../../services/keen/events/linkConstants';

import editCardStyles from '../project_edit_card/project_edit_card.css';
import utilStyles from '../../../styles/global_ui/util.css';

const EventCardVertical = ({ editable, item, itemIndex, overrideTracking, redirectTo, responsive, statusBadge }) => (
  <BaseCardVertical
    className={editable ? `${editCardStyles.overlayParent} ${utilStyles.overflowVisible}` : ''}
    imageAspectRatio="3:2"
    item={{ ...item, url: item.link }}
    linkProps={{ isExternal: true, itemIndex, linkType: EVENT_CARD, overrideTracking }}
    renderAfterImage={editable ? () => <ActionsOverlay editLink={`/events/${item.id}/edit${redirectTo ? `?redirect_to=${redirectTo}` : ''}`} /> : () => null}
    renderBody={() => <EventCardText item={item} itemIndex={itemIndex} overrideTracking={overrideTracking} showYear={true} statusBadge={statusBadge} />}
    responsive={responsive}
  />
);

EventCardVertical.propTypes = {
  editable: PropTypes.bool,
  item: PropTypes.shape({
    approved: PropTypes.bool.isRequired,
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string }).isRequired,
    link: PropTypes.string.isRequired,
    start_date: PropTypes.stringisRequired,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  itemIndex: PropTypes.number,
  overrideTracking: PropTypes.func,
  redirectTo: PropTypes.string,
  responsive: PropTypes.bool,
  statusBadge: PropTypes.func,
};

EventCardVertical.defaultProps = {
  editable: false,
  itemIndex: null,
  overrideTracking: null,
  redirectTo: null,
  responsive: false,
  statusBadge: null,
};

export default EventCardVertical;
