import searchIndex from '../../../../services/algolia/searchIndex';

export function fetchSearchResults(input, callback, componentContext) {
  const q = input.toLowerCase();

  return searchIndex({
    index: 'tags',
    query: q,
  });
}
