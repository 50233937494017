import smoothScroll from '../../../client/utils/smoothScroll';
import utilStyles from '../../../styles/global_ui/util.css';

const BUTTON_CLASS = 'challenge-registration-btn';
const DATA_ATTRIB = 'data-show-registered';
const NAV_PLACEHOLDER_CLASS = 'challenge-nav-placeholder';

export function addRegisterButtonClickHandler(handler) {
  [].slice.call(document.getElementsByClassName(BUTTON_CLASS)).forEach((el) => {
    el.addEventListener('click', handler, true);
  });
}

export function scrollToNav() {
  const el = document.getElementsByClassName(NAV_PLACEHOLDER_CLASS)[0];
  if (el) smoothScroll(el, 300);
}

export function updateDOMRegisteredState(isRegistered = true) {
  [].slice.call(document.querySelectorAll(`[${DATA_ATTRIB}]`)).forEach((el) => {
    // the data attribute is either '0' or '1'. this gets a boolean from that
    const showIfRegistered = !!parseInt(el.getAttribute(DATA_ATTRIB));

    if (showIfRegistered === isRegistered && el.classList.contains(utilStyles.displayNone)) {
      el.classList.remove(utilStyles.displayNone);
    }

    if (showIfRegistered !== isRegistered && !el.classList.contains(utilStyles.displayNone)) {
      el.classList.add(utilStyles.displayNone);
    }
  });
}
