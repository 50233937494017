const MAX_TIMEOUT = 2147483647; // JS limit.
const UNITS_TO_MS = {
  seconds: 1000,
  minutes: 60000,
  hours: 3600000,
  days: 86400000,
};

export const timeTilEndDateMs = (endDate) => {
  const diff = new Date(endDate).valueOf() - Date.now();
  const diffMax = Math.max(diff, 0);

  return diffMax < MAX_TIMEOUT ? diffMax : 0;
};

export const timeRemaining = (endDate, startDate = new Date().toString()) => {
  const units = ['seconds', 'minutes', 'hours', 'days'];

  const time = units.reduce((acc, unit, i) => {
    if (acc.unit) return acc;

    const diffMs = new Date(endDate).valueOf() - new Date(startDate).valueOf();
    const diff = Math.round(diffMs / UNITS_TO_MS[unit]);

    if (i === units.length - 1) return { unit, value: diff };

    return (diff < 100 && diff > 0) ? { unit, value: diff } : acc;
  }, { unit: null, value: 0 });

  if (time.value <= 0) return '0 seconds';

  return `${time.value} ${time.value === 1 ? time.unit.replace(/s$/, '') : time.unit}`;
};
