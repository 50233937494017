import videoSeoConfig from './seoConfig';
import eventSeoConfig from '../events/seoConfig';

const MAP = {
  events: eventSeoConfig,
  videos: videoSeoConfig,
};

export default function getSeoConfig(key) {
  return MAP[key] || videoSeoConfig;
}
