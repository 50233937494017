import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GraphQLPaginatedList from '../../../client/wrappers/graph_ql/GraphQLPaginatedList';
import DummyHorizontalPartCard from '../../../client/parts/horizontal_part_card/DummyHorizontalPartCard';
import HorizontalPartsList from '../../../client/parts/horizontal_parts_list';

import GraphQLPartsService from '../../../services/graphql/parts_service';

import layout from '../../../styles/global_ui/layout.css';

class Products extends Component {
  constructor(props) {
    super(props);
    this.reportPageView = this.reportPageView.bind(this);
    this.qlService = new GraphQLPartsService({ history: this.props.history });
  }

  reportPageView() {
    this.props.seoHandler(this.props.path);
  }

  render() {
    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          <GraphQLPaginatedList
            filters={this.props.filters}
            graphQLArguments={this.props.graphQLArguments}
            graphQLService={this.qlService}
            listComponent={HorizontalPartsList}
            listProps={{
              canManage: this.props.canManage,
              manageProductsLink: this.props.manageProductsLink,
              platformId: this.props.platformId,
            }}
            loaderComponent={DummyHorizontalPartCard}
            reportPageView={this.reportPageView}
            transition={this.props.transition}
          />
        </div>
      </div>
    );
  }
}

Products.propTypes = {
  canManage: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  })).isRequired,
  graphQLArguments: PropTypes.shape({ platform_id: PropTypes.number.isRequired }).isRequired,
  history: PropTypes.object, // Router history
  manageProductsLink: PropTypes.string,
  path: PropTypes.string.isRequired,
  platformId: PropTypes.number.isRequired,
  seoHandler: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};

Products.defaultProps = {
  canManage: false,
  history: {},
  manageProductsLink: '',
};

export default Products;
