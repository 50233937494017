import React from 'react';

import animation from '../../../styles/animation.css';
import utilStyles from '../../../styles/global_ui/util.css';

const BannerSmallContestCardDummy = () => (
  <div
    className={`${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius} ${animation.loader}`}
    // TODO: This should probably be style={{}}
    // eslint-disable-next-line react/no-unknown-property
    styles={{ width: 570 }}
  />
);

export default BannerSmallContestCardDummy;
