import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../link/AnalyticsLink';
import DateRange from './DateRange';
import EventTypeOrLocation from './EventTypeOrLocation';
import LazyImage from '../../reusable_components/LazyImage';
import SponsorList from './SponsorList';

import { EVENT_CARD } from '../../../services/keen/events/linkConstants';
import { getDateRangeAsText } from './eventCardHelpers';
import { getInObj } from '../../../utility/accessors';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './event_card.css';
import Icon from '../../icon';

const EventCardSponsoredSmall = ({ className, item, itemIndex, overrideTracking }) => (
  <div className={`${layout.flexCenterItems} ${layout.flexColumnWrap} ${layout.fullWidth} ${typography.overflowBreakWord} ${className}`}>
    <AnalyticsLink
      className={`${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding3_2} ${layout.fullWidth}`}
      isExternal={true}
      item={{ id: item.id, url: item.link }}
      itemIndex={itemIndex}
      linkType={EVENT_CARD}
      overrideTracking={overrideTracking}
    >
      <LazyImage
        alt={item.title}
        classList={{ root: utilStyles.absolutePlaceholderChild, image: utilStyles.borderRadius }}
        ratio="3:2"
        setStyle={false}
        src={getInObj(['image', 'url'], item)}
        width={570}
      />
      <div className={styles.dateBadge}>
        <DateRange endDate={item.end_date} startDate={item.start_date} />
      </div>
    </AnalyticsLink>
    <div className={`${typography.bodyL} ${layout.flexColumnWrap} ${layout.flexCenterItems}`}>
      <SponsorList className={`${layout.marginTop22} ${typography.textCenter}`} item={item} />
      <div className={`${layout.marginTop10} ${typography.textCenter}`}>
        <AnalyticsLink
          className={`${typography.linkCharcoal} ${typography.h3Responsive}`}
          isExternal={true}
          item={{ id: item.id, url: item.link }}
          itemIndex={itemIndex}
          linkType={EVENT_CARD}
          overrideTracking={overrideTracking}
        >
          {item.title}
        </AnalyticsLink>
      </div>
      <div className={`${layout.marginTop10} ${typography.textCenter}`}>
        {getDateRangeAsText(item)}
        <span className={`${layout.marginLeft10} ${layout.marginRight10}`}>•</span>
        <EventTypeOrLocation item={item} />
        <AnalyticsLink
          className={`${layout.flex} ${layout.flexJustifyCenter} ${layout.marginTop10} ${typography.bold} ${typography.linkBlue} ${typography.textWithIcon}`}
          isExternal={true}
          item={{ id: item.id, url: item.link }}
          itemIndex={itemIndex}
          linkType={EVENT_CARD}
          overrideTracking={overrideTracking}
        >
          Learn more
          <Icon name="arrow-right" />
        </AnalyticsLink>
      </div>
    </div>
  </div>
);

EventCardSponsoredSmall.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    start_date: PropTypes.stringisRequired,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  itemIndex: PropTypes.number,
  overrideTracking: PropTypes.func,
};

EventCardSponsoredSmall.defaultProps = {
  className: '',
  itemIndex: null,
  overrideTracking: null,
};

export default EventCardSponsoredSmall;
