import React from 'react';

import LazyImage from '../../../client/reusable_components/LazyImage';
import TextImageSection from './TextImageSection';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './imageLinks.css';
import { c as sectionStyles } from './TextImageSection';

const c = {
  section: `${layout.flexColumn} ${layout.gutter15} ${layout.paddingBottom30}`,
  text: `${typography.bodyM} ${typography.staticCharcoal}`,
  image: `${util.bgTransparent} ${layout.fullWidth}`,
  bannersWrapper: `${layout.flexJustifyCenter} ${layout.gutter10} ${styles.imageWrapper}`,
};

const Contests = () => (
  <>
    <section className={c.section}>
      <p className={c.text}>
        Want to increase technology adoption, drive customer expansion, and build a rich user-generated project collection? Hackster contests enable you to reach a global community, get direct feedback from engineers about your product, and activate that community of engineers to generate content that include a creative, well-documented, and high-quality showcase of your product.
      </p>
      <p className={c.text}>
        We know contests like the back of our hands, and we also know how complicated they can be to manage. Hackster&apos;s SaaS contest tool and suite of services help take the complexity out of running contests for our partners while providing a smooth experience and interface for participants.
      </p>
    </section>
    <TextImageSection
      content={(
        <ul className={c.text}>
          <li>Intuitive interface</li>
          <li>Customizable contest landing page</li>
          <li>Judging/entries management</li>
          <li>Live analytics dashboard</li>
          <li>Discussion and announcement board</li>
          <li>Easy-to-use project builder for participants</li>
          <li>Automated email reminders to participants</li>
          <li>Project submissions gallery that automatically syncs to your branded channel</li>
          <li>Integrated hardware application form</li>
          <li>Automatic badges for winning entrants&apos; profiles and projects</li>
        </ul>
      )}
      images={[
        { alt: 'Contest creation app', ratio: '658:496', src: 'contest_creation', width: 329 },
      ]}
      title="Industry-Leading SaaS Contest Tool"
    />

    <TextImageSection
      content={(
        <ul className={c.text}>
          <li>Dedicated project manager</li>
          <li>Contest marketing and promotion</li>
          <li>Hardware applications facilitation</li>
          <li>Global hardware/prize distribution</li>
          <li>Technical webinar/workshop or office hours facilitation</li>
          <li>…and more!</li>
        </ul>
      )}
      images={[
        { alt: 'Infineon contest example', ratio: '380:360', src: 'infineon_contest', width: 190 },
        { alt: 'Build2gether inclusive innovation challenge example', ratio: '392:360', src: 'build_2_together_contest', width: 196 },
      ]}
      title="Scalable Services for Every Level of Design Contest"
    />

    <section className={layout.paddingTop30}>
      <h4 className={sectionStyles.header}>Get Inspired by These Cool Example Contests</h4>
      <div className={c.bannersWrapper}>
        <a aria-label="NXP hover games contest" href="/contests/nxp-hovergames-challenge-3" target="_blank">
          <LazyImage
            alt="NXP hover games contest"
            classList={{ root: c.image }}
            fit="fill"
            ratio="664:166"
            src="https://hackster.imgix.net/static/marketing/content/nxp_hover_games.png"
            width={332}
          />
        </a>
        <a aria-label="Nordic Challenge" href="/contests/smarter-sustainable-world" target="_blank">
          <LazyImage
            alt="Nordic Challenge"
            classList={{ root: c.image }}
            fit="fill"
            ratio="664:166"
            src="https://hackster.imgix.net/static/marketing/content/nordic_challenge.png"
            width={332}
          />
        </a>
        <a aria-label="AWS internet of things contest" href="/contests/Healthy-Spaces-with-AWS" target="_blank">
          <LazyImage
            alt="AWS internet of things contest"
            classList={{ root: c.image }}
            fit="fill"
            ratio="664:166"
            src="https://hackster.imgix.net/static/marketing/content/aws_iot.png"
            width={332}
          />
        </a>
      </div>
    </section>
  </>
);

export default Contests;
