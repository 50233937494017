import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { cancelablePromise } from '../../../../utility/promises';
import errorHandler from '../../../../services/error_handler';
import throttle from 'lodash.throttle';

class InfiniteScroll extends Component {
  constructor(props) {
    super(props);

    this.scrollHandler = this.scrollHandler.bind(this);
    this.state = { loading: false };

    // Refs.
    this._itemsContainer;
    // Parent Dialog.
    this.dialog;
    this.activePromise;
  }

  componentDidMount() {
    this.dialog = this._findDialog();
    this.throttledScroll = throttle(this.scrollHandler, 250);
    this.dialog.addEventListener('scroll', this.throttledScroll);

    if (!this.state.loading && this.props.recordsCount < this.props.metadata.total_records
      && this.props.recordsCount < (this.props.metadata.per_page * 2)) { // Rough estimate of records to fill the initial view.
      this._fetchMore();
    }
  }

  componentWillUnmount() {
    if (this.activePromise) this.activePromise.cancel();
    if (this.dialog) {
      this.dialog.removeEventListener('scroll', this.throttledScroll);
    }
  }

  _findDialog() {
    // eslint-disable-next-line react/no-find-dom-node
    let node = ReactDOM.findDOMNode(this);
    /* eslint-disable-next-line no-cond-assign */
    while (node = node.parentNode) {
      if (node.hasAttribute('data-nested-dialog')) {
        break;
      }
    }

    return node;
  }

  scrollHandler() {
    if (!this._itemsContainer || (this.props.recordsCount >= this.props.metadata.total_records) || this.state.loading) return;

    if ((parseInt(window.innerHeight, 10) + 200) > this._itemsContainer.getBoundingClientRect().bottom) {
      this._fetchMore();
    }
  }

  _fetchMore() {
    this.setState({ loading: true });
    this.activePromise = cancelablePromise(this.props.fetchMore());

    return this.activePromise
      .promise
      .then((res) => {
        this.activePromise = undefined;
        /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
        /* eslint-disable-next-line no-prototype-builtins */
        if (!res.hasOwnProperty('promiseCanceled')) {
          this.setState({ loading: false }, this.throttledScroll);
        }
      })
      .catch((err) => {
        this.activePromise = undefined;
        errorHandler('Profile InfiniteScroll Error', err);
        /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
        /* eslint-disable-next-line no-prototype-builtins */
        if (!err.hasOwnProperty('promiseCanceled')) {
          this.setState({ loading: false });
        }
      });
  }

  render() {
    return (
      <div ref={(el) => this._itemsContainer = el}>
        {this.props.children}
        {this.state.loading
        && (
          <div style={{ textAlign: 'center' }}>
            <i className="fa fa-circle-o-notch fa-spin" />
          </div>
        )}
      </div>
    );
  }
}

InfiniteScroll.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  metadata: PropTypes.shape({
    per_page: PropTypes.number.isRequired,
    total_records: PropTypes.number.isRequired,
  }).isRequired,
  recordsCount: PropTypes.number.isRequired,
};

export default InfiniteScroll;
