export const CHANNEL_TYPE_ENUM = ['Community', 'Platform', 'TopicChannel'];
export const PART_TYPE_ENUM = ['HARDWARE', 'PART', 'SOFTWARE', 'TOOL'];

export const ONBOARDING_STEPS = ['activities', 'interests', 'info']; // Note: Order matters.

export const ONBOARDING_STEPS_VALUE_MAP = {
  ACTIVITIES: 'activities',
  INFO: 'info',
  INTERESTS: 'interests',
  V1: 'v1',
};

export const ONBOARDING_STEPS_ENUM_MAP = {
  activities: 'ACTIVITIES',
  info: 'INFO',
  interests: 'INTERESTS',
};

// Matches User::ONBOARDING_STEPS
export const ONBOARDING_STEPS_ORDER_MAP = {
  v1: 0,
  activities: 1,
  interests: 2,
  info: 3,
};
