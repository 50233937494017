// DEPRECATED: 02-14-2023
import PropTypes from 'prop-types';
import React from 'react';
import { timestampToPrettyDate } from '../../../../../utility/time';

const EventDetails = ({ dateString, location }) => {
  const verboseDateObj = { day: 'numeric', month: 'short', weekday: 'long', year: 'numeric' };
  const date = timestampToPrettyDate(dateString, verboseDateObj, false, null);

  return (
    <div>
      {date && <div>{`Date: ${date}`}</div>}
      {location && <div>{`Location: ${location}`}</div>}
    </div>
  );
};

EventDetails.propTypes = {
  dateString: PropTypes.string,
  location: PropTypes.string,
};

EventDetails.defaultProps = {
  dateString: null,
  location: null,
};

export default EventDetails;
