import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../link/AnalyticsLink';
import LazyImage from '../../../reusable_components/LazyImage';

import { getInObj } from '../../../../utility/accessors';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const BaseCardHorizontalSmall = ({ renderBody, item, linkProps }) => (
  <div className={`${layout.flexCenterItems} ${layout.fullWidth} ${typography.overflowBreakWord}`}>
    <AnalyticsLink
      item={item}
      {...linkProps}
    >
      <LazyImage
        alt={item.title}
        classList={{ image: utilStyles.borderRadius }}
        ratio="1:1"
        src={getInObj(['mobile_image', 'url'], item) || getInObj(['image', 'url'], item)}
        width={120}
      />
    </AnalyticsLink>
    <div className={layout.marginLeft15}>
      {renderBody()}
    </div>
  </div>
);

BaseCardHorizontalSmall.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
  }).isRequired,
  linkProps: PropTypes.shape({ linkType: PropTypes.string.isRequired }).isRequired,
  renderBody: PropTypes.func,
};

BaseCardHorizontalSmall.defaultProps = { renderBody: () => null };

export default BaseCardHorizontalSmall;
