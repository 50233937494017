import React from 'react';
import { ReactSurveyElement } from 'survey-react-ui';

import RobotAngry from '../icons/RobotAngry';
import RobotHappy from '../icons/RobotHappy';
import RobotLove from '../icons/RobotLove';
import RobotNeutral from '../icons/RobotNeutral';
import RobotSad from '../icons/RobotSad';

import util from '../../../styles/global_ui/util';
import style from './ratings.css';

const c = {
  label: ({ isChecked }) => [style.label, isChecked ? style.checked : ''].join(' '),
  radioButton: `${util.srOnly}`,
};

const Icon = ({ rating }) => {
  switch (rating) {
    case 1:
      return <RobotAngry />;
    case 2:
      return <RobotSad />;
    case 3:
      return <RobotNeutral />;
    case 4:
      return <RobotHappy />;
    case 5:
      return <RobotLove />;
    default:
      throw new Error(`Missing Icon for rating: ${rating}`);
  }
};

class RobotRating extends ReactSurveyElement {
  renderElement() {
    const { question, item, survey } = this.props;

    const handleOnChange = () => {
      survey.setValue(question.name, item.value);
    };

    const isChecked = question.isItemSelected(item);

    const rating = (value) => {
      switch (value) {
        case 1:
          return 'Terrible';
        case 2:
          return 'Not Good';
        case 3:
          return 'Okay';
        case 4:
          return 'Good';
        case 5:
          return 'Terrific';
      }
    };

    return (
      <label
        className={c.label({ isChecked })}
        title={rating(item.value)}
      >
        <Icon rating={item.value} />
        <input
          aria-checked={isChecked}
          className={c.radioButton}
          name={`${question.name}-${item.value}`}
          onClick={handleOnChange}
          required="Must select one"
          role="radio"
          type="radio"
          value={item.value}
        />
      </label>
    );
  }
}

export default RobotRating;
