import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import throttle from 'lodash.throttle';

import ActionButtons from './ActionButtons';
import LatestArticlesBar from '../latest_articles/LatestArticlesBar';
import StickyWrapper from '../../../../../client/wrappers/sticky_wrapper';

import { windowPageYOffset } from '../../../../../services/window';

import layout from '../../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import styles from './sticky_actions.css';

const FOOTER_HEIGHT = 58;
const SCROLL_PADDING = 80;
const STICKY_FOOTER_ID = 'articleStickyFooter';

// TODO: generalize this component and use it for project pages?

class ArticleStickyFooter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: false,
      lastScrollTop: 0,
    };

    this.setIsHidden = this.setIsHidden.bind(this);
  }

  componentDidMount() {
    this.throttledScroll = throttle(this.setIsHidden, 200);
    window.addEventListener('scroll', this.throttledScroll);

    this.setIsHidden();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll);
  }

  // Logic from assets/javascripts/projects/page.js. TODO: use this component for project pages too
  setIsHidden() {
    const scrollTop = windowPageYOffset();
    if (scrollTop > this.state.lastScrollTop) {
      this.setState({ isHidden: true, lastScrollTop: scrollTop });
    } else if ((scrollTop + SCROLL_PADDING) < this.state.lastScrollTop) {
      this.setState({ isHidden: false, lastScrollTop: scrollTop });
    }
  }

  render() {
    const { article, disabled, latestArticles, social_sharing_links } = this.props;

    return (
      <div
        className={`${utilStyles.posRelative} ${layout.fullWidth} ${layout.hiddenMedUp}`}
        id={STICKY_FOOTER_ID}
        style={{ height: FOOTER_HEIGHT }}
      >
        <StickyWrapper
          className={`${styles.stickyFooterWrapper} ${layout.fullWidth}`}
          parentId={STICKY_FOOTER_ID}
          startPos="bottom"
          stickToEnd={false}
        >
          <div
            className={`${layout.flexJustifySpaceBetween} ${layout.fullWidth} ${utilStyles.bgWhite} ${utilStyles.boxShadow2} ${layout.padding1015} ${styles.stickyFooter} ${this.state.isHidden ? styles.stickyFooterHidden : ''}`}
            style={{ height: FOOTER_HEIGHT }}
          >
            <div className={layout.flex}>
              <ActionButtons article={article} disabled={disabled} position="bottom" social_sharing_links={social_sharing_links} />
            </div>
            <LatestArticlesBar records={latestArticles} />
          </div>
        </StickyWrapper>
      </div>
    );
  }
}

ArticleStickyFooter.propTypes = {
  article: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  latestArticles: PropTypes.array,
  social_sharing_links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

ArticleStickyFooter.defaultProps = {
  disabled: false,
  latestArticles: null,
  social_sharing_links: [],
};

export default ArticleStickyFooter;
