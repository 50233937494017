import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Paginator from '../../client/nav_components/paginator';
import ResultsList from './results_list';
import SearchSettings from './search_settings';

import algoliaService from '../../services/algolia';
import errorHandler from '../../services/error_handler';
import { windowScrollTo } from '../../services/window';
import { isNumber } from '../../utility/types';

import seoConfig from './seoConfig';
import SeoHandler from '../../services/seo_handler';

import layout from '../../styles/global_ui/layout.css';
import overlayStyles from '../../styles/common-overlay.css';
import styles from './search.css';

const emptyQueryResults = {
  index: '',
  query: '',
  pagination: {},
  params: '',
  records: [],
  settings: {},
  suggestion: null,
};

class Search extends Component {
  constructor(props) {
    super(props);

    this.handlePagination = this.handlePagination.bind(this);
    this.handleSettingsChange = this.handleSettingsChange.bind(this);
    this.reportPageView = this.reportPageView.bind(this);

    this.state = {
      isFetching: true,
      results: emptyQueryResults,
      showSettings: false,
    };

    this.seoHandler = new SeoHandler({ config: seoConfig });
  }

  componentDidMount() {
    algoliaService.getChannel().subscribe('locationChange', this.reportPageView);
    algoliaService.getChannel().subscribe('results', (results) => this.setState({ results, isFetching: false }));
    algoliaService.getChannel().subscribe('working', (isFetching) => this.setState({ isFetching }));
  }

  componentWillUnmount() {
    algoliaService.getChannel().unsubscribe();
  }

  /**
   * Methods
   */
  handlePagination(pageNm) {
    this.setState({ isFetching: true });
    windowScrollTo(0, 0);

    const pageTo = Math.abs(pageNm - 1);

    return algoliaService.searchWithEffects({ q: this.state.results.query, page: pageTo })
      .then(() => this.setState({ isFetching: false }))
      .catch((err) => {
        this.setState({ isFetching: false });
        errorHandler('Search handlePagination', err);
      });
  }

  handleSettingsChange(settings) {
    return new Promise((resolve, reject) => {
      this.setState({ isFetching: true, showSettings: false });

      algoliaService.searchWithEffects({ q: this.state.results.query, settings: settings })
        .then(() => {
          this.setState({ isFetching: false });
          resolve();
        })
        .catch((err) => {
          this.setState({ isFetching: false });
          reject(err);
        });
    });
  }

  reportPageView() {
    this.seoHandler.reportView({ path: '/' });
  }

  render() {
    const { isFetching, results } = this.state;
    const { index, pagination, params, query, records, suggestion, settings } = results;

    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          <div className={styles.resultsPanel}>
            <SearchSettings
              channelId={this.props.channelId}
              dismissSettings={() => this.setState({ showSettings: false })}
              handleSearchRequest={this.handleSettingsChange}
              query={query}
              showSettings={this.state.showSettings}
            />
            <div className={styles.resultsListWrapper}>
              <ResultsList
                algoliaIndex={index}
                currentQuery={query}
                isWhitelabel={isNumber(this.props.channelId)}
                loading={isFetching}
                pageKey={`${query}_${pagination.page}_${params}`}
                recommendedResults={suggestion}
                results={records}
                settings={settings}
                toggleFilterPanel={() => this.setState({ showSettings: true })}
                totalRecords={pagination.totalRecords}
              />
            </div>
          </div>
          {pagination.totalPages !== undefined && pagination.totalPages > 1
          && (
            <Paginator
              currentPage={pagination.currentPage + 1}
              currentQuery={params}
              onClick={this.handlePagination}
              totalPages={pagination.totalPages}
            />
          )}
          {isFetching && <div className={overlayStyles.overlay} />}
        </div>
      </div>
    );
  }
}

export default Search;

Search.propTypes = { channelId: PropTypes.number };

Search.defaultProps = { channelId: null };
