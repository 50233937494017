import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsSection from '../../../../client/form_components/templates/ActionsSection';
import BasicFormInput from '../../../../client/form_components/inputs/basic_form_input';

import { getFormErrorView } from '../../../../client/form_components/templates';
import {
  getErrorForField,
  getFieldValuesAsObject,
  initFields,
  setStateOrError,
  validateFields,
} from '../../../../utility/forms';
import { graphMutate } from '../../../../requests/graphql';
import { summonGlobalMessenger } from '../../../../utility/dispatchers';

import layoutStyles from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './suggest_tag_form.css';

const FIELDS_TEMPLATE = {
  name: { validate: () => null, value: '' },
  description: { validate: () => null, notRequired: true, value: '' },
};

class SuggestTagForm extends Component {
  constructor(props) {
    super(props);

    this.state = this._initialState();

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Form helpers
    this.getErrorForField = getErrorForField.bind(this);
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
    this.setStateOrError = setStateOrError.bind(this);
    this.validateFields = validateFields.bind(this);
  }

  /**
   * Initializers
   */
  _initialState() {
    return {
      errors: {},
      fields: initFields(FIELDS_TEMPLATE),
      formError: '',
      isBusy: false,
    };
  }

  /**
   * Methods
   */
  handleDismiss() {
    this.setState(this._initialState());
    this.props.dismiss();
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.isBusy) return;

    const validated = this.validateFields({ doScroll: false });
    if (validated) return this._submitForm();
  }

  /**
   * Helpers
   */
  _submitForm() {
    const { name, description } = this.getFieldValuesAsObject();
    const { project_id } = this.props;

    this.setState({ isBusy: true });

    return graphMutate({ t: 'create_suggested_tag' }, { name, description, project_id })
      .then(() => {
        this.handleDismiss();
        summonGlobalMessenger({ msg: 'Category suggestion submitted successfully.' });
      })
      .catch((err) => {
        this.setState({ formError: err.message, isBusy: false });
      });
  }

  render() {
    const { formError, isBusy } = this.state;

    return (
      <div style={{ maxWidth: 570 }}>
        <header className={layoutStyles.marginBottom30}>
          <h2 className={typography.h2}>Suggest a tag</h2>
        </header>
        <section className={`${layoutStyles.marginBottom30} ${typography.bodyM}`}>
          <p className={layoutStyles.marginBottom10}>Before suggesting a new tag, please try searching for a close variant. Tags should be about topics or technical features, not products or brands–those are identified automatically. Good tags are also brief and specific enough to differentiate your project from others.</p>
          <p>
            {'To learn more about our tags, check out our '}
            <a className={typography.linkBlue} href="http://help.hackster.io/knowledgebase/posting-a-project/learn-more-about-tags">knowledge base</a>
            .
          </p>
        </section>
        <section className={`${layoutStyles.flex} ${layoutStyles.marginBottom30}`}>
          <section className={styles.column}>
            <h4 className={`${typography.h4} ${layoutStyles.marginBottom10}`}>Good</h4>
            <section className={layoutStyles.marginBottom10}>
              <p className={typography.bodyM}>robotics</p>
              <p className={`${typography.bodyS} ${typography.success}`}>Represents a subject</p>
            </section>
            <section className={layoutStyles.marginBottom10}>
              <p className={typography.bodyM}>security</p>
              <p className={`${typography.bodyS} ${typography.success}`}>Represents a specific field</p>
            </section>
          </section>
          <section className={styles.column}>
            <h4 className={`${typography.h4} ${layoutStyles.marginBottom10}`}>Bad</h4>
            <section className={layoutStyles.marginBottom10}>
              <p className={typography.bodyM}>arduino</p>
              <p className={`${typography.bodyS} ${typography.error}`}>Products are already linked to a project using your BOM list</p>
            </section>
            <section className={layoutStyles.marginBottom10}>
              <p className={typography.bodyM}>computers</p>
              <p className={`${typography.bodyS} ${typography.error}`}>Too generic or broad</p>
            </section>
          </section>
        </section>
        <form onSubmit={this.handleSubmit}>
          <BasicFormInput
            errors={this.state.errors.name}
            label="Tag name"
            name="name"
            onChange={(e) => this.setStateOrError(null, 'name', e.target.value)}
            value={this.state.fields.name.value}
          />
          <BasicFormInput
            element="textarea"
            errors={this.state.errors.description}
            label="Description"
            name="description"
            onChange={(e) => this.setStateOrError(null, 'description', e.target.value)}
            value={this.state.fields.description.value}
          />
          <ActionsSection
            isBusy={isBusy}
            primaryBtnConfig={{ onClick: this.handleSubmit, text: 'Submit' }}
            secondaryBtnConfig={{ onClick: this.handleDismiss }}
          />
          {getFormErrorView(formError)}
        </form>
      </div>
    );
  }
}

SuggestTagForm.propTypes = {
  dismiss: PropTypes.func,
  project_id: PropTypes.number.isRequired,
};

SuggestTagForm.defaultProps = { dismiss: () => {} };

export default SuggestTagForm;
