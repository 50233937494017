const US_LOCALE = 'en-US';

function getUserLocale() {
  // TODO: Remove when done testing commas!
  // return 'de-DE';
  if (navigator) {
    return navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage;
  }
}

export function getDecimalSeparator() {
  const n = 1.1;
  const seperator = createCurrencyFormatter().format(n).substring(1, 2);

  return seperator || '.';
}

export default function createCurrencyFormatter() {
  if (typeof window !== 'undefined' && window.Intl) {
    const locale = getUserLocale() || US_LOCALE;

    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });
  } else {
    return { format: (value) => typeof value === 'number' ? value.toString() : value };
  }
}
