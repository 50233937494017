import React, { useCallback, useState } from 'react';

import BannerSmallContestCard from '../../client/cards/contest_card/BannerSmallContestCard.js';
import LazyComponent from '../../client/wrappers/lazy_component';

import errorHandler from '../../services/error_handler';
import { graphQuery } from '../../requests/graphql.js';

import contestStyles from '../contests_page/contests_page.css';
import layout from '../../styles/global_ui/layout.css';
import gridStyles from '../../styles/global_ui/grid.css';
import utilStyles from '../../styles/global_ui/util.css';

const ContestSection = ({ userTier }) => {
  const [contests, setContests] = useState();

  const contestReveal = useCallback(() => {
    // TODO: I want to see the 2 most active contests
    // We don't have a sort for this. We do have counts for impressions, ideas, registrations, and projects. Which shall I use?
    // currently using `ends_last` method for ordering
    graphQuery({ t: 'get_active_challenges' }, { per_page: 2 })
      .then(({ active_contests }) => setContests(active_contests.records))
      .catch((err) => errorHandler('contestReveal', err));
  }, []);

  return (
    <LazyComponent onReveal={contestReveal}>
      <div className={`${gridStyles.grid} ${layout.flexJustifySpaceBetween} ${contestStyles.bannerCardListWrapper}`}>
        {contests?.map((contest) => (
          <div
            key={contest.id}
            className={`
              ${contestStyles.bannerCardWrapper}
              ${gridStyles.cell}
              ${utilStyles.boxShadow3}
              ${utilStyles.borderRadius}
            `}
          >
            <BannerSmallContestCard contest={contest} userTier={userTier} />
          </div>
        ))}
      </div>
    </LazyComponent>
  );
};

export default ContestSection;
