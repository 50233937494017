import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '../../wrappers/form_group';

import createCurrencyFormatter, { getDecimalSeparator } from '../../../../services/locale/createCurrencyFormatter';

import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './decimal_input.css';
import { isBlank } from '../../../../utility/types';

const LOCALE_DECIMAL_SEPARATOR = getDecimalSeparator();
const OUTPUT_DECIMAL_SEPARATOR = '.';

const currencyFormatter = createCurrencyFormatter();

/**
 * NOTE: input[type="number"] has limitations that will cause unexpected behavior,
 * but only for invalid values. see https://github.com/facebook/react/issues/11142
 * If this component gets used in more places and needs more consistent behavior,
 * look into other solutions
 */

class DecimalInput extends Component {
  constructor(props) {
    super(props);

    this.state = { value: currencyFormatter.format(props.value) };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({ value });
    this.props.onChange(this._formatForOutput(value));
  }

  _formatForOutput(value) {
    if (LOCALE_DECIMAL_SEPARATOR === OUTPUT_DECIMAL_SEPARATOR) return value;

    return value.replace(new RegExp(LOCALE_DECIMAL_SEPARATOR, 'g'), OUTPUT_DECIMAL_SEPARATOR);
  }

  render() {
    const { errors, helperText, icon, id, label, name, onBlur, onFocus, placeholder } = this.props;

    return (
      <FormGroup errors={errors} helperText={helperText} label={label} name={name} value="">
        <div className={utilStyles.posRelative}>
          {!!icon && <div className={styles.icon}>{icon}</div>}
          <input
            className={`${isBlank(errors) ? inputStyles.input : inputStyles.inputError} ${icon ? layout.paddingLeft30 : ''}`}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={this.handleChange}
            onFocus={onFocus}
            placeholder={placeholder}
            type="number"
            value={this.state.value}
          />
        </div>
      </FormGroup>
    );
  }
}

DecimalInput.propTypes = {
  errors: PropTypes.array,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

DecimalInput.defaultProps = {
  errors: null,
  icon: null,
  label: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  value: '',
};

export default DecimalInput;
