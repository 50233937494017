import { fetchMapboxNearbyCity } from './index';
import PromiseBatcher from '../../utility/promises/PromiseBatcher';

let isFetching = false;
const batch = new PromiseBatcher();

export default function getNearestCity() {
  return new Promise((resolve, reject) => {
    batch.cachePromise({ resolve, reject });

    if (isFetching) return;

    isFetching = true;

    return fetchMapboxNearbyCity()
      .then((formattedRecord) => {
        isFetching = false;
        batch.resolveBatch(formattedRecord);
      })
      .catch((err) => {
        isFetching = false;
        batch.rejectBatch(err);
      });
  });
}
