import React from 'react';
import PropTypes from 'prop-types';

import EventFilters from './EventFilters';
import ProjectFilters from './ProjectFilters';
import UserFilters from './UserFilters';
import VideoFilters from './VideoFilters';

const contentFilters = {
  channels: null,
  events: EventFilters,
  parts: null,
  projects: ProjectFilters,
  users: UserFilters,
  videos: VideoFilters,
};

const Filters = ({ disabled, index, onChange, filters }) => {
  const FilterComponent = contentFilters[index];
  if (!FilterComponent) return null;

  return (
    <FilterComponent disabled={disabled} filters={filters} onChange={onChange} />
  );
};

Filters.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.object,
  index: PropTypes.string,
  onChange: PropTypes.func,
};

Filters.defaultProps = {
  disabled: false,
  filters: {},
  index: 'project',
  onChange: () => {},
};

export default Filters;
