/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../client/icon';

import { arePathsEqual } from '../../../client/reusable_components/Router/history';
import { appendToPath, getPathUpTilPart } from '../../../utility/urls';
import { objHasPropertyOfLength } from '../../../utility/predicates';

import typography from '../../../styles/global_ui/typography.css';
import styles from './product.css';

function isCurrentRouterPath(configPath, currentPath) {
  return arePathsEqual(configPath, currentPath);
}

function getDynamicProductPartUrl(partsToAppend = []) {
  return appendToPath(getPathUpTilPart('products'), partsToAppend);
}

function objPropOrEmptyString(obj, prop) {
  return obj[prop] || '';
}

function handleInternalTransition(e, part, route, transition) {
  e.preventDefault();
  transition(route, part.slug);
}

/**
 * building PATHS
 */
const externalPathsConfig = [
  { label: 'Getting started', slug: 'get_started' },
  { label: 'Official page', slug: 'product_page' },
  { label: 'Documentation', slug: 'documentation' },
  { label: 'Libraries', slug: 'libraries' },
  { label: 'Data sheet', slug: 'datasheet' },
];

const externalPaths = externalPathsConfig.map((item) => ({
  href: (part) => objPropOrEmptyString(part.links, item.slug),
  icon: <Icon className={`${styles.externalLink} ${typography.iconBaselineFix}`} name="external-link" />,
  internal: false,
  label: item.label,
  onClick: () => {},
  shouldDisplay: (part) => objHasPropertyOfLength(part.links, item.slug),
  shouldHighlight: () => false,
}));

const internalPaths = [
  {
    href: (part) => getDynamicProductPartUrl([part.slug]),
    icon: null,
    internal: true,
    label: 'Projects',
    onClick: (e, part, transition) => handleInternalTransition(e, part, 'projects', transition),
    shouldDisplay: (part) => part.hasOwnProperty('name'),
    shouldHighlight: (currentPath) => isCurrentRouterPath('products/*', currentPath),
  },
  {
    href: (part) => getDynamicProductPartUrl([part.slug, 'specs']),
    icon: null,
    internal: true,
    label: 'Features and specs',
    onClick: (e, part, transition) => handleInternalTransition(e, part, 'specs', transition),
    shouldDisplay: (part) => part.hasOwnProperty('name'),
    shouldHighlight: (currentPath) => isCurrentRouterPath('products/*/specs', currentPath),
  },
];

const PATHS = internalPaths.concat(externalPaths);

const NavBar = ({ currentPath, part, transition }) => (
  <ul className={styles.navbar}>
    {PATHS.filter((path) => path.shouldDisplay(part)).map((path, i) => (
      <li key={i} className={styles.navbarItem}>
        <a
          className={`${styles.navbarLink} ${path.shouldHighlight(currentPath) ? styles.navbarLinkHighlight : ''}`}
          href={path.href(part)}
          onClick={(e) => path.onClick(e, part, transition)}
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          <span>{path.label}</span>
          {path.icon && <span>{path.icon}</span>}
        </a>
      </li>
    ))}
  </ul>
);

NavBar.propTypes = {
  currentPath: PropTypes.string.isRequired,
  part: PropTypes.shape({
    name: PropTypes.string,
    links: PropTypes.shape({
      datasheet: PropTypes.string,
      documentation: PropTypes.string,
      get_started: PropTypes.string,
      libraries: PropTypes.string,
      product_page: PropTypes.string,
    }),
  }),
  transition: PropTypes.func.isRequired,
};

NavBar.defaultProps = {
  part: {
    name: '',
    links: {
      datasheet: '',
      documentation: '',
      get_started: '',
      libraries: '',
      product_page: '',
    },
  },
};

export default NavBar;
