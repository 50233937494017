import React from 'react';
import PropTypes from 'prop-types';

import BaseCardHorizontalLarge from '../base_card/horizontal/BaseCardHorizontalLarge';
import EventCardText from './EventCardText';

import { EVENT_CARD } from '../../../services/keen/events/linkConstants';

const EventCardHorizontalLarge = ({ className, item, itemIndex, overrideTracking }) => (
  <BaseCardHorizontalLarge
    className={className}
    item={{ ...item, url: item.link }}
    linkProps={{
      isExternal: true,
      itemIndex,
      linkType: EVENT_CARD,
      overrideTracking,
    }}
    renderBody={() => (
      <EventCardText
        item={item}
        itemIndex={itemIndex}
        overrideTracking={overrideTracking}
        size="L"
      />
    )}
  />
);

EventCardHorizontalLarge.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    imageRight: PropTypes.bool,
    link: PropTypes.string.isRequired,
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    start_date: PropTypes.stringisRequired,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  itemIndex: PropTypes.number,
  overrideTracking: PropTypes.func,
};

EventCardHorizontalLarge.defaultProps = {
  className: '',
  itemIndex: null,
  overrideTracking: null,
};

export default EventCardHorizontalLarge;
