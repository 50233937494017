import React from 'react';
import PropTypes from 'prop-types';

import LocationFilter from './LocationFilter';
import RadioFilter from './RadioFilter';

const ATTENDANCE_OPTIONS = [
  { label: 'Virtual only', value: 'virtual' },
  { label: 'In-person only', value: 'in_person' },
];

const DATE_OPTIONS = [
  { label: 'Upcoming only', value: 'upcoming' },
  { label: 'Past only', value: 'past' },
];

const TYPE_OPTIONS = [
  { label: 'Webinars', value: 'Webinar' },
  { label: 'Meetups', value: 'Meetup' },
  { label: 'Hackathons', value: 'Hackathon' },
  { label: 'Workshops', value: 'Workshop' },
  { label: 'Conferences', value: 'Conference' },
];

const EventFilters = ({ disabled, filters, onChange }) => (
  <section>
    <RadioFilter
      buttons={DATE_OPTIONS}
      disabled={disabled}
      label="Date"
      onChange={(val) => onChange({ date: val })}
      value={filters.date}
    />
    <RadioFilter
      buttons={TYPE_OPTIONS}
      disabled={disabled}
      label="Event type"
      onChange={(val) => onChange({ event_type: val })}
      value={filters.event_type}
    />
    <RadioFilter
      buttons={ATTENDANCE_OPTIONS}
      disabled={disabled}
      label="Attendance type"
      onChange={(val) => onChange({ attendance_type: val })}
      value={filters.attendance_type}
    />
    <LocationFilter disabled={disabled} filters={filters} onChange={onChange} />
  </section>
);

EventFilters.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.shape({
    date: PropTypes.string,
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    distance: PropTypes.object,
  }),
  onChange: PropTypes.func,
};

EventFilters.defaultProps = {
  disabled: false,
  filters: {},
  onChange: () => {},
};

export default EventFilters;
