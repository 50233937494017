import React from 'react';
import { getCategoryEnum } from '../../../graphql/feed_posts/enums';
import CategoryBadgeLabel from '../../../client/discussion/templates/CategoryBadgeLabel';

// TODO: DRY out duplicated code between this and app/react/scenes/contest_page/discussion/categoryConfig.js

const CATEGORY_CONFIGS = [
  {
    colorClass: 'categoryGreenBG',
    enum: getCategoryEnum('Announcements'),
    path: getCategoryEnum('Announcements').toLowerCase(),
    text: 'Announcements from the admin team',
    title: 'Announcements',
  },
  {
    colorClass: 'categoryCobaltBG',
    enum: getCategoryEnum('General'),
    path: getCategoryEnum('General').toLowerCase(),
    text: 'Share ideas, chat and interact with other members',
    title: 'General',
  },
  {
    colorClass: 'categoryRoseBG',
    enum: getCategoryEnum('Technical help'),
    path: getCategoryEnum('Technical help').toLowerCase(),
    text: 'Getting started, hardware troubleshooting and project help',
    title: 'Technical help',
  },
];

const getCategoriesForSelect = (categoryConfigs, labelFn = null) => categoryConfigs.map((config) => ({
  label: (labelFn && typeof labelFn === 'function') ? labelFn(config) : config.title,
  labelText: config.title,
  value: config.enum,
}));

export const createCategoryConfigs = () => ({
  CATEGORY_CONFIGS: CATEGORY_CONFIGS,
  SELECT_CATEGORIES: getCategoriesForSelect(CATEGORY_CONFIGS, (categoryConfig) => (<CategoryBadgeLabel categoryConfig={categoryConfig} />),
  ),
});
