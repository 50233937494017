import React from 'react';
import { love } from './rating_svgs';

const RobotLove = () => (
  <div
    dangerouslySetInnerHTML={{ __html: love }}
  />
);

export default RobotLove;
