import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../client/reusable_components/LazyImage';

import TopSwoosh from './TopSwoosh';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import util from '../../styles/global_ui/util.css';
import styles from './business.css';

const c = {
  section: styles.partnersWrapper,
  bgWrapper: `${util.bgStaticWhite} ${styles.partnersBG}`,
  partners: `${layout.wrapper1170} ${layout.wrapper1170} ${layout.flexColumnCenterCenter} ${layout.marginAuto} ${layout.padding015} ${layout.flexRow} ${styles.partners}`,
  header: `${typography.h3Responsive} ${typography.bold} ${typography.hackster} ${layout.margin0} ${layout.marginBottom10}`,
  subHeading: `${typography.h1Responsive} ${typography.staticCharcoal}`,
  text: `${typography.staticCharcoal}`,
  ul: `${util.ulReset} ${layout.flexRowWrap} ${styles.logos}`,
  logo: layout.flexCenterCenter,
  transparent: util.bgTransparent,
};

const Partners = ({ logos }) => (
  <section className={c.section}>
    <TopSwoosh />
    <div className={c.bgWrapper}>
      <div className={c.partners}>
        <div>
          <h2 className={c.header}>Our Partners</h2>
          <h3 className={c.subHeading}>Who we work with</h3>
          <p className={c.text}>Our partners, ranging from industry leaders to startups, fuel our collaborative ecosystem. Together, we drive innovation, share knowledge, and contribute to the ever-evolving tech landscape. Join us in shaping the future of technology.</p>
        </div>
        <ul className={c.ul}>
          {logos.map((logo) => (
            <li key={logo.name} className={c.logo}>
              <LazyImage
                alt={`logo for ${logo.name}`}
                classList={{ root: c.transparent }}
                fit="fill"
                ratio="1:1"
                src={logo.src}
                width={73}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>

);

Partners.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  })).isRequired,
};

export default Partners;
