import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './dropdown_list.css';

class DropdownList extends Component {
  constructor(props) {
    super(props);

    this.state = { focused: -1 };

    this.dismiss = this.dismiss.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.isDropdownItemFocused = this.isDropdownItemFocused.bind(this);

    this._isMounted;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentQuery !== this.props.currentQuery) {
      this.setState({ focused: -1 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  dismiss() {
    if (!this._isMounted) return;
    this.props.dismiss();
  }

  handleKeyDown(e) {
    if (!this._isMounted) return;

    if (e.keyCode === 40 /* DOWN */ && this.state.focused < this.props.options.length - 1) {
      this.setState({ focused: this.state.focused + 1 });
    } else if (e.keyCode === 38 /* UP */ && this.state.focused > -1) {
      this.setState({ focused: this.state.focused - 1 });
    } else if (e.keyCode === 13 /* ENTER */ && this.state.focused > -1) {
      this.props.onSelect(this.props.options[this.state.focused]);
    }
  }

  isDropdownItemFocused() {
    return this.state.focused > -1;
  }

  render() {
    return (
      <ul className={styles.list} onMouseLeave={() => this.setState({ focused: -1 })}>
        {this.props.options.map((option, i) => (
          <li
            key={i}
            className={`${styles.listItem} ${this.state.focused === i ? styles.focused : ''}`}
            onClick={() => this.props.onSelect(option)}
            onMouseOver={(e) => this.setState({ focused: i })}
          >
            {this.props.templateFn && typeof this.props.templateFn === 'function' ? this.props.templateFn(option) : option}
          </li>
        ))}
      </ul>
    );
  }
}

DropdownList.propTypes = {
  currentQuery: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  templateFn: PropTypes.func,
};

DropdownList.defaultProps = { templateFn: null };

export default DropdownList;
