import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DummyBaseCardHorizontalLarge from '../../../client/cards/base_card/horizontal/DummyBaseCardHorizontalLarge';
import EventCardHorizontalLarge from '../../../client/cards/event_card/EventCardHorizontalLarge';
import { TextPlaceholder } from '../../home_edit/events/forms/preview_cards/templates';

import layout from '../../../styles/global_ui/layout.css';
import gridStyles from '../../../styles/global_ui/grid.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './events_home_page.css';

const cellStyles = `${gridStyles.cell} ${gridStyles.cell_1_2}`;
const listStyles = `${gridStyles.grid} ${gridStyles.guttersH15} ${gridStyles.guttersV30} ${gridStyles.guttersH60_sm}`;

const _getCards = (events) => (
  events.map((event, i) => (
    <EventCardHorizontalLarge
      key={event.id}
      className={`${cellStyles} ${styles.eventCardHorizontalLarge}`}
      item={event}
      itemIndex={i}
    />
  ))
);

const _getDummyCards = () => (
  [...Array(4).keys()].map((i) => <DummyBaseCardHorizontalLarge key={i} className={`${cellStyles} ${styles.eventCardHorizontalLarge}`} />)
);

const _getDummyHeader = () => (
  <Fragment>
    <TextPlaceholder height={20} width="25%" />
    <span className={layout.hiddenSmallDown}></span>
    <TextPlaceholder height={20} width="15%" />
  </Fragment>
);

const _getHeader = (title, seeAllLink) => (
  <Fragment>
    <p className={typography.h4}>{title}</p>
    {seeAllLink}
  </Fragment>
);

const LocalVirtualEventsList = ({ events, eventType, getSeeAllLink, isDummyList, title }) => (
  <div className={layout.marginTop60}>
    <div className={`${layout.flexJustifySpaceBetween} ${layout.marginBottom22}`}>
      {isDummyList ? _getDummyHeader() : _getHeader(title, getSeeAllLink(eventType))}
    </div>
    <div className={listStyles}>
      {isDummyList ? _getDummyCards() : _getCards(events)}
    </div>
    {!isDummyList && getSeeAllLink(eventType, false)}
  </div>
);

LocalVirtualEventsList.propTypes = {
  eventType: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    start_date: PropTypes.stringisRequired,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  })),
  getSeeAllLink: PropTypes.func,
  isDummyList: PropTypes.bool,
  title: PropTypes.string,
};

LocalVirtualEventsList.defaultProps = {
  eventType: null,
  events: null,
  getSeeAllLink: null,
  isDummyList: false,
  title: null,
};

export default LocalVirtualEventsList;
