const PER_PAGE = 40;

const KNOWN_PARAMS = {
  page: (page) => parseInt(page, 10),
  per_page: (page) => parseInt(page, 10),
};

const KNOWN_SORTS = {
  alpha: 'alpha',
  last_updated: 'last_updated',
  most_members: 'most_members',
  most_projects: 'most_projects',
  new_first: 'DEFAULT',
};

function filterQueryParameters(queryMap, map) {
  const keys = Object.keys(queryMap);
  if (!keys.length) return {};

  return keys.reduce((acc, key) => {
    if (map[key]) {
      acc[key] = map[key](queryMap[key]);
    }

    return acc;
  }, {});
}

function getSort(queryMap) {
  const sort = queryMap.sort;
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!sort || !KNOWN_SORTS.hasOwnProperty(sort)) return {};
  if (KNOWN_SORTS[sort] === 'DEFAULT') return {};

  return { sort: queryMap.sort.toUpperCase() };
}

function composeQueryParameters(queryMap) {
  const params = filterQueryParameters(queryMap, KNOWN_PARAMS);
  const sort = getSort(queryMap);

  return {
    ...params,
    ...sort,
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    per_page: params.hasOwnProperty('per_page') ? params.per_page : PER_PAGE,
  };
}

export default function queryBuilder(queryMap) {
  return new Promise((resolve, reject) => {
    resolve(composeQueryParameters(queryMap));
  });
}
