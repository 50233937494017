import React from 'react';
import PropTypes from 'prop-types';

import GridList from '../../../client/wrappers/grid_list';
import ContestCard from '../../../client/cards/contest_card';

import cardStyles from '../../../client/cards/contest_card/contest_card.css';
import gridStyles from '../../../styles/global_ui/grid.css';
import layout from '../../../styles/global_ui/layout.css';

const ContestsList = ({ results }) => (
  <div className={`${layout.marginTop30} ${layout.marginBottom30}`}>
    <GridList
      ItemComponent={ContestCard}
      className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
      gutterSize={15}
      itemProps={{
        classList: { spacer: cardStyles.spacerForResizableCard },
        config: { btnSize: 'md', imageSetStyle: false },
        sponsorsConfig: {
          limit: 5,
          menuPos: {
            alignRight: true,
            alignTop: false,
          },
        },
      }}
      maxCols={3}
      records={results}
    />
  </div>
);

ContestsList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    alternate_cover_image_url: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })).isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

ContestsList.defaultProps = { results: [] };

export default ContestsList;
