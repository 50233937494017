import React from 'react';

const Camera = ({ className }) => (
  <svg className={className} version="1.1" viewBox="3 0 38 29" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M11.0833333,4 L2.00285922,4 C0.90185079,4 0,4.89703997 0,6.00359486 L0,26.9964051 C0,28.1102368 0.896710617,29 2.00285922,29 L35.9971408,29 C37.0981492,29 38,28.10296 38,26.9964051 L38,6.00359486 C38,4.88976324 37.1032894,4 35.9971408,4 L26.9166667,4 L25.6371916,0.929259896 C25.4208741,0.410097957 24.80148,0 24.2482026,0 L13.7517974,0 C13.1948816,0 12.5766484,0.416043827 12.3628084,0.929259896 L11.0833333,4 Z" id="path-1"></path>
      <mask fill="white" height="29" id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" width="38" x="0" y="0">
        <use xlinkHref="#path-1"></use>
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(3.000000, 0.000000)">
      <use id="Combined-Shape" mask="url(#mask-2)" stroke="#FFFFFF" strokeWidth="4" xlinkHref="#path-1"></use>
      <circle cx="19" cy="16" id="Oval-2" r="6" stroke="#FFFFFF" strokeWidth="2"></circle>
    </g>
  </svg>
);

export default Camera;
