import { formatUserSkillsFromAlgolia } from '../../../../client/cards/search/helpers/formatting.js';
import { buildLocation } from '../../../../services/country_data';
import { getInObj } from '../../../../utility/accessors';

export default function users({
  available_for_hire,
  avatar_url,
  city,
  country,
  followers_count,
  _highlightResult,
  id,
  name,
  projects_count,
  skills,
  state,
  tier,
  url,
}, options = {}) {
  const { appliedFilters } = options;

  return {
    body: { htmlString: getInObj(['bio', 'value'], _highlightResult) },
    id: id,
    image: {
      linkTo: url,
      loadBuffer: 100,
      shape: 'circle',
      url: avatar_url,
      width: 75,
    },
    stats: [
      // these are pluralized / comma formatted in the Stats sub_component
      { metric: projects_count, text: 'Project' },
      { metric: followers_count, text: 'Follower' },
    ],
    subTitle:
      city || state
        ? [{ text: buildLocation(city || '', state || '', country || '') }]
        : [],
    tags: formatUserSkillsFromAlgolia(skills, _highlightResult, appliedFilters),
    title: {
      text: name,
      additionalInfo: available_for_hire ? 'Available for hire' : '',
      linkTo: url,
      user: {
        id: id,
        tier: tier,
      },
    },
  };
}
