import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Close } from '@radix-ui/react-popover';

import setTooltipAsViewed from '../../requests/tooltips';
import errorHandler from '../../services/error_handler';

import Icon from '../../client/icon';
import Popup from '../../client/reusable_components/Popup';
import { SURVEY_SLUG } from '../../client/reusable_components/SurveyPopup/enums';

import animation from '../../styles/global_ui/animation.css';
import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import util from '../../styles/global_ui/util.css';
import style from './tour.css';
import Button from '../../client/buttons/base';

// These ids are stored in 👇🏼
// app/react/client/reusable_components/NewFeatureTooltip/store.js
export const TIPS = [{
  content: 'Here you can find recommendations for projects, videos and more! You can update your interests at any time.',
  id: 12,
  selectorIds: ['#feed-update-preferences'],
  title: 'Welcome to your feed!',
},
{
  content: 'Share your projects with the community by uploading a project here.',
  id: 13,
  selectorIds: ['#top-nav-create-project'],
  title: <Icon name="edit" size={24} />,
},
{
  content: 'Browse projects by going to the projects page.',
  id: 14,
  selectorIds: ['#top-nav-Projects-wrapper'],
  title: <Icon name="eye" size={24} />,
},
{
  alignment: 'start',
  content: 'Follow a Platform Hub to stay up to date with a company’s products, events, and contests.',
  id: 15,
  selectorIds: ['#top-nav-Channels-wrapper'],
  title: <Icon name="product" size={24} />,
},
{
  alignment: 'end',
  content: 'Navigate back to your feed by clicking inside the user menu here.',
  id: 16,
  selectorIds: ['#user-dropdown-toggle', '#mobile-nav-hamburger svg'],
  title: '',
},
{
  content: 'Leave feedback at any time by tapping this icon.',
  id: 17,
  selectorIds: [`#${SURVEY_SLUG.PASSIVE_GENERAL_FEEDBACK}-icon`],
  title: <Icon className={typography.hackster} name="chat-outline" size={24} />,
},
];

const IDS = TIPS.map(({ id }) => id);

const getRef = (selectors) => selectors.map((selector) => {
  const ref = document.querySelector(selector);
  return ref?.clientHeight !== 0 ? ref : null;
}).find((n) => n);

const c = {
  tipContainer: `${layout.flexColumn} ${layout.gutter10} ${layout.padding1020}`,
  tipTitle: `${typography.bodyM} ${typography.hackster} ${typography.bold}`,
  tipContent: typography.bodyS,
  tipFooter: `${layout.flexJustifySpaceBetween} ${layout.flexCenterItems} ${layout.gutter10}`,
  dotWrapper: `${layout.flex} ${layout.gutter5}`,
  dot: `${typography.icon6} ${util.circle}`,
  buttonWrapper: `${layout.flex} ${layout.gutter10}`,
  tipCloseButton: `${util.boxShadowNone} ${typography.pebble} ${util.bgTransparent} ${layout.borderNone} ${util.borderRadius} ${layout.inlineFlex} ${typography.bodyXS} ${typography.bold} ${layout.padding5} ${layout.flexColumnCenterCenter} ${style.closeButton}`,
  tipNextButton: `${typography.white} ${util.bgHackster} ${layout.borderNone} ${util.borderRadius} ${layout.inlineFlex} ${typography.bold} ${layout.padding510} ${layout.flexColumnCenterCenter} ${style.nextButton}`,
};

const Tip = ({ title, content, currentId, handleNext, handleTipClose, showNext, steps }) => {
  const ref = useRef(null);

  useEffect(() => {
    // move focus to the next button if it is present. This allows keyboard user to hit enter through all the tips.
    ref?.current?.focus();
  }, []);

  return (
    <div className={c.tipContainer}>
      <div className={c.tipTitle}>{title}</div>
      <div className={c.tipContent}>{content}</div>
      <div className={c.tipFooter}>
        <div className={c.dotWrapper}>
          {steps.map(({ id }) => (
            <div key={id} className={`${currentId === id ? util.bgHackster : util.bgAsphalt} ${c.dot}`} />
          ))}

        </div>
        <div className={c.buttonWrapper}>
          <Close
            className={showNext ? c.tipCloseButton : c.tipNextButton}
            onClick={handleTipClose}
          >
            Close
          </Close>
          {showNext && <Button ref={(n) => ref.current = n} className={c.tipNextButton} onClick={handleNext} size="sm">Next</Button>}
        </div>
      </div>
    </div>
  );
};

Tip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  currentId: PropTypes.number,
  handleNext: PropTypes.func,
  handleTipClose: PropTypes.func,
  showNext: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.number,
    selectorIds: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  })),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Tour = ({ viewedTips: previouslyViewed }) => {
  const startingId = IDS.find((id) => !previouslyViewed.includes(id));
  const [nodeSearchInterval, setNodeSearchInterval] = useState(startingId ? 300 : null);
  const [allRefs, setAllRefs] = useState();
  // the order tips appear in is currently based on the index which we increment by 1 with handleNext.
  const [remainingTips, setRemainingTips] = useState();
  const [viewedTips, setViewedTips] = useState([]);
  const [currentTip, setCurrentTip] = useState();
  const anchor = useRef(null);

  const handleTipClose = useCallback(() => {
    setCurrentTip(null); // removes popup without triggering close event

    // Set all tips not yet seen as seen when dismissing any tip. We are assuming that if they initially close the tour, they do not want to see any more tips that are a part of this tour.
    const ids = remainingTips.map(({ id }) => id);
    const remainingIds = ids.filter((id) => !viewedTips.includes(id));

    setTooltipAsViewed(remainingIds.join(','))
      .catch((err) => {
        errorHandler('setTooltipAsViewed Error: ', err);
      });
  }, [remainingTips, viewedTips]);

  const handleNext = useCallback(() => {
    const currentIndex = remainingTips.findIndex(({ id }) => id === currentTip.id);
    const nextTip = remainingTips[currentIndex + 1];
    const nextRef = allRefs[nextTip?.id];

    setTooltipAsViewed(currentTip.id)
      .then(() => {
        anchor.current = nextRef;
        setCurrentTip(nextTip);
        setViewedTips([...viewedTips, currentTip.id]);
      })
      .catch((err) => {
        errorHandler('setTooltipAsViewed Error: ', err);
      });
  }, [allRefs, currentTip?.id, remainingTips, viewedTips]);

  const handleDismiss = useCallback(() => {
    if (!currentTip) return;

    setTooltipAsViewed(currentTip.id)
      .then(() => setCurrentTip(null))
      .catch((err) => {
        errorHandler('setTooltipAsViewed Error: ', err);
      });
  }, [currentTip]);

  // Once the dom is ready, look for all nodes that are visible on the screen.
  useEffect(() => {
    // Don't look for nodes until nav bar has loaded.
    if (nodeSearchInterval) {
      return;
    }
    // we only care about tips in our list that they haven't seen yet.
    const tips = TIPS.filter((tip) => !previouslyViewed.includes(tip.id));
    // A node might be hidden on mobile, when that is the case we should skip that node and look for the next.
    // If none are visible, skip that tip.
    const refs = tips.reduce((obj, tip) => {
      const node = getRef(tip.selectorIds);
      if (!node) {
        return obj;
      } else {
        return { ...obj, [tip.id]: node };
      }
    }, {});
    // filter the list again to only include those that are visible.
    const filteredTips = tips.filter(({ id }) => refs[id]);
    const startingTip = filteredTips.find(({ id }) => Object.keys(refs).includes(id.toString()));
    const currentRef = refs[startingTip?.id];

    if (!currentRef) {
      setCurrentTip(null);
      return;
    }

    anchor.current = currentRef;
    setAllRefs(refs);
    setRemainingTips(filteredTips);
    setCurrentTip(startingTip);
  }, [nodeSearchInterval, previouslyViewed]);

  // This effect should only happen on mount. We need to check if portion of the dom where our refs are located has hydrated.
  useEffect(() => {
    if (!nodeSearchInterval) {
      return;
    }

    let found = false;
    // Nav bar might not be on the dom yet, poll until we can find it.
    const find = setInterval(() => {
      found = getRef(['#main-links-nav']);
      if (found) {
        setNodeSearchInterval(null);
        return;
      }
    }, nodeSearchInterval);

    return () => clearInterval(find);
  }, [nodeSearchInterval]);

  if (!currentTip) {
    return null;
  }

  return (
    <Popup
      key={`${currentTip.id}`}
      align={currentTip.alignment || 'center'}
      alignOffset={0}
      anchorRef={anchor}
      asModal
      classList={{ container: `${animation.pulse} ${style.popupContainer}` }}
      collisionPadding={5}
      defaultOpen
      hasArrow
      onOpenChange={(isOpen) => !isOpen && handleDismiss()}
      side="bottom"
      testId={`feed-tour-id-${currentTip.id}`}
    >
      <Tip
        content={currentTip.content}
        currentId={currentTip.id}
        handleNext={handleNext}
        handleTipClose={handleTipClose}
        showNext={currentTip.id !== remainingTips[remainingTips.length - 1].id}
        steps={remainingTips}
        title={currentTip.title}
      />
    </Popup>
  );
};

Tour.propTypes = { viewedTips: PropTypes.arrayOf(PropTypes.number).isRequired };

export default Tour;
