import React, { useCallback, useState } from 'react';

import VideoCard from '../../client/cards/video_card';
import GridList from '../../client/wrappers/grid_list';
import LazyComponent from '../../client/wrappers/lazy_component';
import errorHandler from '../../services/error_handler';
import { graphQuery } from '../../requests/graphql.js';

import gridStyles from '../../styles/global_ui/grid.css';

const VideoSection = () => {
  const [videos, setVideos] = useState();

  const videoReveal = useCallback(() => {
    graphQuery({ t: 'videos_with_simple_pagination' }, { per_page: 4 })
      .then(({ videos }) => setVideos(videos))
      .catch((err) => errorHandler('videoReveal', err));
  }, []);

  return (
    <LazyComponent onReveal={videoReveal}>
      <GridList
        ItemComponent={VideoCard}
        className={`${gridStyles.guttersH15_sm} ${gridStyles.guttersV15_sm}`}
        gutterSize={15}
        itemProps={{ config: { renderOverlay: true } }}
        maxCols={4}
        records={videos?.records}
      />
    </LazyComponent>
  );
};

export default VideoSection;
