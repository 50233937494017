import PropTypes from 'prop-types';
import React from 'react';
import ProjectCard from '../../../../../client/cards/project_card/HorizontalProjectCard';

const HorizontalProjectCard = (props) => {
  const project = {
    content_type: props.content_type,
    cover_image_url: props.cover_image_url,
    difficulty: props.difficulty,
    guest_name: props.guest_name,
    hid: props.hid,
    id: props.id,
    name: props.name,
    one_liner: props.one_liner,
    position: props.position,
    published_state: props.published_state,
    stats: props.stats,
    team: props.team,
    url: props.url,
  };

  return (
    <ProjectCard
      project={project}
      toggleRespect={(hid, createOrDeleteBool) => props.updateResource(hid, 'project', ['projects', 'records', ['hid', hid]], createOrDeleteBool, 'project_respects')}
    />
  );
};

HorizontalProjectCard.propTypes = {
  content_type: PropTypes.string,
  cover_image_url: PropTypes.string,
  difficulty: PropTypes.string,
  guest_name: PropTypes.string,
  hid: PropTypes.string,
  name: PropTypes.string,
  one_liner: PropTypes.string,
  position: PropTypes.number,
  published_state: PropTypes.string,
  stats: PropTypes.shape({
    respects: PropTypes.number,
    views: PropTypes.number,
  }),
  team: PropTypes.shape({
    members: PropTypes.array,
    name: PropTypes.string,
    user_name: PropTypes.string,
  }),
  updateResource: PropTypes.func.isRequired,
  url: PropTypes.string,
};

HorizontalProjectCard.defaultProps = {};

export default HorizontalProjectCard;
