import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import InterestsCard from './InterestCard';
import GridList from '../../client/wrappers/grid_list';
import Tooltip from '../../client/reusable_components/Tooltip';

import animation from '../../styles/animation.css';
import grid from '../../styles/global_ui/grid.css';
import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';
import styles from './interests.css';

import { CHANNEL_TYPE_ENUM } from './constants';

const INTEREST_TYPE_TO_SECTION_INFO = {
  communities: { title: 'Community Hubs', tooltip: 'Community hubs are a place for schools, makerspaces and other organizations to build and organize their community.' },
  platforms: { title: 'Platform Hubs', tooltip: 'Platform hubs are a place for hardware and software platforms to showcase their products and what the community is creating with them.' },
  products: { title: 'Products', tooltip: '' },
  search: { title: 'Search Results', tooltip: null },
  selected: { title: 'Selected', tooltip: null },
  topics: { title: 'Topic Pages', tooltip: 'Topic pages are a place for users to get inspired by projects and have discussions centered around a specific theme or topic. ' },
};

// Note: Adapted from DummyBaseCardVertical.
const DummyCard = () => (
  <div className={`${layout.flexColumn} ${layout.fullWidth}`}>
    <div className={`${layout.fullWidth} ${utilStyles.absolutePlaceholderParent} ${styles.dummyCard}`}>
      <div className={`${animation.loader} ${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}`} />
    </div>
  </div>
);

const genericTypeFromEnum = (enumType) => CHANNEL_TYPE_ENUM.includes(enumType) ? 'channels' : 'products';

const InterestSection = ({ createInterest, deleteInterest, interests, isBusy, options, sectionType }) => {
  const { title, tooltip } = INTEREST_TYPE_TO_SECTION_INFO[sectionType];

  const optsWithChecked = useMemo(() => (
    options.map((opt) => {
      const checked = interests[genericTypeFromEnum(opt.type)].includes(opt.id);
      return { ...opt, checked };
    })
  ), [options, interests]);

  const handleUpdate = ({ value, checked }) => {
    checked
      ? createInterest(value)
      : deleteInterest(value);
  };

  return (
    <section className={layout.marginBottom30}>
      <div className={`${layout.flex} ${utilStyles.borderBottom} ${styles.sectionHeader}`}>
        <h3 className={`${typography.h3Responsive} ${typography.charcoal} ${layout.margin0} ${layout.marginRight10}`}>
          {(optsWithChecked.length === 0 && ['selected', 'search'].includes(sectionType) && !isBusy) ? 'No matches found. Try searching or filtering for something else.' : title }
        </h3>
        <div className={`${layout.flexCenterItems}`}>
          {tooltip && <Tooltip align="start" arrowPadding={24} classList={{ icon: styles.tooltipIcon }} collisionPadding={15} content={tooltip} />}
        </div>
      </div>

      <GridList
        DummyComponent={() => <DummyCard />}
        ItemComponent={InterestsCard}
        cellClassOverride={`${layout.flex} ${grid.cell} ${grid.cell_1_2} ${grid.cell_1_3_sm} ${grid.cell_1_4_md} ${grid.cell_1_6_ml}`}
        gutterSize={30}
        isLoading={isBusy}
        itemKey="option"
        itemProps={{ handleUpdate: handleUpdate }}
        loadingIndicator="dummy"
        maxCols={6}
        placeholder=""
        records={optsWithChecked}
        validationFn={() => true} // Placeholder is handled outside of component.
      />
    </section>
  );
};

InterestSection.propTypes = {
  createInterest: PropTypes.func.isRequired,
  deleteInterest: PropTypes.func.isRequired,
  interests: PropTypes.shape({
    channels: PropTypes.arrayOf(PropTypes.number).isRequired,
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  isBusy: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    avatar_url: PropTypes.string,
    id: PropTypes.number.isRequired,
    image_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired),
  sectionType: PropTypes.string.isRequired,
};

export default InterestSection;
