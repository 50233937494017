import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import DummyCard from '../../../../../client/cards/project_card/DummyCard';
import ProjectCard from '../../../../../client/cards/project_card';
import styles from './tools.css';

import fadeIn from '../../../../../styles/transitions/fade_in_out.css';

const CardFade = ({ config, imageProps, maxWidth, project, toggleRespect }) => (
  <div className={styles.scrollerItem}>
    <CSSTransition appear={true} classNames={fadeIn} timeout={450}>
      {project === null
        ? <DummyCard key="dummy" className={styles.dummy} />
        : (
          <ProjectCard
            key={project.hid}
            cardBorder={true}
            config={config}
            imageProps={imageProps}
            project={project}
            toggleRespect={toggleRespect}
          />
          )}
    </CSSTransition>
  </div>
);

CardFade.propTypes = {
  config: PropTypes.shape({ controlledRespects: PropTypes.bool }), // Do we want a parent component to control the respect counter.
  imageProps: PropTypes.object,
  maxWidth: PropTypes.number,
  project: PropTypes.object,
  toggleRespect: PropTypes.func,
};

CardFade.defaultProps = {
  config: { controlledRespects: false },
  imageProps: {},
  maxWidth: 240,
  project: null,
  toggleRespect: () => {},
};

export default CardFade;
