import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ContentPlaceholder from '../../../../client/reusable_components/ContentPlaceholder';
import ExpandableCard from '../list/ExpandableCard';

import { formatNumberWithCommas } from '../../../../utility/formatters';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import listStyles from '../list/list.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './profile.css';

class Communities extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { records, metadata } = this.props.communities;

    return (
      <section className={`${styles.communities} ${layout.flexColumn}`}>
        <header className={styles.sectionHeader}>
          <span className={`${typography.bodyM} ${typography.bold}`}>Channels</span>
          <span className={`${typography.bodyM} ${styles.count}`}>{`${formatNumberWithCommas(metadata.total_records)}`}</span>
          {metadata.total_records > 0 && (
            <a className={layout.marginLeftAuto} href="/channels">+ Add channels</a>
          )}
        </header>
        {metadata.total_records === 0
          ? (
            <ContentPlaceholder
              isProfileOwner={this.props.isProfileOwner}
              templateArgs={{ name: this.props.profile.name }}
              type="no-communities"
            />
            )
          : (
            <div className={listStyles.list}>
              {records.slice(0, 5).map((community, i) => (
                <ExpandableCard
                  key={i}
                  icon={community.avatar_url}
                  info={community.status}
                  link={community.url}
                  onMouseLeave={() => this.props.killPopover(community.id, 'community')}
                  onMouseOver={(e) => this.props.triggerPopover(
                    ['communities', 'records', ['id', community.id]],
                    community.id,
                    'community',
                    e.target,
                    'left',
                    ['communities', 'records', ['id', community.id], 'brief'],
                    { arrowOffset: 0, popoverOffsetVertical: -120, targetOffset: 15 },
                  )}
                  title={community.name}
                />
              ))}
            </div>
            )}
        {metadata.total_records > 5
        && (
          <button
            className={`${buttonStyles.sm} ${buttonStyles.secondary} ${buttonStyles.pill} ${layout.flexAlignSelfEnd} ${layout.marginTop10}`}
            onClick={() => this.props.openDialog('communities')}
          >
            View all
          </button>
        )}
      </section>
    );
  }
}

Communities.propTypes = {
  communities: PropTypes.shape({
    metadata: PropTypes.shape({
      currentPage: PropTypes.number,
      per_page: PropTypes.number,
      total_pages: PropTypes.number,
      total_records: PropTypes.number,
    }),
    records: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string, // .isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
  }).isRequired,
  isProfileOwner: PropTypes.bool,
  killPopover: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  profile: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  triggerPopover: PropTypes.func.isRequired,
};

Communities.defaultProps = {};

export default Communities;
