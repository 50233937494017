import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../../client/nav_components/breadcrumb';
import LazyImage from '../../client/reusable_components/LazyImage';
import VideoInfo from '../../client/videos/video_info';
import VideoOverlay from '../../client/cards/video_card/VideoOverlay';
import VideoViewer from '../../client/videos/video_viewer';

import layoutStyles from '../../styles/global_ui/layout.css';
import utilStyles from '../../styles/global_ui/util.css';

class VideoPage extends Component {
  constructor(props) {
    super(props);

    this.state = { started: false };

    this.startVideo = this.startVideo.bind(this);
  }

  startVideo(e) {
    this.setState({ started: true });
  }

  _renderPlaceholder(video) {
    return (
      <div className={`${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding16_9}`}>
        <LazyImage
          alt={video.title}
          className={`${utilStyles.absolutePlaceholderChild} ${utilStyles.overflowHidden}`}
          fit="crop"
          ratio="16:9"
          setStyle={false}
          src={video.image.url || ''}
          width={960}
        />
        <VideoOverlay onClick={this.startVideo} />
      </div>
    );
  }

  render() {
    const { video } = this.props;

    return (
      <div className={layoutStyles.container}>
        <div className={layoutStyles.wrapper960}>
          <Breadcrumb href="/videos" text="Back to videos" />
          {this.state.started
            ? <VideoViewer video={video} />
            : this._renderPlaceholder(video)}
          <VideoInfo inDialog={false} video={video} />
        </div>
      </div>
    );
  }
}

VideoPage.propTypes = {
  video: PropTypes.shape({
    category: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default VideoPage;
