import PropTypes from 'prop-types';
import React from 'react';
import { isCurrentPath, history, Link } from '../../../../client/reusable_components/Router';
import UserCard from './UserCard';

import layout from '../../../../styles/global_ui/layout.css';
import navBarStyles from '../../../../styles/navbar.css';
import styles from './navbar.css';

const NavBar = ({ basePath, currentUser, initPath, isProfileOwner, fetchedCurrentUser, openAlert, openDialog, profile, rootPath, routes, store, transition, updateResource }) => (
  <div className={`${layout.container} ${layout.paddingBottom0} ${styles.container}`}>
    <div className={layout.wrapper1170}>
      <UserCard
        currentUser={currentUser}
        fetchedCurrentUser={fetchedCurrentUser}
        isProfileOwner={isProfileOwner}
        openAlert={openAlert}
        openDialog={openDialog.bind(this, store)}
        profile={profile}
        profileStats={store.get('profileStats')}
        rootPath={rootPath}
        transition={transition}
        updateResource={updateResource}
      />
      <div className={`${navBarStyles.themeLight} ${navBarStyles.center}`}>
        <ul className={`${navBarStyles.list} ${layout.noScrollBar} ${styles.navList}`}>
          {routes.map((route, i) => (
            <li
              key={i}
              className={navBarStyles.listItem}
            >
              <Link
                classList={{
                  anchor: isCurrentPath(history, route, initPath) ? navBarStyles.linkFocused : navBarStyles.link,
                  root: isCurrentPath(history, route, initPath) ? navBarStyles.linkWrapperFocused : navBarStyles.linkWrapper,
                }}
                href={`${basePath}${route.href}`}
                name={route.name}
                onClick={() => transition(route.href, store)}
                redirect={route.redirect}
              >
                {route.dropdown
                && (
                  <div className={navBarStyles.dropdown}>
                    {route.dropdown.map((subroute, ii) => (
                      <Link
                        key={ii}
                        classList={{ anchor: navBarStyles.link, root: navBarStyles.subLinkWrapper }}
                        href={`${basePath}${subroute.href}`}
                        name={subroute.name}
                        onClick={() => transition(subroute.href, store)}
                        redirect={subroute.redirect}
                      />
                    ))}
                  </div>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

NavBar.propTypes = {
  basePath: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchedCurrentUser: PropTypes.bool.isRequired,
  initPath: PropTypes.string.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
  openAlert: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  store: PropTypes.object.isRequired,
  transition: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
};

export default NavBar;
