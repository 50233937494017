const DEFAULT_PER_PAGE = 20;

const DEFAULT_PARAMS = {
  page: 1,
  per_page: DEFAULT_PER_PAGE,
};

const KNOWN_PARAMS = {
  page: (page) => parseInt(page, 10),
  per_page: (per_page) => parseInt(per_page, 10),
};

function filterQueryParameters(queryMap, map) {
  const keys = Object.keys(queryMap);
  if (!keys.length) return {};

  return keys.reduce((acc, key) => {
    if (map[key]) {
      acc[key] = map[key](queryMap[key]);
    }

    return acc;
  }, {});
}

function composeQueryParameters(queryMap) {
  return {
    ...DEFAULT_PARAMS,
    ...filterQueryParameters(queryMap, KNOWN_PARAMS),
  };
}

export default function queryBuilder(queryMap) {
  return new Promise((resolve, reject) => {
    resolve(composeQueryParameters(queryMap));
  });
}
