import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../link/AnalyticsLink';
import EventTypeOrLocation from './EventTypeOrLocation';

import { EVENT_CARD } from '../../../services/keen/events/linkConstants';
import { getDateRangeAsText, getDateRangeWithYearAsText } from './eventCardHelpers';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const EventCardText = ({ className, color, item, itemIndex, overrideTracking, showYear, size, statusBadge }) => (
  <div className={`${className} ${layout.fullWidth} ${typography.overflowBreakWord}`}>
    <AnalyticsLink
      className={`${typography.link} ${typography.bold} ${size === 'M' ? typography.bodyM : typography.bodyL} ${typography[color]}`}
      isExternal={true}
      item={{ id: item.id, url: item.link }}
      itemIndex={itemIndex}
      linkType={EVENT_CARD}
      overrideTracking={overrideTracking}
    >
      {item.title}
    </AnalyticsLink>
    <div className={`${size === 'M' ? typography.bodyS : typography.bodyM} ${typography[color]}`}>
      <div className={layout.marginTop5}>{showYear ? getDateRangeWithYearAsText(item) : getDateRangeAsText(item)}</div>
      <div className={layout.marginTop5}><EventTypeOrLocation item={item} /></div>
      {statusBadge && statusBadge(item)}
    </div>
  </div>
);

EventCardText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  item: PropTypes.shape({
    approved: PropTypes.bool.isRequired,
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string }),
    link: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  itemIndex: PropTypes.number,
  overrideTracking: PropTypes.func,
  showYear: PropTypes.bool,
  size: PropTypes.oneOf(['M', 'L']),
  statusBadge: PropTypes.func,
};

EventCardText.defaultProps = {
  className: '',
  color: 'charcoal',
  itemIndex: null,
  overrideTracking: null,
  showYear: false,
  size: 'M',
  statusBadge: null,
};

export default EventCardText;
