import React from 'react';

import animation from '../../../styles/animation.css';
import layout from '../../../styles/global_ui/layout.css';
import cardStyles from '../cards.css';
import projectCardStyles from '../project_card/project_card.css';
import utilStyles from '../../../styles/global_ui/util.css';

const DummyListCard = () => (
  <div className={layout.fullWidth}>
    <div className={`${animation.loader} ${projectCardStyles.itemImage}  ${utilStyles.border} ${utilStyles.borderRadius}`} />
    <div className={`${cardStyles.dummyTextL} ${layout.marginTop15}`} />
    <div className={`${cardStyles.dummyTextS} ${layout.marginTop10}`} />
  </div>
);

export default DummyListCard;
