import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LazyBackgroundImage from '../../../image/LazyBackgroundImage';

import { getInObj } from '../../../../utility/accessors';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const _getClasses = (classList) => ({
  image: `${utilStyles.borderRadius} ${classList && classList.image ? classList.image : ''}`,
  root: `${classList && classList.root ? classList.root : ''}`,
});

const DefaultImageView = ({ config, item }) => (
  <LazyBackgroundImage
    alt={item.title}
    classList={_getClasses(getInObj(['image', 'classList'], config))}
    ratio={config.image.ratio}
    setStyle={false}
    src={getInObj(['image', 'url'], item)}
    width={config.image.width}
  />
);

const WithMobileImageView = ({ config, item }) => (
  <Fragment>
    {/* Mobile image view */}
    <div className={getInObj(['mobileImage', 'breakpoint'], config) || layout.hiddenSmallUp}>
      <LazyBackgroundImage
        alt={item.title}
        classList={_getClasses(getInObj(['mobileImage', 'classList'], config))}
        ratio={config.mobileImage.ratio}
        setStyle={false}
        src={getInObj(['mobile_image', 'url'], item)}
        width={config.mobileImage.width}
      />
    </div>

    {/* Default view */}
    <div className={getInObj(['image', 'breakpoint'], config) || layout.hiddenSmallDown}>
      <DefaultImageView config={config} item={item} />
    </div>
  </Fragment>
);

const OptionalMobileLazyBgImageView = (props) => (
  getInObj(['mobile_image', 'url'], props.item) !== null ? WithMobileImageView(props) : DefaultImageView(props)
);

OptionalMobileLazyBgImageView.propTypes = {
  config: PropTypes.shape({
    image: PropTypes.shape({
      breakpoint: PropTypes.string,
      classList: PropTypes.shape({
        image: PropTypes.string,
        root: PropTypes.string,
      }),
      ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
    mobileImage: PropTypes.shape({
      breakpoint: PropTypes.string,
      classList: PropTypes.shape({
        image: PropTypes.string,
        root: PropTypes.string,
      }),
      ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
  }).isRequired,
  item: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    title: PropTypes.string,
  }).isRequired,
};

export default OptionalMobileLazyBgImageView;
