import React from 'react';
import PropTypes from 'prop-types';

import keenService from '../../../services/keen/main';
import errorHandler from '../../../services/error_handler';

import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';
import style from './logo.css';

const HACKSTER_HOLIDAYS_URL = 'https://events.hackster.io/hacksterholidays';

const c = {
  wrapper: `${style.wrapper} ${layout.flex}`,
  hacksterLogoContainer: `${style.hacksterLogo}`,
  presentSvg: `${style.presentSvg}`,
};

const HacksterPresent = () => (
  <div>
    <div className={utilStyles.hideOnDarkMode}>
      <svg className={c.presentSvg} id="present-rbg" viewBox="0 0 29.5 32.1" xmlns="http://www.w3.org/2000/svg">
        <g data-name="Layer_1" id="Layer_1-2">
          <g>
            <rect fill="var(--hackster)" height="23.1" rx=".7" ry=".7" width="26.8" x="1.1" y="9" />
            <g>
              <path d="M10.1,16.9h2.2c.2,0,.3-.1.3-.3v-.8c0-.2-.1-.3-.3-.2l-2.2.6c-.2,0-.3.2-.3.4h0c0,.2.1.4.3.4v-.1Z" fill="var(--staticWhite)" />
              <path d="M16.7,18.5h2.2c.2,0,.3-.1.3-.3v-4.3c0-.2-.1-.3-.3-.2l-2.2.6c-.2,0-.3.2-.3.4v3.5c0,.2.1.3.3.3Z" fill="var(--staticWhite)" />
              <path d="M12.3,22.6h-2.2c-.2,0-.3.1-.3.3v4.3c0,.2.1.3.3.2l2.2-.6c.2,0,.3-.2.3-.4v-3.5c0-.2-.1-.3-.3-.3Z" fill="var(--staticWhite)" />
              <path d="M18.9,24.2h-2.2c-.2,0-.3.1-.3.3v.8c0,.2.1.3.3.2l2.2-.6c.2,0,.3-.2.3-.4h0c0-.2-.1-.4-.3-.4v.1Z" fill="var(--staticWhite)" />
              <path d="M22.5,21.7h-2.9c-.2,0-.3-.1-.3-.3v-1.6c0-.2-.1-.3-.3-.3h-6c-.2,0-.3-.1-.3-.3v-1.4c0-.2-.1-.3-.3-.2l-5.4,1.5c-.2,0-.4,0-.5.2v.1c0,.1,0,.2.2.2h2.9c.2,0,.3.1.3.3v1.6c0,.2.1.3.3.3h6c.2,0,.3.1.3.3v1.4c0,.2.1.3.3.2,1-.3,4.4-1.2,5.4-1.5.2,0,.4,0,.5-.2v-.1c0-.1,0-.2-.2-.2Z" fill="var(--staticWhite)" />
              <path d="M16.4,14.7c0-.2.1-.4.3-.4l2.2-.6c.2,0,.3,0,.3.2v4.3c0,.2-.1.3-.3.3h-2.2c-.2,0-.3-.1-.3-.3v-3.5ZM9.8,16.5c0-.2.1-.4.3-.4l2.2-.6c.2,0,.3,0,.3.2v.8c0,.2-.1.3-.3.3h-2.2c-.2,0-.3-.1-.3-.3h0ZM12.7,26.4c0,.2-.1.4-.3.4l-2.2.6c-.2,0-.3,0-.3-.2v-4.3c0-.2.1-.3.3-.3h2.2c.2,0,.3.1.3.3v3.5ZM19.3,24.6c0,.2-.1.4-.3.4l-2.2.6c-.2,0-.3,0-.3-.2v-.8c0-.2.1-.3.3-.3h2.2c.2,0,.3.1.3.3h0ZM22.6,22s-.3.1-.5.2c-1,.3-4.4,1.2-5.4,1.5-.2,0-.3,0-.3-.2v-1.4c0-.2-.1-.3-.3-.3h-6c-.2,0-.3-.1-.3-.3v-1.6c0-.2-.1-.3-.3-.3h-2.9c-.1,0-.2,0-.2-.2s0-.1,0-.1c0,0,.3-.1.5-.2l5.4-1.5c.2,0,.3,0,.3.2v1.4c0,.2.1.3.3.3h6c.2,0,.3.1.3.3v1.6c0,.2.1.3.3.3h2.9c.1,0,.2,0,.2.2s0,.1,0,.1Z" fill="var(--staticWhite)" />
            </g>
            <rect fill="var(--lake)" height="4.1" rx=".5" ry=".5" width="29.5" y="5.6" />
            <path d="M6.3,5.6c-1.2-.2-4.5-1-5.3-1.6s1-.3,1.1-.5-.5-1.3-.4-1.5,1.8,1,2.1,1.1c2.4,1.1,5.2,1.9,7.8,2.1l-5.8-1.9c.4-.4,3-3.3,3.2-3.3s4.1,4.6,4.5,4.6.8-.6,1.7,0,.2,0,.2,0L19.9,0c.3.5,2.7,3,2.7,3.2s-5.7,1.8-6.1,2.1c3.9-.5,7.7-1.8,11-3.8.2.2-.4,1.3-.3,1.4.1.1,1.1.2,1.4.3h0c-2.3,1.3-5.8,2.4-7.7,2.4H6.3Z" fill="var(--rose)" />
            <rect fill="var(--rose)" height="4.8" width="1.6" x="13.8" y="4.9" />
            <rect fill="var(--lake)" height=".6" opacity="0.4" width="26.8" x="1.1" y="9.7" />
          </g>
        </g>
      </svg>
    </div>
    <div className={utilStyles.showOnDarkMode}>
      <svg className={c.presentSvg} id="present-light" viewBox="0 0 29.5 31.64" xmlns="http://www.w3.org/2000/svg">
        <g data-name="Layer_1">
          <g>
            <path className="cls-1" d="M27.1,31.64H2.3c-.6,0-1.1-.5-1.1-1.1V10.04c0-.6.5-1.1,1.1-1.1h24.8c.6,0,1.1.5,1.1,1.1v20.5c0,.6-.5,1.1-1.1,1.1ZM2.3,9.84s-.2,0-.2.1v20.5s0,.1.2.1h24.8s.2,0,.2-.1V10.04s0-.1-.2-.1H2.3v-.1Z" fill="var(--staticWhite)" />
            <path className="cls-1" d="M28.7,9.84H.9c-.5,0-.9-.4-.9-.9v-3.1c0-.5.4-.9.9-.9h12.7c.3,0,.5.2.5.5s-.2.5-.5.5H.9v3.1h27.7v-3.1h-13.1c-.3,0-.5-.2-.5-.5s.2-.5.5-.5h13.1c.5,0,.9.4.9.9v3.1c0,.5-.4.9-.9.9h.1Z" fill="var(--staticWhite)" />
            <path className="cls-1" d="M15.5,9.84h-1.9c-.3,0-.5-.2-.5-.5v-3.6h-6.5c-3.8-.4-4.7-.9-5.3-1.2-.2,0-.3-.3-.3-.5,0-.4.4-.5.8-.6h.2c0-.1,0-.2-.1-.3-.2-.5-.3-.9,0-1.2s.3-.4,3.2.8c.3.1.6.3.9.4.2-.2.6-.6.9-1C8.8.14,9,.14,9.2.14s.5.1,2.5,2.2c.7.7,1.6,1.7,2,2h0c.3-.1.8-.3,1.5,0L19.4.14c.1-.1.2-.2.4-.1.1,0,.3,0,.4.2s.7.8,1.2,1.4c.7.7,1,1.1,1.3,1.4,1.4-.5,2.7-1,4.1-1.8.2-.1.4,0,.6,0,.3.3.2.8,0,1.5.2,0,.4,0,.7.1h.2c.2,0,.4.3.4.5h0c0,.3,0,.4-.2.5-3,2-5.9,2-7.8,2h-4.7c0-.1,0,3.5,0,3.5,0,.1,0,.3-.1.3s-.2.1-.3.1l-.1.1ZM14.1,8.94h.9v-3.6h0c-.4-.2-.6-.2-.8,0h0v3.6h-.1ZM2.8,4.14c.7.2,1.9.5,3.9.8h1.4c-1-.4-1.9-.7-3.3-1.2-.5-.2-1.2-.5-1.8-.7.2.4.3.8,0,1.1,0,0-.1.1-.2.2v-.2ZM19.7,4.84h1.1c1.6,0,3.9,0,6.3-1.3-.3,0-.4-.1-.5-.2-.2-.2-.1-.4,0-.7v-.3c-1.4.7-2.8,1.3-4.3,1.7-.5.2-1.2.4-2.4.7h-.2v.1ZM7,3.34l3.8,1.4h1.9c-.4-.4-.9-.9-1.8-1.9-.6-.6-1.4-1.5-1.8-1.8-.4.3-1.2,1.1-1.6,1.6-.2.2-.4.4-.6.6l.1.1ZM16.2,4.84h.5c.3-.1,1-.3,2.4-.8.4-.1.7-.2,1-.3.5-.2,1.1-.3,1.6-.5-.3-.3-.7-.7-1-1-.4-.4-.7-.8-1-1l-3.6,3.6h.1Z" fill="var(--staticWhite)" />
            <path className="cls-1" d="M10,16.44c0-.2.2-.4.3-.5l2.2-.6c.2,0,.3,0,.3.2v.8c0,.2-.2.3-.3.3h-2.2c-.2,0-.3-.2-.3-.3h0v.1ZM18.8,18.34h-2.2c-.2,0-.3-.2-.3-.3v-3.4c0-.2.2-.4.3-.4l2.2-.6c.2,0,.3,0,.3.2v4.1c0,.2-.2.3-.3.3v.1ZM19.1,24.14c0,.2-.2.4-.3.5l-2.2.6c-.2,0-.3,0-.3-.2v-.8c0-.2.2-.3.3-.3h2.2c.2,0,.3.2.3.3h0v-.1ZM10.3,22.24h2.2c.2,0,.3.2.3.3v3.4c0,.2-.2.4-.3.4l-2.2.6c-.2,0-.3,0-.3-.2v-4.1c0-.2.2-.3.3-.3v-.1ZM6.8,18.84s.3,0,.5-.2l5.2-1.5c.2,0,.3,0,.3.2v1.4c0,.2.2.3.3.3h5.8c.2,0,.3.2.3.3v1.5c0,.2.2.3.3.3h2.8s.2,0,.2.2v.2s-.3,0-.5.2c-1,.3-4.2,1.2-5.2,1.5-.2,0-.3,0-.3-.2v-1.4c0-.2-.2-.3-.3-.3h-5.8c-.2,0-.3-.2-.3-.3v-1.6c0-.2-.2-.3-.3-.3h-2.8s-.2,0-.2-.2v-.2h0v.1Z" fill="var(--staticWhite)" />
          </g>
        </g>
      </svg>
    </div>
  </div>
);

const HacksterLogo = () => (
  <div className={c.hacksterLogoContainer}>
    <div className={utilStyles.hideOnDarkMode}>
      <svg id="hackster-logo-rgb" viewBox="0 0 108.2 32.1" xmlns="http://www.w3.org/2000/svg">
        <g data-name="Layer_1" id="Layer_1-2">
          <path d="M2.7,16.8H0V.2h2.7v6.5c.8-1,2-1.4,3.2-1.4,2.7,0,4.1,2,4.1,4.4v7h-2.7v-6.6c0-1.4-.6-2.5-2.3-2.5s-2.3,1.1-2.3,2.5v6.7Z" fill="var(--hackster)" />
          <path d="M15.6,10.4l2.9-.4c.6,0,.8-.4.8-.8,0-.9-.6-1.7-2.1-1.7s-2.2.9-2.3,2l-2.4-.5c.2-1.9,1.9-3.6,4.7-3.6s4.7,1.9,4.7,4.2v5.5c0,1,.1,1.7.1,1.8h-2.5s-.1-.5-.1-1.4c-.5.8-1.6,1.8-3.4,1.8s-3.8-1.6-3.8-3.4,1.5-3.1,3.4-3.4v-.1ZM19.3,12.2v-.5l-2.9.4c-.8.1-1.5.6-1.5,1.5s.6,1.4,1.6,1.4c1.5,0,2.8-.7,2.8-2.9v.1Z" fill="var(--hackster)" />
          <path d="M26.6,11.2c0,2.2,1.4,3.5,3.1,3.5s2.5-1.2,2.8-2.1l2.3.8c-.5,1.7-2.2,3.6-5.1,3.6s-5.8-2.5-5.8-5.9,2.5-5.9,5.7-5.9,4.6,1.9,5.1,3.7l-2.4.9c-.3-1-1-2.1-2.7-2.1s-3.1,1.2-3.1,3.5h.1Z" fill="var(--hackster)" />
          <path d="M42.8,10.4l4.7,6.4h-3.3l-3.3-4.6-1.4,1.4v3.1h-2.6V.2h2.6v9.9l4.3-4.5h3.5l-4.6,4.7.1.1Z" fill="var(--hackster)" />
          <path d="M50.3,13.1c0,1,.8,1.9,2.3,1.9s1.7-.6,1.7-1.3-.4-1.1-1.3-1.2l-1.6-.4c-2-.4-3-1.7-3-3.3s1.8-3.6,4.1-3.6,4.1,2,4.3,3.1l-2.2.6c0-.6-.6-1.7-2.1-1.7s-1.6.6-1.6,1.3.4,1,1.1,1.2l1.7.3c2.1.5,3.2,1.7,3.2,3.4s-1.4,3.6-4.2,3.6-4.5-2.1-4.6-3.4l2.3-.6-.1.1Z" fill="var(--hackster)" />
          <path d="M62.9,5.6h2.3v2.4h-2.3v5.2c0,1,.4,1.4,1.4,1.4s.8,0,.9,0v2.2c-.2,0-.7.3-1.6.3-2.1,0-3.3-1.2-3.3-3.3v-5.6h-2.1v-2.4h.6c1.2,0,1.7-.8,1.7-1.7v-1.7h2.4v3.4-.2Z" fill="var(--hackster)" />
          <path d="M77.2,13.6c-.6,1.9-2.4,3.5-5,3.5s-5.7-2.2-5.7-6,2.6-5.9,5.4-5.9,5.4,2.3,5.4,5.8,0,.8,0,.8h-8.1c0,1.7,1.4,2.9,3,2.9s2.4-.8,2.8-1.9l2.2.7v.1ZM74.7,10c0-1.3-.9-2.5-2.7-2.5s-2.6,1.3-2.7,2.5h5.5-.1Z" fill="var(--hackster)" />
          <path d="M86.2,8.3h-.9c-2.1,0-3,1.2-3,3.3v5.3h-2.7V5.8h2.6v1.8c.5-1.2,1.8-1.9,3.2-1.9s.6,0,.7,0v2.7l.1-.1Z" fill="var(--hackster)" />
          <path d="M87.1,13.2c1,0,1.9.8,1.9,1.9s-.8,1.9-1.9,1.9-1.9-.8-1.9-1.9.8-1.9,1.9-1.9Z" fill="var(--hackster)" />
          <path d="M93,0c1,0,1.7.8,1.7,1.7s-.8,1.7-1.7,1.7-1.7-.8-1.7-1.7.8-1.7,1.7-1.7ZM91.7,16.8V5.7h2.6v11.1h-2.6Z" fill="var(--hackster)" />
          <path d="M108.2,11.2c0,3.4-2.4,5.9-5.8,5.9s-5.8-2.5-5.8-5.9,2.4-5.9,5.8-5.9,5.8,2.5,5.8,5.9ZM105.5,11.2c0-2.3-1.5-3.5-3.1-3.5s-3.1,1.2-3.1,3.5,1.5,3.5,3.1,3.5,3.1-1.2,3.1-3.5Z" fill="var(--hackster)" />
          <polygon fill="#52ba64" points="25.8 23.3 25.8 32.1 105.3 32.1 108.2 23.3 25.8 23.3" />
          <g>
            <path d="M32.5,29h-1.5l-.3,1.1h-1l1.6-4.8h.9l1.6,4.8h-1s-.3-1.1-.3-1.1ZM31.2,28.2h1l-.5-1.7-.5,1.7Z" fill="var(--staticWhite)" />
            <path d="M37.9,25.3v4.8h-.9l-1.8-3.2v3.2h-.9v-4.8h.9l1.8,3.2v-3.2h.9Z" fill="var(--staticWhite)" />
            <path d="M43.1,29h-1.5l-.3,1.1h-1l1.6-4.8h.9l1.6,4.8h-1s-.3-1.1-.3-1.1ZM41.9,28.2h1l-.5-1.7-.5,1.7Z" fill="var(--staticWhite)" />
            <path d="M45.7,30.1l-1.6-4.8h1l1.1,3.5,1.1-3.5h1l-1.6,4.8h-1Z" fill="var(--staticWhite)" />
            <path d="M52.3,25.3v4.8h-.9l-1.8-3.2v3.2h-.9v-4.8h.9l1.8,3.2v-3.2h.9Z" fill="var(--staticWhite)" />
            <path d="M56.3,25.3v.8h-2.1v1h1.7v.8h-1.7v1.3h2.1v.8h-3v-4.8h3v.1Z" fill="var(--staticWhite)" />
            <path d="M57,25.3h3.5v.8h-1.3v4h-.9v-4h-1.3s0-.8,0-.8Z" fill="var(--staticWhite)" />
            <path d="M66.1,26.9c-.2-.5-.5-.8-1.2-.8s-1.4.5-1.4,1.6.6,1.6,1.4,1.6,1-.3,1.2-.8l.8.2c-.2.9-.9,1.4-2,1.4s-2.3-.8-2.3-2.4,1.1-2.4,2.3-2.4,1.8.5,2,1.4l-.8.2Z" fill="var(--staticWhite)" />
            <path d="M72,27.7c0,1.7-1,2.4-2.3,2.4s-2.3-.8-2.3-2.4,1-2.4,2.3-2.4,2.3.8,2.3,2.4ZM68.4,27.7c0,1.1.6,1.6,1.4,1.6s1.4-.5,1.4-1.6-.6-1.6-1.4-1.6-1.4.5-1.4,1.6Z" fill="var(--staticWhite)" />
            <path d="M73,25.3h1l1.1,3,1.1-3h1v4.8h-.9v-3l-.9,2.5h-.6l-.9-2.5v3h-.9s0-4.8,0-4.8Z" fill="var(--staticWhite)" />
            <path d="M78.3,25.3h1l1.1,3,1.1-3h1v4.8h-.9v-3l-.9,2.5h-.6l-.9-2.5v3h-.9s0-4.8,0-4.8Z" fill="var(--staticWhite)" />
            <path d="M87,28.3c0,1.3-.7,1.9-1.8,1.9s-1.8-.6-1.8-1.9v-3h.9v3c0,.7.3,1,.9,1s.9-.3.9-1v-3h.9v3h0Z" fill="var(--staticWhite)" />
            <path d="M91.5,25.3v4.8h-.9l-1.8-3.2v3.2h-.9v-4.8h.9l1.8,3.2v-3.2h.9Z" fill="var(--staticWhite)" />
            <path d="M93.3,30.1h-.9v-4.8h.9v4.8Z" fill="var(--staticWhite)" />
            <path d="M94.1,25.3h3.5v.8h-1.3v4h-.9v-4h-1.3v-.8Z" fill="var(--staticWhite)" />
            <path d="M99.1,25.3l.9,1.9.9-1.9h1l-1.4,2.7v2h-.9v-2l-1.4-2.7h1-.1Z" fill="var(--staticWhite)" />
          </g>
        </g>
      </svg>
    </div>
    <div className={utilStyles.showOnDarkMode}>
      <svg id="hackster-light-logo" viewBox="0 0 108.2 32.1" xmlns="http://www.w3.org/2000/svg">
        <g data-name="Layer_1">
          <path className="cls-2" d="M25.8,32.1v-8.8h82.4l-2.9,8.8H25.8ZM31,29h1.5l.3,1.1h1l-1.6-4.8h-.9l-1.6,4.8h1s.3-1.1.3-1.1ZM32.2,28.2h-1l.5-1.7.5,1.7ZM38,30.2v-4.8h-.9v3.2l-1.8-3.2h-.9v4.8h.9v-3.2l1.8,3.2h.9ZM43.1,29h-1.5l-.3,1.1h-1l1.6-4.8h.9l1.6,4.8h-1s-.3-1.1-.3-1.1ZM41.9,28.2h1l-.5-1.7-.5,1.7ZM44.1,25.3l1.6,4.8h1l1.6-4.8h-1l-1.1,3.5-1.1-3.5h-1ZM52.4,25.4v4.8h-.9l-1.8-3.2v3.2h-.9v-4.8h.9l1.8,3.2v-3.2h.9ZM54.2,26.2h2.1v-.9h-3v4.8h3v-.8h-2.1v-1.3h1.7v-.8h-1.7v-1ZM57,25.4h3.5v.8h-1.3v4h-.9v-4h-1.3s0-.8,0-.8ZM64.9,26.2c.7,0,1,.3,1.2.8l.8-.2c-.2-.9-.8-1.4-2-1.4s-2.3.8-2.3,2.4,1.2,2.4,2.3,2.4,1.8-.5,2-1.4l-.8-.2c-.2.5-.4.8-1.2.8s-1.4-.5-1.4-1.6.7-1.6,1.4-1.6ZM72.1,27.8c0,1.7-1,2.4-2.3,2.4s-2.3-.8-2.3-2.4,1-2.4,2.3-2.4,2.3.8,2.3,2.4ZM68.4,27.8c0,1.1.6,1.6,1.4,1.6s1.4-.5,1.4-1.6-.6-1.6-1.4-1.6-1.4.5-1.4,1.6ZM74,25.4h-1v4.8h.9v-3l.9,2.5h.6l.9-2.5v3h.9v-4.8h-1l-1.1,3s-1.1-3-1.1-3ZM78.3,25.4h1l1.1,3,1.1-3h1v4.8h-.9v-3l-.9,2.5h-.6l-.9-2.5v3h-.9s0-4.8,0-4.8ZM85.3,30.2c1.1,0,1.8-.6,1.8-1.9v-3h-.9v3c0,.7-.3,1-.9,1s-.9-.3-.9-1v-3h-.9v3c0,1.3.7,1.9,1.8,1.9h0ZM91.5,30.2h-.9l-1.8-3.2v3.2h-.9v-4.8h.9l1.8,3.2v-3.2h.9v4.8ZM92.5,30.1h.9v-4.8h-.9v4.8ZM94.1,25.4h3.5v.8h-1.3v4h-.9v-4h-1.3v-.8ZM100,27.3l-.9-1.9h-.9l1.4,2.7v2h.9v-2l1.4-2.7h-1l-.9,1.9Z" fill="var(--staticWhite)" fillRule="evenodd" />
          <path className="cls-1" d="M108.2,11.2c0,3.4-2.4,5.9-5.8,5.9s-5.8-2.5-5.8-5.9,2.4-5.9,5.8-5.9,5.8,2.5,5.8,5.9ZM105.5,11.2c0-2.3-1.5-3.5-3.1-3.5s-3.1,1.2-3.1,3.5,1.5,3.5,3.1,3.5,3.1-1.2,3.1-3.5ZM93.1,0c1,0,1.7.8,1.7,1.7s-.8,1.7-1.7,1.7-1.7-.8-1.7-1.7.8-1.7,1.7-1.7ZM91.8,16.8V5.7h2.6v11.1h-2.6ZM87.1,13.2c1,0,1.9.8,1.9,1.9s-.8,1.9-1.9,1.9-1.9-.8-1.9-1.9.8-1.9,1.9-1.9ZM86.3,8.3h-.9c-2.1,0-3,1.2-3,3.3v5.3h-2.7V5.8h2.6v1.8c.5-1.2,1.8-1.9,3.2-1.9s.6,0,.7,0v2.7h.1v-.1ZM77.2,13.6c-.6,1.9-2.4,3.5-5,3.5s-5.7-2.2-5.7-6,2.6-5.9,5.4-5.9,5.4,2.3,5.4,5.8,0,.8,0,.8h-8.1c0,1.7,1.4,2.9,3,2.9s2.4-.8,2.8-1.9l2.2.7h0v.1ZM74.7,10c0-1.3-.9-2.5-2.7-2.5s-2.6,1.3-2.7,2.5h5.5-.1ZM62.9,5.7h2.3v2.4h-2.3v5.2c0,1,.4,1.4,1.4,1.4s.8,0,.9,0v2.2c-.2,0-.7.3-1.6.3-2.1,0-3.3-1.2-3.3-3.3v-5.6h-2.1v-2.4h.6c1.2,0,1.7-.8,1.7-1.7v-1.7h2.4v3.4-.2ZM50.4,13.1c0,1,.8,1.9,2.3,1.9s1.7-.6,1.7-1.3-.4-1.1-1.3-1.2l-1.6-.4c-2-.4-3-1.7-3-3.3s1.8-3.6,4.1-3.6,4.1,2,4.3,3.1l-2.2.6c0-.6-.6-1.7-2.1-1.7s-1.6.6-1.6,1.3.4,1,1.1,1.2l1.7.3c2.1.5,3.2,1.7,3.2,3.4s-1.4,3.6-4.2,3.6-4.5-2.1-4.6-3.4l2.3-.6h-.1v.1ZM42.9,10.4l4.7,6.4h-3.3l-3.3-4.6-1.4,1.4v3.1h-2.6V.1h2.6v9.9l4.3-4.5h3.5l-4.6,4.7v.2h.1ZM26.7,11.2c0,2.2,1.4,3.5,3.1,3.5s2.5-1.2,2.8-2.1l2.3.8c-.5,1.7-2.2,3.6-5.1,3.6s-5.8-2.5-5.8-5.9,2.5-5.9,5.7-5.9,4.6,1.9,5.1,3.7l-2.4.9c-.3-1-1-2.1-2.7-2.1s-3.1,1.2-3.1,3.5h.1ZM15.6,10.4l2.9-.4c.6,0,.8-.4.8-.8,0-.9-.6-1.7-2.1-1.7s-2.2.9-2.3,2l-2.4-.5c.2-1.9,1.9-3.6,4.7-3.6s4.7,1.9,4.7,4.2v5.5c0,1,.1,1.7.1,1.8h-2.5s-.1-.5-.1-1.4c-.5.8-1.6,1.8-3.4,1.8s-3.8-1.6-3.8-3.4,1.5-3.1,3.4-3.4h0v-.1ZM19.3,12.2v-.5l-2.9.4c-.8.1-1.5.6-1.5,1.5s.6,1.4,1.6,1.4c1.5,0,2.8-.7,2.8-2.9h0v.1ZM2.7,16.8H0V.2h2.7v6.5c.8-1,2-1.4,3.2-1.4,2.7,0,4.1,2,4.1,4.4v7.1h-2.7v-6.6c0-1.4-.6-2.5-2.3-2.5s-2.3,1.1-2.3,2.5v6.5h0v.1Z" fill="var(--staticWhite)" />
        </g>
      </svg>
    </div>
  </div>
);

const HacksterHolidaysLogo = ({ asLink, location }) => {
  const handleClick = async ({ e, url }) => {
    keenService.recordEventWithDelayedRedirect({ eventName: 'Clicked link' },
      { value: `Hackster ${url === '/' ? '' : 'Holidays '}Logo`, href: url, location, type: 'logo' }, url, e,
    )
      .catch((err) => errorHandler('Record Logo click', err));
  };

  if (!asLink) {
    return (
      <div className={c.wrapper}>
        <HacksterPresent />
        <HacksterLogo />
      </div>
    );
  }

  return (
    <div className={c.wrapper}>
      <a
        aria-label="Hackster Holidays link"
        href={HACKSTER_HOLIDAYS_URL}
        id={`Hackster-holidays-logo-${location}`}
        onClick={(e) => handleClick({ e, url: HACKSTER_HOLIDAYS_URL })}
        title="Hackster Holidays present icon"
      >
        <HacksterPresent />
      </a>
      <a
        aria-label="Hackster Homepage link"
        className={layout.fullWidth}
        href="/"
        id={`Hackster-logo-${location}`}
        onClick={(e) => handleClick({ e, url: '/' })}
        title="Hackster logo"
      >
        <HacksterLogo />
      </a>
    </div>
  );
};

HacksterHolidaysLogo.propTypes = {
  asLink: PropTypes.bool,
  location: PropTypes.string.isRequired,
};

export default HacksterHolidaysLogo;
