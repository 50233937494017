import React from 'react';
import PropTypes from 'prop-types';

import keenService from '../../../services/keen/main';
import errorHandler from '../../../services/error_handler';

import Logo from '../icons/Logo.js';
import layout from '../../../styles/global_ui/layout.css';
import style from './logo.css';

const c = { linkWrapper: `${layout.block} ${style.wrapper}` };

const HacksterLogo = ({ asLink = true, location }) => {
  if (!asLink) {
    return <Logo />;
  }

  const handleClick = async (e) => {
    keenService.recordEventWithDelayedRedirect({ eventName: 'Clicked link' },
      { value: 'Hackster Logo', href: '/', location, type: 'logo' }, '/', e,
    )
      .catch((err) => errorHandler('Record Logo click', err));
  };

  return (
    <a
      aria-label="Hackster Homepage link"
      className={`${c.linkWrapper}`}
      href="/"
      id={`Hackster-logo-${location}`}
      onClick={handleClick}
      title="Hackster logo"
    >
      <Logo />
    </a>
  );
};

HacksterLogo.propTypes = {
  asLink: PropTypes.bool,
  location: PropTypes.string.isRequired,
};

export default HacksterLogo;
