import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DragAndDropList from '../../reusable_components/DnDKit/DragAndDropList';
import ProjectEditCard from '../../cards/project_edit_card';

import getGridClassNames from './getGridClassNames';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import isTouchDevice from '../../../utility/isTouchDevice';

const Cell = ({ className, ...props }) => (
  <div className={className}>
    <ProjectEditCard {...props} />
  </div>
);

const List = ({ className, children }) => (<div className={className}>{children}</div>);

export default class EditableProjectsList extends Component {
  constructor(props) {
    super(props);

    this.isTouchDevice = isTouchDevice();
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  /**
   * Methods
   */
  onSortEnd(projects) {
    this.props.handleReorder(projects.map((project, i) => ({ ...project, position: i })));
  }

  /**
   * Views
   */
  _getUnsortableCell(project, className) {
    return (
      <Cell
        key={project.hid}
        cardBorder={this.props.cardBorder}
        className={className}
        isTouchDevice={this.isTouchDevice}
        item={project}
        sortable={false}
        toggleRespect={this.props.toggleRespect}
      />
    );
  }

  _getUnsortableList(classNames) {
    return (
      <List className={classNames.grid}>
        {this.props.records.map((project) => this._getUnsortableCell(project, classNames.cell))}
      </List>
    );
  }

  _getListView(sortable, classNames) {
    return sortable
      ? (
        <DragAndDropList
          ItemComponent={ProjectEditCard}
          classNames={{ item: classNames.cell, container: classNames.grid }}
          dragEndCallback={this.onSortEnd}
          hasDragHandle={true}
          itemProps={{
            cardBorder: this.props.cardBorder,
            isTouchDevice: this.isTouchDevice,
            sortable: true,
            toggleRespect: this.props.toggleRespect,
          }}
          items={this.props.records}
          sortingStrategy={rectSortingStrategy}
        />
        )
      : this._getUnsortableList(classNames);
  }

  render() {
    const classNames = getGridClassNames(this.props.horizontal, this.props.maxCols);
    const sortable = this.props.orderable && this.props.records.length > 1 && !this.isTouchDevice;

    return this._getListView(sortable, classNames);
  }
}

EditableProjectsList.propTypes = {
  cardBorder: PropTypes.bool,
  currentUser: PropTypes.shape({ id: PropTypes.number }),
  handleReorder: PropTypes.func,
  horizontal: PropTypes.bool,
  maxCols: PropTypes.number,
  orderable: PropTypes.bool,
  records: PropTypes.array,
  toggleRespect: PropTypes.func,
};

EditableProjectsList.defaultProps = {
  cardBorder: false,
  currentUser: {},
  handleReorder: () => {},
  horizontal: false,
  maxCols: 4,
  orderable: false,
  records: [],
  toggleRespect: () => Promise.resolve(),
};
