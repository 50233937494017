import PropTypes from 'prop-types';
import React from 'react';

import ExpandableCard from '../list/ExpandableCard';
import UserRelationButton from '../../relation_button';

import styles from '../list/list.css';

const CommunitiesList = ({ killPopover, records, triggerPopover, updateResource }) => (
  <div className={styles.dialogContainer}>
    <div className={styles.list}>
      {records.map((record, i) => (
        <ExpandableCard
          key={i}
          icon={record.avatar_url}
          info={record.status}
          isNestedInDialog={true}
          link={record.url}
          onMouseLeave={() => killPopover(record.id, 'community')}
          onMouseOver={(e) => triggerPopover(
            null,
            'communities_list',
            ['communities', 'records', ['id', record.id]],
            record.id,
            'community',
            e.target,
            'left',
            ['communities', 'records', ['id', record.id], 'brief'],
            { arrowOffset: 0, popoverOffsetVertical: -120, targetOffset: 15 },
            true,
          )}
          title={record.name}
        >
          <div>
            <UserRelationButton
              config={{ label: ['Join', 'Joined'] }}
              id={record.id}
              onClick={(createOrDeleteBool) => updateResource(
                record.id,
                'community',
                ['communities', 'records', ['id', record.id]],
                createOrDeleteBool,
                'community_members',
              )}
              type="channel_md"
            />
          </div>
        </ExpandableCard>
      ))}
    </div>
  </div>
);

CommunitiesList.propTypes = {
  killPopover: PropTypes.func,
  records: PropTypes.arrayOf(PropTypes.shape({
    avatar_url: PropTypes.string,
    brief: PropTypes.string,
    cover_image_url: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stats: PropTypes.shape({
      members: PropTypes.number,
      projects: PropTypes.number,
    }),
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
  triggerPopover: PropTypes.func,
  updateResource: PropTypes.func.isRequired,
};

CommunitiesList.defaultProps = {
  killPopover: () => {},
  triggerPopover: () => {},
};

export default CommunitiesList;
