import PropTypes from 'prop-types';
import React from 'react';

import ChannelCard from '../../../../client/cards/channel_card';
import Event from '../../../../client/cards/search/components/Vertical/Event';
import LinkToTab from '../link_to_tab';
import Thumb from '../../../../client/cards/search/components/Vertical/Thumb';

import { windowScrollTo } from '../../../../services/window';
import { indexToLabel } from '../../algoliaIndexes';
import * as contentMap from '../contentTypes';

import styles from './recommended_results.css';
import typography from '../../../../styles/global_ui/typography.css';

const verticalCardTypes = {
  channels: (props) => (
    <ChannelCard
      classList={{ wrapper: styles.communityCardWrapper }}
      item={{ avatar_url: props.image.url, id: props.id, members_count: props.stats[0].metric, name: props.title.text, pitch: props.body.plainText, projects_count: props.stats[1].metric, url: props.title.linkTo }}
    />
  ),
  events: (props) => <Event {...props} />,
  parts: (props) => <Thumb {...props} image={props.image && { ...props.image, width: 120 }} />,
  users: (props) => <Thumb {...props} image={props.image && { ...props.image, width: 120 }} />,
};

function composeVerticalCard(index, result) {
  if (!verticalCardTypes[index]) return null;

  const content = contentMap[index](result);

  return verticalCardTypes[index](content);
}

const RecommendedResults = ({ channelId, currentQuery, recommendedIndex, results, setIndex }) => {
  if (!results.length) return null;

  return (
    <div>
      <h5 className={styles.header}>
        {`Recommended results for "${currentQuery}" in ${indexToLabel(recommendedIndex)}`}
      </h5>
      <div className={styles.wrapper}>
        {results.map((result, i) => (
          <div key={i} className={styles.result}>
            {composeVerticalCard(recommendedIndex, result)}
          </div>
        ))}
      </div>
      <LinkToTab
        className={`${typography.bodyXS} ${typography.linkBlue}`}
        href={`/search?q=${currentQuery}&i=${recommendedIndex}`}
        onClick={() => {
          setIndex(recommendedIndex);
          windowScrollTo(0, 0);
        }}
      >
        {`Search for "${currentQuery}" in ${indexToLabel(recommendedIndex)}`}
      </LinkToTab>
    </div>
  );
};

RecommendedResults.propTypes = {
  channelId: PropTypes.number,
  currentQuery: PropTypes.string.isRequired,
  recommendedIndex: PropTypes.string,
  results: PropTypes.array,
  setIndex: PropTypes.func,
};

RecommendedResults.defaultProps = {
  channelId: null,
  results: [],
  setIndex: () => {},
};

export default RecommendedResults;
