import React from 'react';
import { sad } from './rating_svgs';

const RobotSad = () => (
  <div
    dangerouslySetInnerHTML={{ __html: sad }}
  />
);

export default RobotSad;
