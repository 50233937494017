import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../client/icon';
import DropdownList from './DropdownList';

import algoliaService from '../../../services/algolia';
import { stripExcessWhiteSpace } from '../../../utility/formatters';
import errorHandler from '../../../services/error_handler';

import layout from '../../../styles/global_ui/layout.css';
import styles from './search_input.css';

const SEARCH_GLASS_ID = 'nav-search-icon';

class SimpleSearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
      suggestions: [],
      value: '',
    };

    this.fetchSuggestions = this.fetchSuggestions.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleWindowClick = this.handleWindowClick.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);

    // Refs.
    this.root;
    this.input;
  }

  componentDidMount() {
    if (this.props.fireInitSearch) {
      this.handleQuery();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
  }

  _clearInput() {
    this.setState({ suggestions: [], value: '' }, () => {
      if (this.input) this.input.focus();
      this.props.search('');
    });
  }

  _getValue() {
    return stripExcessWhiteSpace(this.state.value);
  }

  fetchSuggestions(query) {
    return algoliaService.getSuggestions(query)
      .then((suggestions) => this.setState({ suggestions }))
      .catch((err) => errorHandler('fetchSuggestions', err));
  }

  handleKeyDown(e) {
    if (e.keyCode === 13 /* ENTER */) {
      this._handleReturnKey(e);
    } else if (e.keyCode === 27 /* ESC */ || e.keyCode === 9 /* TAB */) {
      e.preventDefault();
      this.handleInputBlur();
    } else if (this.dropdownlist && ((e.keyCode === 40 /* DOWN */ || e.keyCode === 38) /* UP */)) {
      e.preventDefault();
      this.dropdownlist.handleKeyDown(e);
    }
  }

  _handleReturnKey(e) {
    if (this.dropdownlist && this.dropdownlist.isDropdownItemFocused()) {
      this.dropdownlist.handleKeyDown(e);
    } else {
      this.handleQuery();
    }
  }

  handleOnChange(e) {
    let value = e.target.value;

    if (value.length > 255) {
      value = value.slice(0, 255);
    }

    this.setState({
      value,
      suggestions: (value && value.length > 0 && value.split(' ').join('').length > 0) ? this.state.suggestions : [],
    });

    this.props.instantSearch ? this.handleQuery(value) : this.fetchSuggestions(value);
  }

  handleQuery(query = this.state.value) {
    if (!this.props.instantSearch) {
      this.handleInputBlur();
    }

    this.props.search(stripExcessWhiteSpace(query));
  }

  handleInputBlur() {
    window.removeEventListener('click', this.handleWindowClick);
    this.setState({ inputFocused: false, suggestions: [] });

    if (this.input) {
      this.input.blur();
    }
  }

  onInputFocus() {
    window.addEventListener('click', this.handleWindowClick);
    this.setState({ inputFocused: true });

    if (this.state.value.length > 0) {
      this.handleOnChange({ target: { value: this.state.value } });
    }
  }

  handleWindowClick(e) {
    // The search glass icon will fire focus/blur handlers, we don't want double events which will
    // trigger here and cause toggle loops.
    if (e.target && e.target.id && e.target.id === SEARCH_GLASS_ID) return;

    if (this.root && !this.root.contains(e.target)) {
      this.handleInputBlur();
    }
  }

  render() {
    return (
      <div
        ref={(el) => this.root = el}
        className={`${styles.containerSimple} ${this.props.classList.root}`}
      >
        <div className={`
          ${styles.inputContainer}
          ${this.props.classList.container}
          ${this.state.inputFocused ? styles.inputFocused : ''}
          ${this.state.suggestions.length > 0 ? styles.listOpen : ''}
          `.trim()}
        >
          <div className={`${styles.searchIconWrapper} ${layout.paddingLeft15} ${layout.paddingRight0}`} onClick={() => this.handleQuery()}>
            <Icon className={`${styles.searchIcon} ${this.props.classList.searchIcon}`} name="search" />
          </div>

          <input
            ref={(el) => this.input = el}
            className={`${styles.input} ${this.props.classList.input}`}
            onChange={this.handleOnChange}
            onFocus={this.onInputFocus}
            onKeyDown={this.handleKeyDown}
            placeholder="Search"
            type="text"
            value={this.state.value}
          />

          <div className={styles.dismissWithinBorderWrapper}>
            <span
              className={`${styles.dismissWithinBorder} ${this.state.value.length > 0 ? '' : styles.hideDismiss}`}
              onClick={() => this._clearInput()}
            >
              &times;
            </span>
          </div>

          {this.state.suggestions && this.state.suggestions.length > 0
          && (
            <DropdownList
              ref={(el) => this.dropdownlist = el}
              currentQuery={this.state.value}
              dismiss={() => this.setState({ suggestions: [] })}
              onSelect={(option) => this.setState({ value: option.value.query }, () => this.handleQuery(option.value.query, option.value))}
              options={this.state.suggestions}
              templateFn={(option) => (<span dangerouslySetInnerHTML={{ __html: option.label }} />)}
            />
          )}
        </div>
      </div>
    );
  }
}

SimpleSearchInput.propTypes = {
  classList: PropTypes.shape({
    container: PropTypes.string,
    input: PropTypes.string,
    root: PropTypes.string,
    searchIcon: PropTypes.string,
  }),
  fireInitSearch: PropTypes.bool,
  instantSearch: PropTypes.bool, // Whether we require confirmation before firing a search query.
  search: PropTypes.func.isRequired,
};

SimpleSearchInput.defaultProps = {
  classList: {
    container: '',
    input: '',
    root: '',
    searchIcon: '',
  },
  fireInitSearch: true,
  instantSearch: true,
};

export default SimpleSearchInput;
