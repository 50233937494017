import React from 'react';
import PropTypes from 'prop-types';

import Community from './Community';
import Connect from './Connect';
import Expertise from './Expertise';
import Hero from './Hero';
import Partners from './Partners';

import styles from './business.css';
// TODO: case studies! pdf downloads? or maybe just links?
const Business = ({ logos }) => (
  <main className={styles.root}>
    <Hero />
    <Expertise />
    <Community />
    <Partners logos={logos} />
    <Connect />
  </main>
);

Business.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  })).isRequired,
};

export default Business;
