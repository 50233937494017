import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import InterestSection from './InterestSection';
import InterestSectionAsync from './InterestSectionAsync';
import Select from '../../client/form_components/select';
import SimpleSearchInput from '../../server/search/input/SimpleSearchInput';

import { graphMutate } from '../../requests/graphql';
import { searchMultipleIndexes } from '../../services/algolia/searchIndex';
import errorHandler from '../../services/error_handler';
import { windowScrollTo } from '../../services/window';
import { isBlank } from '../../utility/types';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';
import styles from './interests.css';

import { PART_TYPE_ENUM } from './constants';

const FILTER_OPTS = [
  { label: 'Communities', value: 'communities' },
  { label: 'Platforms', value: 'platforms' },
  { label: 'Products', value: 'products' },
  { label: 'Topics', value: 'topics' },
  { label: 'Selected', value: 'selected' },
];

const SEARCH_TYPE_TO_FILTER_MAP = {
  Community: 'communities',
  Platform: 'platforms',
  TopicChannel: 'topics',
  PART: 'products',
};

const SelectedCount = ({ className, count }) => (
  <p className={`${count >= 3 ? utilStyles.hidden : ''} ${typography.bodyS} ${typography.error} ${styles.selectedCount} ${className}`}>
    Select at least
    {' '}
    {Math.max(3 - count, 0)}
    {' '}
    more
  </p>
);

const Interests = ({
  defaultFilter,
  options,
  setSelectedCommunities,
  setSelectedPlatforms,
  setSelectedProducts,
  setSelectedTopics,
  userId,
  ...props
}) => {
  const { communities, platforms, products, topics } = props;
  const selectedInterestsCount = [communities, platforms, products, topics].flat().length;
  const [isBusy, setIsBusy] = useState(false);

  const [filter, setFilter] = useState(defaultFilter || '');
  const [searchActive, setSearchActive] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  /**
   * Helpers
   */
  const resetSearch = () => {
    if (!searchActive) return;

    setSearchActive(false);
    setSearchResults([]);
  };

  const handleFilterChange = (opt) => {
    setFilter((opt && opt.value) || '');
  };

  /**
   * Requests
   */
  const fetchAllInterests = () => {
    setIsBusy(true);

    return graphMutate({ t: 'get_user_channels_and_products' }, { user_id: userId })
      .then(({ records: { channels, products } }) => [...channels, ...products].sort((a, b) => {
        if (a.user_joined_at < b.user_joined_at) return 1;
        if (a.user_joined_at > b.user_joined_at) return -1;
        return 0;
      }))
      .catch((err) => {
        errorHandler('UserOnboardingPage/Interests fetchAllInterests Error: ', err);
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  const handleChannelMemberCreation = (id, type) => {
    if (type === 'Community') setSelectedCommunities([...communities, id]);
    if (type === 'Platform') setSelectedPlatforms([...platforms, id]);
    if (type === 'TopicChannel') setSelectedTopics([...topics, id]);

    return graphMutate({ t: 'create_channel_member' }, { id: id })
      .catch((err) => {
        errorHandler('UserOnboardingPage/Interests handleChannelMemberCreation Error: ', err);
      });
  };

  const handleChannelMemberDeletion = (id, type) => {
    if (type === 'Community') setSelectedCommunities(communities.filter((val) => val !== id));
    if (type === 'Platform') setSelectedPlatforms(platforms.filter((val) => val !== id));
    if (type === 'TopicChannel') setSelectedTopics(topics.filter((val) => val !== id));

    graphMutate({ t: 'delete_channel_member' }, { id: id })
      .catch((err) => {
        errorHandler('UserOnboardingPage/Interests handleChannelMemberDeletion Error: ', err);
      });
  };

  const handlePartUserCreation = (id) => {
    setSelectedProducts([...products, id]);
    return graphMutate({ t: 'create_part_user' }, { id: id })
      .catch((err) => {
        setSelectedProducts(products);
        errorHandler('UserOnboardingPage/Interests handlePartUserCreation Error: ', err);
      });
  };

  const handlePartUserDeletion = (id) => {
    setSelectedProducts(products.filter((val) => val !== id));
    graphMutate({ t: 'delete_part_user' }, { id: id })
      .catch((err) => {
        setSelectedProducts(products);
        errorHandler('UserOnboardingPage/Interests handlePartUserDeletion Error: ', err);
      });
  };

  const handleSearch = (queryStr) => {
    // If user clears search, clear results and return early.
    if (isBlank(queryStr)) {
      resetSearch();
      return;
    }

    setSearchActive(true);
    setIsBusy(true);
    windowScrollTo(0, 0);

    // v2: Right now, search max is 24 results. We can add pagination/endless scroll if we want.
    const queryObjs = [
      { indexName: 'parts', params: { attributesToRetrieve: ['id', 'image_url', 'model', 'name'], hitsPerPage: '12' }, query: queryStr, sort: 'most_owned' }, // TODO: See if we want sort here? Can test on algolia UI.
      { indexName: 'channels', params: { attributesToRetrieve: ['id', 'avatar_url', 'model', 'name'], hitsPerPage: '12' }, query: queryStr, sort: null },
    ];

    return searchMultipleIndexes(queryObjs)
      .then(({ results }) => {
        const [parts, channels] = results;
        const resultsWithProperType = [...channels.hits, ...parts.hits].map((hit) => ({ ...hit, type: hit.model === 'Part' ? 'PART' : hit.model }));
        setSearchResults(resultsWithProperType); // TODO: b/c these are 2 separate indexes, the response is also separated. Can randomize/mix/merge the results on our end if desired.
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        errorHandler('UserOnboardingPage/Interests', err);
      });
  };
  const debouncedHandleSearch = debounce(handleSearch, 300);

  /**
   * Methods
   */
  const handleCreateInterest = ({ id, type }) => {
    PART_TYPE_ENUM.includes(type) ? handlePartUserCreation(id) : handleChannelMemberCreation(id, type);
  };

  const handleDeleteInterest = ({ id, type }) => {
    PART_TYPE_ENUM.includes(type) ? handlePartUserDeletion(id) : handleChannelMemberDeletion(id, type);
  };

  /**
   * Views
   */
  const getSectionView = () => {
    if (searchActive) {
      if (filter) {
        return (
          <InterestSection
            createInterest={handleCreateInterest}
            deleteInterest={handleDeleteInterest}
            interests={{ channels: [...communities, ...platforms, ...topics], products: products }}
            isBusy={isBusy}
            options={
              filter === 'selected'
                ? searchResults.filter((sr) => [...communities, ...platforms, ...topics, ...products].includes(sr.id)) // Only show searchResults a user has previously selected.
                : searchResults.filter((sr) => SEARCH_TYPE_TO_FILTER_MAP[sr.type] === filter) // Filter search results by type.
            }
            sectionType="search"
          />
        );
      } else {
        return (
          <InterestSection
            createInterest={handleCreateInterest}
            deleteInterest={handleDeleteInterest}
            interests={{
              channels: [...communities, ...platforms, ...topics],
              products,
            }}
            isBusy={isBusy}
            options={searchResults}
            sectionType="search"
          />
        );
      }
    } else if (filter) {
      if (filter === 'selected') {
        return (
          <InterestSectionAsync
            createInterest={handleCreateInterest}
            deleteInterest={handleDeleteInterest}
            getOptions={fetchAllInterests}
            interests={{ channels: [...communities, ...platforms, ...topics], products: products }}
            isBusy={isBusy}
            sectionType={filter}
          />
        );
      } else {
        return (
          <InterestSection
            createInterest={handleCreateInterest}
            deleteInterest={handleDeleteInterest}
            interests={filter === 'products' ? { channels: [], products: props[filter] } : { channels: props[filter], products: [] }}
            isBusy={isBusy}
            options={options[filter]}
            sectionType={filter}
          />
        );
      }
    } else {
      return (
        <>
          <InterestSection createInterest={handleCreateInterest} deleteInterest={handleDeleteInterest} interests={{ channels: platforms, products: [] }} isBusy={isBusy} options={options.platforms.slice(0, 12)} sectionType="platforms" />
          <InterestSection createInterest={handleCreateInterest} deleteInterest={handleDeleteInterest} interests={{ channels: communities, products: [] }} isBusy={isBusy} options={options.communities.slice(0, 6)} sectionType="communities" />
          <InterestSection createInterest={handleCreateInterest} deleteInterest={handleDeleteInterest} interests={{ channels: topics, products: [] }} isBusy={isBusy} options={options.topics.slice(0, 12)} sectionType="topics" />
          <InterestSection createInterest={handleCreateInterest} deleteInterest={handleDeleteInterest} interests={{ channels: [], products }} isBusy={isBusy} options={options.products.slice(0, 12)} sectionType="products" />
        </>
      );
    }
  };

  useLayoutEffect(() => {
    window?.scrollTo({ top: 0 });
  }, []);

  return (
    <main className={`${layout.flexColumn} ${layout.fullWidth}`}>
      <form className={`${styles.filterSearchCountContainer}`}>
        <SelectedCount className={layout.hiddenMedUp} count={selectedInterestsCount} />

        <div className={styles.filterSearchContainer}>
          <div className={`${styles.searchWrapper}`}>
            <SimpleSearchInput
              classList={{ container: styles.searchContainer, input: styles.searchInput, root: styles.searchRoot, searchIcon: styles.searchIcon }}
              fireInitSearch={false}
              search={debouncedHandleSearch}
            />
          </div>
          <div className={`${styles.filterWrapper}`}>
            <Select
              classList={{ container: styles.filterContainer, root: styles.filterRoot, input: layout.fullWidth, inputWrapper: layout.marginRight0 }}
              name="interest-filter"
              onSelectedChange={handleFilterChange}
              options={FILTER_OPTS}
              placeholder="Filter"
              value={filter}
            />
          </div>
        </div>

        <SelectedCount className={layout.hiddenMedDown} count={selectedInterestsCount} />
      </form>

      {getSectionView()}
    </main>
  );
};

Interests.propTypes = {
  communities: PropTypes.arrayOf(PropTypes.number),
  defaultFilter: PropTypes.string,
  options: PropTypes.shape({
    communities: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    platforms: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    topics: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  }).isRequired,
  platforms: PropTypes.arrayOf(PropTypes.number),
  products: PropTypes.arrayOf(PropTypes.number),
  setSelectedCommunities: PropTypes.func.isRequired,
  setSelectedPlatforms: PropTypes.func.isRequired,
  setSelectedProducts: PropTypes.func.isRequired,
  setSelectedTopics: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(PropTypes.number),
  userId: PropTypes.number.isRequired,
};

Interests.defaultProps = {
  communities: [],
  defaultFilter: '',
  platforms: [],
  products: [],
  topics: [],
};

export default Interests;
