import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '../../../../client/form_components/checkboxes/custom';
import FilterLabel from './FilterLabel';
import FormSelect from '../../../../client/form_components/selects/form_select';

import { searchTags } from '../../../../requests/algolia';
import { boolOrStringToBool } from '../../../../utility/forms/formatters';

import layout from '../../../../styles/global_ui/layout.css';

const fetchSkills = (query) => (
  new Promise((resolve, reject) => (
    searchTags(query)
      .then((res) => resolve({ options: res.hits.map((hit) => ({ label: hit.name, value: hit.name })) }))
      .catch((err) => reject(err))
  ))
);

const UserFilters = ({ disabled, filters, onChange }) => (
  <section>
    <FormSelect
      asyncOpts={{
        initOnMount: true,
        request: fetchSkills,
      }}
      disabled={disabled || (filters.skills && filters.skills.length === 3)}
      label={(
        <FilterLabel
          className={layout.marginBottom10}
          count={filters.skills ? filters.skills.length : 0}
          disabled={disabled}
          label="Skills"
          limit={3}
        />
      )}
      name="skills"
      onSelectedChange={(skillsArr) => onChange({ skills: skillsArr.map((s) => s.value) })}
      searchOpts={{ multiLimit: 3 }}
      type="multi"
      value={filters.skills}
    />
    <FilterLabel className={layout.marginBottom10} disabled={disabled} label="Availability" />
    <Checkbox
      disabled={disabled}
      isChecked={boolOrStringToBool(filters.available_for_hire)}
      label="Available for hire"
      name="availability"
      onChange={(isChecked) => onChange({ available_for_hire: isChecked })}
      type="checkbox"
      value="available_for_hire"
    />
  </section>
);

UserFilters.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.shape({
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    distance: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    available_for_hire: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  onChange: PropTypes.func,
};

UserFilters.defaultProps = {
  disabled: false,
  filters: {},
  onChange: () => {},
};

export default UserFilters;
