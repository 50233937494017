import { updateAllProjects } from './helpers';
import { getInObj } from '../../../utility/accessors';

function appendRecords(oldData, newData, mapFunc) {
  const newRecords = mapFunc ? newData.records.map(mapFunc) : newData.records;

  return {
    metadata: newData.metadata,
    records: oldData.records.concat(newRecords),
  };
}

function setNewRecordsInStore(res, store, path, pathToResource, appendRecordsMapFunc = null) {
  const oldData = store.getIn(path);
  const updatedData = appendRecords(oldData, getInObj(pathToResource, res), appendRecordsMapFunc);
  store.setIn(path, updatedData);

  return updatedData;
}

export function resolveMoreArticles(res, store, path) { // TODO: returnPromise?
  setNewRecordsInStore(res, store, path, ['articles']);
  this.forceUpdate();

  return Promise.resolve({});
}

export function resolveMoreCommunities(res, store, path, returnPromise) {
  const updatedData = setNewRecordsInStore(res, store, path, ['communities']);
  this.setState({
    dialog: {
      ...this.state.dialog,
      props: updatedData,
    },
  });

  return returnPromise ? Promise.resolve({}) : true;
}

export function resolveMoreFollowers(res, store, path) {
  const updatedData = setNewRecordsInStore(res, store, path, ['users', 'followers']);
  this.setState({
    dialog: {
      ...this.state.dialog,
      props: updatedData,
    },
  });

  return Promise.resolve({});
}

export function resolveMoreFollowing(res, store, path) {
  const updatedData = setNewRecordsInStore(res, store, path, ['users', 'followed_users']);
  this.setState({
    dialog: {
      ...this.state.dialog,
      props: updatedData,
    },
  });

  return Promise.resolve({});
}

export function resolveMoreParts(res, store, path, returnPromise) {
  const updatedData = setNewRecordsInStore(res, store, path, ['tools']);
  this.setState({
    dialog: {
      ...this.state.dialog,
      props: {
        ...this.state.dialog.props,
        ...updatedData,
      },
    },
  });

  return returnPromise ? Promise.resolve({}) : true;
}

export function resolveMorePartProjects(res, store, path, returnPromise) {
  setNewRecordsInStore(res, store, path, ['product', 'projects'], (record) => ({ hid: record.hid }));
  updateAllProjects(store, res.product.projects.records);
  this.forceUpdate();

  return returnPromise ? Promise.resolve({}) : true;
}

export function resolveMoreProjects(res, store, path, returnPromise) {
  setNewRecordsInStore(res, store, path, ['projects'], (record) => ({ hid: record.hid }));
  updateAllProjects(store, res.projects.records);
  this.forceUpdate();

  return returnPromise ? Promise.resolve({}) : true;
}
