import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../link/AnalyticsLink';
import DateRange from './DateRange';
import EventTypeOrLocation from './EventTypeOrLocation';
import LazyBackgroundImage from '../../image/LazyBackgroundImage';
import SponsorList from './SponsorList';

import { EVENT_CARD } from '../../../services/keen/events/linkConstants';
import { getDateRangeAsText } from './eventCardHelpers';
import { getInObj } from '../../../utility/accessors';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './event_card.css';
import Icon from '../../icon';

const EventCardSponsoredLarge = ({ className, imageRight, item, itemIndex, overrideTracking }) => (
  <div className={`${layout.flexCenterItems} ${imageRight ? layout.flexRowReverse : layout.flexRow} ${styles.largeCard} ${layout.fullWidth} ${typography.overflowBreakWord} ${className}`}>
    <AnalyticsLink
      className={`${layout.flex1} ${styles.imageLarge} ${utilStyles.posRelative}`}
      isExternal={true}
      item={{ id: item.id, url: item.link }}
      itemIndex={itemIndex}
      linkType={EVENT_CARD}
      overrideTracking={overrideTracking}
    >
      <LazyBackgroundImage
        alt={item.title}
        classList={{ root: utilStyles.absolutePlaceholderChild }}
        ratio="3:2"
        setStyle={false}
        src={getInObj(['image', 'url'], item)}
        width={555}
      />
      <div className={styles.dateBadge}>
        <DateRange endDate={item.end_date} startDate={item.start_date} />
      </div>
    </AnalyticsLink>
    <div className={`${typography.bodyL} ${layout.flexColumnWrap} ${layout.flex1} ${imageRight ? styles.largeCardInfoWrapperLeft : styles.largeCardInfoWrapperRight}`}>
      <SponsorList className={styles.largeCardInfoSection} item={item} />
      <div className={styles.largeCardInfoSection}>
        <AnalyticsLink
          className={`${typography.linkCharcoal} ${typography.h2Responsive} ${styles.largeCardTitle}`}
          isExternal={true}
          item={{ id: item.id, url: item.link }}
          itemIndex={itemIndex}
          linkType={EVENT_CARD}
          overrideTracking={overrideTracking}
        >
          {item.title}
        </AnalyticsLink>
      </div>
      {!!item.summary
      && (
        <div className={styles.largeCardInfoSection}>
          {item.summary}
        </div>
      )}
      <div className={styles.largeCardInfoSection}>
        {getDateRangeAsText(item)}
        <span className={`${layout.marginLeft10} ${layout.marginRight10}`}>•</span>
        <EventTypeOrLocation item={item} />
        <AnalyticsLink
          className={`${styles.ctaLink} ${typography.bold} ${typography.linkBlue} ${typography.textWithIcon}`}
          isExternal={true}
          item={{ id: item.id, url: item.link }}
          itemIndex={itemIndex}
          linkType={EVENT_CARD}
          overrideTracking={overrideTracking}
        >
          Learn more
          <Icon className={layout.marginLeft5} name="arrow-right" />
        </AnalyticsLink>
      </div>
    </div>
  </div>
);

EventCardSponsoredLarge.propTypes = {
  className: PropTypes.string,
  imageRight: PropTypes.bool,
  item: PropTypes.shape({
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    custom_sponsors: PropTypes.arrayOf(PropTypes.string),
    event_type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    start_date: PropTypes.string.isRequired,
    state: PropTypes.string,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  itemIndex: PropTypes.number,
  overrideTracking: PropTypes.func,
};

EventCardSponsoredLarge.defaultProps = {
  className: '',
  imageRight: false,
  itemIndex: null,
  overrideTracking: null,
};

export default EventCardSponsoredLarge;
