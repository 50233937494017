import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ArticleLayout from './article_layout';
import ArticleStickyFooter from './templates/sticky_actions/ArticleStickyFooter';
import LatestArticlesBottom from './templates/latest_articles/LatestArticlesBottom';
import RelatedArticlesBottom from './templates/related_articles/RelatedArticlesBottom';

import errorHandler from '../../../services/error_handler';
import { graphQuery } from '../../../requests/graphql';
import { timeIsFuture } from '../../../utility/time';

import { PUBLISHED_STATUS } from '../../../graphql/news/enums';

import layout from '../../../styles/global_ui/layout.css';

// TODO: Either make a unique graphQL query to fetch all the news_article_page_suggested_articles at once to avoid
// duplicate records between the latestArticles and relatedArticles or wrap the RelatedArticles in a lazy loader
// to fetch seperately passing in the latestsArticle ids to de-dupe.
class NewsArticlePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPreview: this._isPreview(props.article),
      latestArticles: {
        bar: null,
        bottom: null,
        side: null,
      },
      relatedArticles: {
        bottom: null,
        side: null,
      },
      sponsoredArticles: null,
    };
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    if (!this.state.isPreview) this._fetchSuggestedArticles();
  }

  /**
   * Helpers
   */
  _fetchSuggestedArticles() {
    return graphQuery({ t: 'news_article_page_suggested_articles' }, { id: this.props.article.id })
      .then(({ article, latest_articles, sponsored_articles }) => {
        this.setState({
          latestArticles: this._splitLatestArticles(latest_articles),
          relatedArticles: this._splitRelatedArticles(article.related_news_articles),
          sponsoredArticles: sponsored_articles,
        });
      })
      .catch((err) => errorHandler('NewsArticlePage _fetchSuggestedArticles:', err));
  }

  _isPreview(article) {
    return article.status !== PUBLISHED_STATUS || timeIsFuture(article.published_at);
  }

  _splitLatestArticles(records) {
    return {
      bar: records.slice(0, 1),
      bottom: records.slice(4, 8),
      side: records.slice(0, 4),
    };
  }

  _splitRelatedArticles(records) {
    return {
      bottom: records.slice(4, 8),
      side: records.slice(0, 4),
    };
  }

  render() {
    const { article, social_sharing_links } = this.props;
    const { isPreview, latestArticles, relatedArticles, sponsoredArticles } = this.state;

    return (
      <div>
        <div className={layout.container}>
          <div className={`${layout.wrapper1170} ${layout.paddingTop15}`}>
            <ArticleLayout
              article={article}
              isPreview={isPreview}
              latestArticles={latestArticles.side}
              relatedArticles={relatedArticles.side}
              social_sharing_links={social_sharing_links}
              sponsoredArticles={sponsoredArticles}
            />
            {!isPreview && <LatestArticlesBottom records={latestArticles.bottom} />}
            {!isPreview && <RelatedArticlesBottom records={relatedArticles.bottom} />}
          </div>
        </div>
        {!isPreview
        && (
          <ArticleStickyFooter
            article={article}
            latestArticles={latestArticles.bar}
            social_sharing_links={social_sharing_links}
          />
        )}
      </div>
    );
  }
}

NewsArticlePage.propTypes = {
  article: PropTypes.shape({
    published_at: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  social_sharing_links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequred,
    service: PropTypes.string.isRequred,
    url: PropTypes.string.isRequred,
  })).isRequired,
};

export default NewsArticlePage;
