import React from 'react';
import { neutral } from './rating_svgs';

const RobotNeutral = () => (
  <div
    dangerouslySetInnerHTML={{ __html: neutral }}
  />
);

export default RobotNeutral;
