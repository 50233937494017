import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DummyList from '../../client/wrappers/grid_list/DummyList';
import DummyVideoCard from '../../client/cards/video_card/DummyCard';
import GraphQLPaginatedList from '../../client/wrappers/graph_ql/GraphQLPaginatedList';
import GridList from '../../client/wrappers/grid_list';
import VideoCard from '../../client/cards/video_card';
import VideoDialog from '../../client/videos/video_dialog';

import { VIDEO_CATEGORIES_FILTER } from '../../graphql/videos/enums';

import GraphQLVideosService from '../../services/graphql/videos_service';
import SeoHandler from '../../services/seo_handler';
import createHistory from '../../client/reusable_components/Router/history';

import getSeoConfig from './getSeoConfig';

import layoutStyles from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';

class VideosPage extends Component {
  constructor(props) {
    super(props);

    this.state = { currentHistoryData: null };

    this.history = createHistory(props.pathHelpers.basePath);
    this.graphQLService = new GraphQLVideosService({ history: this.history });

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.summonDialog = this.summonDialog.bind(this);

    this.seoHandler = new SeoHandler({ config: getSeoConfig(props.seoHelpers.configKey) });
  }

  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
    if (this.props.seoHelpers.reportOnMount) this._updateSEOData();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  _updateSEOData() {
    this.seoHandler.reportView({ path: this.props.seoHelpers.path, data: this.history.location });
  }

  handleLocationChange(pathData, action) {
    const historyData = { ...pathData, action };
    this.setState({ currentHistoryData: historyData });
    this._updateSEOData();
  }

  renderDummyList() {
    return <DummyList ItemComponent={DummyVideoCard} />;
  }

  summonDialog(e, video) {
    if (this._dialog) {
      e.preventDefault();
      this._dialog._summonDialog(video);
    }
  }

  render() {
    const listProps = {
      ItemComponent: VideoCard,
      itemProps: { onClick: this.summonDialog },
    };

    return (
      <div className={layoutStyles.container}>
        <div className={layoutStyles.wrapper1170}>
          <header className={layoutStyles.marginBottom60}>
            <h1 className={typography.h1}>{this.props.title}</h1>
          </header>
          <GraphQLPaginatedList
            currentHistoryData={this.state.currentHistoryData}
            filters={this.props.filters.concat(VIDEO_CATEGORIES_FILTER)}
            graphQLArguments={{ by_category: this.props.category }}
            graphQLService={this.graphQLService}
            listComponent={GridList}
            listProps={listProps}
            loaderComponent={this.renderDummyList}
          />
        </div>
        <VideoDialog
          ref={(c) => this._dialog = c}
          pathHelpers={this.props.pathHelpers}
          seoHandler={this.seoHandler}
        />
      </div>
    );
  }
}

VideosPage.propTypes = {
  category: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  })).isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
    videosPath: PropTypes.string.isRequired,
  }).isRequired,
  seoHelpers: PropTypes.shape({
    configKey: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    reportOnMount: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string,
};

VideosPage.defaultProps = {
  category: null,
  title: 'Explore videos',
};

export default VideosPage;
