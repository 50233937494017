import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../reusable_components/Router/Loader';
import NotificationsList from '../notifications/NotificationsList';

import errorHandler from '../../../../services/error_handler';
import notificationsStore from '../../../../services/notifications_store';
import urlService from '../../../../services/url_service';

import { graphQueryWithUser } from '../../../../requests/graphql';
import { goTo } from '../../../reusable_components/Router';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import util from '../../../../styles/global_ui/util.css';
import styles from '../dashboard/dashboard.css';

// TODO: Remove the dependence on total_unread when FE notification caching is removed. Used total_entries instead.
class UnreadNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = { notifications: null };

    this.getUnreads = this.getUnreads.bind(this);
    this.handleOptimisticAll = this.handleOptimisticAll.bind(this);
    this.handleOptimisticSingle = this.handleOptimisticSingle.bind(this);

    this.subscriptions = [];
  }

  componentDidMount() {
    this.getUnreads();
    this._subscribeToStore();
  }

  componentWillUnmount() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  _subscribeToStore() {
    this.subscriptions = [
      notificationsStore.getChannel().subscribe('notifications.markRead.all.optimistic', this.handleOptimisticAll),
      notificationsStore.getChannel().subscribe('notifications.markRead.single.optimistic', this.handleOptimisticSingle),
      notificationsStore.getChannel().subscribe('notifications.markRead.single.success', this.getUnreads),

      // just do a whole fetch if an optimistic change fails
      notificationsStore.getChannel().subscribe('notifications.markRead.#.failure', this.getUnreads),
    ];
  }

  dismiss(receipt_id) {
    return notificationsStore.markRead(receipt_id).catch((err) => errorHandler(err)); // store subscriptions will take it from here
  }

  handleOptimisticSingle(changes) {
    const { receipt_id } = changes;
    const doUpdate = this.state.notifications.records.findIndex((n) => n.receipt_id === receipt_id) > -1;

    if (doUpdate) {
      this.setState({
        notifications: {
          ...this.state.notifications,
          metadata: { ...this.state.notifications.metadata, total_unread: this.state.notifications.metadata.total_unread - 1 },
          records: this.state.notifications.records.filter((n) => n.receipt_id !== receipt_id),
        },
      });
    }
  }

  handleOptimisticAll() {
    this.setState({
      notifications: {
        metadata: {
          ...this.state.notifications.metadata,
          total_unread: 0,
        },
        records: [],
      },
    });
  }

  // TODO: Why not use the Notifications store fetchPage? Only difference is the read attribute here. We could pass it as an optional flag.
  getUnreads() {
    return graphQueryWithUser({ t: 'get_notifications' }, {
      page: 1,
      per_page: 5,
      read: false,
      query: 'FOR_UNREAD',
      created_before: notificationsStore.initializedAt(), // null if not initialized, OK
    })
      .then((res) => {
        this.setState({ notifications: res.notifications });
      })
      .catch((err) => errorHandler(err));
  }

  render() {
    const { notifications } = this.state;

    return (
      <section className={styles.section}>
        <header className={styles.sectionHeader}>
          <a
            className={`${styles.headerTitle} ${typography.bodyM} ${typography.bold}`}
            href={urlService.url('/dashboard/notifications')}
            onClick={(e) => goTo(e, 'notifications')}
          >
            Unread Notifications
          </a>
          {notifications !== null
          && <span className={`${styles.headerCount} ${typography.bodyL}`}>{notifications.metadata.total_unread.toString()}</span>}
        </header>
        {notifications === null
          ? <Loader />
          : notifications.records.length === 0
            ? <span className={typography.bodyM}>You have no unread notifications</span>
            : (
              <NotificationsList
                className={`${layout.marginBottom15} ${util.border}`}
                currentUser={this.props.currentUser}
                individualTransitions={true}
                markRead={this.dismiss}
                notifications={notifications.records.slice(0, 5)}
              />
              )}
        {notifications !== null
        && (
          <a
            className={`${buttonStyles.sm} ${buttonStyles.secondary} ${buttonStyles.pill} ${layout.flexAlignSelfEnd}`}
            href={urlService.url('/dashboard/notifications')}
            onClick={(e) => goTo(e, 'notifications')}
          >
            View all
          </a>
        )}
      </section>
    );
  }
}

UnreadNotifications.propTypes = { currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired };

export default UnreadNotifications;
