import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../link/AnalyticsLink';
import OptionalMobileLazyBgImageView from '../../base_card/templates/OptionalMobileLazyBgImageView';

import { NEWS_ARTICLE_CARD } from '../../../../services/keen/events/linkConstants';

import { getImgContainerStyles, getRootStyles, getTitleStyles } from './styleHelpers';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const IMAGE_RATIO_CONFIG = {
  image: {
    breakpoint: layout.hiddenMedDown,
    classList: { root: utilStyles.absolutePlaceholderChild },
    ratio: '16:9',
    width: 555,
  },
  mobileImage: {
    breakpoint: layout.hiddenMedUp,
    classList: { root: utilStyles.absolutePlaceholderChild },
    ratio: '1:1',
    width: 120,
  },
};

const NewsArticleFeaturedCard = ({ article, isHero, location }) => (
  <div className={getRootStyles(isHero)}>
    <AnalyticsLink
      className={getImgContainerStyles(isHero)}
      item={article}
      linkType={NEWS_ARTICLE_CARD}
      location={location}
    >
      <OptionalMobileLazyBgImageView
        config={IMAGE_RATIO_CONFIG}
        item={article}
      />
    </AnalyticsLink>
    <div className={`${layout.flexColumn} ${layout.fullWidth}`}>
      <AnalyticsLink
        className={getTitleStyles(isHero)}
        item={article}
        linkType={NEWS_ARTICLE_CARD}
        location={location}
      >
        {article.title}
      </AnalyticsLink>
      <p className={`${typography.bodyM} ${typography.charcoal} ${layout.marginBottom10}`}>
        {article.summary}
      </p>
      <span className={`${typography.charcoal} ${typography.bodyM}`}>
        {'by '}
        <a className={typography.linkBlue} href={article.user.url}>
          {article.user.name}
        </a>
      </span>
    </div>
  </div>
);

NewsArticleFeaturedCard.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    summary: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  isHero: PropTypes.bool,
  location: PropTypes.string,
};

NewsArticleFeaturedCard.defaultProps = {
  isHero: false,
  location: null,
};

export default NewsArticleFeaturedCard;
