/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { Map } from 'immutable';
import postal from 'postal';

import IndexSettings from './stores/IndexSettings';
import Suggestions from './stores/Suggestions';

import createHistory from '../../client/reusable_components/Router/history';
import { filterSearchQueryInMap } from './filterSearchQuery';
import normalizedToQueryString from './converters/normalizedToQueryString';
import queryStringToNormalized from './converters/queryStringToNormalized';
import queryTranslator from './queryTranslator';
import searchIndex, { searchForWhitelabel, searchWithSuggestion } from './searchIndex';

import { trackSearchEvent } from '../keen/searchTracker';

import { DEFAULT_INDEX } from './constants';
import shouldAllowEmptySearchQuery from './shouldAllowEmptySearchQuery';

const history = createHistory();

class AlgoliaService {
  constructor() {
    this.channel = postal.channel('AlgoliaService');
    this.currentIndex = DEFAULT_INDEX;
    this.indexSettings = new IndexSettings();
    this.pushState = false;
    this.searches = Map();
    this.searchSettings = {};
    this.suggestions = new Suggestions();
    this.whitelabel = null;

    history.listen(this.onHistoryChange.bind(this));
  }

  // should be called with normalized query map that has index and query fields
  initializeWithEffects(normalized, whitelabel) {
    return new Promise((resolve, reject) => {
      if (whitelabel) {
        this.whitelabel = whitelabel;
      }

      // TODO: figure out why this is only happening for DEFAULT_INDEX
      if (this._isEmptyQuery(normalized) && normalized.index === DEFAULT_INDEX) {
        return resolve();
      }

      if (!this.indexSettings._inWhiteList(normalized.index, whitelabel)) {
        reject(new Error(`${normalized.index} is not a known index!  Search aborted`));
      }

      if (this.currentIndex !== normalized.index) {
        this.currentIndex = normalized.index;
      }

      this.channel.publish('working', true);

      return this._resolveSearchWithEffects(normalized, null, true)
        .then(() => {
          this.channel.publish('filters', normalized);
          this.channel.publish('working', false);
          this.searchSettings = normalized;
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  createSuggestion(q) {
    this.suggestions.create(q);
  }

  enableHistoryPushState() {
    this.pushState = true;
  }

  getChannel() {
    return this.channel;
  }

  getRedirectPath(q, path = '/search', index = DEFAULT_INDEX) {
    return `${path}?i=${index}&q=${q}`;
  }

  getSuggestions(q) {
    return this.suggestions.get(q);
  }

  onHistoryChange(location, action) {
    if (action === 'POP') {
      return this.searchWithEffects(queryStringToNormalized(location.search), null, true, true);
    }
  }

  processQueryMap(queryMap = {}) {
    return this._normalizeParams(this._reconcileSettings(queryMap));
  }

  publishEvent(event, data) {
    this.channel.publish(event, data);
  }

  replaceHistoryState(normalized, fromInitializer = false, ignoreHistoryChange = false) {
    if (this.pushState === true && ignoreHistoryChange === false) {
      if (fromInitializer) {
        history.replace(normalizedToQueryString(normalized));
      } else {
        history.push(normalizedToQueryString(normalized));
      }
    }
  }

  /**
   * Use this method when we are in the /search app for full functionality.
   * Argument is a query map ({q: 'query', i: 'projects'}).
   * Second argument is a suggestion from the input dropdown.
   */
  searchWithEffects(queryMap, querySuggestion, preProcessed = false, ignoreHistoryChange = false) {
    return new Promise((resolve, reject) => {
      const normalized = preProcessed ? queryMap : this.processQueryMap(queryMap);

      if (!this.indexSettings._inWhiteList(normalized.index, this.whitelabel)) {
        reject(new Error(`${normalized.index} is not a known index!  Search aborted`));
      }

      if (normalized.index !== this.currentIndex) {
        this.currentIndex = normalized.index;
      }

      /**
       * the queryUpdate event is for when a change in index/settings triggers a
       * filter that modifies the search query. The input component subscribes
       * to this event and will change to reflect the new search query
       */
      this.channel.publish('queryUpdate', normalized.query);
      this.channel.publish('working', true);

      return this._resolveSearchWithEffects(normalized, querySuggestion, false, ignoreHistoryChange)
        .then(() => {
          this.channel.publish('locationChange');
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  _resolveSearchWithEffects(queryMap, querySuggestion, fromInitializer = false, ignoreHistoryChange = false) {
    return new Promise((resolve, reject) => {
      if (!shouldAllowEmptySearchQuery(queryMap.index) && this._isEmptyQuery(queryMap)) {
        this.replaceHistoryState(queryMap, fromInitializer);
        this.channel.publish('results', this._createEmptyRecord());

        return resolve();
      }

      // Only create a suggestion on default index.
      if (this.whitelabel === null && this.currentIndex === DEFAULT_INDEX && this._validateForPagination(queryMap)) {
        return searchWithSuggestion(queryMap, querySuggestion)
          .then(({ res, suggestion }) => {
            this._resolveSuccessfulSearch(res, queryMap, suggestion, fromInitializer, ignoreHistoryChange);
            resolve();
          })
          .catch((err) => reject(err));
      }

      if (this.whitelabel !== null) {
        return searchForWhitelabel(queryMap, this.whitelabel)
          .then((res) => {
            this._resolveSuccessfulSearch(res, queryMap, null, fromInitializer, ignoreHistoryChange);
            resolve();
          })
          .catch((err) => reject(err));
      }

      return searchIndex(queryMap)
        .then((res) => {
          this._resolveSuccessfulSearch(res, queryMap, null, fromInitializer, ignoreHistoryChange);
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  _validateForPagination(queryMap) {
    if (
      !queryMap.hasOwnProperty('params')
      || (queryMap.hasOwnProperty('params') && !queryMap.params.hasOwnProperty('page'))
      || (queryMap.hasOwnProperty('params') && queryMap.params.hasOwnProperty('page') && (queryMap.params.page === '0' || queryMap.params.page === 0))
    ) {
      return true;
    } else {
      return false;
    }
  }

  _resolveSuccessfulSearch(res, queryMap, suggestion = null, fromInitializer = false, ignoreHistoryChange = false) {
    this.replaceHistoryState(queryMap, fromInitializer, ignoreHistoryChange);
    this.channel.publish('results', this._createRecordForQuery(res, suggestion));
    this.channel.publish('filters', queryMap);
    trackSearchEvent(queryMap, res);
  }

  /**
   * Use this method when we don't want side effects such as pubsub.
   * queryMap = {index: '', query: '', sort: ''}
   * (sort extends an index's title such as {index: 'part', sort: "name"} === "hackster_development_part_name")
   */
  search(queryMap) {
    return this.whitelabel ? searchForWhitelabel(queryMap, this.whitelabel) : searchIndex(queryMap);
  }

  /**
   * Useful when we want to avoid the whitelabel context applied to the service.
   */
  searchWithOptionalWhitelabel(queryMap, whitelabelId = null) {
    return whitelabelId ? searchForWhitelabel(queryMap, whitelabelId) : searchIndex(queryMap);
  }

  _createRecordForQuery(res, suggestion = null) {
    return {
      index: this.currentIndex,
      query: res.query,
      pagination: {
        currentPage: res.page,
        perPage: res.hitsPerPage,
        totalPages: res.nbPages,
        totalRecords: res.nbHits,
      },
      params: res.params,
      records: res.hits,
      settings: this.searchSettings,
      suggestion,
    };
  }

  _createEmptyRecord() {
    return {
      index: this.currentIndex,
      query: '',
      pagination: {},
      params: {},
      records: [],
      settings: {},
      suggestion: null,
    };
  }

  _isEmptyQuery(queryMap) {
    return (
      (queryMap.hasOwnProperty('index') && queryMap.hasOwnProperty('query'))
      && queryMap.query.length <= 0
    );
  }

  _normalizeParams(queryMap) {
    const normalized = queryTranslator(queryMap);

    if (!normalized.hasOwnProperty('index')) {
      normalized.index = this.currentIndex;
    }

    return filterSearchQueryInMap(normalized);
  }

  _reconcileSettings(queryMap) {
    if (!queryMap.hasOwnProperty('settings')) { // Honor previously stored settings from the filter panel. The input is unaware.
      queryMap.settings = this.searchSettings;
    } else { // Cache previous UI settings from the filter panel for the input.
      this.searchSettings = queryMap.settings;
    }

    return queryMap;
  }
}

const algoliaService = new AlgoliaService();
export default algoliaService;
