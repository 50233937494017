import React from 'react';
import PropTypes from 'prop-types';

import layout from '../../../../styles/global_ui/layout.css';
import tableStyles from '../../../../styles/global_ui/table.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../idea_page.css';

const StatTable = ({ header, rows }) => (
  <div className={styles.statTable}>
    <h5 className={`${layout.marginBottom10} ${typography.h5}`}>{header}</h5>
    <table className={`${tableStyles.table} ${utilStyles.bgWhite}`}>
      <tbody className={tableStyles.body}>
        {rows.map((row, i) => (
          <tr key={i} className={tableStyles.row}>
            <td className={`${tableStyles.cell} ${typography.bold}`}>{row[0]}</td>
            <td className={tableStyles.cell}>{row[1]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

StatTable.propTypes = {
  header: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.array),
};

StatTable.defaultProps = {
  header: '',
  rows: [],
};

export default StatTable;
