import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorPage from '../../../client/reusable_components/Router/ErrorPage';
import Loader from '../../../client/reusable_components/Router/Loader';

import { graphQuery } from '../../../requests/graphql';
import errorHandler from '../../../services/error_handler';

import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      analytics: null,
      initialized: false,
    };
  }

  componentDidMount() {
    if (this.props.initialized && this.props.permitAdminView()) {
      this._fetchUrl();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.initialized && (this.props.initialized && !prevProps.initialized)) {
      this._fetchUrlOrInitialize();
    }
  }

  // The MODE iframeUrl works once per request, so when we navigate away from this view and come back we're forced to
  // fetch another url.  DidMount will handle the fetch when the user navigates via the Router. DidUpdate handles the fetch
  // when this route is the landing page via url. PlatformPage fetches permissions first, initializes then we deal with the logic
  // to grab the url or not.
  _fetchUrl() {
    return graphQuery({ t: 'get_channel_analytics' }, { id: this.props.platformId })
      .then(({ channel }) => {
        this.setState({
          analytics: channel.analytics,
          initialized: true,
        });
        this.props.seoHandler(this.props.path, this.props.history);
      })
      .catch((err) => errorHandler('Platform Page Analytics: ', err));
  }

  _fetchUrlOrInitialize() {
    if (this.props.permitAdminView()) {
      this._fetchUrl();
    } else {
      this.setState({ initialized: true });
    }
  }

  /**
   * Views
   */
  _getAnalyticsView() {
    return (
      <div className={`${layout.container} ${utilStyles.bgFog}`}>
        <div className={layout.wrapper1170}>
          <iframe
            frameBorder="0"
            height={this.state.analytics.metadata.height}
            src={this.state.analytics.url}
            width={this.state.analytics.metadata.width}
          />
        </div>
      </div>
    );
  }

  _getErrorPage() {
    return (
      <ErrorPage basePath={this.props.basePath} history={this.props.history} transition={this.props.transition} />
    );
  }

  _getView() {
    return this.state.analytics === null ? this._getErrorPage() : this._getAnalyticsView();
  }

  render() {
    if (!this.state.initialized) return (<Loader />);

    return this._getView();
  }
}

Analytics.propTypes = {
  basePath: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  initialized: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  permitAdminView: PropTypes.func.isRequired,
  platformId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  seoHandler: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};

Analytics.defaultProps = {};

export default Analytics;
