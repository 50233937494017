import React from 'react';

import LazyImage from '../../../client/reusable_components/LazyImage';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './imageLinks.css';

const c = {
  section: `${layout.flexColumn} ${layout.gutter30}`,
  text: `${typography.bodyM} ${typography.staticCharcoal}`,
  columnWrapper: `${layout.flexColumn} ${layout.gutter10}`,
  boldText: `${typography.bodyM} ${typography.bold} ${typography.staticCharcoal}`,
  columnWrapper20: `${layout.flexColumn} ${layout.gutter20}`,
  imageWrapper: `${layout.flexJustifyCenter} ${layout.gutter10} ${styles.imageWrapper}`,
  image: `${util.bgTransparent} ${layout.fullWidth}`,
};

const DevEngagement = () => (
  <section className={c.section}>
    <p className={c.text}>
      We craft bespoke developer engagement programs to fit your specific business goals — whether it be brand awareness, product training, collect feedback, or to understand developer preferences. Our programs are constructed by taking your audience and goals and adding in our expertise and out of the box engagement tactics in order to create a customized experience for you and your community.
    </p>
    <div className={c.columnWrapper}>
      <p className={c.boldText}>Why consider a bespoke program?</p>
      <p className={c.text}>
        Developer engagement isn&apos;t something that happens overnight. We design our programs to be interactive and run anywhere from 3-9+ months, allowing community members to become fully immersed with our partners&apos; brand and products, while enabling our partners to gain deeper insights from participants.
      </p>
    </div>
    <div className={c.columnWrapper20}>
      <p className={c.boldText}>
        Examples of Past and Current Developer Engagement Programs:
      </p>
      <div className={c.imageWrapper}>
        <a aria-label="Xcellent event" href="https://events.hackster.io/xcellent" rel="noreferrer" target="_blank">
          <LazyImage
            alt="Xcellent event"
            classList={{ root: c.image }}
            fit="fill"
            ratio="608:482"
            src="https://hackster.imgix.net/static/marketing/content/xcellent_event.png"
            width={304}
          />
        </a>
        <a
          aria-label="Take it to the edge event"
          href="https://events.hackster.io/take-it-to-the-edge"
          rel="noreferrer"
          target="_blank"
        >
          <LazyImage
            alt="Take it to the edge event"
            classList={{ root: c.image }}
            fit="fill"
            ratio="584:480"
            src="https://hackster.imgix.net/static/marketing/content/edge_event.png"
            width={292}
          />
        </a>
        <a aria-label="Chip's challenge event" href="https://events.hackster.io/chips-challenge" rel="noreferrer" target="_blank">
          <LazyImage
            alt="Chip's challenge event"
            classList={{ root: c.image }}
            fit="fill"
            ratio="764:480"
            src="https://hackster.imgix.net/static/marketing/content/chips_challenge.png"
            width={382}
          />
        </a>
      </div>
    </div>
  </section>
);

export default DevEngagement;
