import PropTypes from 'prop-types';
import React from 'react';

import NotificationsList from '../../../client/user/edit/notifications/NotificationsList';
import RingSpinner from '../../../client/spinners/ring';

import { isBlank } from '../../../utility/types';
import { NOOP_HREF } from '../../../constants/links';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './notification_dropdown.css';

const PLACEHOLDER_COPY = "Notifications about other members' activity related to you will appear here.";
const SETTINGS_PATH = '/users/notifications/edit';
const VIEW_ALL_PATH = '/dashboard/notifications';

const _getBody = ({ currentUserId, isLoading, markAllRead, markRead, notifications }) => {
  if (isLoading) return <span className={styles.loadingIcon}><RingSpinner /></span>;
  if (isBlank(notifications)) return <p className={layout.padding15}>{PLACEHOLDER_COPY}</p>;

  return (
    <div>
      <div className={styles.header}>
        <a className={`${typography.bodyS} ${typography.linkBlue}`} href={NOOP_HREF} onClick={markAllRead}>
          Mark all as read
        </a>
        <a className={`${typography.bodyS} ${typography.linkBlue} ${layout.marginLeft10}`} href={SETTINGS_PATH} onClick={markAllRead}>
          Settings
        </a>
      </div>
      <NotificationsList
        currentUser={{ id: currentUserId }}
        markRead={markRead}
        notifications={notifications}
      />
    </div>
  );
};

const Dropdown = ({ currentUserId, isLoading, markAllRead, markRead, notifications }) => (
  <div>
    <div className={`${styles.list} ${isLoading ? typography.textCenter : ''}`}>
      {_getBody({ currentUserId, isLoading, markAllRead, markRead, notifications })}
    </div>
    <div className={styles.footer}>
      <a className={`${typography.bodyS} ${typography.link} ${typography.bold}`} href={VIEW_ALL_PATH}>
        View all
      </a>
    </div>
  </div>
);

Dropdown.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  markAllRead: PropTypes.func.isRequired,
  markRead: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
};

export default Dropdown;
