import React from 'react';

import DummyBaseCardText from '../DummyBaseCardText';
import ImagePlaceholder from '../../../image/ImagePlaceholder';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './base_card_horizontal_lg.css';

const DummyBaseCardHorizontalLarge = ({ className }) => (
  <div className={`${styles.card} ${layout.fullWidth} ${className}`}>
    <div className={`${styles.image} ${utilStyles.posRelative}`}>
      <ImagePlaceholder animated={true} aspectRatio={3 / 2} className={`${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}`} />
    </div>
    <DummyBaseCardText className={styles.text} size="L" />
  </div>
);

export default DummyBaseCardHorizontalLarge;
