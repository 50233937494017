import React from 'react';

import AnchorButton from '../../client/buttons/anchor';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import styles from './business.css';

const c = {
  section: `${layout.flexColumnCenterCenter} ${typography.textCenter} ${styles.footer}`,
  wrapper: layout.wrapper1170,
  header: `${typography.h1Responsive} ${typography.bold} ${typography.staticWhite} ${layout.margin0} ${layout.marginBottom10}`,
  text: `${typography.h4} ${typography.staticWhite} ${typography.fontWeightNormal}`,
};

const Connect = () => (
  <section className={c.section}>
    <div className={c.wrapper}>
      <h2 className={c.header}>Let&apos;s connect</h2>
      <p className={c.text}>Our team is here to help design custom programs and packages to meet your company&apos;s unique objectives.</p>
      <AnchorButton
        colorStyle="outline"
        href="mailto:partners@hackster.io"
      >
        Contact Me
      </AnchorButton>
    </div>
  </section>
);

export default Connect;
