import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../client/buttons/base';
import Icon from '../../client/icon';
import ProjectsList from '../../client/projects/projects_list';
import smoothScroll from '../../client/utils/smoothScroll';
import isMobileBrowser from '../../utility/isMobileBrowser';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';
import styles from './feed.css';

const ProjectSection = ({ projects }) => {
  // defaults to showing 4 projects when on mobile, or 8 on desktop
  const isMobile = isMobileBrowser();
  const defaultShowing = isMobile ? 4 : 8;
  const [showAllProjects, setShowAll] = useState(false);
  const projectsList = projects?.slice(0, showAllProjects ? 64 : defaultShowing);
  const projectListRef = useRef(null);

  const [scrollLocation, setScrollLocation] = useState();

  const buttonRef = useCallback((node) => {
    if (node !== null) {
      setScrollLocation(node.getBoundingClientRect().top);
    }
  }, []);

  const handleClick = useCallback(() => {
    setShowAll(!showAllProjects);
    if (showAllProjects) {
      smoothScroll(scrollLocation);
    }
  }, [showAllProjects, scrollLocation]);

  useEffect(() => {
    if (showAllProjects) {
      const firstNewItem = projectListRef.current.children[defaultShowing].getElementsByTagName('a')[0];
      // We want the user on on the first newly added item, but we don't necessarily need them focused.
      firstNewItem.focus();
      firstNewItem.blur();
    }
  }, [defaultShowing, showAllProjects]);

  return (
    <>
      <ProjectsList
        ref={projectListRef}
        cardBorder
        maxCols={4}
        records={projectsList}
      />
      <div className={`${layout.flexJustifyCenter} ${layout.marginTop30} ${utilStyles.posRelative}`}>
        <Button
          ref={buttonRef}
          className={`${styles.viewMoreButton} ${typography.bodyS}`}
          colorStyle="outlineBlack"
          onClick={handleClick}
        >
          <span className={`${typography.bodyS} ${typography.pebble} ${typography.bold}`}>
            {showAllProjects ? 'View less' : 'View more'}
          </span>
          <Icon className={`${typography.pebble} ${styles.icon} ${showAllProjects ? styles.toggled : ''}`} name="arrow-down" size={10} />
        </Button>
        <div className={`${utilStyles.borderBottom} ${styles.hrBar}`} />
      </div>
    </>
  );
};

ProjectSection.propTypes = { projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired };

export default ProjectSection;
