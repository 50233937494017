// This is the order in which indexes will show on the search page.
export const indexes = [
  'projects',
  'news_articles',
  'channels',
  'contests',
  'events',
  'users',
  'parts',
  'videos',
];

export const whitelabelIndexes = [
  'projects',
  'parts',
  'users',
];

export const blacklistedWhitelabelIndexesById = { 18265: ['parts'] };

const _indexToLabel = {
  channels: 'Channels',
  contests: 'Contests',
  events: 'Events',
  news_articles: 'News',
  projects: 'Projects',
  parts: 'Products',
  users: 'People',
  videos: 'Videos',
};

const _labelToIndex = indexes.reduce((acc, index) => ({ ...acc, [_indexToLabel[index]]: index }), {});

export function indexToLabel(index) {
  return _indexToLabel[index];
}

export function labelToIndex(index) {
  return _labelToIndex[index];
}

export function isBlacklistedWhitelabelIndex(channelId, index) {
  return (
    blacklistedWhitelabelIndexesById[channelId]
    && blacklistedWhitelabelIndexesById[channelId].indexOf(index) >= 0
  );
}
