import PropTypes from 'prop-types';
import React from 'react';
import Tools from '../profile/tools';
import styles from '../list/list.css';

const ToolsList = ({ blockHoverForResource, fetchProjects, fetchProjectsForResource, killPopover, metadata, profile, records, store, toggleRespect, triggerPopover }) => (
  <div className={styles.dialogContainer}>
    <Tools
      allProjects={store.get('allProjects')}
      blockHoverForResource={(shouldBlock) => blockHoverForResource('tools', shouldBlock)}
      fetchProjects={fetchProjects.bind(this, store)}
      fetchProjectsForResource={fetchProjectsForResource.bind(this, store)}
      inDialog={true}
      killPopover={killPopover}
      profile={{ id: profile.id, name: profile.name }}
      toggleRespect={(id, createOrDeleteBool) => toggleRespect(id, 'allProjects', ['allProjects', id], createOrDeleteBool, 'project_respects')}
      tools={{ records, metadata }}
      triggerPopover={triggerPopover.bind(this, store, 'tools')}
    />
  </div>
);

ToolsList.propTypes = {
  blockHoverForResource: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  fetchProjectsForResource: PropTypes.func.isRequired,
  killPopover: PropTypes.func.isRequired,
  metadata: PropTypes.shape({
    currentPage: PropTypes.number,
    per_page: PropTypes.number,
    total_pages: PropTypes.number,
    total_records: PropTypes.number,
  }).isRequired,
  profile: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }).isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    projects: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.shape({
        cover_image_url: PropTypes.string,
        hid: PropTypes.string,
      })),
      metadata: PropTypes.object,
    }),
    stats: PropTypes.shape({ projects: PropTypes.number }),
  })).isRequired,
  store: PropTypes.object,
  toggleRespect: PropTypes.func.isRequired,
  triggerPopover: PropTypes.func.isRequired,
};

ToolsList.defaultProps = {};

export default ToolsList;
