import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from '../../client/reusable_components/ProgressBar';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import styles from './feed.css';

import { ONBOARDING_STEPS, ONBOARDING_STEPS_ORDER_MAP } from '../user_onboarding_page/constants';

function canAccessStep(step, index, lastStepCompleted, onboardingProgress) {
  if (index === 0) return true;
  if (!lastStepCompleted) return false;
  if (onboardingProgress.includes(step)) return true;

  return (ONBOARDING_STEPS_ORDER_MAP[lastStepCompleted] + 1) >= ONBOARDING_STEPS_ORDER_MAP[step];
}

const FinishOnboarding = ({ onboardingProgress }) => {
  const percent = Math.floor((onboardingProgress.length / ONBOARDING_STEPS.length) * 100);
  const lastStepCompleted = onboardingProgress[onboardingProgress.length - 1];

  const steps = ONBOARDING_STEPS.map((step, index) => {
    const previousStep = ONBOARDING_STEPS[index - 1];

    const canAccess = canAccessStep(step, index, lastStepCompleted, onboardingProgress);
    const href = `/onboarding/${step}`;
    const isActive = lastStepCompleted === previousStep;
    const isDisabled = !isActive && !canAccess;

    return {
      href,
      isComplete: onboardingProgress.includes(step),
      isActive,
      isDisabled,
      isLast: index >= ONBOARDING_STEPS.length - 1,
      name: step,
    };
  });

  return (
    <section className={`${layout.paddingBottom45} ${layout.flexJustifySpaceBetween} ${layout.gutter5} ${layout.flexCenterItems} ${styles.finishOnboarding}`}>
      <div>
        <h3 className={`${typography.h3Responsive} ${layout.margin0}`}>
          {percent < Math.floor((1 / ONBOARDING_STEPS.length) * 100) ? 'Complete your onboarding!' : "You're almost done onboarding!"}
        </h3>
        <a className={`${typography.bodyM} ${layout.margin0} ${typography.linkBlue}`} href="/onboarding">Continue where you left off</a>
      </div>
      <div className={`${layout.flexColumn} ${layout.flexJustifyEnd} ${layout.gutter10} ${styles.progressBarWrapper}`}>
        <ProgressBar
          currentStep={ONBOARDING_STEPS[onboardingProgress.length]}
          iconSize={18}
          steps={steps}
        />
        <p className={`${typography.hackster} ${typography.bodyM} ${layout.margin0} ${typography.textRight}`}>
          {percent}
          % complete
        </p>
      </div>
    </section>
  );
};

FinishOnboarding.propTypes = { onboardingProgress: PropTypes.arrayOf(PropTypes.oneOf(['activities', 'info', 'interests'])).isRequired };

export default FinishOnboarding;
