// challenge status
export const IN_PROGRESS = 'IN_PROGRESS';
export const JUDGED = 'JUDGED';
export const JUDGING = 'JUDGING';
export const PRE_REGISTRATION = 'PRE_REGISTRATION';

// free hardware status
export const CLOSED = 'CLOSED';
export const OPEN = 'OPEN';
export const LAUNCH_PENDING = 'LAUNCH_PENDING';
export const WINNERS_ANNOUNCED = 'WINNERS_ANNOUNCED';

// leftover idea status
export const IDEA_EDITING_STATUS = 'DRAFT';

// submission type: project entry or idea
export const ENTRY = 'ENTRY';
export const IDEA = 'IDEA';

/* Contest Submission statuses. TODO: combine with IDEA_EDITING_STATUS above? */
// Idea OR Project Entry
export const APPROVED = 'APPROVED';
export const DRAFT = 'DRAFT';
export const PENDING_REVIEW = 'PENDING_REVIEW';
export const REJECTED = 'REJECTED';

// Project Entry only
export const INCOMPLETE = 'INCOMPLETE';
export const LOST = 'LOST';
export const RESUBMITTED = 'RESUBMITTED';
export const WON = 'WON';
export const WON_OTHER_CONTEST = 'WON_OTHER_CONTEST';
