import GraphQLBaseService from '../base_service';
import queryBuilder from './query_builder';

const CHANNELS_QUERY_TEMPLATE = () => ({ t: 'get_channels_simple_pagination' });

export default class GraphQLChannelsService extends GraphQLBaseService {
  constructor({ history, initChannels } = {}) {
    super({ history, queryBuilder, queryString: CHANNELS_QUERY_TEMPLATE });
    this.initChannels = initChannels;
  }

  /**
   * Initializers
   */

  // Override the method to fallback to the initChannels prop for caching.
  initializeFromUrl(initArgs) {
    return new Promise((resolve, reject) => this.mapifySearchQuery()
      .then((map) => this.buildAndStoreNextHistory(map))
      .then((map) => this.queryBuilder({ ...initArgs, ...map }))
      .then((params) => this._hasValidQueryParams(params))
      .then(([hasValidParams, params]) => hasValidParams ? this.search(this.graphQLQueryString(), { ...initArgs, ...params }) : this.initChannels)
      .then((currentQuery) => resolve(currentQuery))
      .catch((err) => reject(err)));
  }

  /**
   * Helpers
   */

  // If the current url doesn't have a sort or page parameter, fallback to the cached initChannels prop.
  // The page parameter is removed for the first page and will only exist on page 2 and up.
  _hasValidQueryParams(params) {
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return Promise.resolve([(params.hasOwnProperty('sort') || params.hasOwnProperty('page')), params]);
  }
}
