import PropTypes from 'prop-types';
import React from 'react';

import LinkToTab from './link_to_tab';

import { indexes, indexToLabel } from '../algoliaIndexes';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const NoResults = ({ algoliaIndex, currentQuery, isWhitelabel, setIndex }) => {
  if (!currentQuery || isWhitelabel) return null;

  const recommendedSearchLinks = indexes.filter((index) => index !== algoliaIndex);

  return (
    <div className={layout.marginTop30}>
      <h5>Search for</h5>
      <ul className={`${utilStyles.ulReset} ${layout.paddingLeft15}`}>
        {recommendedSearchLinks.map((index, i) => (
          <li key={i}>
            {'- '}
            <LinkToTab
              className={`${typography.linkBlue} ${typography.bodyM}`}
              href={`/search?q=${currentQuery}&i=${index}`}
              onClick={() => setIndex(index)}
            >
              {` ${currentQuery} in ${indexToLabel(index)}`}
            </LinkToTab>
          </li>
        ))}
      </ul>
    </div>
  );
};

NoResults.propTypes = {
  algoliaIndex: PropTypes.string.isRequired,
  currentQuery: PropTypes.string.isRequired,
  isWhitelabel: PropTypes.bool,
  setIndex: PropTypes.func,
};

NoResults.defaultProps = {
  isWhitelabel: false,
  setIndex: () => {},
};

export default NoResults;
