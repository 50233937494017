import PropTypes from 'prop-types';
import React from 'react';

import ContentPlaceholder from '../../../../client/reusable_components/ContentPlaceholder';
import ExpandableCard from '../list/ExpandableCard';
import UserRelationButton from '../../relation_button';
import urlService from '../../../../services/url_service';

import { pluralize } from '../../../../utility/formatters';

import styles from '../list/list.css';

const FollowingList = ({ currentUser, metadata, noContentPlaceholderType, profile, records, updateResource }) => (
  <div className={styles.dialogContainer}>
    {metadata.total_records > 0
      ? (
        <div className={styles.list}>
          {records.map((record, i) => (
            <ExpandableCard
              key={i}
              classList={{ icon: styles.roundIcon }}
              icon={record.avatar_url}
              info={`${record.stats.live_projects} ${pluralize('project', record.stats.live_projects)} • ${record.stats.followers} ${pluralize('follower', record.stats.followers)}`}
              isNestedInDialog={true}
              link={urlService.url(record.user_name)}
              subtitle={record.bio}
              title={record.name}
            >
              <div>
                {currentUser && currentUser.id !== record.id
                && (
                  <UserRelationButton
                    config={{ label: ['Follow', 'Following'] }}
                    id={record.id}
                    onClick={(createOrDeleteBool) => updateResource(
                      record.id,
                      'profileStats',
                      ['profileStats', 'following'],
                      createOrDeleteBool,
                      'following_list_follow_button',
                    )}
                    type="followed_user_md"
                  />
                )}
              </div>
            </ExpandableCard>
          ))}
        </div>
        )
      : (
        <ContentPlaceholder
          classList={{ content: styles.noRecordsMessage }}
          isProfileOwner={currentUser.isProfileOwner}
          templateArgs={{ name: profile.name }}
          type={noContentPlaceholderType}
        />
        )}
  </div>
);

FollowingList.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }).isRequired,
  metadata: PropTypes.shape({
    currentPage: PropTypes.number,
    per_page: PropTypes.number,
    total_pages: PropTypes.number,
    total_records: PropTypes.number.isRequired,
  }).isRequired,
  noContentPlaceholderType: PropTypes.string,
  profile: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({})),
  updateResource: PropTypes.func.isRequired,
};

FollowingList.defaultProps = { noContentPlaceholderType: 'no-follows' };

export default FollowingList;
