import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../client/reusable_components/LazyImage';
import FormCountrySelect from '../../client/form_components/selects/form_country_select';
import FormSelect from '../../client/form_components/selects/form_select';
import Tooltip from '../../client/reusable_components/Tooltip';

import { jobOptsFormatter, objectToOptions, skillLevelOptsFormatter } from './helpers';
import { InfoPageOptions } from './types';
import { graphMutate } from '../../requests/graphql';
import errorHandler from '../../services/error_handler';
import { getInObj } from '../../utility/accessors';
import { isBlank } from '../../utility/types';

import inputStyles from '../../styles/global_ui/inputs.css';
import layout from '../../styles/global_ui/layout.css';
import util from '../../styles/global_ui/util.css';
import styles from './info.css';

const INPUT_INFO = {
  country: { label: 'What country do you live in?', tooltip: 'This helps us suggest events in your area. This can be hidden from your profile.' },
  industry: { label: 'What is your industry?', tooltip: 'This helps us suggest news, projects, and other content most relevant to your industry.' },
  job: { label: 'What do you do for work?', tooltip: 'This helps us suggest news, projects, and other content most relevant to your occupation.' },
  skillLevel: { label: 'What is your skill level?', tooltip: 'This helps us suggest projects and parts most useful to you.' },
};

const InputLabel = ({ type }) => {
  const { label, tooltip } = INPUT_INFO[type];

  return (
    <span className={`${inputStyles.label} ${layout.flexCenterItems}`}>
      <span className={`${layout.marginRight5} ${layout.marginBottom0}`}>{label}</span>
      <span className={`${layout.flexCenterItems}`}>
        <Tooltip align="start" arrowPadding={24} collisionPadding={15} content={tooltip} sideOffset={5} />
      </span>
    </span>
  );
};

const Info = ({
  country,
  industry,
  job,
  options,
  setSelectedCountry,
  setSelectedIndustry,
  setSelectedJob,
  setSelectedSkillLevel,
  skillLevel,
}) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSelection = ({ attribute, value }) => {
    setErrors({});
    setIsSubmitting(true);
    const currentUserInfo = { country_iso2: country, industry, job, skill_level: skillLevel };

    return graphMutate({ t: 'update_user_info' }, { ...currentUserInfo, [attribute]: value })
      .then(({ user_info: { country_iso2, industry, job, skill_level } }) => {
        setSelectedCountry(country_iso2);
        setSelectedIndustry(industry);
        setSelectedJob(job);
        setSelectedSkillLevel(skill_level);
      })
      .catch((err) => {
        setErrors({ [attribute]: value });
        errorHandler('UserOnboardingPage/Info handleFormSelection Error: ', err);
      }).finally(setIsSubmitting(false));
  };

  useLayoutEffect(() => {
    // scroll to top
    window?.scrollTo({ top: 0 });
  }, []);

  return (
    <main
      className={`${layout.flexColumn} ${layout.flexCenterCenter} ${layout.fullWidth}`}
    >
      <form
        className={`${layout.flexJustifyCenter} ${layout.fullWidth} ${styles.wrapper}`}
      >
        <LazyImage
          alt="two robots checking a list"
          classList={{ root: `${util.bgTransparent} ${styles.hideOnDarkMode}` }}
          ratio="500:312"
          src="https://hackster.imgix.net/static/marketing/checklist-robots-chatting.png"
          width={500}
        />
        <LazyImage
          alt="two robots checking a list"
          classList={{ root: `${util.bgTransparent} ${styles.showOnDarkMode}` }}
          ratio="500:312"
          src="https://hackster.imgix.net/static/marketing/checklist-robots-chatting-dark.png"
          width={500}
        />

        {/* TODO: For below inputs, use 'scrollToError' util to move errors into view after submit? */}
        <div
          className={`${layout.flexColumn} ${layout.fullWidth} ${styles.forms}`}
        >
          <div id="vfskill_level">
            <FormSelect
              disabled={isSubmitting}
              errors={
                errors?.skill_level
                  ? 'Oops, something went wrong. Please try again.'
                  : null
              }
              label={<InputLabel type="skillLevel" />}
              onSelectedChange={(skill) => handleFormSelection({
                attribute: 'skill_level',
                value: getInObj(['value'], skill),
              })}
              options={skillLevelOptsFormatter(options.skill_levels)}
              searchOpts={{ rule: 'norule' }}
              value={
                isBlank(skillLevel)
                  ? ''
                  : skillLevelOptsFormatter([skillLevel])[0]
              }
            />
          </div>
          <div id="vfjob">
            <FormSelect
              disabled={isSubmitting}
              errors={
                errors?.job
                  ? 'Oops, something went wrong. Please try again.'
                  : null
              }
              label={<InputLabel type="job" />}
              onSelectedChange={(job) => handleFormSelection({
                attribute: 'job',
                value: getInObj(['value'], job),
              })}
              options={jobOptsFormatter(options.jobs)}
              searchOpts={{ rule: 'norule' }}
              value={isBlank(job) ? '' : jobOptsFormatter([job])[0]}
            />
          </div>
          <div id="vfindustry">
            <FormSelect
              disabled={isSubmitting}
              errors={
                errors?.industry
                  ? 'Oops, something went wrong. Please try again.'
                  : null
              }
              label={<InputLabel type="industry" />}
              onSelectedChange={(ind) => handleFormSelection({
                attribute: 'industry',
                value: getInObj(['value'], ind),
              })}
              options={objectToOptions(options.industries)}
              searchOpts={{ rule: 'norule' }}
              value={industry || ''}
            />
          </div>
          <div id="vflocation">
            <FormCountrySelect
              countryCode={country}
              disabled={isSubmitting}
              errors={
                errors?.country_iso2
                  ? 'Oops, something went wrong. Please try again.'
                  : null
              }
              label={<InputLabel type="country" />}
              onChange={(countryCode) => handleFormSelection({
                attribute: 'country_iso2',
                value: countryCode,
              })}
              placeholder="Search..."
            />
          </div>
        </div>
      </form>
    </main>
  );
};

export default Info;

Info.propTypes = {
  country: PropTypes.string,
  industry: PropTypes.string,
  job: PropTypes.string,
  options: PropTypes.shape(InfoPageOptions).isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedIndustry: PropTypes.func.isRequired,
  setSelectedJob: PropTypes.func.isRequired,
  setSelectedSkillLevel: PropTypes.func.isRequired,
  skillLevel: PropTypes.string,
};
