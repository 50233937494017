import { getInObj } from '../../../../utility/accessors';

export default function parts({
  id,
  _highlightResult,
  image_url,
  name,
  platforms,
  projects_count,
  url,
}, options = {}) {
  return {
    body: { htmlString: getInObj(['pitch', 'value'], _highlightResult) },
    id: id,
    image: {
      fit: 'fill',
      linkTo: url,
      loadBuffer: 100,
      queryParams: { bg: 'ffffff' },
      url: image_url,
      width: 75,
    },
    stats: [{ metric: projects_count, text: 'Project' }],
    subTitle: (Array.isArray(platforms) && platforms.length) ? platforms.map((p, i) => ({ text: p.name, linkTo: p.url })) : null,
    title: {
      text: name,
      linkTo: url,
    },
  };
}
