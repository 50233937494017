import React from 'react';
import PropTypes from 'prop-types';

import NewsArticleSponsoredCardLarge from '../../../client/cards/news_article/sponsored_card_large';

import { isBlank } from '../../../utility/types';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import gridStyles from '../../../styles/global_ui/grid.css';
import styles from './news_home_page.css';

const SponsoredSection = ({ records }) => {
  if (isBlank(records)) return null;

  return (
    <div className={`${styles.sponsoredSection} ${layout.container} ${layout.marginBottom60}`}>
      <div className={`${layout.wrapper1170}`}>
        <div className={styles.sponsoredList}>
          <div className={`${gridStyles.grid} ${gridStyles.guttersH15} ${gridStyles.guttersV30} ${gridStyles.guttersH60_sm}`}>
            {records.map((record, i) => (
              <div key={record.id} className={`${gridStyles.cell} ${gridStyles.cell_1_2} ${styles.sponsoredCardContainer}`}>
                <NewsArticleSponsoredCardLarge
                  article={record}
                  location={`news_article_sponsored_card-${i}`}
                />
              </div>
            ))}
          </div>
          <h5 className={`${layout.fullWidth} ${layout.marginTop15} ${typography.textRight} ${typography.h5}`}>
            sponsored articles
          </h5>
        </div>
      </div>
    </div>
  );
};

SponsoredSection.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    sponsor_link: PropTypes.string.isRequired,
    sponsor_name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    url: PropTypes.string.isRequired,
  })),
};

SponsoredSection.defaultProps = { records: null };

export default SponsoredSection;
