import PropTypes from 'prop-types';
import React from 'react';

import EventCardDate from '../../../../cards/event_card/EventCardDate';

import styles from './event_info.css';

const EventDate = ({ dateString, orientation }) => (
  <div className={orientation === 'vertical' ? styles.eventDateVertical : styles.eventDateHorizontal}>
    <EventCardDate classList={{ day: styles.day }} dateString={dateString} />
  </div>
);

EventDate.propTypes = {
  dateString: PropTypes.string,
  orientation: PropTypes.string,
};

EventDate.defaultProps = {
  orientation: 'vertical',
  dateString: '',
};

export default EventDate;
