import PropTypes from 'prop-types';
import React from 'react';

import Badge from '../../../../reusable_components/Badge';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';

const Title = ({ text, linkTo, additionalInfo, user }) => {
  if (!text) return null;

  return (
    <div className={layout.flexCenterItems}>
      <span className={`${typography.bodyM} ${typography.bold}`}>
        {linkTo
          ? (
            <a className={typography.linkBlue} href={linkTo}>
              {text}
            </a>
            )
          : (
              text
            )}
      </span>
      {user && (
        <Badge
          className={layout.marginLeft5}
          size="medium"
          user={user}
        />
      )}
      {additionalInfo && (
        <span className={`${typography.h5} ${layout.marginLeft10}`}>
          {additionalInfo}
        </span>
      )}
    </div>
  );
};

Title.propTypes = {
  additionalInfo: PropTypes.string,
  linkTo: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Title.defaultProps = {
  additionalInfo: '',
  linkTo: '',
};

export default Title;
