import React from 'react';
import PropTypes from 'prop-types';

import ContentPlaceholder from '../../../reusable_components/ContentPlaceholder';
import ProjectsSection from '../../projects_section';

import styles from '../dashboard/dashboard.css';

const Drafts = ({ currentUser, drafts }) => (
  drafts && drafts.records.length
    ? (
      <ProjectsSection
        cardBorder={true}
        category="drafts"
        currentUser={currentUser}
        projects={drafts.records}
        singleCategory={false}
        totalRecords={drafts.metadata.total_records}
      />
      )
    : (
      <section className={styles.section}>
        <ContentPlaceholder type={currentUser.stats.projects ? 'with-public-without-drafts' : 'no-projects'} />
      </section>
      )
);

Drafts.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    stats: PropTypes.shape({ projects: PropTypes.number }),
  }).isRequired,
  drafts: PropTypes.shape({
    metadata: PropTypes.shape({
      current_page: PropTypes.number,
      per_page: PropTypes.number,
      total_pages: PropTypes.number,
      total_records: PropTypes.number,
    }),
    records: PropTypes.arrayOf(PropTypes.shape({
      content_type: PropTypes.string,
      cover_image_url: PropTypes.string,
      difficulty: PropTypes.string,
      edit_url: PropTypes.string,
      guest_name: PropTypes.string,
      hid: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      one_liner: PropTypes.string,
      position: PropTypes.number,
      project_type: PropTypes.string,
      published_state: PropTypes.string,
      stats: PropTypes.shape({
        respects: PropTypes.number,
        views: PropTypes.number,
      }),
      team: PropTypes.shape({
        members: PropTypes.arrayOf(
          PropTypes.shape({
            avatar_url: PropTypes.string,
            id: PropTypes.number,
            name: PropTypes.string,
            stats: PropTypes.shape({ live_projects: PropTypes.number }),
            url: PropTypes.string,
          }),
        ),
        name: PropTypes.string,
        user_name: PropTypes.string,
      }),
      url: PropTypes.string,
    })),
  }),
};

Drafts.defaultProps = { drafts: null };

export default Drafts;
