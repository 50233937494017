/* eslint-disable react/jsx-key */

import React from 'react';

import Analytics from './analytics';
import Discussion from './discussion';
import Members from './members';
import ChannelHome from './home';
import ChannelProjects from './projects';
import ProductProjects from './product/ProductProjects';
import ProductSpecs from './product/ProductSpecs';
import Products from './products';
import { Route } from '../../client/reusable_components/Router';

import errorHandler from '../../services/error_handler';

import communitySeoConfig from './seoConfigs/communitySeoConfig';
import platformSeoConfig from './seoConfigs/platformSeoConfig';
import topicSeoConfig from './seoConfigs/topicSeoConfig';

/**
 * Helpers
 */
const permitAdminView = (permissions) => permissions.channelRole === 'admin' || permissions.canManage === true;

/**
 * Route constants
 */
const COMMUNITY_ROUTES = [
  { href: '/', name: 'Home' },
  { href: '/projects', name: 'Projects' },
  { href: '/discussion', name: 'Discussion' },
  { href: '/members', name: 'Members' },
];

const PLATFORM_ROUTES = [
  { href: '/', name: 'Home' },
  { href: '/projects', name: 'Projects' },
  { href: '/discussion', name: 'Discussion' },
  { href: '/products', name: 'Products' },
  { href: '/members', name: 'Members' },
  { href: '/analytics', name: 'Analytics', deferred: permitAdminView },
];

const TOPIC_ROUTES = [
  { href: '/', name: 'Home' },
  { href: '/projects', name: 'Projects' },
  { href: '/discussion', name: 'Discussion' },
  { href: '/members', name: 'Members' },
];

/**
 * Individual route component getters
 */
const getHomeRoute = ({ channel, channel_type, handleProjectsTransition, seoHandler, userPermissions }) => (
  <Route
    component={ChannelHome}
    path="/"
    routerProps={{
      canManage: userPermissions.canManage,
      homeSections: channel.home_sections,
      seoHandler: (path) => seoHandler.reportView({ path, data: channel }),
      transition: (path) => handleProjectsTransition(path, { scrollTo: 'reactSceneHeader' }, 'sort=trending'),
      viewAllBuilderArg: () => ({ type: channel_type, props: { [channel_type]: channel } }),
    }}
  />
);

const getProjectsRoute = ({ channel, currentHistoryData, filters, path = '/projects', seoHandler, userPermissions }) => (
  <Route
    component={ChannelProjects}
    path={path}
    routerProps={{
      canManage: userPermissions.canManage,
      channel: channel,
      currentHistoryData: currentHistoryData,
      filters: filters.project_filters,
      graphQLArguments: { base_channel_id: channel.id },
      seoHandler: (path) => seoHandler.reportView({ path, data: channel }),
    }}
  />
);

const getDiscussionRoutes = ({ channel, currentUser, discussion_props, pathHelpers, seoHandler }) => {
  const routerProps = {
    currentUser,
    origin: discussion_props.origin,
    pathHelpers,
    pathPrefix: 'discussion',
    seoHandler: () => seoHandler.reportView({ path: '/discussion', data: channel }),
  };

  return [
    <Route
      component={Discussion}
      path="/discussion"
      routerProps={routerProps}
    />,
    <Route
      component={Discussion}
      path="/discussion/*"
      routerProps={routerProps}
    />,
    <Route
      component={Discussion}
      path="/discussion/posts/*"
      routerProps={routerProps}
    />,
  ];
};

// currently only used with platforms. If this ever changes, generalize platform-specific props
const getProductRoutes = ({ channel, currentHistoryData, filters, getAdminLink, handleProductTransition, seoHandler, userPermissions }) => (
  [
    <Route
      component={Products}
      path="/products"
      routerProps={{
        canManage: userPermissions.canManage,
        filters: filters.products_filters,
        graphQLArguments: { platform_id: channel.id },
        manageProductsLink: getAdminLink('Manage products'),
        platformId: channel.id,
        seoHandler: (path) => seoHandler.reportView({ path, data: channel }),
        transition: (route, partSlug, e) => handleProductTransition(route, partSlug, { scrollTo: 'reactSceneHeader' }, '', e),
      }}
    />,
    <Route
      component={ProductProjects}
      path="/products/*"
      routerProps={{
        canManage: userPermissions.canManage,
        currentHistoryData: currentHistoryData,
        filters: filters.product_filters,
        graphQLArgumentsParts: { platform_id: channel.id, platform_user_name: channel.user_name },
        graphQLArgumentsProjects: { platform_id: channel.id },
        platformId: channel.id,
        seoHandler: (path, part) => seoHandler.reportView({ path, data: { part, platform: channel } }),
        transition: handleProductTransition,
      }}
    />,
    <Route
      component={ProductSpecs}
      path="/products/*/specs"
      routerProps={{
        graphQLArguments: { platform_id: channel.id, platform_user_name: channel.user_name },
        platformId: channel.id,
        seoHandler: (path, part) => seoHandler.reportView({ path, data: { part, platform: channel } }),
        transition: handleProductTransition,
      }}
    />,
  ]
);

const getMembersRoute = ({ channel, channel_type, currentHistoryData, seoHandler }) => (
  <Route
    component={Members}
    path="/members"
    routerProps={{
      currentHistoryData: currentHistoryData,
      graphQLArguments: { id: channel.id },
      graphQLServiceType: channel_type,
      seoHandler: (path) => seoHandler.reportView({ path, data: channel }),
    }}
  />
);

// currently only used with platforms. If this ever changes, generalize platform-specific props
const getAnalyticsRoute = ({ basePath, channel, initialized, seoHandler, transition, userPermissions }) => (
  <Route
    component={Analytics}
    path="/analytics"
    routerProps={{
      basePath: basePath,
      initialized: initialized,
      permitAdminView: () => permitAdminView(userPermissions),
      platformId: channel.id,
      transition: (history, route) => transition(history, route),
      seoHandler: (path) => seoHandler.reportView({ path, data: channel }),
    }}
  />
);

/**
 * Channel-type specific route getters
 */
const getRouteComponentsForCommunity = (props) => (
  [
    getHomeRoute(props),
    getProjectsRoute(props),
    ...getDiscussionRoutes(props),
    getMembersRoute(props),
  ]
);

const getRouteComponentsForPlatform = (props) => (
  [
    getHomeRoute(props),
    getProjectsRoute(props),
    ...getDiscussionRoutes(props),
    ...getProductRoutes(props),
    getMembersRoute(props),
    getAnalyticsRoute(props),
  ]
);

const getRouteComponentsForTopic = (props) => (
  [
    getHomeRoute(props),
    getProjectsRoute(props),
    ...getDiscussionRoutes(props),
    getMembersRoute(props),
  ]
);

/**
 * exports
 */
export const getConfigForChannelType = (channelType) => {
  switch (channelType) {
    case 'community':
      return {
        getRouteComponents: getRouteComponentsForCommunity,
        routes: COMMUNITY_ROUTES,
        seoConfig: communitySeoConfig,
      };

    case 'platform':
      return {
        getRouteComponents: getRouteComponentsForPlatform,
        routes: PLATFORM_ROUTES,
        seoConfig: platformSeoConfig,
      };

    case 'topic':
      return {
        getRouteComponents: getRouteComponentsForTopic,
        routes: TOPIC_ROUTES,
        seoConfig: topicSeoConfig,
      };

    default:
      errorHandler('Invalid channel type:', channelType);

      return null;
  }
};
