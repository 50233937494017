import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Discussion from '../../../client/discussion';
import { FLAGGED_ID_KEYS, getFlaggedIds, setFlaggedIds } from '../../../client/discussion/transactions/flags';

import userRelationsStore from '../../../services/user_relations_store';
import { cleanPreAndPostSlashes } from '../../../client/reusable_components/Router/history';
import { createCategoryConfigs } from './categoryConfig';
import { prependLeadingSlash } from '../../../utility/urls';

// NOTE: the "Discussion" app expects itself to be at the "basePath" level of the app,
// and expects the path data that it gets from and sends to a `history` object
// to reflect that. However, SPAs for channels (platform, topic, community)
// usually put the discussion app at a nested "discussion" route beyond the basePath.
// We need to add/remove this route segment ("pathPrefix" prop, will usually be "discussion")
// in between the main channel app and the discussion app. This lets us use the
// same `history` object for all routing concerns.

class ChannelDiscussion extends PureComponent {
  constructor(props) {
    super(props);

    const flaggedIds = getFlaggedIds((key) => props.store.get(key) || []);

    this.state = {
      ...flaggedIds,
      isMember: false,
    };

    this.handleTransition = this.handleTransition.bind(this);
    this.setIsMember = this.setIsMember.bind(this);

    // bound transaction
    this.setFlaggedIds = setFlaggedIds.bind(this);

    this._subscription;

    // Category opts
    const { CATEGORY_CONFIGS, SELECT_CATEGORIES } = createCategoryConfigs();
    this._CATEGORY_CONFIGS = CATEGORY_CONFIGS;
    this._SELECT_CATEGORIES = SELECT_CATEGORIES;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this.setIsMember(userRelationsStore.getStore());
    this._subscription = userRelationsStore.getChannel().subscribe('*.*', this.setIsMember);
  }

  componentWillUnmount() {
    if (this._subscription) this._subscription.unsubscribe();
    FLAGGED_ID_KEYS.forEach((key) => this.props.store.set(key, this.state[key]));
  }

  /**
   * Methods
   */
  handleTransition(historyData) {
    this.props.history.push(this._prependPathPrefixInHistoryData(historyData));
  }

  setIsMember(store) {
    const isMember = this._getMembershipFromStore(store);
    if (isMember !== this.state.isMember) this.setState({ isMember });
  }

  /**
   * Helpers
   */
  _appendPathPrefix(path) {
    if (!this.props.pathPrefix) return path;

    return prependLeadingSlash(cleanPreAndPostSlashes(`${path}/${this.props.pathPrefix}`));
  }

  _getMembershipFromStore(store) {
    return !!(store.channel_ids && store.channel_ids.includes(this.props.origin.id));
  }

  _prependPathPrefix(path) {
    if (!this.props.pathPrefix) return path;

    return prependLeadingSlash(cleanPreAndPostSlashes(`${this.props.pathPrefix}/${path}`));
  }

  _prependPathPrefixInHistoryData({ pathname, search }) {
    return { pathname: this._prependPathPrefix(pathname), search };
  }

  _stripPathPrefix(path) {
    if (!this.props.pathPrefix) return path;

    const cleanPrefix = cleanPreAndPostSlashes(this.props.pathPrefix);
    const cleanPath = cleanPreAndPostSlashes(path);

    if (cleanPath.startsWith(cleanPrefix)) {
      return prependLeadingSlash(cleanPath.substring(cleanPrefix.length));
    }

    // if we get to this point, something isn't right
    console.warn(`The input path should have started with ${cleanPrefix}. Please check routing`);

    return path;
  }

  _stripPathPrefixInHistoryData({ pathname, search }) {
    return { pathname: this._stripPathPrefix(pathname), search };
  }

  render() {
    const flaggedIds = getFlaggedIds((key) => this.state[key]);

    return (
      <Discussion
        key={this.props.history.location.key} // Important: this remounts the component on navigation, forcing reinitialization
        categoryConfigs={this._CATEGORY_CONFIGS}
        currentHistoryData={this._stripPathPrefixInHistoryData(this.props.history.location)}
        currentUser={this.props.currentUser}
        isMember={this.state.isMember}
        isNotMemberPlaceholder="Join this community to post in the discussion"
        onInit={this.props.seoHandler}
        origin={this.props.origin}
        pathHelpers={{ basePath: this._appendPathPrefix(this.props.pathHelpers.basePath) }}
        selectCategories={this._SELECT_CATEGORIES}
        setFlaggedIds={this.setFlaggedIds}
        stickyBuffer={30}
        transition={this.handleTransition}
        {...flaggedIds}
      />
    );
  }
}

ChannelDiscussion.propTypes = {
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  history: PropTypes.object.isRequired,
  origin: PropTypes.shape({
    admin_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  pathHelpers: PropTypes.shape({ basePath: PropTypes.string.isRequired }).isRequired,
  pathPrefix: PropTypes.string,
  seoHandler: PropTypes.func.isRequired,
  stickyBuffer: PropTypes.number,
  store: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  topAnchorId: PropTypes.string,
};

ChannelDiscussion.defaultProps = {
  pathPrefix: '',
  stickyBuffer: 0,
  topAnchorId: null,
};

export default ChannelDiscussion;
