import { timestampToRelativeTime } from '../../../../utility/time';

export default function newsArticles({
  cover_image_url,
  id,
  published_at,
  respects_count,
  title,
  user,
  url,
}, options = {}) {
  return {
    id: id,
    image: {
      linkTo: url,
      loadBuffer: 100,
      ratio: '16:9',
      url: cover_image_url,
      width: 100,
    },
    stats: [
      { override: `Published ${timestampToRelativeTime(published_at)}` },
      { metric: respects_count, text: 'Respect' },
    ],
    subTitle: [{ text: user.name, linkTo: user.url }],
    title: {
      text: title,
      linkTo: url,
    },
  };
}
