import { boolOrNullOut } from '../../../../utility/forms/formatters';
import { agreeTo, maxLength } from '../../../../services/validation/validators';
import { isInputTypeRadio, isInputTypeSelect, resolveInputType } from '../../../../graphql/challenges/enums';

const AGREE_TO_ERROR_MSG = 'You must review your project before submitting.';
const AGREE_TO_VALIDATION = (value) => agreeTo(value, boolOrNullOut, AGREE_TO_ERROR_MSG);

const getLabelForQuestion = (q) => q.required ? `${q.label} (is required)` : q.label;

const getValidation = (q) => {
  if (q.input_type === 'TEXT' || q.input_type === 'TEXTAREA') {
    return { validate: (value) => maxLength(255, value) };
  }
  return;
};

const getOptionsForQuestion = (q) => {
  if (q.input_type === 'MULTI') {
    return { formatOut: (value) => JSON.stringify(value), options: q.options.map((o) => ({ label: o, value: o })), placeholder: 'Select option(s)' };
  } else if (isInputTypeSelect(q.input_type) || isInputTypeRadio(q.input_type)) {
    return { formatOut: (value) => value, options: q.options.map((o) => ({ label: o, value: o })), placeholder: isInputTypeRadio(q.input_type) ? undefined : 'Select an option' };
  } else {
    return {};
  }
};

/**
 * Creates the states.fields object for ContestEntrySubmitForm.
 */
export function buildFields({ challenge, user }) {
  const full_name = !user.has_full_name
    ? { full_name: { order: 0, validate: (v) => maxLength(155, v), inputType: 'input', label: 'Enter your full name', value: '' } }
    : {};

  const categoryIdOrder = !user.has_full_name ? 1 : 0;
  const prize_category_ids = challenge.submission_categories.length > 0
    ? { prize_category_ids: { order: categoryIdOrder, validate: (v) => null, inputType: 'multi', label: 'Choose categories for your submission', placeholder: 'Select categories', options: challenge.submission_categories.map((c) => ({ label: c.name, value: c.id })), value: [] } }
    : {};

  // Start question order at index 1 if theres a category field.
  const questionOrder = challenge.submission_categories.length > 0 ? (categoryIdOrder + 1) : categoryIdOrder;
  const questions = challenge.submission_questions.length > 0
    ? challenge.submission_questions.reduce((acc, q, i) => {
      acc[`question_${q.id}`] = {
        order: i + questionOrder,
        validate: (v) => maxLength(255, v),
        value: '',
        id: q.id,
        inputType: resolveInputType(q.input_type),
        label: getLabelForQuestion(q),
        notRequired: !q.required,
        ...getValidation(q),
        ...getOptionsForQuestion(q),
      };

      return acc;
    }, {})
    : {};

  const agreeOrder = Object.keys(full_name).length + Object.keys(prize_category_ids).length + Object.keys(questions).length;
  const agree = { agree: { order: agreeOrder, validate: AGREE_TO_VALIDATION, value: false, customRequired: (n) => null, formatOut: boolOrNullOut } };

  // Maintain the order of the full_name, prize_category_ids and questions here so we dont have to reorder them when creating the views.
  return {
    ...full_name,
    ...prize_category_ids,
    ...questions,
    ...agree,
  };
}
