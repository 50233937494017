import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TabbedHeader from '../../../client/nav_components/tabbed_header';
import NewsArticleLightCard from '../../../client/cards/news_article/light_card';
import NewsArticleLightDummyCard from '../../../client/cards/news_article/light_card/dummy';

import styles from './news_home_page.css';

const ANALYTICS_LOCATION = 'news_page_middle';
const NAV_ITEMS = ['Latest', 'Trending'];

class TrendingSection extends Component {
  constructor(props) {
    super(props);

    this.state = { activeTabIndex: 0 };

    this.handleNavClick = this.handleNavClick.bind(this);
  }

  /**
   * Methods
   */
  handleNavClick(activeTabIndex) {
    if (activeTabIndex === 0) this.props.onLatestClick();
    if (activeTabIndex === 1 && !this.props.trending) this.props.getTrendingRecords();
    this.setState({ activeTabIndex });
  }

  /**
   * Views
   */
  _getViewForActiveIndex() {
    return this._getListView(NAV_ITEMS[this.state.activeTabIndex].toLowerCase());
  }

  _getListView(type) {
    if (type === 'trending' && !this.props.trending) return this._getLoaderView();

    return (
      <div>
        {this.props[type].map((record) => (
          <NewsArticleLightCard
            key={record.id}
            article={record}
            location={ANALYTICS_LOCATION}
            type={type}
          />
        ))}
      </div>
    );
  }

  _getLoaderView() {
    return (
      <div>
        {[...Array(7).keys()].map((n) => (<NewsArticleLightDummyCard key={n} />))}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.trendingSection}>
        <TabbedHeader
          activeIndex={this.state.activeTabIndex}
          centered={false}
          onClick={this.handleNavClick}
          tabs={NAV_ITEMS}
        />
        {this._getViewForActiveIndex()}
      </div>
    );
  }
}

TrendingSection.propTypes = {
  getTrendingRecords: PropTypes.func.isRequired,
  latest: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    published_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  onLatestClick: PropTypes.func.isRequired,
  trending: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  })),
};

TrendingSection.defaultProps = { trending: null };

export default TrendingSection;
