import React from 'react';

import AvatarButton from './AvatarButton';
import UploaderWrapper from '../UploaderWrapper';

import { filterObject } from '../../../../utility/filters';
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
/* eslint-disable-next-line no-prototype-builtins */
const viewHasProp = (val, key) => AvatarButton.propTypes.hasOwnProperty(key);
const filterProps = (props) => filterObject(props, viewHasProp);

const renderView = (props) => (<AvatarButton {...filterProps(props)} />);

const AvatarUploader = (props) => (<UploaderWrapper {...props} renderView={renderView} />);

export default AvatarUploader;
