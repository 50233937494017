import React from 'react';
import PropTypes from 'prop-types';

import ArticlesList from '../../../shared/articles_list';

import gridStyles from '../../../../../styles/global_ui/grid.css';
import layout from '../../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import typography from '../../../../../styles/global_ui/typography.css';

const LatestArticlesBottom = ({ records }) => {
  if (records && records.length === 0) return null;

  return (
    <div className={`${layout.marginTop60} ${layout.marginBottom30} ${layout.fullWidth}`}>
      <div className={`${layout.flexJustifySpaceBetween} ${layout.paddingBottom15} ${layout.marginBottom22} ${utilStyles.borderBottom}`}>
        <h5 className={typography.h5}>Latest articles</h5>
        <a className={`${typography.bodyS} ${typography.bold} ${typography.linkPebble}`} href="/news?ref=ha_rm_btn">Read more</a>
      </div>

      <ArticlesList
        articles={{ records: (records || []), metadata: {} }}
        className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
        gutterSize={15}
        isBusy={!records}
        location="latest_articles_bottom"
      />
    </div>
  );
};

LatestArticlesBottom.propTypes = { records: PropTypes.array };

LatestArticlesBottom.defaultProps = { records: null };

export default LatestArticlesBottom;
