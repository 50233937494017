import React from 'react';
import PropTypes from 'prop-types';

import ContentPlaceholder from '../../../../client/reusable_components/ContentPlaceholder';
import GridList from '../../../../client/wrappers/grid_list';
import NewsArticleCard from '../../../../client/cards/news_article';

import { transition } from '../../../../client/reusable_components/Router';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './profile.css';

const Articles = ({ articles, history, isProfileOwner, profile }) => (
  <section className={styles.sectionContainer}>
    <header className={styles.sectionHeader}>
      <span className={`${typography.bodyM} ${typography.bold}`}>Articles</span>
    </header>

    {articles.records.length > 0
      ? (
        <GridList
          ItemComponent={NewsArticleCard}
          horizontal={true}
          itemKey="article"
          itemProps={{ onUserProfile: true }}
          records={articles.records.slice(0, 8)}
        />
        )
      : (
        <ContentPlaceholder
          isProfileOwner={isProfileOwner}
          templateArgs={{ name: profile.name }}
          type="no-published-articles"
        />
        )}
    {articles.metadata.next_page
    && (
      <button
        className={`${buttonStyles.sm} ${buttonStyles.secondary} ${buttonStyles.pill} ${layout.flexAlignSelfEnd} ${layout.marginTop10}`}
        onClick={() => transition(history, '/articles')}
      >
        View all
      </button>
    )}
  </section>
);

Articles.propTypes = {
  articles: PropTypes.shape({
    metadata: PropTypes.shape({
      current_page: PropTypes.number,
      next_page: PropTypes.number,
      per_page: PropTypes.number,
      prev_page: PropTypes.number,
    }),
    records: PropTypes.array,
  }).isRequired,
  history: PropTypes.object.isRequired,
  isProfileOwner: PropTypes.bool,
  profile: PropTypes.shape({ name: PropTypes.string }),
};

Articles.defaultProps = {
  isProfileOwner: false,
  profile: { name: '' },
};

export default Articles;
