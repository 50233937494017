import React from 'react';
import PropTypes from 'prop-types';

import ButtonDropdown from '../../../../../client/buttons/button_dropdown';
import Icon from '../../../../../client/icon';

import { ENTRY, IDEA, REJECTED } from '../../../constants';

import buttonStyles from '../../../../../styles/global_ui/buttons.css';
import menuStyles from '../../../../../client/form_components/simple_select/simple_select.css';
import typography from '../../../../../styles/global_ui/typography.css';

const _isRejectedEntry = (item, type) => type === ENTRY && item.status === REJECTED;

const SubmissionMenu = ({ canEdit, item, type, withdraw }) => (
  <ButtonDropdown
    alignRight={true}
    classList={{
      button: buttonStyles.blank,
      dropdown: menuStyles.dropdown,
    }}
    closeOnSelect={true}
    label={<Icon name="view-more" />}
  >
    <ul>
      {canEdit && !_isRejectedEntry(item, type)
      && (
        <li>
          <a
            className={`${menuStyles.option} ${typography.bodyS} ${typography.link} ${typography.bold} ${typography.anchorNoUnderlineOnFocus} ${typography.anchorNoUnderlineOnHover}`}
            href={type === ENTRY ? item.project.edit_url : `${item.url}/edit`}
          >
            Edit
          </a>
        </li>
      )}
      {canEdit
      && (
        <li className={menuStyles.option} onClick={() => withdraw(item, type)}>
          Withdraw
        </li>
      )}
    </ul>
  </ButtonDropdown>
);

SubmissionMenu.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.shape({ // ENTRY
      can_be_resubmitted: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      is_prize_address_present: PropTypes.bool,
      moderator_feedback: PropTypes.string,
      prize_requires_shipping: PropTypes.bool,
      project: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        edit_url: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      status: PropTypes.string.isRequired,
      submit_url: PropTypes.string,
      updated_at: PropTypes.string.isRequired,
    }),
    PropTypes.shape({ // IDEA
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ]),
  type: PropTypes.oneOf([ENTRY, IDEA]).isRequired,
  withdraw: PropTypes.func,
};

SubmissionMenu.defaultProps = { withdraw: () => {} };

export default SubmissionMenu;
