import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContestIdeaCard from '../../../../client/cards/contest_idea_card';
import DummyContestIdeaCard from '../../../../client/cards/contest_idea_card/DummyCard';
import DummyList from '../../../../client/wrappers/grid_list/DummyList';
import GraphQLChallengeIdeasService from '../../../../services/graphql/challenge_ideas_service';
import GraphQLPaginatedList from '../../../../client/wrappers/graph_ql/GraphQLPaginatedList';
import GridList from '../../../../client/wrappers/grid_list';
import IdeaView from '../../idea/IdeaView';
import PushStateDialog from '../../../../client/wrappers/push_state_dialog';

import { clickEventHasModifierKey } from '../../../../utility/events';
import { getInObj } from '../../../../utility/accessors';

import { OPEN, WINNERS_ANNOUNCED } from '../../constants';

import layoutStyles from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './submissions.css';

class IdeasSubmissions extends Component {
  constructor(props) {
    super(props);

    this.state = { totalRecords: null };

    this.isChallenge = this.props.challenge.contest_type === 'challenge';

    this.buildPath = this.buildPath.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.renderIdeaCard = this.renderIdeaCard.bind(this);
    this.renderDialogBody = this.renderDialogBody.bind(this);
    this.renderPlaceholder = this.renderPlaceholder.bind(this);
    this.summonDialog = this.summonDialog.bind(this);

    this.graphQLService = new GraphQLChallengeIdeasService({ history: this.props.history });
  }

  buildPath(idea) {
    return `/${idea.id}`;
  }

  handleQuery(query) {
    const totalRecords = getInObj(['metadata', 'total_records'], query);
    if (totalRecords !== this.state.totalRecords) {
      this.setState({ totalRecords });
    }
  }

  renderDialogBody({ entity }) {
    return (
      <IdeaView
        contestType={this.props.challenge.contest_type}
        hardwareName={this.props.challenge.free_hardware_name}
        hideDefaultQuestions={this.props.hideDefaultHWQuestions}
        idea={entity}
      />
    );
  }

  renderDummyList() {
    return <DummyList ItemComponent={DummyContestIdeaCard} maxCols={3} />;
  }

  renderIdeaCard(props) {
    const winnersAnnounced = this.props.challenge.free_hardware_status === WINNERS_ANNOUNCED;

    return (
      <ContestIdeaCard
        {...props}
        challengeState={this.props.challenge.status}
        contestType={this.props.challenge.contest_type}
        showProjectsBeforeJudged={this.props.challenge.show_submissions_before_judged}
        winnersAnnounced={winnersAnnounced}
      />
    );
  }

  renderPlaceholder() {
    return (
      <div className={typography.bodyM}>
        {'There are no submissions yet. '}
        {this.props.challenge.free_hardware_status === OPEN && (
          <a
            className={`${typography.linkBlue} ${typography.bold}`}
            href={`${this.props.basePath}/new`}
          >
            Be the first to submit an application
          </a>
        )}
      </div>
    );
  }

  summonDialog(e, idea) {
    if (this._dialog && !clickEventHasModifierKey(e)) {
      e.preventDefault();
      this._dialog._summonDialog(idea);
    }
  }

  _getListProps() {
    return {
      ItemComponent: this.renderIdeaCard,
      maxCols: 3,
      itemProps: { onClick: this.summonDialog },
      placeholder: this.renderPlaceholder,
    };
  }

  render() {
    const shouldShowIdeas = !this.isChallenge
      || this.props.challenge.free_hardware_status === WINNERS_ANNOUNCED;
    return (
      <div id={this.props.id}>
        <h2 className={`${typography.h2} ${layoutStyles.marginBottom30}`}>
          {this.isChallenge ? 'Proposals' : 'Submissions'}
          {this.state.totalRecords !== null && ` (${this.state.totalRecords})`}
        </h2>
        {shouldShowIdeas
          ? (
            <>
              <GraphQLPaginatedList
                currentHistoryData={this.props.currentHistoryData}
                getPaginatorHook={this.props.getPaginatorHook}
                graphQLArguments={{ id: this.props.challenge.id }}
                graphQLService={this.graphQLService}
                listComponent={GridList}
                listProps={this._getListProps()}
                loaderComponent={this.renderDummyList}
                propagateQuery={this.handleQuery}
                scrollId={this.props.id}
                validateRefetch={this.props.validatePaginatorRefetch}
              />
              <PushStateDialog
                ref={(c) => (this._dialog = c)}
                BodyComponent={this.renderDialogBody}
                buildPath={this.buildPath}
                history={this.props.history}
                wrapperClassName={styles.dialogWrapper}
              />
            </>
            )
          : (
            <p>Proposals are hidden until contenders are announced.</p>
            )}
      </div>
    );
  }
}

IdeasSubmissions.propTypes = {
  basePath: PropTypes.string.isRequired,
  challenge: PropTypes.shape({
    contest_type: PropTypes.oneOf(['challenge', 'competition']),
    end_date: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    free_hardware_name: PropTypes.string,
    free_hardware_quantity: PropTypes.number.isRequired,
    free_hardware_status: PropTypes.string.isRequired,
    hideDefaultHWQuestions: PropTypes.bool.isRequired,
    show_submissions_before_judged: PropTypes.bool,
    status: PropTypes.string.isRequired,
    support_email: PropTypes.string.isRequired,
  }).isRequired,
  currentHistoryData: PropTypes.object,
  getPaginatorHook: PropTypes.func,
  history: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

IdeasSubmissions.defaultProps = {
  currentHistoryData: null,
  getPaginatorHook: null,
};

export default IdeasSubmissions;
