import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import SurveyPopup from '../SurveyPopup';
import { QUESTION_NAME, SURVEY_SLUG } from '../SurveyPopup/enums';
import TooltipTarget from '../TooltipTarget';
import { angry, happy, love, neutral, sad } from '../icons/rating_svgs';

import { graphMutateWithoutUser } from '../../../requests/graphql';
import { summonGlobalMessenger } from '../../../utility/dispatchers';

import layout from '../../../styles/global_ui/layout';
import typography from '../../../styles/global_ui/typography';
import util from '../../../styles/global_ui/util';
import style from './feedback.css';

const VERSION = 1.0;

const surveyJson = {
  title: SURVEY_SLUG.PASSIVE_GENERAL_FEEDBACK,
  version: VERSION,
  showTitle: false,
  showQuestionNumbers: false,
  completeText: 'Submit',
  pagePrevText: '< Back',
  completedHtmlOnCondition: [
    {
      expression: `{${QUESTION_NAME.FEELING}} == 1`,
      html: `<h4>Thank you!<span>Your feedback was received.</span></h4><div>${angry}</div><p>{feedback}</p>`,
    },
    {
      expression: `{${QUESTION_NAME.FEELING}} == 2`,
      html: `<h4>Thank you!<span>Your feedback was received.</span></h4><div>${sad}</div><p>{feedback}</p>`,
    },
    {
      expression: `{${QUESTION_NAME.FEELING}} == 3`,
      html: `<h4>Thank you!<span>Your feedback was received.</span></h4><div>${neutral}</div><p>{feedback}</p>`,
    },
    {
      expression: `{${QUESTION_NAME.FEELING}} == 4`,
      html: `<h4>Thank you!<span>Your feedback was received.</span></h4><div>${happy}</div><p>{feedback}</p>`,
    },
    {
      expression: `{${QUESTION_NAME.FEELING}} == 5`,
      html: `<h4>Thank you!<span>Your feedback was received.</span></h4><div>${love}</div><p>{feedback}</p>`,
    },
  ],
  pages: [
    {
      name: 'Feeling and Comment',
      title: 'Help us improve Hackster!',
      elements: [
        {
          type: 'rating',
          name: `${QUESTION_NAME.FEELING}`,
          title: 'How was your recent experience with Hackster?',
          itemComponent: 'RobotRating',
          rateCount: 5,
          rateMax: 5,
          displayMode: 'buttons', // this forces options to be inline on one row.
          isRequired: true,
          errorLocation: 'bottom',
        },
        {
          type: 'comment',
          name: `${QUESTION_NAME.FEEDBACK}`,
          title: 'Feedback',
          isRequired: true,
          rows: 4,
          width: '100%',
          errorLocation: 'bottom',
        },
      ],
    },
  ],
};

const c = {
  popupContainer: `${style.popupContainer}`,
  container: `${layout.flexColumn} ${layout.gutter10} ${style.container}`,
  iconWrapper: `${style.iconWrapper}`,
};

const customCss = { completedPage: c.container };

const FeedbackSurvey = ({ align, alignOffset, sideOffset }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTip, setShowTip] = useState(!isOpen);
  const [isDisabled, setIsDisabled] = useState(false);
  const saveSurveyResults = useCallback(({ content }) => {
    const response = {
      content: JSON.stringify(content),
      survey_slug: SURVEY_SLUG.PASSIVE_GENERAL_FEEDBACK,
      version: VERSION,
    };

    graphMutateWithoutUser({ t: 'create_survey_result' }, { result: response })
      .catch((err) => {
        summonGlobalMessenger({ msg: 'Oops, something went wrong. Try again later.', type: 'error' });
        return err;
      });
  }, []);

  const onOpenChange = useCallback((isOpening) => {
    setShowTip(!isOpening);
    setIsOpen(isOpening);
  }, [setIsOpen, setShowTip]);

  // We need to disable the popup if any auto opening survey is mounted. If more than one survey is initialized, the data might get tied to the wrong survey model
  useEffect(() => {
    document.addEventListener('openSurvey', () => setIsDisabled(true));
    document.addEventListener('closeSurvey', () => setIsDisabled(false));

    return () => {
      document.removeEventListener('openSurvey');
      document.removeEventListener('closeSurvey');
    };
  }, []);

  return (
    <TooltipTarget classList={{ tooltipInner: typography.lineHeight1 }} offsetY={6} tooltip={showTip ? 'Leave Feedback' : null}>
      <SurveyPopup
        onSubmit={saveSurveyResults}
        popup={{
          align,
          alignOffset,
          classList: { container: c.popupContainer, trigger: c.iconWrapper },
          hasClose: true,
          open: isOpen,
          onOpenChange,
          sideOffset,
          disabled: isDisabled,
          trigger: <Icon className={`${typography.iconClickableStaticWhite}`} disabled={isDisabled} id={`${SURVEY_SLUG.PASSIVE_GENERAL_FEEDBACK}-icon`} name="chat-outline" size={16} />,
          triggerAria: 'Leave Feedback',
        }}
        surveyCss={customCss}
        surveyJson={surveyJson}
      />
    </TooltipTarget>

  );
};

export default FeedbackSurvey;

FeedbackSurvey.propTypes = {
  align: PropTypes.string,
  alignOffset: PropTypes.number,
  sideOffset: PropTypes.number,
};
