import { DEFAULT_INDEX } from './constants';

const INDEX_KEYWORDS = { projects: ['project', 'projects'] };

export function filterSearchQuery(query = '', index = DEFAULT_INDEX) {
  const keywords = INDEX_KEYWORDS[index];
  if (!keywords) return query;

  return query.split(' ').filter((word) => word.length > 0 && !keywords.includes(word)).join(' ');
}

/* apply once keys have been normalized to "query" and "index" */
export function filterSearchQueryInMap(map = {}) {
  return {
    ...map,
    query: filterSearchQuery(map.query, map.index),
  };
}
