import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon from '../../../client/icon';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const ALLOWED_CONTENT_TYPES = ['tutorial', 'showcase', 'wip', 'protip', 'getting_started', 'teardown'];

export default class ContentTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = this.computeState(this.props.initialContentType, this.props.initialHasError);
  }

  computeState(contentType, hasError) {
    const isProtip = contentType ? ['protip', 'getting_started', 'teardown'].indexOf(contentType) > -1 : null;
    const isWip = isProtip !== false || contentType === 'select_wip' ? null : contentType === 'wip';
    const isTutorial = isWip !== false || contentType === 'select_tutorial' ? null : contentType === 'tutorial';

    return {
      contentType: (ALLOWED_CONTENT_TYPES.indexOf(contentType) > -1 ? contentType : ''),
      hasError: hasError,
      isProtip: isProtip,
      isWip: isWip,
      isTutorial: isTutorial,
    };
  }

  handleChange(e) {
    const states = this.computeState(e.target.value, false);
    this.setState(states);
  }

  _renderProjectType() {
    const classes = ['form-group'];
    let errorMsg = null;
    if (this.state.hasError && this.state.isProtip === null) {
      classes.push('has-error');
      errorMsg = (<p className="help-block">is required for publication</p>);
    }

    return (
      <div className={classes.join(' ')}>
        <div className="col-md-4">
          <label className="control-label">Project type</label>
        </div>
        <div className="col-md-8">
          <div className="radio small">
            <label>
              <input checked={this.state.isProtip === false} name="protip" onChange={this.handleChange} type="radio" value="select_wip" />
              <div>
                <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="protip" />
                <span>I am documenting how I built a project</span>
              </div>
              <div className="small text-muted">
                Examples: a weather station, a smart thermostat
              </div>
            </label>
          </div>
          <div className="radio small">
            <label>
              <input checked={this.state.isProtip === true} name="protip" onChange={this.handleChange} type="radio" value="protip" />
              <div>
                <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="showcase" />
                <span>I am solely describing how to use a component or application</span>
              </div>
              <div className="small text-muted">
                Examples: how to control a motor with Arduino Uno, get started with Raspberry Pi
              </div>
            </label>
          </div>
          {errorMsg}
        </div>
      </div>
    );
  }

  _renderProgress() {
    if (this.state.isProtip !== false) {
      return;
    }

    const classes = ['form-group'];
    let errorMsg = null;
    if (this.state.hasError && this.state.isWip === null) {
      classes.push('has-error');
      errorMsg = (<p className="help-block">is required for publication</p>);
    }

    return (
      <div className={classes.join(' ')}>
        <div className="col-md-4">
          <label className="control-label">Progress</label>
        </div>
        <div className="col-md-8">
          <div className="radio small">
            <label>
              <input checked={this.state.isWip === true} name="wip" onChange={this.handleChange} type="radio" value="wip" />
              <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="wip" />
              <span>I am still working on my project</span>
            </label>
          </div>
          <div className="radio small">
            <label>
              <input checked={this.state.isWip === false} name="wip" onChange={this.handleChange} type="radio" value="select_tutorial" />
              <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="thumbs-up" />
              <span>My project is complete</span>
            </label>
          </div>
          {errorMsg}
        </div>
      </div>
    );
  }

  _renderInstructions() {
    if (this.state.isWip !== false) {
      return;
    }

    const classes = ['form-group'];
    let errorMsg = null;
    if (this.state.hasError && this.state.isTutorial === null) {
      classes.push('has-error');
      errorMsg = (<p className="help-block">is required for publication</p>);
    }

    return (
      <div className={classes.join(' ')}>
        <div className="col-md-4">
          <label className="control-label">Instructions</label>
        </div>
        <div className="col-md-8">
          <div className="radio small">
            <label>
              <input checked={this.state.isTutorial === true} name="instructions" onChange={this.handleChange} type="radio" value="tutorial" />
              <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="full-instructions" />
              <span>My project provides full instructions</span>
            </label>
          </div>
          <div className="radio small">
            <label>
              <input checked={this.state.isTutorial === false} name="instructions" onChange={this.handleChange} type="radio" value="showcase" />
              <Icon className={`${typography.iconBaselineFix} ${layout.marginRight5}`} name="file" />
              <span>My project provides no or partial instructions</span>
            </label>
          </div>
          {errorMsg}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this._renderProjectType()}
        {this._renderProgress()}
        {this._renderInstructions()}
        <input name="project[content_type]" type="hidden" value={this.state.contentType} />
      </div>
    );
  }
}

ContentTypeSelector.propTypes = {
  initialContentType: PropTypes.string,
  initialHasError: PropTypes.bool.isRequired,
};

ContentTypeSelector.defaultProps = { initialContentType: '' };
