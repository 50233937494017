import React from 'react';
import { ReactSurveyElement } from 'survey-react-ui';

import typography from '../../../styles/global_ui/typography';
import util from '../../../styles/global_ui/util';
import style from './radio.css';

const c = {
  label: ({ isChecked }) => [style.label, isChecked ? style.checked : ''].join(' '),
  radioButton: `${util.srOnly}`,
  text: ({ hasMany }) => [typography.lakeToAsphalt, typography.bold, hasMany ? typography.bodyXXS : typography.bodyXS].join(' '),
};

class RadioGroup extends ReactSurveyElement {
  renderElement() {
    const { question, item, survey } = this.props;
    const { text, value } = item;

    if (!text || !value) {
      throw new Error('choices must contain text and value properties');
    }

    const handleOnChange = () => {
      survey.setValue(question.name, item.value);
    };

    const isChecked = question.isItemSelected(item);

    return (
      <label
        className={c.label({ isChecked })}
      >
        <div className={c.text({ hasMany: question.choices.length > 2 })}>{text}</div>
        <input
          aria-checked={isChecked}
          className={c.radioButton}
          name={text}
          onClick={handleOnChange}
          required="Must select one"
          role="radio"
          type="radio"
          value={value}
        />
      </label>
    );
  }
}

export default RadioGroup;
