import PropTypes from 'prop-types';
import React from 'react';

import EventCardVertical from '../../../client/cards/event_card/EventCardVertical';
import GridList from '../../../client/wrappers/grid_list';

import gridStyles from '../../../styles/global_ui/grid.css';
import layout from '../../../styles/global_ui/layout.css';

const mapRecords = (records) => records.map((record) => ({ ...record, image: { url: record.image_url } }));

const EventsList = ({ results }) => (
  <div className={`${layout.marginTop30} ${layout.marginBottom30}`}>
    <GridList
      ItemComponent={EventCardVertical}
      className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
      gutterSize={15}
      itemProps={{ responsive: true }}
      records={mapRecords(results)}
    />
  </div>
);

export default EventsList;

EventsList.propTypes = { results: PropTypes.array };

EventsList.defaultProps = { results: [] };
