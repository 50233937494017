import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GraphQLPaginatedList from '../../../client/wrappers/graph_ql/GraphQLPaginatedList';
import ProjectsList from '../../../client/projects/projects_list';
import DummyProjectsList from '../../../client/projects/projects_list/DummyProjectsList';
import ProductWrapper from './ProductWrapper';

import GraphQLProjectsService from '../../../services/graphql/projects_service';
import styles from './product.css';

class ProductProjects extends Component {
  constructor(props) {
    super(props);

    this.state = { part: null };

    this.reportPageView = this.reportPageView.bind(this);
    this.qlService = new GraphQLProjectsService({ history: this.props.history });
  }

  reportPageView() {
    this.props.seoHandler(this.props.path, this.state.part);
  }

  render() {
    return (
      <ProductWrapper
        canManage={this.props.canManage}
        graphQLArguments={this.props.graphQLArgumentsParts}
        history={this.props.history}
        path={this.props.path}
        platformId={this.props.platformId}
        propagatePart={(part) => this.setState({ part })}
        transition={this.props.transition}
      >
        {this.state.part
        && (
          <GraphQLPaginatedList
            currentHistoryData={this.props.currentHistoryData}
            filters={this.props.filters}
            graphQLArguments={{ ...this.props.graphQLArgumentsProjects, part_id: this.state.part.id, per_page: 9 }}
            graphQLService={this.qlService}
            listComponent={ProjectsList}
            listProps={{
              cardBorder: true,
              className: styles.listWrapper,
              maxCols: 3,
            }}
            loaderComponent={DummyProjectsList}
            reportPageView={this.reportPageView}
          />
        )}
      </ProductWrapper>
    );
  }
}

ProductProjects.propTypes = {
  canManage: PropTypes.bool,
  currentHistoryData: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  })).isRequired,
  graphQLArgumentsParts: PropTypes.shape({
    platform_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    platform_user_name: PropTypes.string,
  }),
  graphQLArgumentsProjects: PropTypes.shape({ platform_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  history: PropTypes.object, // Router history
  path: PropTypes.string.isRequired,
  platformId: PropTypes.number.isRequired,
  seoHandler: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};

ProductProjects.defaultProps = {
  canManage: false,
  currentHistoryData: null,
  history: {},
  initFacets: [],
};

export default ProductProjects;
