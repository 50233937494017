import PropTypes from 'prop-types';
import React from 'react';

import InfiniteScroll from '../../../reusable_components/InfiniteScroll';
import Loader from '../../../reusable_components/Router/Loader';
import PageHeader from '../../page_header';
import EditableProjectsList from '../../../projects/projects_list/EditableProjectsList';

import layout from '../../../../styles/global_ui/layout.css';
import styles from '../dashboard/dashboard.css';

const ProjectsCategory = ({ category, currentUser, handleReorder, loadMore, projects, toggleRespect }) => {
  const loading = !projects;
  const numProjects = loading ? null : projects.metadata.total_records;

  return (
    <div key={category} className={layout.container}>
      <div className={layout.wrapper960}>
        <PageHeader
          type="projects"
          typeArgs={{ numProjects, category, singleCategory: true }}
        />
        {loading
          ? <Loader />
          : (
            <InfiniteScroll
              classList={{ container: styles.projectList }}
              fetchMore={loadMore}
              recordsCount={projects.records.length}
              totalRecordsCount={numProjects}
            >
              <EditableProjectsList
                cardBorder={true}
                handleReorder={handleReorder}
                maxCols={3}
                orderable={category === 'public'}
                records={projects.records}
                toggleRespect={toggleRespect}
              />
            </InfiniteScroll>
            )}
      </div>
    </div>
  );
};

ProjectsCategory.propTypes = {
  category: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({ id: PropTypes.number }),
  handleReorder: PropTypes.func,
  loadMore: PropTypes.func,
  projects: PropTypes.shape({
    records: PropTypes.array,
    metadata: PropTypes.object,
  }),
  toggleRespect: PropTypes.func,
};

ProjectsCategory.defaultProps = {
  currentUser: {},
  handleReorder: () => Promise.resolve(),
  loadMore: () => Promise.resolve(),
  projects: {
    records: [],
    metadata: { total_records: 0 },
  },
  toggleRespect: () => Promise.resolve(),
};

export default ProjectsCategory;
