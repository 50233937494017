/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import queryTranslator from '../queryTranslator';
import { DEFAULT_INDEX } from '../constants';
import { mapifyStringQuery } from '../../../utility/converters';
import { filterSearchQueryInMap } from '../filterSearchQuery';
import { LOCATION_KEYS } from '../filters/location';

const arrayFields = {
  category: true,
  difficulty: true,
  language: true,
  parts: true,
  project_type: true,
  skills: true,
};

function tryParse(val) {
  try {
    return JSON.parse(val);
  } catch (err) {
    return null;
  }
}

function createMap(queryStr) {
  const queryMap = mapifyStringQuery(queryStr);

  return Object.keys(queryMap).reduce((acc, key) => {
    if (arrayFields.hasOwnProperty(key)) {
      // Create arrays.
      acc[key] = queryMap[key].split(',');
    } else if (LOCATION_KEYS.includes(key)) {
      // Create location object, queryTranslator will validate it later on.
      const location = acc.location || {};
      location[key] = tryParse(queryMap[key]) ? JSON.parse(queryMap[key]) : queryMap[key];
      acc.location = location;
    } else {
      acc[key] = queryMap[key];
    }

    return acc;
  }, {});
}

export default function queryStringToNormalized(queryStr) {
  const queryMap = createMap(queryStr);

  if (!queryMap.hasOwnProperty('i') && !queryMap.hasOwnProperty('index')) {
    queryMap.index = DEFAULT_INDEX;
  }

  return filterSearchQueryInMap(queryTranslator(queryMap));
}
