import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../../../buttons/anchor';
import DummyList from '../../../wrappers/grid_list/DummyList';
import ConfirmEmailMessage from '../../../reusable_components/ConfirmEmailMessage';
import DummyBaseCardVertical from '../../../cards/base_card/vertical/DummyBaseCardVertical';
import EventCardVertical from '../../../cards/event_card/EventCardVertical';
import GridList from '../../../wrappers/grid_list';

import GraphQLEventsService from '../../../../services/graphql/events_service';
import GraphQLPaginatedList from '../../../wrappers/graph_ql/GraphQLPaginatedList';

import currentUserService from '../../../../services/current_user';
import errorHandler from '../../../../services/error_handler';
import { summonGlobalDialog } from '../../../../utility/dispatchers';

import dashboardStyles from '../dashboard/dashboard.css';
import headerStyles from '../../page_header/page_header.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

const NEW_EVENT_URL = '/events/new?redirect_to=dashboard';

const renderDummyList = () => (
  <DummyList ItemComponent={() => (<DummyBaseCardVertical imageAspectRatio="3:2" />)} />
);

class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentHistoryData: null,
      currentUser: null,
    };

    this.handleNewEventClick = this.handleNewEventClick.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.qlService = new GraphQLEventsService({ history: props.history });

    this._isMounted;
    this.unlisten;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._isMounted = true;
    this.unlisten = this.props.history.listen(this.handleLocationChange);
    this._initCurrentUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (typeof this.unlisten === 'function') this.unlisten();
  }

  /**
   * Initializers
   */
  _initCurrentUser() {
    return currentUserService.getStoreAsync()
      .then((currentUser) => this._setStateIfMounted({ currentUser }))
      .catch((err) => errorHandler(err));
  }

  /**
   * Methods
   */
  handleLocationChange(pathData, action) {
    this._setStateIfMounted({ currentHistoryData: { ...pathData, action } });
  }

  handleNewEventClick(e) {
    if (!this.state.currentUser.isConfirmed) {
      e.preventDefault();

      return summonGlobalDialog({ getChildren: () => (<ConfirmEmailMessage actionText="create an event" />) });
    }
  }

  /**
   * Helpers
   */
  _getNewEventHref() {
    return this.state.currentUser ? NEW_EVENT_URL : '#';
  }

  _setStateIfMounted(state, cb) {
    if (this._isMounted) this.setState(state, cb);
  }

  /**
   * Views
   */
  _getNoResultsPlaceholder() {
    return (
      <span className={typography.bodyM}>
        {'You have no events. '}
        <a
          className={`${typography.linkBlue} ${this.state.currentUser ? '' : typography.linkDisabled}`}
          href={this._getNewEventHref()}
          onClick={this.handleNewEventClick}
        >
          Create one
        </a>
        {' now.'}
      </span>
    );
  }

  // NOTE: We only ever want to render a Status indicator in a User's dashboard for the EventCards, so we let the parent create the view here vs the cards themselves.
  _getStatusBadge(event) {
    if (event.approved) return null;

    return (<div className={`${layout.marginTop5} ${typography.error}`}>Under review</div>);
  }

  render() {
    return (
      <div className={layout.container}>
        <div className={`${layout.wrapper1170} ${layout.marginBottom30}`}>
          <header className={layout.marginBottom22}>
            <h2 className={`${headerStyles.pageHeader} ${layout.marginTop0} ${layout.marginBottom15}`}>
              Events
            </h2>
            <AnchorButton
              disabled={!this.state.currentUser}
              href={this._getNewEventHref()}
              onClick={this.handleNewEventClick}
            >
              Create a new event
            </AnchorButton>
          </header>
          <section>
            <header className={`${dashboardStyles.sectionHeader} ${dashboardStyles.headerTitle}`}>
              Published events
            </header>
            <GraphQLPaginatedList
              currentHistoryData={this.state.currentHistoryData}
              graphQLArguments={{ by_current_user: 'true' }}
              graphQLService={this.qlService}
              listComponent={GridList}
              listProps={{
                ItemComponent: EventCardVertical,
                gutterSize: 30,
                itemProps: { editable: true, redirectTo: 'dashboard', statusBadge: (event) => this._getStatusBadge(event) },
                placeholder: () => this._getNoResultsPlaceholder(),
              }}
              loaderComponent={renderDummyList}
            />
          </section>
        </div>
      </div>
    );
  }
}

Events.propTypes = {
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  store: PropTypes.shape({ get: PropTypes.func.isRequired }).isRequired,
};

export default Events;
