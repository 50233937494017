import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CameraIcon from '../../../reusable_components/icons/Camera';
import { getErrorHelperViewWithoutWrapper } from '../../templates';

import typography from '../../../../styles/global_ui/typography.css';
import styles from './avatar.css';

class AvatarButton extends PureComponent {
  render() {
    const { disabled, errors, handleUploadBtnClick, imageData, isBusy, warningText } = this.props;

    return (
      <div className={styles.columnCenter}>
        <div
          className={styles.image}
          onClick={disabled ? null : handleUploadBtnClick}
          style={{ backgroundImage: `url(${imageData.url})` }}
        >
          {/* TODO: spinner on isBusy? */}
          <CameraIcon className={styles.camera} />
          <p className={styles.text}>{isBusy ? 'Updating' : 'Update'}</p>
          <p className={styles.text}>{`photo${isBusy ? '...' : ''}`}</p>
        </div>
        <div className={typography.textCenter}>
          {getErrorHelperViewWithoutWrapper(errors, null, warningText)}
        </div>
      </div>
    );
  }
}

AvatarButton.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  handleUploadBtnClick: PropTypes.func,
  imageData: PropTypes.shape({ url: PropTypes.string }).isRequired,
  isBusy: PropTypes.bool,
};

AvatarButton.defaultProps = {
  disabled: false,
  errors: '',
  handleUploadBtnClick: () => {},
  imageData: {},
  isBusy: false,
};

export default AvatarButton;
