/** Publication types */
export const PRIVATE = 'PRIVATE';
export const PUBLIC = 'PUBLIC';
export const PUBLISHED = 'PUBLISHED';
export const UNLISTED = 'UNLISTED';

/** Sort types */
export const AUTHOR = 'AUTHOR';
export const DATE_ASC = 'DATE_ASC';
export const DATE_DESC = 'DATE_DESC';
export const FEATURED_DESC = 'FEATURED_DESC';
export const POPULARITY = 'POPULARITY';
export const RESPECTS = 'RESPECTS';
export const TRENDING = 'TRENDING';
export const UPDATE_DATE = 'UPDATE_DATE';
