import React from 'react';
import DummyPlatformMemberCard from '../cards/platform_member_card/DummyPlatformMemberCard';
import DummyList from '../../wrappers/grid_list/DummyList';

const DummyPlatformMemberList = () => (
  <div>
    <div style={{ height: 30 }} />
    <DummyList ItemComponent={DummyPlatformMemberCard} />
  </div>
);

export default DummyPlatformMemberList;
