import GraphQLBaseService from '../base_service';
import queryBuilder from './query_builder';

import { getUserIdFromBody } from '../../../services/window';
import { mapifyStringQuery } from '../../../utility/converters';

const queryString = () => ({ t: 'events_with_simple_pagination' });

export default class GraphQLEventsService extends GraphQLBaseService {
  constructor({ history } = {}) {
    super({ history, queryBuilder, queryString });
  }

  // overriden, allowing multiple filters to be set at once
  _appendFilterStringToQueryMap(queryMap, filterString, removePage) {
    const newFilters = mapifyStringQuery(filterString);

    Object.keys(newFilters).forEach((key) => {
      // Filter will be null if its the default, we dont want to do anything with a null value, so remove it!
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
      if (newFilters[key] === 'null' && queryMap.hasOwnProperty(key)) {
        delete queryMap[key];
        /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
        /* eslint-disable-next-line no-prototype-builtins */
      } else if ((key === 'page' && newFilters[key] === '1' && queryMap.hasOwnProperty('page'))) {
        delete queryMap.page;
      } else if (newFilters[key] !== 'null') {
        queryMap[key] = newFilters[key];
      }
    });

    // removePage if a new filter is chosen.
    if (removePage && queryMap.page) {
      delete queryMap.page;
    }

    return Promise.resolve(queryMap);
  }

  // overridden so we can bypass the GraphQL request if necessary
  search(query, params) {
    return new Promise((resolve, reject) => {
      if (this._shouldBypassRequest(params)) {
        return this._bypassRequest()
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      }

      return resolve(super.search(query, params));
    });
  }

  _bypassRequest() {
    return this.setCurrentHistoryAndClearNext(this._resolveQLResponse(this._getEmptyResult()));
  }

  // If we are requesting the current user's events, and there's no current user,
  // skip the request and send an empty result
  _shouldBypassRequest(params = {}) {
    return !!params.by_current_user && getUserIdFromBody() === null;
  }

  _getEmptyResult() {
    return {
      metadata: { current_page: 1, next_page: null, per_page: 20, prev_page: null },
      records: [],
    };
  }
}
