import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ButtonDropdown from '../../../client/buttons/button_dropdown';
import Icon from '../../../client/icon';

import styles from './admin_menu.css';
import dropdownStyles from '../../../styles/common-dropdown.css';
import buttonStyles from '../../../styles/global_ui/buttons.css';

class AdminMenu extends Component {
  constructor(props) {
    super(props);
  }

  _renderOptions() {
    return this.props.menu.map((item, i) => (
      <li key={i} className={dropdownStyles.listItem}>
        <a className={dropdownStyles.link} href={item.url}>{item.label}</a>
      </li>
    ));
  }

  _renderLabel(open = false) {
    return (
      <span className={styles.label}>
        <i aria-hidden="true" className={`${styles.cog} fa fa-cog`} />
        <span className={styles.labelText}>Settings</span>
        <Icon className={`${styles.arrow} ${open && styles.arrowUp}`} name="arrow-down" />
      </span>
    );
  }

  render() {
    if (!this.props.canManage) return null;

    return (
      <ButtonDropdown
        alignRight={true}
        classList={{
          button: `${buttonStyles.sm} ${buttonStyles.secondary}`,
          wrapper: styles.buttonWrapper,
        }}
        label={this._renderLabel(false)}
        labelOpen={this._renderLabel(true)}
      >
        <ul className={dropdownStyles.list}>
          {this._renderOptions()}
        </ul>
      </ButtonDropdown>
    );
  }
}

AdminMenu.propTypes = {
  canManage: PropTypes.bool,
  menu: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
};

AdminMenu.defaultProps = { canManage: null };

export default AdminMenu;
