import request from 'superagent';
import OAuth from '../services/oauth';

export function updateChallengeRegistration(data) {
  return OAuth.apiRequest(
    request
      .patch(`${OAuth.getApiPath()}/private/challenge_registrations`)
      .send(data),
    true,
    false,
  );
}
