import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../../client/buttons/anchor';
import ChannelCard from '../../client/cards/channel_card';
import GraphQLPaginatedList from '../../client/wrappers/graph_ql/GraphQLPaginatedList';
import GridList from '../../client/wrappers/grid_list';

import GraphQLChannelsService from '../../services/graphql/channels_service';
import createHistory from '../../client/reusable_components/Router/history';
import currentUserService from '../../services/current_user';
import errorHandler from '../../services/error_handler';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';

class ChannelsPage extends Component {
  constructor(props) {
    super(props);

    this.state = { currentUser: null };

    this.history = createHistory(props.base_path);
    this.graphQLService = new GraphQLChannelsService({ history: this.history, initChannels: props.channels });
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._getCurrentUser();
  }

  /**
   * Initializers
   */
  _getCurrentUser() {
    return currentUserService.getStoreAsync()
      .then((currentUser) => this.setState({ currentUser }))
      .catch((err) => errorHandler('ChannelsPage _getCurrentUser', err));
  }

  /**
   * Views
   */
  _getNewChannelButtonView() {
    if (!this.state.currentUser || !this.props.launch_config) return null;

    return (
      <div className={`${layout.flexCenterItems} ${layout.flexRowWrap}`}>
        <p className={`${typography.bodyM} ${layout.marginRight15}`}>{this.props.launch_config.text}</p>

        <AnchorButton href={this.props.launch_config.path}>
          Launch it
        </AnchorButton>
      </div>
    );
  }

  render() {
    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          <header className={`${layout.flexJustifySpaceBetween} ${layout.flexRowWrap} ${layout.marginBottom60}`}>
            <h1 className={typography.h1}>{this.props.title}</h1>
            {this._getNewChannelButtonView()}
          </header>

          <GraphQLPaginatedList
            filters={this.props.filters}
            graphQLArguments={{ type: this.props.type }}
            graphQLService={this.graphQLService}
            listComponent={GridList}
            listProps={{
              ItemComponent: ChannelCard,
              gutterSize: 30,
            }}
          />
        </div>
      </div>
    );
  }
}

ChannelsPage.propTypes = {
  base_path: PropTypes.string.isRequired,
  channels: PropTypes.shape({
    metadata: PropTypes.shape({
      current_page: PropTypes.number.isRequired,
      next_page: PropTypes.number,
      per_page: PropTypes.number.isRequired,
      prev_page: PropTypes.number,
    }).isRequired,
    records: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      is_new: PropTypes.bool.isRequired,
      members_count: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      pitch: PropTypes.string.isRequired,
      projects_count: PropTypes.number.isRequired,
      type: PropTypes.oneOf(['Community', 'Platform', 'TopicChannel']).isRequired,
      url: PropTypes.string.isRequired,
    })).isRequired,
  }),
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  })).isRequired,
  launch_config: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['COMMUNITY', 'PLATFORM', 'TOPIC_CHANNEL']),
};

ChannelsPage.defaultProps = {
  launch_config: null,
  type: null,
};

export default ChannelsPage;
