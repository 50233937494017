import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GraphQLPaginatedList from '../../client/wrappers/graph_ql/GraphQLPaginatedList';
import ProjectsList from '../../client/projects/projects_list';
import DummyProjectsList from '../../client/projects/projects_list/DummyProjectsList';

import GraphQLProjectsService from '../../services/graphql/projects_service';

import SeoHandler from '../../services/seo_handler';
import createHistory from '../../client/reusable_components/Router/history';
import seoConfig from './seoConfig';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';

class ProjectsPage extends Component {
  constructor(props) {
    super(props);

    this.state = { currentHistoryData: null };

    this.history = createHistory(props.pathHelpers.basePath);
    this.qlService = new GraphQLProjectsService({ history: this.history });

    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.seoHandler = new SeoHandler({ config: seoConfig });
  }

  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
    this._updateSEOData();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleLocationChange(pathData, action) {
    const historyData = { ...pathData, action };
    this.setState({ currentHistoryData: historyData });
    this._updateSEOData();
  }

  _updateSEOData() {
    this.seoHandler.reportView({ path: '/', data: this.history.location });
  }

  render() {
    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          <h1 className={`${typography.h1Responsive} ${layout.marginBottom30} ${layout.paddingBottom15} ${utilStyles.borderBottom}`}>
            Explore projects
          </h1>
          <GraphQLPaginatedList
            currentHistoryData={this.state.currentHistoryData}
            filters={this.props.filters}
            graphQLArguments={{ approved: true }}
            graphQLService={this.qlService}
            listComponent={ProjectsList}
            listProps={{ cardBorder: true }}
            loaderComponent={DummyProjectsList}
          />
        </div>
      </div>
    );
  }
}

ProjectsPage.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  })).isRequired,
};

ProjectsPage.defaultProps = {};

export default ProjectsPage;
