import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InterestSection from './InterestSection';

// Note: Simple wrapper to fetch additional/different options outside of the initial SSR'd options.
const InterestSectionAsync = ({ getOptions, ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let ignore = false;

    getOptions()
      .then((fetchedOpts) => { if (!ignore) setOptions(fetchedOpts); });

    return () => {
      ignore = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const propsWithOpts = { ...props, options };
  return (<InterestSection {...propsWithOpts} />);
};

InterestSectionAsync.propTypes = {
  createInterest: PropTypes.func.isRequired,
  deleteInterest: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  interests: PropTypes.shape({
    channels: PropTypes.arrayOf(PropTypes.number).isRequired,
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  isBusy: PropTypes.bool.isRequired,
  sectionType: PropTypes.string.isRequired,
};

export default InterestSectionAsync;
