import GraphQLBaseService from '../base_service';
import queryBuilder from './query_builder';
import errorHandler from '../../../services/error_handler';

const QUERY_TEMPLATE = { t: 'get_channel_members' };
const TYPE_TO_QUERY = {
  community: () => QUERY_TEMPLATE,
  platform: () => QUERY_TEMPLATE,
  topic: () => QUERY_TEMPLATE,
};

function _getQueryForType(type) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  if (!TYPE_TO_QUERY.hasOwnProperty(type)) {
    errorHandler(`GraphQLMembersService expected a known type, instead received: ${type}.  Known types: ${Object.keys(TYPE_TO_QUERY)}`);

    return () => '';
  }

  return TYPE_TO_QUERY[type];
}

export default class GraphQLMembersService extends GraphQLBaseService {
  constructor({ history, type } = {}) {
    super({ history, queryBuilder, queryString: _getQueryForType(type) });
  }
}
