import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../link/AnalyticsLink';
import OptionalMobileLazyBgImageView from '../templates/OptionalMobileLazyBgImageView';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './base_card_horizontal_lg.css';

const EVENT_CARD_RATIO_CONFIG = {
  image: {
    ratio: '3:2',
    width: 340,
  },
  mobileImage: {
    ratio: '1:1',
    width: 120,
  },
};

// NOTE: 11-4-2021
// This Component is specific to EventCards. Mind the hard set ratio and width.
const BaseCardHorizontalLarge = ({ className, item, linkProps, renderBody }) => (
  <div className={`${styles.card} ${layout.fullWidth} ${typography.overflowBreakWord} ${className}`}>
    <AnalyticsLink
      className={`${styles.image} ${utilStyles.posRelative}`}
      item={item}
      {...linkProps}
    >
      <OptionalMobileLazyBgImageView config={EVENT_CARD_RATIO_CONFIG} item={item} />
    </AnalyticsLink>
    <div className={styles.text}>
      {renderBody()}
    </div>
  </div>
);

BaseCardHorizontalLarge.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
  }).isRequired,
  linkProps: PropTypes.shape({ linkType: PropTypes.string.isRequired }).isRequired,
  renderBody: PropTypes.func,
};

BaseCardHorizontalLarge.defaultProps = {
  className: '',
  renderBody: () => null,
};

export default BaseCardHorizontalLarge;
