import React from 'react';
import PropTypes from 'prop-types';

import GridList from '../../../client/wrappers/grid_list';
import VideoCard from '../../../client/cards/video_card';

import gridStyles from '../../../styles/global_ui/grid.css';
import layout from '../../../styles/global_ui/layout.css';

const _handleClick = (e, record) => {
  e.preventDefault();
  window.open(record.url, '_blank');
};

const VideosList = ({ results }) => (
  <div className={`${layout.marginTop30} ${layout.marginBottom30}`}>
    <GridList
      ItemComponent={VideoCard}
      className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
      gutterSize={15}
      itemProps={{
        config: { renderOverlay: false },
        onClick: _handleClick,
      }}
      maxCols={3}
      records={results.map((record) => ({ ...record, image: { url: record.image_url } }))}
    />
  </div>
);

VideosList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    created_at: PropTypes.number.isRequired,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.number.isRequired,
    image_url: PropTypes.string.isRequired,
    impressions_count: PropTypes.number,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

VideosList.defaultProps = { results: [] };

export default VideosList;
