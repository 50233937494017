import React from 'react';
import PropTypes from 'prop-types';
import StyleTransition, {
  ENTER,
  ENTERING,
  ENTERED,
  EXIT,
  EXITING,
  EXITED,
} from '../style_transition';

const ACTIVE_STATUSES = [ENTERING, EXITING];

const _getMaxHeight = ({ maxHeight, node, status }) => {
  switch (status) {
    case ENTERING:
      return maxHeight;
    case EXIT:
      return _getNodeHeight(node, maxHeight);
    case ENTER:
    case EXITING:
    case EXITED:
      return 0;
    case ENTERED:
    default:
      return 'none';
  }
};

// prevent delay when exiting. TODO: do something like this for entering too and just use height instead of maxheight?
const _getNodeHeight = (node, fallback) => (
  node ? node.getBoundingClientRect().height : fallback
);

const _getStyle = ({ maxHeight, node, status, timeout }) => {
  const activeStyle = ACTIVE_STATUSES.includes(status) ? { transition: `max-height ${timeout}ms ease` } : {};
  const hiddenStyle = status === ENTERED ? {} : { overflow: 'hidden' };

  return {
    ...activeStyle,
    ...hiddenStyle,
    maxHeight: _getMaxHeight({ maxHeight, node, status }),
  };
};

const MaxHeightTransition = ({ children, className, maxHeight, timeout, ...transitionProps }) => (
  <StyleTransition
    {...transitionProps}
    getStyle={(node, status, appearing) => _getStyle({ maxHeight, node, status, timeout })}
    timeout={timeout}
  >
    <div className={className}>{children}</div>
  </StyleTransition>
);

MaxHeightTransition.propTypes = {
  className: PropTypes.string,
  maxHeight: PropTypes.number,
  timeout: PropTypes.number,
};

MaxHeightTransition.defaultProps = {
  className: '',
  maxHeight: 500,
  timeout: 350,
};

export default MaxHeightTransition;
