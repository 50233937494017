import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ProjectsCategory from './ProjectsCategory';
import ProjectsRoot from './ProjectsRoot';

import errorHandler from '../../../../services/error_handler';
import smoothScroll from '../../../utils/smoothScroll';
import { graphQuery } from '../../../../requests/graphql';
import { setStore, updateRecords } from './helpers';
import { summonGlobalMessenger } from '../../../../utility/dispatchers';

import { AUTHOR, PRIVATE, PUBLIC } from '../../../../graphql/projects/enums';

class Projects extends Component {
  constructor(props) {
    super(props);

    this.getExistingProjects = this.getExistingProjects.bind(this);
    this.getMissingProjects = this.getMissingProjects.bind(this);
    this.handleReorder = this.handleReorder.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.toggleRespect = this.toggleRespect.bind(this);

    const category = this.props.path.split('/').pop();
    const categories = props.isRoot ? ['drafts', 'public', 'guest'] : [category];
    const projects = this.getExistingProjects(categories);

    this.state = {
      category,
      projects,
    };
  }

  /**
   * Lifecycle Methods
   */
  componentDidMount() {
    smoothScroll(0, 300);
    this.getMissingProjects(this.props.isRoot ? ['drafts', 'public', 'guest'] : [this.state.category]);
  }

  /**
   * Methods
   */
  getExistingProjects(categories = ['drafts', 'public', 'guest']) {
    return categories.reduce((acc, category) => {
      const store = this.props.store.get(`projects/${category}`);
      const projects = store ? store.projects[category] : null;

      return { ...acc, [category]: projects };
    }, {});
  }

  getMissingProjects(categories = ['drafts', 'public', 'guest']) {
    // 5-13-2021 - Removed dynamic JS queries. Rails template "get_projects_by_category_all" is a potentially expensive request.
    // If there are projects missing from state (pulled from store), we must fetch all projects for the 3 categories.

    const emptyCategories = categories.filter((category) => this.state.projects[category] === null);
    const params = {
      author_id: this.props.currentUser.id,
      public_publication_state: PUBLIC,
      private_publication_state: PRIVATE,
      public_sort: AUTHOR,
    };

    return emptyCategories.length > 0
      ? graphQuery({ t: 'get_projects_by_category_all' }, params)
        .then((res) => {
          const projects = { ...this.state.projects, ...res };
          setStore(this.props.store, projects);
          this.setState({ projects });
        })
        .catch((err) => {
          summonGlobalMessenger({ msg: 'There was a problem loading projects. Please try again.', type: 'error' });
          errorHandler(`Error fetching projects ${err}`);
        })
      : Promise.resolve();
  }

  handleReorder(updated) {
    const oldProjects = this.state.projects;
    const newProjects = updateRecords(updated, oldProjects);
    this.setState({ projects: newProjects }); // Optimistic loading

    const formattedProjects = updated.map((project) => ({ projectHid: project.hid, projectPosition: project.position }));

    return graphQuery({ t: 'update_projects_position' }, { projects: formattedProjects })
      .then((res) => setStore(this.props.store, newProjects))
      .catch((err) => {
        this.setState({ projects: oldProjects });
        summonGlobalMessenger({ msg: 'Could not reorder projects. Please try again.', type: 'error' });
        errorHandler('Dashboard Projects', err);
      });
  }

  loadMore(category) {
    const { current_page, per_page } = this.state.projects[category].metadata;
    const { query, extendedParams } = this._getTemplateAndParamsFromCategory({ category, author_id: this.props.currentUser.id, page: current_page + 1, per_page });

    return graphQuery(query, extendedParams)
      .then((res) => {
        const projects = {
          ...this.state.projects,
          [category]: {
            metadata: res[category].metadata,
            records: this.state.projects[category].records.concat(res[category].records),
          },
        };
        setStore(this.props.store, projects);
        this.setState({ projects });
      })
      .catch((err) => {
        summonGlobalMessenger({ msg: 'There was a problem loading more projects. Please try again.', type: 'error' });
        errorHandler('Project Load Error:', err);
      });
  }

  toggleRespect(hid, createOrDeleteBool, category) {
    const oldProjects = this.state.projects;

    const records = oldProjects[category].records.map((record) => record.hid === hid
      ? {
          ...record,
          stats: {
            ...record.stats,
            respects: createOrDeleteBool ? record.stats.respects + 1 : Math.max(record.stats.respects - 1, 0),
          },
        }
      : record);

    const projects = {
      ...oldProjects,
      [category]: {
        metadata: oldProjects[category].metadata,
        records,
      },
    };

    this.setState({ projects });
  }

  /**
   * Helpers
   */
  _extendedParamsByCategory(category) {
    if (category !== 'public' && category !== 'drafts') return {};

    return category === 'public' ? { publication_state: PUBLIC, sort: AUTHOR } : { publication_state: PRIVATE };
  }

  _getTemplateAndParamsFromCategory({ category, ...params }) {
    const query = { t: `get_projects_by_category_${category}` };
    const extendedParams = { ...params, ...this._extendedParamsByCategory(category) };

    return { query, extendedParams };
  }

  render() {
    const { category, projects } = this.state;

    return this.props.isRoot
      ? (
        <ProjectsRoot
          currentUser={this.props.currentUser}
          handleReorder={this.handleReorder}
          projects={projects}
          toggleRespect={(hid, createOrDeleteBool, categoryFromProjectsRoot) => this.toggleRespect(hid, createOrDeleteBool, categoryFromProjectsRoot)}
        />
        )
      : (
        <ProjectsCategory
          category={category}
          currentUser={this.props.currentUser}
          handleReorder={this.handleReorder}
          loadMore={() => this.loadMore(category)}
          projects={projects[category]}
          toggleRespect={(hid, createOrDeleteBool) => this.toggleRespect(hid, createOrDeleteBool, category)}
        />
        );
  }
}

Projects.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }),
  isRoot: PropTypes.bool,
  path: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
};

Projects.defaultProps = {
  currentUser: {},
  isRoot: false,
};

export default Projects;
