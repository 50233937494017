const seoConfig = (path) => () => {
  const title = 'Your dashboard - Hackster.io';

  switch (path) {
    case '/bookmarks':
      return { title, pageType: 'bookmarks#index' };

    default:
      return { title, pageType: 'users#dashboard' };
  }
};

export default seoConfig;
