const CONFIG = {
  '/': (community) => ({
    title: `${community.name} community hub`,
    meta_desc: `Explore the ${community.name} community hub to learn and share about their products! Join ${community.stats.members} hardware developers who follow ${community.name} on Hackster.io.`,
    pageType: 'community#show',
    entity_id: community.id,
  }),
  '/discussion': (community) => ({
    title: `${community.name}'s discussion`,
    meta_desc: `Discuss with other ${community.name} developers to find answers to your questions! Join ${community.stats.members} hardware developers who follow ${community.name} on Hackster.io.`,
    pageType: 'community#discussion',
    entity_id: community.id,
  }),
  '/members': (community) => ({
    title: `${community.name} community`,
    meta_desc: `Join ${community.stats.members} hardware developers in the ${community.name} community on Hackster.io.`,
    pageType: 'community#members',
    entity_id: community.id,
  }),
  '/projects': (community) => ({
    title: `${community.name} projects`,
    meta_desc: `Explore ${community.stats.projects} ${community.name} projects, and share your own! Join ${community.stats.members} hardware developers who follow ${community.name} on Hackster.io.`,
    pageType: 'community#projects',
    entity_id: community.id,
  }),
};

export default (path) => CONFIG[path];
