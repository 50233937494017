import React from 'react';

const MoveArrows = ({ className }) => (
  <svg className={className} version="1.1" viewBox="5 4 23 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(16.647042, 15.980375) rotate(45.000000) translate(-16.647042, -15.980375) translate(8.647042, 7.980375)">
      <path d="M9.6,15.1428571 C9.6,15.5428571 9.94285714,15.8285714 10.2857143,15.8285714 L14.9142857,15.8285714 C15.0285714,15.8285714 15.0857143,15.8285714 15.2,15.7714286 C15.3714286,15.7142857 15.4857143,15.5428571 15.6,15.3714286 C15.6571429,15.2571429 15.6571429,15.2 15.6571429,15.0857143 L15.6571429,10.4571429 C15.6571429,10.0571429 15.3142857,9.77142857 14.9714286,9.77142857 C14.6285714,9.77142857 14.2857143,10.1142857 14.2857143,10.4571429 L14.2857143,13.3714286 L10.8571429,9.94285714 C10.5714286,9.65714286 10.1142857,9.65714286 9.82857143,9.94285714 C9.54285714,10.2285714 9.54285714,10.6857143 9.82857143,10.9714286 L13.2571429,14.4 L10.3428571,14.4 C9.94285714,14.4571429 9.6,14.7428571 9.6,15.1428571 L9.6,15.1428571 Z" fill="#4A4A4A" id="Shape"></path>
      <path d="M0.742857143,15.8285714 C0.857142857,15.8857143 0.914285714,15.8857143 1.02857143,15.8857143 L5.65714286,15.8857143 C6.05714286,15.8857143 6.34285714,15.5428571 6.34285714,15.2 C6.34285714,14.8571429 6,14.5142857 5.65714286,14.5142857 L2.74285714,14.5142857 L6.17142857,11.0857143 C6.45714286,10.8 6.45714286,10.3428571 6.17142857,10.0571429 C5.88571429,9.77142857 5.42857143,9.77142857 5.14285714,10.0571429 L1.71428571,13.4857143 L1.71428571,10.5714286 C1.71428571,10.1714286 1.37142857,9.88571429 1.02857143,9.88571429 C0.685714286,9.88571429 0.342857143,10.2285714 0.342857143,10.5714286 L0.342857143,15.2 C0.342857143,15.3142857 0.342857143,15.3714286 0.4,15.4857143 C0.457142857,15.6 0.571428571,15.7714286 0.742857143,15.8285714 L0.742857143,15.8285714 Z" fill="#4A4A4A" id="Shape"></path>
      <path d="M1.02857143,6.57142857 C1.42857143,6.57142857 1.71428571,6.22857143 1.71428571,5.88571429 L1.71428571,2.97142857 L5.14285714,6.4 C5.25714286,6.51428571 5.48571429,6.62857143 5.65714286,6.62857143 C5.82857143,6.62857143 6,6.57142857 6.17142857,6.4 C6.45714286,6.11428571 6.45714286,5.65714286 6.17142857,5.37142857 L2.74285714,1.94285714 L5.65714286,1.94285714 C6.05714286,1.94285714 6.34285714,1.6 6.34285714,1.25714286 C6.34285714,0.914285714 6,0.571428571 5.65714286,0.571428571 L1.02857143,0.571428571 L1.02857143,0.571428571 C0.914285714,0.571428571 0.857142857,0.571428571 0.742857143,0.628571429 C0.571428571,0.685714286 0.457142857,0.857142857 0.342857143,1.02857143 C0.285714286,1.14285714 0.285714286,1.2 0.285714286,1.31428571 L0.285714286,1.31428571 L0.285714286,5.94285714 C0.285714286,6.22857143 0.628571429,6.57142857 1.02857143,6.57142857 L1.02857143,6.57142857 Z" fill="#4A4A4A" id="Shape"></path>
      <path d="M10.3428571,0.457142857 C9.94285714,0.457142857 9.65714286,0.8 9.65714286,1.14285714 C9.65714286,1.48571429 10,1.82857143 10.3428571,1.82857143 L13.2571429,1.82857143 L9.82857143,5.25714286 C9.54285714,5.54285714 9.54285714,6 9.82857143,6.28571429 C9.94285714,6.4 10.1714286,6.51428571 10.3428571,6.51428571 C10.5142857,6.51428571 10.6857143,6.45714286 10.8571429,6.28571429 L14.2857143,2.85714286 L14.2857143,5.77142857 C14.2857143,6.17142857 14.6285714,6.45714286 14.9714286,6.45714286 C15.3142857,6.45714286 15.6571429,6.11428571 15.6571429,5.77142857 L15.6571429,1.14285714 L15.6571429,1.14285714 C15.6571429,1.02857143 15.6571429,0.971428571 15.6,0.857142857 C15.5428571,0.685714286 15.3714286,0.571428571 15.2,0.457142857 C15.0857143,0.4 15.0285714,0.4 14.9142857,0.4 L10.3428571,0.4 L10.3428571,0.457142857 Z" fill="#4A4A4A" id="Shape"></path>
    </g>
  </svg>
);

export default MoveArrows;
