import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ContentPlaceholder from '../../../../client/reusable_components/ContentPlaceholder';
import PaginatedList from '../../../../client/wrappers/paginated_list';
import ProjectsList from '../../../../client/projects/projects_list';

import errorHandler from '../../../../services/error_handler';
import smoothScroll from '../../../../client/utils/smoothScroll';

import layout from '../../../../styles/global_ui/layout.css';
import styles from '../profile/profile.css';

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = { isBusy: false };

    this.fetchMoreRecords = this.fetchMoreRecords.bind(this);
  }

  componentDidMount() {
    smoothScroll(0, 300);
  }

  /**
   * Helpers
   */
  _getQueryArgs() {
    return {
      author_id: this.props.profile.id,
      guest_post: false,
      publication_state: 'PUBLIC',
      sort: 'AUTHOR',
    };
  }

  /**
   * Methods
   */
  fetchMoreRecords() {
    this.setState({ isBusy: true });
    this.props.fetchMoreRecords(this.props.store, 'projects', ['projects'], this._getQueryArgs(), true)
      .then(() => this.setState({ isBusy: false }))
      .catch((err) => {
        this.setState({ isBusy: false });
        errorHandler(err);
      });
  }

  /**
   * Views
   */
  _createList({ currentUser, profile, projectsQuery, records, toggleRespect }) {
    return (
      <PaginatedList
        buttonText="Show more"
        disabled={this.state.isBusy}
        onPaginatorClick={this.fetchMoreRecords}
        paginator={{
          currentPage: projectsQuery.metadata.current_page,
          nextPage: projectsQuery.metadata.next_page,
          prevPage: projectsQuery.metadata.prev_page,
        }}
        type="button"
      >
        <ProjectsList
          currentUser={currentUser}
          editable={false}
          orderable={false}
          records={records}
          toggleRespect={(hid, createOrDeleteBool) => toggleRespect(hid, 'project', ['projects', 'records', ['hid', hid]], createOrDeleteBool, 'project_respects')}
        />
      </PaginatedList>
    );
  }

  _createPlaceholder({ isProfileOwner, name }) {
    return (
      <ContentPlaceholder
        classList={{
          container: styles.container,
          labelSegments: { 1: styles.placeholderLabelSegment },
        }}
        isProfileOwner={isProfileOwner}
        label={['Projects ', '0']}
        templateArgs={{ name }}
        type="no-public-projects"
      />
    );
  }

  render() {
    const { currentUser, fetchMoreRecords, isProfileOwner, store, toggleRespect, profile } = this.props;
    const projectsQuery = store.get('projects');
    const allProjects = store.get('allProjects');

    const loading = !projectsQuery;
    const records = loading ? [] : projectsQuery.records.map((project) => allProjects[project.hid]);

    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          {!loading && records.length === 0
            ? this._createPlaceholder({ isProfileOwner, name: profile.name })
            : this._createList({ currentUser, fetchMoreRecords, profile, projectsQuery, records, toggleRespect })}
        </div>
      </div>
    );
  }
}

Projects.propTypes = {
  currentUser: PropTypes.shape({ id: PropTypes.number }),
  fetchMoreRecords: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setMessage: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  toggleRespect: PropTypes.func,
};

Projects.defaultProps = {
  currentUser: {},
  toggleRespect: () => Promise.resolve(),
};

export default Projects;
