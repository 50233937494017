import PropTypes from 'prop-types';
import React from 'react';

import { formatNumberWithCommas, pluralize } from '../../../../../utility/formatters';

import typography from '../../../../../styles/global_ui/typography.css';
import styles from './stats.css';

const getStat = ({ metric, text }) => `${formatNumberWithCommas(metric)} ${pluralize(text, metric)}`;

const Stats = ({ stats }) => {
  if (!stats || (Array.isArray(stats) && !stats.length)) return null;

  return (
    <div className={`${typography.pebble} ${typography.bodyS}`}>
      {stats.map((stat, i) => (
        <span key={i} className={styles.stat}>
          {stat.override ? stat.override : getStat(stat)}
        </span>
      ))}
    </div>
  );
};

Stats.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape({
    metric: PropTypes.number,
    text: PropTypes.string,
  })),
};

Stats.defaultProps = { stats: [] };

export default Stats;
