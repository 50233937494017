import React from 'react';
import { angry } from './rating_svgs';

const RobotAngry = () => (
  <div
    dangerouslySetInnerHTML={{ __html: angry }}
  />
);

export default RobotAngry;
