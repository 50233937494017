import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckboxGroup from '../../../../client/form_components/inputs/checkbox_group';
import FilterLabel from './FilterLabel';
import FormSelect from '../../../../client/form_components/selects/form_select';

import algoliaService from '../../../../services/algolia';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const difficultyOptions = [
  { label: 'Beginner', value: 'beginner' },
  { label: 'Intermediate', value: 'intermediate' },
  { label: 'Advanced', value: 'advanced' },
  { label: 'Super Advanced', value: 'hardcore' },
];

const projectTypeOptions = [
  { label: 'Featured', value: 'featured' },
  { label: 'Protip', value: 'protip' },
  { label: 'Showcase', value: 'showcase' },
  { label: 'Tutorial', value: 'tutorial' },
  { label: 'Work in progress', value: 'wip' },
  // {label: 'In my toolbox', value: 'toolbox'},
  // {label: 'Contest submissions', value: 'constest_submissions'},
  { label: 'Contest winners', value: 'constest_winners' },
];

const programmingLanguageOptions = [
  { label: 'Arduino', value: 'arduino' },
  { label: 'C/C++', value: 'c_cpp' },
  { label: 'JavaScript', value: 'javascript' },
  { label: 'Python', value: 'python' },
  { label: 'ABAP', value: 'abap' },
  { label: 'ActionScript', value: 'actionscript' },
  { label: 'ADA', value: 'ada' },
  { label: 'Apache Conf', value: 'apache_conf' },
  { label: 'AsciiDoc', value: 'asciidoc' },
  { label: 'Assembly x86', value: 'assembly_x86' },
  { label: 'AutoHotKey', value: 'autohotkey' },
  { label: 'BatchFile', value: 'batchfile' },
  { label: 'C9Search', value: 'c9search' },
  { label: 'Cirru', value: 'cirru' },
  { label: 'Clojure', value: 'clojure' },
  { label: 'Cobol', value: 'cobol' },
  { label: 'CoffeeScript', value: 'coffee' },
  { label: 'ColdFusion', value: 'coldfusion' },
  { label: 'C#', value: 'csharp' },
  { label: 'CSS', value: 'css' },
  { label: 'Curly', value: 'curly' },
  { label: 'D', value: 'd' },
  { label: 'Dart', value: 'dart' },
  { label: 'Diff', value: 'diff' },
  { label: 'Dockerfile', value: 'dockerfile' },
  { label: 'Dot', value: 'dot' },
  { label: 'EJS', value: 'ejs' },
  { label: 'Erlang', value: 'erlang' },
  { label: 'Forth', value: 'forth' },
  { label: 'FreeMarker', value: 'ftl' },
  { label: 'Gherkin', value: 'gherkin' },
  { label: 'Gitignore', value: 'gitignore' },
  { label: 'Glsl', value: 'glsl' },
  { label: 'Go', value: 'golang' },
  { label: 'Groovy', value: 'groovy' },
  { label: 'HAML', value: 'haml' },
  { label: 'Handlebars', value: 'handlebars' },
  { label: 'Haskell', value: 'haskell' },
  { label: 'haXe', value: 'haxe' },
  { label: 'HTML', value: 'html' },
  { label: 'HTML (Ruby)', value: 'html_ruby' },
  { label: 'INI', value: 'ini' },
  { label: 'Jack', value: 'jack' },
  { label: 'Jade', value: 'jade' },
  { label: 'Java', value: 'java' },
  { label: 'JSON', value: 'json' },
  { label: 'JSONiq', value: 'jsoniq' },
  { label: 'JSP', value: 'jsp' },
  { label: 'JSX', value: 'jsx' },
  { label: 'Julia', value: 'julia' },
  { label: 'LaTeX', value: 'latex' },
  { label: 'LESS', value: 'less' },
  { label: 'Liquid', value: 'liquid' },
  { label: 'Lisp', value: 'lisp' },
  { label: 'LiveScript', value: 'livescript' },
  { label: 'LogiQL', value: 'logiql' },
  { label: 'LSL', value: 'lsl' },
  { label: 'Lua', value: 'lua' },
  { label: 'LuaPage', value: 'luapage' },
  { label: 'Lucene', value: 'lucene' },
  { label: 'Makefile', value: 'makefile' },
  { label: 'Markdown', value: 'markdown' },
  { label: 'MATLAB', value: 'matlab' },
  { label: 'mcScript', value: 'mcscript' },
  { label: 'MEL', value: 'mel' },
  { label: 'MicroPython', value: 'micropython' },
  { label: 'MUSHCode', value: 'mushcode' },
  { label: 'MySQL', value: 'mysql' },
  { label: 'Nix', value: 'nix' },
  { label: 'Objective-C', value: 'objectivec' },
  { label: 'OCaml', value: 'ocaml' },
  { label: 'Pascal', value: 'pascal' },
  { label: 'Perl', value: 'perl' },
  { label: 'pgSQL', value: 'pgsql' },
  { label: 'PHP', value: 'php' },
  { label: 'Powershell', value: 'powershell' },
  { label: 'Processing', value: 'processing' },
  { label: 'Prolog', value: 'prolog' },
  { label: 'Properties', value: 'properties' },
  { label: 'Protobuf', value: 'protobuf' },
  { label: 'R', value: 'r' },
  { label: 'RDoc', value: 'rdoc' },
  { label: 'RHTML', value: 'rhtml' },
  { label: 'Ruby', value: 'ruby' },
  { label: 'Rust', value: 'rust' },
  { label: 'SASS', value: 'sass' },
  { label: 'SCAD', value: 'scad' },
  { label: 'Scala', value: 'scala' },
  { label: 'Scheme', value: 'scheme' },
  { label: 'Scratch', value: 'scratch' },
  { label: 'SCSS', value: 'scss' },
  { label: 'SH', value: 'sh' },
  { label: 'SJS', value: 'sjs' },
  { label: 'Smarty', value: 'smarty' },
  { label: 'snippets', value: 'snippets' },
  { label: 'Soy Template', value: 'soy_template' },
  { label: 'Space', value: 'space' },
  { label: 'SQL', value: 'sql' },
  { label: 'Stylus', value: 'stylus' },
  { label: 'SVG', value: 'svg' },
  { label: 'Swift', value: 'swift' },
  { label: 'Tcl', value: 'tcl' },
  { label: 'Tex', value: 'tex' },
  { label: 'Plain text', value: 'text' },
  { label: 'Textile', value: 'textile' },
  { label: 'Toml', value: 'toml' },
  { label: 'Twig', value: 'twig' },
  { label: 'Typescript', value: 'typescript' },
  { label: 'Vala', value: 'vala' },
  { label: 'VB.NET', value: 'vb.net' },
  { label: 'VBScript', value: 'vbscript' },
  { label: 'Velocity', value: 'velocity' },
  { label: 'Verilog', value: 'verilog' },
  { label: 'VHDL', value: 'vhdl' },
  { label: 'XML', value: 'xml' },
  { label: 'XQuery', value: 'xquery' },
  { label: 'YAML', value: 'yaml' },
];

class ProjectFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageExpanded: false,
      typeExpanded: false,
    };

    this.fetchParts = this.fetchParts.bind(this);
  }

  fetchParts(query) {
    return new Promise((resolve, reject) => (
      algoliaService.search({ index: 'parts', query, sort: 'name' })
        .then((res) => resolve({ options: res.hits.map((hit) => ({ label: hit.full_name, value: hit.full_name })) }))
        .catch((err) => reject(err))
    ));
  }

  _getExpandButton(stateKey) {
    return (
      <button
        className={`${buttonStyles.blank} ${typography.link} ${typography.bodyS} ${layout.marginTop10} ${layout.marginBottom30} ${this.props.disabled ? utilStyles.disabled : ''}`}
        disabled={this.props.disabled}
        onClick={() => this.setState({ [stateKey]: !this.state[stateKey] })}
      >
        {this.state[stateKey] ? '- Less' : '+ More'}
      </button>
    );
  }

  render() {
    const { disabled, filters, onChange } = this.props;
    const languageOptions = this.state.languageExpanded ? programmingLanguageOptions : programmingLanguageOptions.slice(0, 4);
    const typeOptions = this.state.typeExpanded ? projectTypeOptions : projectTypeOptions.slice(0, 5);

    return (
      <section>
        <FormSelect
          asyncOpts={{
            initOnMount: true,
            request: this.fetchParts,
          }}
          disabled={disabled || (filters.parts && filters.parts.length === 3)}
          label={(
            <FilterLabel
              className={layout.marginBottom10}
              count={filters.parts ? filters.parts.length : 0}
              disabled={disabled}
              label="Products"
              limit={3}
            />
          )}
          name="part"
          onSelectedChange={(partsArr) => onChange({ parts: partsArr.map((p) => p.value) })}
          searchOpts={{ multiLimit: 3 }}
          type="multi"
          value={filters.parts || []}
        />
        <CheckboxGroup
          classList={{ root: layout.marginBottom0 }}
          disabled={disabled}
          label={<FilterLabel disabled={disabled} label="Project type" />}
          name="projectType"
          onChange={(value) => onChange({ project_type: value })}
          options={typeOptions}
          values={filters.project_type}
        />
        {this._getExpandButton('typeExpanded')}
        <CheckboxGroup
          classList={{ root: layout.marginBottom30 }}
          disabled={disabled}
          label={<FilterLabel disabled={disabled} label="Difficulty" />}
          name="difficulty"
          onChange={(value) => onChange({ difficulty: value })}
          options={difficultyOptions}
          values={filters.difficulty}
        />
        <CheckboxGroup
          classList={{ root: layout.marginBottom0 }}
          disabled={disabled}
          label={<FilterLabel disabled={disabled} label="Programming language" />}
          name="programmingLanguage"
          onChange={(value) => onChange({ language: value })}
          options={languageOptions}
          values={filters.language}
        />
        {this._getExpandButton('languageExpanded')}
      </section>
    );
  }
}

ProjectFilters.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.shape({
    parts: PropTypes.array,
    project_type: PropTypes.array,
    difficulty: PropTypes.array,
  }),
  onChange: PropTypes.func,
};

ProjectFilters.defaultProps = {
  disabled: false,
  filters: {},
  onChange: () => {},
};

export default ProjectFilters;
