import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';
import LazyImage from '../../reusable_components/LazyImage';
import ProjectCardStats from './ProjectCardStats';
import ProjectCardTeam from './ProjectCardTeam';
import ProjectLink from './ProjectLink';

import { normalizeProject } from './helpers';

import cardStyles from '../cards.css';
import colors from '../../../styles/global_ui/colors.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './project_card_horizontal.css';

const _getColor = (difficulty) => (difficulty === 'beginner') ? colors['v-easy'] : colors[`v-${difficulty}`];
const _getCustomIcon = (project) => <Icon name={project.difficulty} style={{ color: _getColor(project.difficulty) }} />;

const HorizontalProjectCard = (props) => {
  const project = normalizeProject(props.project);
  const { location, overrideTracking, toggleRespect } = props;

  return (
    <div className={cardStyles.wrapper}>
      <div className={styles.card}>
        <ProjectLink location={location} overrideTracking={overrideTracking} project={project}>
          <LazyImage
            alt={project.name}
            ratio="4:3"
            src={project.cover_image_url}
            width={200}
          />
        </ProjectLink>
        <div className={styles.content}>
          <div>
            <div className={typography.h5}>
              {project.published_state.toLowerCase() === 'private' ? 'Draft ' : null}
              {project.type_label}
            </div>
            <ProjectLink
              className={`${cardStyles.title} ${typography.bodyM} ${typography.linkCharcoal}`}
              location={location}
              overrideTracking={overrideTracking}
              project={project}
            >
              {project.name}
            </ProjectLink>
            <div>
              <ProjectCardTeam project={project} />
            </div>
            {!!project.one_liner && <div className={styles.description}>{project.one_liner}</div>}
            <div className={cardStyles.spacer}></div>
          </div>
          <ProjectCardStats
            className={styles.moreInfo}
            customIcon={_getCustomIcon}
            project={project}
            toggleRespect={toggleRespect}
          >
          </ProjectCardStats>
        </div>
      </div>
    </div>
  );
};

HorizontalProjectCard.propTypes = {
  location: PropTypes.string,
  overrideTracking: PropTypes.func,
  project: PropTypes.shape({
    content_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    cover_image_url: PropTypes.string,
    difficulty: PropTypes.string,
    edit_url: PropTypes.string,
    guest_name: PropTypes.string,
    hid: PropTypes.string,
    name: PropTypes.string,
    one_liner: PropTypes.string,
    published_state: PropTypes.string,
    respects: PropTypes.number,
    stats: PropTypes.object,
    team: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  toggleRespect: PropTypes.func,
};

HorizontalProjectCard.defaultProps = {
  enableOverlay: true,
  imageProps: {},
  location: null,
  maxWidth: 350,
  overrideTracking: null,
  toggleRespect: () => {},
};

export default HorizontalProjectCard;
