/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { FILTER_WHITELIST } from '../filters/buildParamsFromFilters';
import { validatedSortIndex } from '../getAlgoliaIndex';
import { validateLocationObj } from '../filters/location';
import { isObject } from '../../../utility/types';

const firstLvl = {
  query: 'q',
  index: 'i',
};

const secondLvl = { params: { page: 'page' } };

function shouldAddFilterToString(value) {
  return (
    (typeof value === 'boolean' && value === true)
    || (typeof value !== 'boolean' && value !== null && value !== undefined)
  );
}

function locationToStringsArray(loc) {
  if (!validateLocationObj(loc)) return [];

  return Object.keys(loc).reduce((acc, key) => acc.concat(`${key}=${isObject(loc[key]) ? encodeURIComponent(JSON.stringify(loc[key])) : encodeURIComponent(loc[key])}`), []);
}

function flattenFiltersObj(filters) {
  return Object.keys(filters).reduce((acc, filter) => {
    if (FILTER_WHITELIST.hasOwnProperty(filter) && shouldAddFilterToString(filters[filter])) {
      if (filter === 'location') {
        acc = acc.concat(locationToStringsArray(filters[filter]));
      } else {
        acc = acc.concat(`${filter}=${isObject(filters[filter])
          ? encodeURIComponent(JSON.stringify(filters[filter]))
          : encodeURIComponent(filters[filter])}`);
      }
    }

    return acc;
  }, []);
}

export default function normalizedToQueryString(queryMap) {
  const queryString = Object.keys(queryMap).reduce((acc, key) => {
    if (firstLvl.hasOwnProperty(key)) {
      acc = acc.concat(`${firstLvl[key]}=${queryMap[key]}`);
    } else if (secondLvl.hasOwnProperty(key)) {
      const lvl2 = secondLvl[key];
      Object.keys(lvl2).forEach((nestedKey) => {
        if (queryMap[key][nestedKey]) {
          acc = acc.concat(`${lvl2[nestedKey]}=${encodeURIComponent(queryMap[key][nestedKey])}`);
        }
      });
    } else if (key === 'filters') {
      acc = acc.concat(flattenFiltersObj(queryMap.filters));
    } else if (key === 'sort' && validatedSortIndex(queryMap.index, queryMap.sort)) {
      acc = acc.concat(`sort_by=${encodeURIComponent(queryMap.sort)}`);
    }

    return acc;
  }, []).join('&');

  return `?${queryString}`;
}
