import { DEFAULT_PAGE_DESCRIPTION, DEFAULT_PAGE_TITLE } from '../../constants/pageMetadata';

const getDefaultValues = (pageType) => ({
  meta_desc: DEFAULT_PAGE_DESCRIPTION,
  pageType,
  title: DEFAULT_PAGE_TITLE,
});

const CONFIG = { '/': () => getDefaultValues('events#index') };

export default (path) => CONFIG[path];
