/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Articles from './articles';
import CommunitiesList from './dialogs/CommunitiesList';
import CommunityCard from './popovers/CommunityCard';
import CurrentUserWrapper from '../../../client/reusable_components/CurrentUserWrapper';
import Dialog from '../../../client/reusable_components/Dialog';
import FollowersList from './dialogs/FollowersList';
import FollowingList from './dialogs/FollowingList';
import HorizontalProjectCard from './popovers/HorizontalProjectCard';
import InfiniteScroll from './dialogs/InfiniteScroll';
import Navbar from './navbar';
import Popover from '../../../client/reusable_components/Popover';
import Profile from './profile';
import Projects from './projects';
import Router, { history, initCurrentPath, Route, transition } from '../../../client/reusable_components/Router';
import ToolsList from './dialogs/ToolsList';

import { graphQuery } from '../../../requests/graphql';

import {
  resolveMoreArticles, resolveMoreCommunities, resolveMoreFollowers, resolveMoreFollowing, resolveMoreParts,
  resolveMorePartProjects, resolveMoreProjects,
} from './resolvers';
import { updateCommunityFollowing, updateProjectRespects, updateUserFollowers, updateUserFollowing } from './updaters';

import SeoHandler from '../../../services/seo_handler';
import errorHandler from '../../../services/error_handler';
import seoConfig from './seoConfig';
import urlService from '../../../services/url_service';

import { cancelablePromise } from '../../../utility/promises';
import { formatNumberWithCommas } from '../../../utility/formatters';
import { listToMapByProperty } from '../../../utility/converters';
import { getInObj } from '../../../utility/accessors';
import { updateAllProjects } from './helpers';

import listStyles from './list/list.css';

const resourceToComponentMap = {
  communities_dialog: CommunitiesList,
  community_popover: CommunityCard,
  followers_dialog: FollowersList,
  following_dialog: FollowingList,
  project_popover: HorizontalProjectCard,
  tools_dialog: ToolsList,
};

const resourceToGraphQLQueryMap = {
  articles: (variables) => graphQuery({ t: 'news_articles_simple_pagination' }, variables),
  communities: (variables) => graphQuery({ t: 'get_communities_for_user' }, variables),
  community: (id) => graphQuery({ t: 'get_community_hover_data' }, { id }),
  followers: (variables) => graphQuery({ t: 'get_users_following_user' }, variables),
  following: (variables) => graphQuery({ t: 'get_users_followed_by_user' }, variables),
  project: (hid) => graphQuery({ t: 'get_project_by_hid' }, { hid }),
  projects: (variables) => graphQuery({ t: 'projects_with_simple_pagination' }, variables),
  projects_by_hid: (variables) => graphQuery({ t: 'get_projects_by_hid' }, variables),
  tool_projects: (variables) => graphQuery({ t: 'get_part_projects_for_user' }, variables),
  tools: (variables) => graphQuery({ t: 'get_parts_for_user' }, variables),
};

const emptyAlert = {
  component: null,
  props: null,
  show: false,
};

const emptyDialog = {
  component: null,
  props: null,
  resource: null,
  show: false,
};

const emptyPopover = {
  component: null,
  id: null,
  position: 'top',
  props: null,
  target: null,
};

function getRoutes(isWhiteLabel, newsRole) {
  const routes = [
    { href: '/', name: 'Profile' },
    { href: '/projects', name: 'Projects' },
  ];

  return !isWhiteLabel && newsRole ? routes.concat({ href: '/articles', name: 'Articles' }) : routes;
}

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.blockHoverEvent = this.blockHoverEvent.bind(this);
    this.fetchMoreRecords = this.fetchMoreRecords.bind(this);
    this.fetchProjects = this.fetchProjects.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handlePopoverEnter = this.handlePopoverEnter.bind(this);
    this.handlePopoverLeave = this.handlePopoverLeave.bind(this);
    this.handleResourceUpdate = this.handleResourceUpdate.bind(this);

    this.state = {
      alert: emptyAlert,
      basePath: props.pathHelpers.basePath,
      currentUser: null,
      dialog: emptyDialog,
      initPath: initCurrentPath(props.pathHelpers.fullPath, props.pathHelpers.basePath),
      popover: emptyPopover,
    };

    this.isFetchingKeys = [];
    this.popoverHovered = false;
    this.blockHoverForResource = null;
    this.activePromise;

    this.seoHandler = new SeoHandler({ config: seoConfig });
    urlService.setRootPath(props.pathHelpers.rootPath);

    // Refs
    this.router;
  }

  componentDidMount() {
    this.seoHandler.reportView({ path: this.state.initPath, data: this.props.initProps.profile });
  }

  _compoundKey(id, resource) {
    return `${resource}_${id}`;
  }

  _filterKeys(key) {
    this.isFetchingKeys = this.isFetchingKeys.filter((k) => k !== key);
  }

  _pushToKeys(key) {
    this.isFetchingKeys.push(key);
  }

  blockHoverEvent(resource, shouldBlock) {
    this.blockHoverForResource = shouldBlock ? resource : null;
  }

  fetchMoreRecords(store, resource, path, queryParams = {}, returnPromise = false) {
    if (!store.hasIn(path)) {
      return Promise.reject(new Error(`fetchMoreRecords. Store does not have, ${resource} (resource)!`));
    }

    const { current_page, per_page } = store.getIn(path).metadata;

    return resourceToGraphQLQueryMap[resource]({ ...queryParams, per_page, page: current_page + 1 })
      .then((res) => {
        switch (resource) {
          case 'articles':
            return resolveMoreArticles.call(this, res, store, path, returnPromise);

          case 'communities':
            return resolveMoreCommunities.call(this, res, store, path, returnPromise);

          case 'followers':
            return resolveMoreFollowers.call(this, res, store, path);

          case 'following':
            return resolveMoreFollowing.call(this, res, store, path);

          case 'projects':
            return resolveMoreProjects.call(this, res, store, path, returnPromise);

          case 'tool_projects':
            return resolveMorePartProjects.call(this, res, store, path, returnPromise);

          case 'tools':
            return resolveMoreParts.call(this, res, store, path, returnPromise);

          default:
            return;
        }
      })
      .catch((err) => returnPromise ? Promise.reject(err) : errorHandler(`fetchMoreRecords: ${err}`));
  }

  fetchProjects(store, hids) {
    return graphQuery({ t: 'get_projects_by_hid' }, { hids })
      .then((res) => {
        updateAllProjects(store, res.projects.records);
        this.forceUpdate();
      })
      .catch((err) => errorHandler(`fetchProjects Error: ${err}`));
  }

  fetchUser(id, path, store) {
    return graphQuery({ t: 'get_user' }, { id })
      .then((res) => {
        const oldRecords = store.getIn(path);
        const updatedRecords = [res.user, ...oldRecords];

        store.setIn(path, updatedRecords);
      })
      .catch((err) => errorHandler('fetchUser Error: ', err));
  }

  handleAlert(component, props) {
    this.setState({ alert: { component, props, show: true } });
  }

  handleDialog(store, resource) {
    if (!resourceToComponentMap[`${resource}_dialog`]) {
      return errorHandler(new Error(`${resource} is unknown for dialog type!`));
    }

    this.setState({
      dialog: {
        component: resourceToComponentMap[`${resource}_dialog`],
        props: this._dialogProps(store, resource),
        resource: resource,
        show: true,
        title: this._dialogTitleForResource(store, resource),
      },
      popover: emptyPopover,
    });
  }

  _dialogProps(store, resource) {
    const customProps = resource === 'tools'
      ? {
          blockHoverForResource: this.blockHoverEvent,
          fetchProjects: this.fetchProjects,
          fetchProjectsForResource: this.fetchMoreRecords,
          killPopover: this.handlePopoverLeave,
          profile: this.props.initProps.profile,
          store: store,
          toggleRespect: this.handleResourceUpdate,
          triggerPopover: this.handlePopoverEnter,
        }
      : {};

    return { ...customProps, ...store.get(resource) };
  }

  _dialogTitleForResource(store, resource) {
    const { communities, profile, tools } = this.props.initProps;
    const { name } = profile;
    const profileStats = store.get('profileStats');

    return {
      communities: `${name}'s Channels (${formatNumberWithCommas(communities.metadata.total_records)})`,
      followers: `${name}'s Followers (${formatNumberWithCommas(profileStats.followers)})`,
      following: `${name} Follows (${formatNumberWithCommas(profileStats.following)})`,
      tools: `${name}'s Products (${formatNumberWithCommas(tools.metadata.total_records)})`,
    }[resource];
  }

  handlePopoverEnter(storeOrNull, container, path, id, resource, target, position, optionalPropCheck = [], popoverAdjustments = {}, inDialog = false) {
    if (this.blockHoverForResource === container) return;

    const store = storeOrNull ? storeOrNull : this.router.getStore();
    const compoundKey = this._compoundKey(id, resource);
    const hasInCheck = optionalPropCheck.length ? optionalPropCheck : path;

    if (!store.hasIn(hasInCheck) && !this.isFetchingKeys.includes(compoundKey)) {
      return this._delayAndFetchRecord({ compoundKey, store, path, id, resource, target, position, popoverAdjustments, inDialog });
    } else if (store.hasIn(hasInCheck) && !this.isFetchingKeys.includes(compoundKey)) {
      return this._delayAndRenderKnownRecord({ compoundKey, store, path, id, resource, target, position, popoverAdjustments, inDialog });
    }
  }

  _delayAndFetchRecord({ compoundKey, store, path, id, resource, target, position, popoverAdjustments, inDialog }) {
    this.activePromise = cancelablePromise(this._delayedEvent(compoundKey));

    return this.activePromise
      .promise
      .then((res) => {
        this.activePromise = undefined;
        if (res.proceed && !res.hasOwnProperty('promiseCanceled')) {
          return this._fetchNewResourceForPopOver({ compoundKey, store, path, id, resource, target, position, popoverAdjustments, inDialog });
        } else {
          return Promise.resolve();
        }
      })
      .catch((err) => {
        this.activePromise = undefined;
        errorHandler('_delayAndFetchRecord Error', err);

        return Promise.reject(err);
      });
  }

  _delayAndRenderKnownRecord({ compoundKey, store, path, id, resource, target, position, popoverAdjustments, inDialog }) {
    this.activePromise = cancelablePromise(this._delayedEvent(compoundKey));

    return this.activePromise
      .promise
      .then((res) => {
        this.activePromise = undefined;
        this._filterKeys(compoundKey);

        if (res.proceed && !res.hasOwnProperty('promiseCanceled')) {
          this._setPopover({
            adjustments: popoverAdjustments,
            component: resourceToComponentMap[`${resource}_popover`],
            id: compoundKey,
            position: position,
            props: store.getIn(path),
            target: target,
          });
        }

        return Promise.resolve();
      })
      .catch((err) => {
        this.activePromise = undefined;
        errorHandler('_delayAndRenderKnownRecord Error', err);

        return Promise.reject(err);
      });
  }

  _delayedEvent(compoundKey, time = 500) {
    this._pushToKeys(compoundKey);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.isFetchingKeys.includes(compoundKey)) {
          return resolve({ proceed: true });
        } else {
          return resolve({ proceed: false });
        }
      }, time);
    });
  }

  _fetchNewResourceForPopOver({ compoundKey, store, path, id, resource, target, position, popoverAdjustments, inDialog }) {
    if (!resourceToGraphQLQueryMap[resource]) {
      return Promise.reject(new Error(`${resource} (resource) is unknown!`));
    }

    return resourceToGraphQLQueryMap[resource](id)
      .then((res) => {
        const resourceKey = Object.keys(res)[0];
        const record = resourceKey === 'record' ? res['record'] : res[resourceKey]['record'];
        store.setIn(path, record);

        // We want to prevent multiple state updates from subsequent hover events when we only care about the last thing hovered.
        if (this.isFetchingKeys[this.isFetchingKeys.length - 1] === compoundKey && inDialog === this.state.dialog.show) {
          this._setPopover({
            adjustments: popoverAdjustments,
            component: resourceToComponentMap[`${resource}_popover`],
            id: compoundKey,
            position: position,
            props: store.getIn(path),
            target: target,
          });
        }
        // Clean up previously hovered keys.
        this._filterKeys(compoundKey);

        return Promise.resolve();
      })
      .catch((err) => {
        this._filterKeys(compoundKey);
        errorHandler('_fetchNewResourceForPopOver Error:', err);

        return Promise.reject(err);
      });
  }

  _setPopover(popover) {
    if (this.state.popover.id === null || this.state.popover.id !== popover.id) {
      this.setState({ popover });
    }
  }

  handleLocationChange(location, action) {
    this.seoHandler.reportView({ path: location.pathname, data: this.props.initProps.profile });
  }

  handlePopoverLeave(id, resource, waitTime = 20) {
    const compoundKey = this._compoundKey(id, resource);
    this._filterKeys(compoundKey);

    return new Promise((resolve, reject) => {
      if (this.state.popover.id && this.state.popover.id === compoundKey) {
        setTimeout(() => {
          // NOTE: We need to repeat the above conditional since during the timelapse there may have been another hovered
          // element that we don't want to unset.
          if (!this.popoverHovered && this.state.popover.id && this.state.popover.id === compoundKey) {
            this.setState({ popover: { ...emptyPopover, position: this.state.popover.position } });
          }

          return resolve();
        }, waitTime);
      } else {
        return resolve();
      }
    });
  }

  handleResourceUpdate(id, resource, path, action, updater) {
    if (!this.router || !updater) return;
    const store = this.router.getStore();

    switch (updater) {
      case 'community_members':
        return updateCommunityFollowing.call(this, id, resource, path, action, store);

      case 'follower_list_follow_button':
      case 'following_list_follow_button':
        return this.state.currentUser && this.state.currentUser.isProfileOwner ? updateUserFollowing.call(this, id, path, action, store) : true;

      case 'project_respects':
        return updateProjectRespects.call(this, id, resource, path, action, store);

      case 'user_card_follow_button':
        return updateUserFollowers.call(this, path, action, store);

      default:
        return;
    }
  }

  _getPopoverView() {
    return React.createElement(this.state.popover.component, {
      key: this._getPopoverViewKey(this.state.popover.props),
      updateResource: this.handleResourceUpdate,
      ...this.state.popover.props,
    });
  }

  _getPopoverViewKey(project) {
    return (getInObj(['stats', 'respects'], project) || project.id || project.hid);
  }

  render() {
    const { articles, communities, profile, projects, tools } = this.props.initProps;
    const isProfileOwner = this.state.currentUser && this.state.currentUser.isProfileOwner ? true : false;

    return (
      <CurrentUserWrapper
        onResolve={(currentUser) => this.setState({ currentUser: { ...currentUser, isProfileOwner: currentUser.id === profile.id } })}
      >
        <Router
          ref={(el) => this.router = el}
          basePath={this.state.basePath}
          initPath={this.state.initPath}
          initializeStoreFn={(ctx) => {
            ctx.store.setAll({
              articles,
              allProjects: listToMapByProperty(projects.records, 'hid'),
              communities,
              followers: { metadata: { current_page: 0, per_page: 10, total_records: profile.stats.followers }, records: [] },
              following: { metadata: { current_page: 0, per_page: 10, total_records: profile.stats.following }, records: [] },
              profileStats: { ...profile.stats },
              projects,
              tools,
            });
          }}
          onUpdate={this.handleLocationChange}
        >
          <Navbar
            basePath={this.state.basePath}
            currentUser={this.state.currentUser || {}}
            fetchedCurrentUser={this.state.currentUser !== null}
            initPath={this.state.initPath}
            isProfileOwner={isProfileOwner}
            openAlert={this.handleAlert}
            openDialog={this.handleDialog}
            profile={profile}
            rootPath={this.props.pathHelpers.rootPath}
            routes={getRoutes(this.props.isWhiteLabel, profile.news_role)}
            store={{}} // Since Navbar is a stateless component the required store prop will complain on the first tick.
            transition={(path) => transition(history, path)}
            updateResource={this.handleResourceUpdate}
          />
          <Route
            component={Profile}
            path="/"
            routerProps={{
              blockHoverForResource: this.blockHoverEvent,
              fetchProjects: this.fetchProjects,
              fetchProjectsForResource: this.fetchMoreRecords,
              isProfileOwner: isProfileOwner,
              isWhiteLabel: this.props.isWhiteLabel,
              killPopover: this.handlePopoverLeave,
              openDialog: this.handleDialog,
              profile: profile,
              toggleRespect: this.handleResourceUpdate,
              triggerPopover: this.handlePopoverEnter,
            }}
          />
          <Route
            component={Projects}
            path="projects"
            routerProps={{
              isProfileOwner: isProfileOwner,
              fetchMoreRecords: this.fetchMoreRecords,
              profile,
              toggleRespect: this.handleResourceUpdate,
            }}
          />
          <Route
            component={Articles}
            path="articles"
            routerProps={{
              fetchMoreRecords: this.fetchMoreRecords,
              isProfileOwner,
              profile,
            }}
          />
        </Router>
        <Dialog
          classList={{ dismiss: listStyles.dialogDismissArrow }}
          dismiss={() => {
            if (this.activePromise) this.activePromise.cancel();
            this.setState({ dialog: emptyDialog, popover: emptyPopover });
          }}
          fullScreen={true}
          nestedDialogLevel={1}
          open={this.state.dialog.show}
          title={<h3 className={listStyles.dialogTitle}>{this.state.dialog.title}</h3>}
        >
          {this.state.dialog.component
          && (
            <InfiniteScroll
              fetchMore={() => {
                if (this.state.dialog && this.state.dialog.resource) {
                  return this.fetchMoreRecords(
                    this.router.getStore(),
                    this.state.dialog.resource,
                    [this.state.dialog.resource],
                    { user_id: profile.id },
                    true,
                  );
                } else {
                  return Promise.resolve({});
                }
              }}
              metadata={this.state.dialog.props.metadata}
              recordsCount={this.state.dialog.props.records.length}
            >
              {React.createElement(this.state.dialog.component, {
                currentUser: this.state.currentUser,
                killPopover: this.handlePopoverLeave,
                profile: { name: profile.name },
                triggerPopover: this.handlePopoverEnter,
                updateResource: this.handleResourceUpdate,
                ...this.state.dialog.props,
              })}
            </InfiniteScroll>
          )}
        </Dialog>
        <Popover
          adjustments={this.state.popover.adjustments}
          hideAtScreenWidth={1024}
          onMouseEnter={() => this.popoverHovered = true}
          onMouseLeave={() => {
            this.popoverHovered = false;
            this.setState({ popover: { ...emptyPopover, position: this.state.popover.position } });
          }}
          position={this.state.popover.position}
          target={this.state.popover.target}
        >
          {this.state.popover.component && this._getPopoverView()}
        </Popover>
        <Dialog
          dismiss={() => this.setState({ alert: emptyAlert })}
          open={this.state.alert.show}
        >
          {this.state.alert.show && React.createElement(this.state.alert.component, this.state.alert.props)}
        </Dialog>
      </CurrentUserWrapper>
    );
  }
}

UserProfile.propTypes = {
  initProps: PropTypes.shape({
    articles: PropTypes.shape({
      metadata: PropTypes.shape({
        current_page: PropTypes.number,
        next_page: PropTypes.number,
        per_page: PropTypes.number,
        prev_page: PropTypes.number,
      }),
      records: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        image: PropTypes.shape({
          id: PropTypes.number,
          url: PropTypes.string,
        }),
        published_at: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
        user: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          url: PropTypes.string,
        }),
      })),
    }).isRequired,
    communities: PropTypes.shape({
      metadata: PropTypes.shape({
        currentPage: PropTypes.number,
        per_page: PropTypes.number,
        total_pages: PropTypes.number,
        total_records: PropTypes.number,
      }),
      records: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        status: PropTypes.string,
        url: PropTypes.string,
      })),
    }).isRequired,
    profile: PropTypes.shape({
      available_for_hire: PropTypes.bool,
      avatar_url: PropTypes.string,
      bio: PropTypes.string,
      challenge_prizes: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.shape({ id: PropTypes.number }),
        challenge: PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        }),
        icon_urls: PropTypes.shape({
          x1: PropTypes.string,
          x2: PropTypes.string,
        }),
        id: PropTypes.number,
        position_name: PropTypes.string,
      })),
      city: PropTypes.string,
      country_iso2: PropTypes.string,
      email: PropTypes.string,
      hourly_rate: PropTypes.number,
      id: PropTypes.number,
      interest: PropTypes.array,
      name: PropTypes.string,
      news_role: PropTypes.oneOf(['admin', 'author', 'editor']),
      skills: PropTypes.array,
      state: PropTypes.string,
      stats: PropTypes.shape({
        followers: PropTypes.number,
        following: PropTypes.number,
        projects: PropTypes.number,
        reputation: PropTypes.number,
      }),
      user_name: PropTypes.string,
      website: PropTypes.string,
    }).isRequired,
    projects: PropTypes.shape({
      metadata: PropTypes.shape({
        current_page: PropTypes.number,
        next_page: PropTypes.number,
        per_page: PropTypes.number,
        prev_page: PropTypes.number,
      }),
      records: PropTypes.arrayOf(PropTypes.shape({
        content_type: PropTypes.string,
        cover_image_url: PropTypes.string,
        difficulty: PropTypes.string,
        guest_name: PropTypes.string,
        hid: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        one_liner: PropTypes.string,
        position: PropTypes.number,
        published_state: PropTypes.string,
        stats: PropTypes.shape({
          respects: PropTypes.number,
          views: PropTypes.number,
        }),
        team: PropTypes.shape({
          members: PropTypes.array,
          name: PropTypes.string,
          user_name: PropTypes.string,
        }),
        url: PropTypes.string,
      })),
    }).isRequired,
    tools: PropTypes.shape({
      metadata: PropTypes.shape({
        currentPage: PropTypes.number,
        per_page: PropTypes.number,
        total_pages: PropTypes.number,
        total_records: PropTypes.number,
      }),
      records: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        projects: PropTypes.shape({
          records: PropTypes.arrayOf(PropTypes.shape({
            cover_image_url: PropTypes.string,
            hid: PropTypes.string,
          })),
          metadata: PropTypes.object,
        }),
        stats: PropTypes.shape({ projects: PropTypes.number }),
      })),
    }).isRequired,
  }).isRequired,
  isWhiteLabel: PropTypes.bool.isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserProfile;
