import React from 'react';
import PropTypes from 'prop-types';

import ContentPlaceholder from '../../reusable_components/ContentPlaceholder';
import HorizontalPartCard from '../horizontal_part_card';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import styles from './horizontal_parts_list.css';

function getListView({ canManage, manageProductsLink, platformId, records, transition }) {
  return records.length > 0 ? renderListView({ canManage, platformId, records, transition }) : renderPlaceholderView({ canManage, manageProductsLink });
}

function renderListView({ canManage, platformId, records, transition }) {
  return (
    <div>
      {records.map((record) => (
        <HorizontalPartCard
          key={record.id}
          canManage={canManage}
          part={record}
          platformId={platformId}
          transition={transition}
        />
      ))}
    </div>
  );
}

function renderPlaceholderView({ canManage, manageProductsLink }) {
  return (
    <ContentPlaceholder
      classList={{
        content: styles.placeholderContent,
        placeholderLink: buttonStyles.md,
        placeholderText: styles.placeholderLabel,
      }}
      templateArgs={{ canManage, manageProductsLink }}
      type="no-products"
    />
  );
}

const HorizontalPartsList = (props) => getListView(props);

HorizontalPartsList.propTypes = {
  canManage: PropTypes.bool,
  manageProductsLink: PropTypes.string,
  platformId: PropTypes.number.isRequired,
  records: PropTypes.array,
  transition: PropTypes.func.isRequired,
};

HorizontalPartsList.defaultProps = {
  canManage: false,
  manageProductsLink: '',
  records: [],
};

export default HorizontalPartsList;
