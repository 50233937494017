function createNewOption(option) {
  const newOption = option.replace(/\s\s+/g, ' ').trim();

  return { label: newOption, value: newOption, userCreated: true };
}

function formatOptionLabel(option) {
  const count = option.projects_count > 1000
    ? `${(option.projects_count / 1000).toFixed(1)}k`
    : `${option.projects_count}`;

  const nameParts = option.name.split(' ');

  return `${nameParts.join(' ')} (${count})`;
}

function formatOption(option) {
  return {
    label: option.name,
    optionLabel: formatOptionLabel(option),
    value: option.name,
    id: option.id,
    cue_id: option.tagging_cue_id,
    popularity_count: option.projects_count,
    status: getOptionStatus(option),
  };
}

function getSelectedTags(option) {
  return option.status === 'saved' || option.status === 'pending_creation' ? [option] : [];
}

function getTagsPendingDeletion(option) {
  return option.status === 'pending_deletion' ? [option] : [];
}

function getOptionStatus(option) {
  return option.marked_for_deletion
    ? 'pending_deletion'
    : option.tagging_cue_id
      ? 'saved'
      : 'pending_creation';
}

export function filterOptions(menuOptions = [], filter = '', creatable = false) {
  if (!filter.trim().length) return menuOptions;

  const formattedFilter = normalizeString(filter);
  const { filtered, exactMatch } = menuOptions.reduce((memo, option) => {
    const formattedLabel = normalizeString(option.label);

    return {
      filtered: [...memo.filtered, ...[option]],
      exactMatch: memo.exactMatch ? true : formattedLabel === formattedFilter,
    };
  }, { filtered: [], exactMatch: false });

  // if creatable is true, and the filter is not in the selected options
  // add the filter as an option
  return creatable && !exactMatch
    ? [...filtered, createNewOption(filter)]
    : filtered;
}

export function formatOptions(options) {
  return options.map((option) => formatOption(option));
}

export function initOptions(options, withCount) {
  if (!options) {
    return [];
  }

  return options.reduce((memo, option) => {
    const newOption = formatOption(option);

    return ({
      ...memo,
      selected: [...memo.selected, ...getSelectedTags(newOption)],
      markedForDeletion: [...memo.markedForDeletion, ...getTagsPendingDeletion(newOption)],
    });
  },
  {
    selected: [],
    markedForDeletion: [],
  });
}

export function formatSearchInput(input) {
  return input.replace(/\s+/g, ' ').trim();
}

export function normalizeString(target = '') {
  const string = typeof target === 'string' ? target : target.toString();

  return string.replace(/\W/g, '').toLowerCase().trim();
}
