import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Filters from './Filters';
import Icon from '../../../client/icon';
import IndexSetting from './IndexSetting';
import Sort from './Sort';

import algoliaService from '../../../services/algolia';
import errorHandler from '../../../services/error_handler';
import shouldAllowEmptySearchQuery from '../../../services/algolia/shouldAllowEmptySearchQuery';
import { filterObject } from '../../../utility/filters';
import { isBlank } from '../../../utility/types';
import { windowScrollTo } from '../../../services/window';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import styles from './search_settings.css';

const emptySettings = {
  index: 'projects',
  filters: {},
  sort: 'relevance',
};

const filterPredicate = (val) => (!isBlank(val) && val !== false);

class SearchSettings extends Component {
  constructor(props) {
    super(props);

    this.handleApply = this.handleApply.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleSettingsChange = this.handleSettingsChange.bind(this);
    this.handleSubscription = this.handleSubscription.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setIndex = this.setIndex.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.setSort = this.setSort.bind(this);

    this.state = {
      settings: emptySettings,
      cachedSettings: emptySettings,
    };

    // parent component will unsubscribe all from this channel
    algoliaService.getChannel().subscribe('filters', this.handleSubscription);
    algoliaService.getChannel().subscribe('setIndex', (index) => this.setIndex(index, true));
  }

  handleApply() { // this should only happen on mobile
    windowScrollTo(0, 0);
    this.handleSearch(this.state.settings);
  }

  handleDismiss() {
    windowScrollTo(0, 0);
    this.setState({ settings: { ...this.state.cachedSettings } });

    this.props.dismissSettings();
  }

  handleSearch(newSettings) {
    if (this.props.showSettings) this.props.dismissSettings();

    return this.props.handleSearchRequest(newSettings)
      .then(() => {
        this.setState({ cachedSettings: { ...newSettings } });
      })
      .catch((err) => {
        this.setState({ settings: { ...this.state.cachedSettings } });
        errorHandler('search from filters Error:', err);
      });
  }

  handleSettingsChange(newSettings, doSearch) {
    this.setState({ settings: newSettings });

    if (doSearch) {
      this.handleSearch(newSettings);
    }
  }

  handleSubscription(newSettings) {
    const settings = {
      ...emptySettings,
      ...newSettings,
    };

    // clear "cache" and set all to main source of truth
    this.setState({
      settings,
      cachedSettings: settings,
    });
  }

  setIndex(index, doSearch) {
    const newSettings = {
      ...emptySettings,
      index,
    };

    this.handleSettingsChange(newSettings, doSearch);
  }

  setFilters(filters, doSearch) {
    const newFilters = {
      ...this.state.settings.filters,
      ...filters,
    };

    const newSettings = {
      ...this.state.settings,
      filters: filterObject(newFilters, filterPredicate),
    };

    this.handleSettingsChange(newSettings, doSearch);
  }

  setSort(sort, doSearch) {
    const newSettings = {
      ...this.state.settings,
      sort,
    };

    this.handleSettingsChange(newSettings, doSearch);
  }

  _shouldDisableSettings() {
    return !this.props.query && !shouldAllowEmptySearchQuery(this.state.settings.index);
  }

  render() {
    const { channelId, showSettings } = this.props;
    const { index, filters, sort } = this.state.settings;

    return (
      <div className={styles.panel}>
        {/* Desktop */}
        <section className={styles.settingsDesktop}>
          <IndexSetting channelId={channelId} index={index} onChange={(e) => this.setIndex(e.target.value, true)} />
          <Filters
            disabled={this._shouldDisableSettings()}
            filters={filters}
            index={index}
            onChange={(filters) => this.setFilters(filters, true)}
          />
          <Sort disabled={this._shouldDisableSettings()} index={index} onChange={(e) => this.setSort(e.target.value, true)} sort={sort} />
        </section>

        {/* Mobile */}
        <section className={showSettings ? styles.settingsMobile : styles.settingsHidden}>
          <button className={`${buttonStyles.blank} ${styles.backButton}`} onClick={this.handleDismiss}>
            <Icon name="close" />
          </button>
          <div className={styles.settingsMobileInner}>
            <IndexSetting channelId={channelId} index={index} mobile={true} onChange={(e) => this.setIndex(e.target.value)} />
            <Filters disabled={this._shouldDisableSettings()} filters={filters} index={index} onChange={this.setFilters} />
            <Sort disabled={this._shouldDisableSettings()} index={index} mobile={true} onChange={(e) => this.setSort(e.target.value)} sort={sort} />
          </div>
          <div className={styles.applyContainer}>
            <button className={`${buttonStyles.md} ${layout.fullWidth}`} onClick={this.handleApply}>
              Apply filters
            </button>
          </div>
        </section>
      </div>
    );
  }
}

SearchSettings.propTypes = {
  channelId: PropTypes.number,
  dismissSettings: PropTypes.func,
  handleSearchRequest: PropTypes.func,
  query: PropTypes.string,
  showSettings: PropTypes.bool,
};

SearchSettings.defaultProps = {
  channelId: null,
  dismissSettings: () => {},
  handleSearchRequest: () => Promise.resolve(),
  query: '',
  showSettings: false,
};

export default SearchSettings;
