import React from 'react';
import PropTypes from 'prop-types';

// HOC
import adAnalyticsTracker from '../wrappers/adAnalyticsTracker';

import Image from '../../image';

import { adFAQLink } from '../../../constants/links';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './news_page_hero.css';

const NewsPageHeroAd = ({ ad, forwardedRef, initialized, onClick, onImageLoad }) => {
  if (!initialized) return (<div className={`${styles.placeholder} ${utilStyles.bgFog} ${utilStyles.borderRadius}`} />);
  if (initialized && ad === null) return null;

  return (
    <div ref={forwardedRef} className={styles.adContainer}>
      <div>
        <a
          href={ad.link}
          onClick={() => onClick(ad)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Image
            className={`${styles.desktopImage} ${utilStyles.borderRadius}`}
            lazy={true}
            onLoad={() => onImageLoad(ad)}
            ratio="27:50"
            setStyle={false}
            src={ad.cover_image.url}
            width={270}
          />
          <Image
            className={`${styles.mobileImage} ${utilStyles.borderRadius}`}
            lazy={true}
            onLoad={() => onImageLoad(ad)}
            ratio="4:1"
            setStyle={false}
            src={ad.mobile_cover_image.url}
            width={480}
          />
        </a>
      </div>
      <div className={`${layout.flexJustifyEnd}`}>
        <a
          className={`${typography.bodyS} ${typography.linkCharcoal}`}
          href={adFAQLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Ad
        </a>
      </div>
    </div>
  );
};

NewsPageHeroAd.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ad_type: PropTypes.string.isRequired,
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    link: PropTypes.string.isRequired,
  }),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  initialized: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onImageLoad: PropTypes.func.isRequired,
};

NewsPageHeroAd.defaultProps = { ad: null };

export default adAnalyticsTracker(NewsPageHeroAd);
