import React from 'react';
import PropTypes from 'prop-types';

import CheckboxGroup from '../../../../client/form_components/inputs/checkbox_group';
import FilterLabel from './FilterLabel';

import { VIDEO_CATEGORIES } from '../../../../graphql/videos/enums';
const VIDEO_CATEGORY_OPTIONS = VIDEO_CATEGORIES.map((key) => ({ label: key, value: key }));

import layout from '../../../../styles/global_ui/layout.css';

const EventFilters = ({ disabled, filters, onChange }) => (
  <section>
    <CheckboxGroup
      classList={{ root: layout.marginBottom30 }}
      disabled={disabled}
      label={<FilterLabel disabled={disabled} label="Category" />}
      name="difficulty"
      onChange={(value) => onChange({ category: value })}
      options={VIDEO_CATEGORY_OPTIONS}
      values={filters.category}
    />
  </section>
);

EventFilters.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.shape({ category: PropTypes.array }),
  onChange: PropTypes.func,
};

EventFilters.defaultProps = {
  disabled: false,
  filters: {},
  onChange: () => {},
};

export default EventFilters;
