import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ButtonDropdown from '../button_dropdown';
import Icon from '../../icon';

import keenService from '../../../services/keen/main';
import errorHandler from '../../../services/error_handler';

import styles from './buy_button.css';
import buttonStyles from '../../../styles/global_ui/buttons.css';
import dropdownStyles from '../../../styles/common-dropdown.css';
import layout from '../../../styles/global_ui/layout.css';

class BuyButton extends Component {
  constructor(props) {
    super(props);

    this.trackBuyClick = this.trackBuyClick.bind(this);
  }

  /**
   * Views
   */
  _getDropdownLabel(className) {
    return <Icon className={className} name="arrow-down" size={16} />;
  }

  trackBuyClick(customProps) {
    keenService.recordEvent({ eventName: 'Clicked part buy link' }, customProps)
      .catch((err) => errorHandler(err));
  }

  render() {
    if (!this.props.stores.length) return null;
    const { stores } = this.props;

    return (
      <div className={`${styles.root} ${layout.marginRight10}`}>
        <a
          className={buttonStyles.md}
          href={stores[0].trackable_link}
          onClick={() => this.trackBuyClick({
            part_id: this.props.partId,
            link_id: stores[0].id,
            retailer_id: stores[0].retailer && stores[0].retailer.id,
            link_position: 0,
          })}
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          Buy now
        </a>
        {stores.length > 1
        && (
          <Fragment>
            <span className={styles.divider}></span>
            <ButtonDropdown
              alignRight={true}
              classList={{ button: `${buttonStyles.md} ${styles.dropdownButton}` }}
              closeOnSelect={true}
              label={this._getDropdownLabel()}
              labelOpen={this._getDropdownLabel(styles.arrowUp)}
            >
              <ul className={dropdownStyles.list}>
                {stores.map((store, i) => (
                  <li key={store.id} className={dropdownStyles.listItem}>
                    <a
                      className={dropdownStyles.link}
                      href={store.trackable_link}
                      onClick={() => this.trackBuyClick({
                        part_id: this.props.partId,
                        link_id: store.id,
                        retailer_id: store.retailer && store.retailer.id ? store.retailer.id : null,
                        link_position: i + 1,
                      })}
                      rel="noopener nofollow noreferrer"
                      target="_blank"
                    >
                      {store.name}
                    </a>
                  </li>
                ))}
              </ul>
            </ButtonDropdown>
          </Fragment>
        )}
      </div>
    );
  }
}

BuyButton.propTypes = {
  partId: PropTypes.number.isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    retailer: PropTypes.shape({ id: PropTypes.number }),
    trackable_link: PropTypes.string.isRequired,
  })).isRequired,
};

export default BuyButton;
