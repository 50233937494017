import { mapifyStringQuery } from '../../utility/converters';

const CONFIG = {
  '/': (location) => {
    const queryMap = mapifyStringQuery(location.search);

    return {
      title: `Explore all projects - Page ${queryMap.page || 1} - Hackster.io`,
      pageType: 'projects#index',
    };
  },
};

const seoConfig = (path) => CONFIG[path];

export default seoConfig;
