import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../../../client/icon';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import cardStyles from '../../../cards/cards.css';
import styles from './custom_checkbox_card.css';

const CheckboxCard = ({
  children,
  checkboxStyles,
  isChecked,
  isDisabled,
  name,
  onBlur,
  onChange,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  value,
  wrapperStyles,
}) => {
  const handleClick = (e) => onChange({ value: value, checked: e.target.checked });

  return (
    <label
      className={`${layout.marginBottom0} ${styles.label} ${wrapperStyles}`}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        checked={isChecked}
        className={`${utilStyles.srOnly}`}
        disabled={isDisabled}
        name={name}
        onChange={handleClick}
        type="checkbox"
        value={value}
      />
      <div
        className={`
        ${isDisabled ? '' : utilStyles.cursorPointer}
        ${cardStyles.cardWithBorder}
        ${styles.card}
        ${isChecked ? styles.selected : utilStyles.border}
        ${checkboxStyles}`}
      >
        {isChecked ? <Icon className={`${utilStyles.posAbsolute} ${utilStyles.bgHackster} ${utilStyles.circle} ${typography.white} ${styles.icon}`} name="checkmark" /> : null}
        {children}
      </div>
    </label>
  );
};

CheckboxCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  value: PropTypes.any.isRequired,
};

export default CheckboxCard;
