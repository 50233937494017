import React from 'react';
import PropTypes from 'prop-types';

import LinkList from '../../link_list';

import typography from '../../../../styles/global_ui/typography.css';
import styles from './nav_dropdown.css';

const _getHeader = ({ column, trackLinkClick }) => (
  column.linkList && column.linkList.viewAllLink
    ? (
      <a
        className={typography.linkCharcoal}
        href={column.linkList.viewAllLink.url}
        onClick={(e) => trackLinkClick({ e, type: 'column-header' })}
      >
        {column.header}
      </a>
      )
    : column.header
);

const MenuColumn = ({ column, trackLinkClick }) => (
  <div className={styles.column}>
    {column.header
    && (
      <div className={`${typography.bodyM} ${typography.bold}`}>
        {_getHeader({ column, trackLinkClick })}
      </div>
    )}
    <LinkList
      className={styles.linkList}
      items={column.linkList.items}
      trackLinkClick={trackLinkClick}
      viewAllLink={column.linkList.viewAllLink}
    />
  </div>
);

MenuColumn.propTypes = {
  column: PropTypes.shape({
    header: PropTypes.string,
    linkList: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })).isRequired,
      viewAllLink: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  trackLinkClick: PropTypes.func,
};

MenuColumn.defaultProps = { trackLinkClick: () => {} };

export default MenuColumn;
