import PropTypes from 'prop-types';
import React, { Component } from 'react';
import store from '../../../services/user_relations_store';
import currentUser from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';

class CurrentUserWrapper extends Component {
  constructor(props) {
    super(props);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.state = { currentUser: null };
  }

  componentDidMount() {
    return currentUser.getStoreAsync()
      .then((user) => {
        /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
        /* eslint-disable-next-line no-prototype-builtins */
        const hasUser = user && user.hasOwnProperty('id');

        if (hasUser) {
          store.set('currentUser', user);
        }
        //  We respond with null here if the user has no id, meaning the user is not logged in.
        //  UUID will be the only property in this case, which is for tracking.
        this.setState({ currentUser: user });
        this.props.onResolve(user);
      })
      .catch((err) => errorHandler('CurrentUserWrapper Error:', err));
  }

  getCurrentUser() {
    return this.state.currentUser;
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

CurrentUserWrapper.propTypes = {
  onResolve: PropTypes.func,
  propsToFetch: PropTypes.array, // TODO: Use this as a way to optionally get currentUser data when we switch to a GraphQL request.
};

CurrentUserWrapper.defaultProps = {
  onResolve: () => {},
  propsToFetch: [],
};

export default CurrentUserWrapper;
