import React from 'react';
import PropTypes from 'prop-types';

import ContestSponsorsList from './helpers/ContestSponsorsList';
import LazyBackgroundImage from '../../image/LazyBackgroundImage';
import ProBadge from '../../reusable_components/Badge/ProBadge';

import { windowLocationRedirect } from '../../../services/window';

import { getButtonProps } from './index';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './contest_card.css';

import { MEMBERSHIP_TIERS } from '../../../scenes/contests_page/enum.js';

/**
 * Views
 */
const SponsorsView = ({ sponsors }) => {
  if (sponsors.length === 0) return (<span className={layout.flex1} />);

  return (
    <ContestSponsorsList
      classList={{
        root: `${layout.flex1} ${layout.hiddenMedLargeDown}`,
        typography: typography.bodyXS,
      }}
      sponsors={sponsors}
      sponsorsConfig={{ limit: 100, menuPos: { alignRight: false, alignTop: true } }}
    />
  );
};

/**
 * Main Component
 */
const BannerSmallContestCard = ({ contest, userTier }) => {
  const { href: buttonHref, label: buttonLabel } = getButtonProps({ required_user_tier: contest.required_user_tier, status: contest.status, url: contest.url, userTier });

  return (
    <div className={styles.bannerCard}>
      <a href={contest.url}>
        <LazyBackgroundImage
          alt={contest.name}
          classList={{ root: `${utilStyles.absolutePlaceholderChild} ${utilStyles.borderRadius}` }}
          ratio="4:1"
          setStyle={false}
          src={contest.cover_image.url}
          width={570}
        />
      </a>

      <div className={styles.bannerCardOverlay} onClick={() => windowLocationRedirect(contest.url)}>
        {contest.required_user_tier === 'PRO' && (
          <ProBadge
            classList={{ container: styles.proIconMobileBg }}
            contentType="contest"
            size="small"
            tooltipProps={{ classList: { btn: `${layout.widthFit} ${styles.proIconPastContests}` } }}
          />
        )}
        <p className={`${typography.bodyS} ${typography.bold} ${typography.charcoal} ${layout.marginBottom5} ${styles.bannerCardOverlayName}`}>
          {contest.name}
        </p>

        <SponsorsView sponsors={contest.sponsors} />

        <a className={`${buttonStyles.sm} ${buttonStyles.hackster} ${buttonStyles.pill} ${layout.alignSelfStart} ${layout.hiddenMedLargeDown}`} href={buttonHref}>
          {buttonLabel}
        </a>
      </div>
    </div>
  );
};

BannerSmallContestCard.propTypes = {
  contest: PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    required_user_tier: PropTypes.oneOf(MEMBERSHIP_TIERS).isRequired,
    status: PropTypes.string.isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    url: PropTypes.string.isRequired,
  }),

};

export default BannerSmallContestCard;
