import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../client/buttons/base';
import Icon from '../../../client/icon';

import { formatNumberWithCommas, pluralize, capitalize } from '../../../utility/formatters';
import { indexToLabel } from '../algoliaIndexes';

import styles from './results_list.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import shouldAllowEmptySearchQuery from '../../../services/algolia/shouldAllowEmptySearchQuery';
import joinComponents from '../../../client/utility_components/joinComponents';

const _getCountText = (count) => count ? formatNumberWithCommas(count) : 'No';

const _getFilterBreadcrumb = ({ algoliaIndex, appliedFilters }) => {
  switch (algoliaIndex) {
    case 'events':
      return appliedFilters.event_type ? capitalize(appliedFilters.event_type) : '';
    default:
      return '';
  }
};

const _getIndexBreadcrumb = (algoliaIndex) => {
  switch (algoliaIndex) {
    case 'events':
      return (
        <a className={typography.link} href="/events">
          {indexToLabel(algoliaIndex)}
        </a>
      );
    default:
      return indexToLabel(algoliaIndex);
  }
};

const _getQueryBreadCrumb = (query) => (
  query ? (<span className={typography.bold}>{`"${query}"`}</span>) : null
);

const _getHeaderText = ({ algoliaIndex, appliedFilters, currentQuery, loading, totalRecords }) => {
  if (loading) return `Searching for "${currentQuery}" in ${indexToLabel(algoliaIndex)}...`;
  if (!currentQuery && !shouldAllowEmptySearchQuery(algoliaIndex)) return 'Search using the box above!';

  const breadCrumbs = [
    _getIndexBreadcrumb(algoliaIndex),
    _getFilterBreadcrumb({ algoliaIndex, appliedFilters }),
    _getQueryBreadCrumb(currentQuery),
  ].filter((b) => b);

  return (
    <span>
      {joinComponents(breadCrumbs, ' — ')}
      {` (${_getCountText(totalRecords)} ${pluralize('result', totalRecords)})`}
    </span>
  );
};

const ResultsHeader = ({ algoliaIndex, appliedFilters, currentQuery, loading, toggleFilterPanel, totalRecords }) => {
  if (loading && !currentQuery) return null;
  const filterCount = Object.keys(appliedFilters).length;

  return (
    <header className={`${layout.fullWidth} ${layout.flexJustifySpaceBetween} ${layout.flexCenterItems}`}>
      <p className={typography.bodyM}>
        {_getHeaderText({ algoliaIndex, appliedFilters, currentQuery, loading, totalRecords })}
      </p>
      {!loading
      && (
        <Button
          className={`${layout.hiddenMedUp} ${layout.flexCenterItems}`}
          colorStyle="blank"
          onClick={toggleFilterPanel}
        >
          {filterCount > 0
          && (
            <div className={`${styles.appliedFiltersCount} ${typography.bodyS} ${layout.marginLeft10} `}>
              {filterCount.toString()}
              {/* toString is for tests */}
            </div>
          )}
          <Icon className={typography.pebble} name="filters" />
        </Button>
      )}
    </header>
  );
};

ResultsHeader.propTypes = {
  algoliaIndex: PropTypes.string.isRequired,
  appliedFilters: PropTypes.object,
  currentQuery: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  toggleFilterPanel: PropTypes.func.isRequired,
  totalRecords: PropTypes.number,
};
ResultsHeader.defaultProps = {
  appliedFilters: {},
  totalRecords: 0,
};

export default ResultsHeader;
