import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserCard from '../../../cards/user_card';
import UserRelationButton from '../../../../server/user/relation_button';

import { formatNumberLimit4Chars, pluralize } from '../../../../utility/formatters';

import layoutStyles from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

class PlatformMemberCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { avatar_url, id, name, stats, url } = this.props.item;

    return (
      <UserCard
        avatarBorder={false}
        avatarMargin={15}
        avatarSize={60}

        user={{ avatar_url, id, name, url }}
      >
        <div className={`${typography.bodyS} ${typography.pebble} ${layoutStyles.marginBottom10}`}>
          {`${formatNumberLimit4Chars(stats.live_projects)} ${pluralize('project', stats.live_projects)} • ${formatNumberLimit4Chars(stats.followers)} ${pluralize('follower', stats.followers)}`}
        </div>
        <div>
          <UserRelationButton
            id={id}
            type="followed_user_sm"
          />
          <a
            className={`${typography.linkBlue} ${typography.bold} ${typography.bodyS} ${layoutStyles.marginLeft15}`}
            href={`/messages/new?recipient_id=${id}`}
          >
            Contact
          </a>
        </div>
      </UserCard>
    );
  }
}

PlatformMemberCard.propTypes = {
  item: PropTypes.shape({
    avatar_url: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    stats: PropTypes.shape({
      followers: PropTypes.number,
      live_projects: PropTypes.number,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default PlatformMemberCard;
