import React from 'react';
import styles from './platform_member_card.css';

import DummyUserCard from '../../../cards/user_card/DummyCard';

const DummyPlatformMemberCard = () => (
  <DummyUserCard
    avatarBorder={false}
    avatarMargin={15}
    avatarSize={60}
  >
    <div className={styles.dummyStats} />
    <div className={styles.dummyButton} />
  </DummyUserCard>
);

export default DummyPlatformMemberCard;
