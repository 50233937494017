import React from 'react';
import PropTypes from 'prop-types';

import ArticlesList from '../shared/articles_list';
import LazyComponent from '../../../client/wrappers/lazy_component';
import gridStyles from '../../../styles/global_ui/grid.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const MoreArticlesSection = ({ injectableConfig, getArticles, isBusy, pagination, records }) => (
  <div>
    <h4 className={`${typography.h4} ${layout.paddingBottom22}`}>
      All articles
    </h4>

    {/* minHeight: 1 to force intersection on MS Edge */}
    <LazyComponent onReveal={getArticles} style={{ minHeight: 1 }}>
      <ArticlesList
        articles={{ records, metadata: pagination }}
        className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
        gutterSize={15}
        handlePaginatorClick={getArticles}
        injectableConfig={injectableConfig}
        isBusy={isBusy}
      />
    </LazyComponent>
  </div>
);

MoreArticlesSection.propTypes = {
  getArticles: PropTypes.func.isRequired,
  injectableConfig: PropTypes.shape({
    component: PropTypes.func,
    index: PropTypes.number,
    props: PropTypes.shape({
      ad: PropTypes.object,
      initialized: PropTypes.bool,
    }),
  }),
  isBusy: PropTypes.bool,
  pagination: PropTypes.shape({
    current_page: PropTypes.number,
    next_page: PropTypes.number,
    per_page: PropTypes.number,
    prev_page: PropTypes.number,
  }).isRequired,
  records: PropTypes.arrayOf(PropTypes.object),
};

MoreArticlesSection.defaultProps = {
  injectableConfig: null,
  isBusy: false,
  records: null,
};

export default MoreArticlesSection;
