import PropTypes from 'prop-types';
import React from 'react';

import { CardImage, SubTitle, Title } from '../../sub_components';

import styles from './vertical.css';

const Thumb = ({ id, image, subTitle, title }) => (
  <div className={styles.thumb}>
    <CardImage captureScroll={true} classList={{ container: styles.thumbImageContainer }} style={{ width: '120px' }} {...image} />
    <div className={styles.thumbContent}>
      <Title linkTo={title.linkTo} text={title.text} />
      <SubTitle classList={{ subTitle: styles.subTitle }} content={subTitle} withCommas={false} />
    </div>
  </div>
);

Thumb.propTypes = {
  id: PropTypes.number,
  image: PropTypes.shape({
    linkTo: PropTypes.string,
    shape: PropTypes.string,
    url: PropTypes.string,
  }),
  subTitle: PropTypes.arrayOf(PropTypes.shape({
    linkTo: PropTypes.string,
    text: PropTypes.string,
  })),
  title: PropTypes.shape({
    linkTo: PropTypes.string,
    text: PropTypes.string,
    additionalInfo: PropTypes.string,
  }),
};

Thumb.defaultProps = {}; // fill out default props

export default Thumb;
