import React from 'react';

import LazyImage from '../../client/reusable_components/LazyImage';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import styles from './business.css';

const c = {
  section: `${layout.flexCenterCenter} ${layout.gutter5} ${layout.fullWidth} ${styles.hero}`,
  header: `${typography.h1Responsive} ${typography.staticWhite}`,
  subHeading: `${typography.h3Responsive} ${typography.fontWeightNormal} ${typography.staticWhite}`,
  rightImage: styles.rightImage,
};

const Hero = () => (
  <section className={c.section}>
    <div className={styles.left}>
      <h1 className={c.header}>Hackster for business</h1>
      <h2 className={c.subHeading}>The biggest names in tech partner with Hackster.io because we are one of the largest and most trusted hardware developer communities in the world.</h2>
    </div>
    <LazyImage
      alt="Hackster builds awareness, Generates MQLs, Engages users, and Drives technology adoption"
      classList={{ root: c.rightImage }}
      ratio="442:297"
      src="https://hackster.imgix.net/static/marketing/rocket.png"
      width={500}
    />
  </section>
);

export default Hero;
