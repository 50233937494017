import GraphQLBaseService from '../base_service';
import queryBuilder from './query_builder';

const GET_BOOKMARKS_QL = 'current_user_bookmark_lists';
const queryString = () => ({ t: GET_BOOKMARKS_QL });

export default class GraphQLBookmarkListsService extends GraphQLBaseService {
  constructor({ history } = {}) {
    super({ history, queryBuilder, queryString });
  }
}
