import React from 'react';
import PropTypes from 'prop-types';

import AsyncCheckbox from '../../../../client/form_components/checkboxes/async';
import Icon from '../../../../client/icon';
import { updateChallengeRegistration } from '../../../../requests/challenges';
import { isPendingVerification, isVerified } from '../../../../utility/user_helpers';
import { VERIFICATION_LINK } from '../../../tiers/constants';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './post_registration_dialog.css';

const PostRegistrationDialog = ({
  activate_email_sharing,
  after_registration_tweet,
  challengeId,
  email_sharing_label,
  facebook_url,
  reddit_url,
  twitter_url,
  userTier,
}) => {
  const verified = isVerified(userTier);
  const pendingVerification = isPendingVerification(userTier);
  return (
    <div className={styles.prPrompt}>
      <h2 className={`${typography.h2} ${layout.marginBottom15}`}>
        Thanks for your interest!
      </h2>
      {verified
        ? (
          <p className={`${typography.bodyL} ${layout.marginBottom15}`}>
            Now don&apos;t forget to submit your entry!
          </p>
          )
        : pendingVerification
          ? (
            <p>
              We are working on getting your verification processed. You can get
              started now! You&apos;ll be notified regarding your verification
              status.
            </p>
            )
          : (
            <p className={`${typography.bodyL} ${layout.marginBottom15}`}>
              In order to participate in this contest, you must get verified. Head
              over to your email and follow the instructions, or
              {' '}
              <a href={VERIFICATION_LINK} rel="noopener noreferrer" target="_blank">
                learn more
              </a>
              {' '}
              about getting verified.
            </p>
            )}

      {activate_email_sharing && (
        <div
          className={`${layout.marginBottom15} ${layout.flexJustifyCenter} ${typography.textLeft}`}
        >
          <AsyncCheckbox
            checkboxPosition="top"
            classList={{ label: typography.bold }}
            label={email_sharing_label}
            request={updateChallengeRegistration}
            requestArgs={(checked) => ({
              challenge_id: challengeId,
              challenge_registration: { receive_sponsor_news: checked },
            })}
            successMsg="You're subscribed"
          />
        </div>
      )}
      <p className={`${typography.bodyM} ${layout.marginBottom15}`}>
        In the meantime, tell your friends:
      </p>
      <div className={styles.prTweetContainer}>
        <div className={styles.prTweet}>{after_registration_tweet}</div>
        <div className={styles.triangleDown}></div>
      </div>

      <div>
        <div className={styles.socialBtnWrapper}>
          <a
            className={`${styles.socialBtn} ${styles.socialBtnF}`}
            href={facebook_url}
            rel="noreferrer"
            target="_blank"
          >
            <i className="fa fa-facebook"></i>
            <span>Facebook</span>
          </a>
        </div>

        <div className={styles.socialBtnWrapper}>
          <a
            className={`${styles.socialBtn} ${styles.socialBtnT}`}
            href={twitter_url}
            rel="noreferrer"
            target="_blank"
          >
            <Icon className={layout.marginRight5} name="x" size="12" />
            <span>X</span>
          </a>
        </div>

        <div className={styles.socialBtnWrapper}>
          <a
            className={`${styles.socialBtn} ${styles.socialBtnR}`}
            href={reddit_url}
            rel="noreferrer"
            target="_blank"
          >
            <i className="fa fa-reddit-alien"></i>
            <span>Reddit</span>
          </a>
        </div>
      </div>
    </div>
  );
};

PostRegistrationDialog.propTypes = {
  activate_email_sharing: PropTypes.bool,
  after_registration_tweet: PropTypes.string.isRequired,
  challengeId: PropTypes.number.isRequired,
  email_sharing_label: PropTypes.string,
  facebook_url: PropTypes.string.isRequired,
  reddit_url: PropTypes.string.isRequired,
  twitter_url: PropTypes.string.isRequired,
  userTier: PropTypes.oneOf([
    'BASIC',
    'HACKSTER',
    'PENDING',
    'PRO',
    'VERIFIED',
  ]),
};

export default PostRegistrationDialog;
