import { QUESTION_NAME, SURVEY_SLUG } from '../SurveyPopup/enums';
import typography from '../../../styles/global_ui/typography';

export const VERSION = 1.0;

export const json = {
  title: SURVEY_SLUG.ACTIVE_GENERAL_FEEDBACK,
  version: VERSION,
  showTitle: false,
  showQuestionNumbers: false,
  completeText: 'Submit',
  pagePrevText: '< Back',
  firstPageIsStarted: true,
  startSurveyText: 'Start Survey',
  goNextPageAutomatic: true,
  completedHtml: `<h4 class='${typography.h4} ${typography.textCenter}'>Thank you!</h4><p class='${typography.bodyXS} ${typography.textCenter}'>Your feedback was received and will greatly help us improve Hackster.</p>`,
  pages: [
    {
      name: 'Start',
      questions: [
        {
          type: 'html',
          html: `<h4 class='${typography.h4} ${typography.textCenter}'>Thanks for visiting Hackster!</h4><p class='${typography.bodyXS} ${typography.textCenter}'>We would love to get some feedback from you.</p>`,
        }],
    },
    {
      name: 'Feeling',
      questions: [
        {
          type: 'rating',
          name: `${QUESTION_NAME.FEELING}`,
          title: 'How was your recent experience with Hackster?',
          itemComponent: 'RobotRating',
          rateCount: 5,
          rateMax: 5,
          displayMode: 'buttons', // this forces options to be inline on one row.
          isRequired: true,
          errorLocation: 'bottom',
        },
      ],
    },
    {
      name: 'Account',
      questions: [
        {
          type: 'radiogroup',
          itemComponent: 'RadioGroup',
          isRequired: true,
          errorLocation: 'bottom',
          name: `${QUESTION_NAME.ACCOUNT}`,
          title: 'Do you have a Hackster account?',
          choices: [
            {
              value: 'true',
              text: 'Yes',
            },
            {
              value: 'false',
              text: 'No',
            },
          ],
        },
      ],
    },
    {
      name: 'Visit frequency',
      questions: [
        {
          type: 'radiogroup',
          itemComponent: 'RadioGroup',
          name: `${QUESTION_NAME.VISIT}`,
          title: 'How often do you visit Hackster?',
          choices: [
            {
              value: 'weekly',
              text: 'Weekly',
            },
            {
              value: 'monthly',
              text: 'Monthly',
            },
            {
              value: 'rarely',
              text: 'Rarely',
            },
            {
              value: 'first',
              text: 'First time',
            },
          ],
        },
      ],
      visibleIf: `{${QUESTION_NAME.ACCOUNT}} = 'false'`,
    },
    {
      name: 'Feature request',
      questions: [
        {
          type: 'comment',
          name: 'feature-request',
          title: 'Are there any features or benefits that you would drive you to sign up?',
        },
      ],
      visibleIf: `{${QUESTION_NAME.ACCOUNT}} = 'false'`,
    },
    {
      name: 'Like most',
      questions: [
        {
          type: 'comment',
          name: 'like-most',
          title: 'What do you like **most** about Hackster?',
        },
      ],
      visibleIf: `{${QUESTION_NAME.ACCOUNT}} = 'true'`,
    },
    {
      name: 'Like least',
      questions: [
        {
          type: 'comment',
          name: 'like-least',
          title: 'What do you like **least** about Hackster?',
        },
      ],
      visibleIf: `{${QUESTION_NAME.ACCOUNT}} = 'true'`,
    },
    {
      name: 'Comments',
      questions: [
        {
          type: 'comment',
          name: `${QUESTION_NAME.FEEDBACK}`,
          title: 'Any other feedback?',
        },
      ],
    },
  ],
};
