import React from 'react';
import PropTypes from 'prop-types';

import { isCurrentPath, history, Link } from '../../../client/reusable_components/Router';
import layout from '../../../styles/global_ui/layout.css';
import navBarStyles from '../../../styles/navbar.css';
import styles from './header.css';

const NavBar = ({ initPath, externalLinkRoot, routes, transition }) => (
  <div className={`${navBarStyles.center} ${navBarStyles.themeLight}`}>
    <ul className={`${navBarStyles.list} ${layout.noScrollBar} ${styles.navList}`}>
      {routes.map((route, i) => (
        <li
          key={i}
          className={navBarStyles.listItem}
        >
          <Link
            classList={{
              anchor: isCurrentPath(history, route, initPath) ? navBarStyles.linkFocused : navBarStyles.link,
              root: isCurrentPath(history, route, initPath) ? navBarStyles.linkWrapperFocused : navBarStyles.linkWrapper,
            }}
            href={route.href}
            links={{
              external: `/${externalLinkRoot}${route.href}`,
              internal: route.href,
            }}
            name={route.name}
            onClick={() => transition(route.href)}
            redirect={route.redirect}
          />
        </li>
      ))}
    </ul>
  </div>
);

NavBar.propTypes = {
  currentPath: PropTypes.string.isRequired,
  externalLinkRoot: PropTypes.string.isRequired,
  isWorking: PropTypes.bool.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  transition: PropTypes.func.isRequired,
};

NavBar.defaultProps = {};

export default NavBar;
