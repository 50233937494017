import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionsSection from '../../../form_components/templates/ActionsSection';
import AvatarUploader from '../../../form_components/image_uploaders/avatar_uploader';
import BasicFormInput from '../../../form_components/inputs/basic_form_input';
import DecimalInput from '../../../form_components/inputs/decimal_input';
import FormLocationSelect from '../../../form_components/selects/form_location_select';
import FormSelect from '../../../form_components/selects/form_select';
import RadioGroup from '../../../form_components/inputs/radio_group';

import errorHandler from '../../../../services/error_handler';
import urlService from '../../../../services/url_service';

import { boolOrNullIn, boolOrNullOut, imageWithNestedFileIn, imageWithNestedFileOut, locationSelectIn, locationSelectOut, arrayToMultiSelectIn, multiSelectToArrayOut } from '../../../../utility/forms/formatters';
import { isBlank } from '../../../../utility/types';
import { filterObject } from '../../../../utility/filters';
import { getErrorForField, getFieldValuesAsObject, initFields, setIsBusy, scrollToError, setStateOrError, validateFields } from '../../../../utility/forms';
import { getFirstString } from '../../../../utility/accessors';
import { imageV, isDecimal, maxLength, minLength } from '../../../../services/validation/validators';
import OAuth from '../../../../services/oauth';
import { summonGlobalMessenger } from '../../../../utility/dispatchers';
import { transformObjValues } from '../../../../utility/converters';
import { updateUserProfile } from '../../../../requests/dashboard';

import formStyles from '../../../../styles/global_ui/forms.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './profile_form.css';

const FIELDS_TEMPLATE = {
  avatar: { order: 0, validate: (value) => imageV(value), value: {}, formatIn: imageWithNestedFileIn, formatOut: imageWithNestedFileOut },
  available_for_hire: { order: 7, validate: () => null, value: null, formatIn: boolOrNullIn, formatOut: boolOrNullOut, notRequired: true },
  bio: { order: 4, validate: (value) => maxLength(140, value), value: '', notRequired: true },
  hourly_rate: { order: 8, validate: (value) => isDecimal(value), value: '', notRequired: true },
  location: { order: 3, validate: (value) => null, value: {}, notRequired: true, formatIn: locationSelectIn, formatOut: locationSelectOut },
  name: { order: 1, validate: (value) => null, value: '', notRequired: true },
  skills: { order: 6, validate: (value) => null, value: [], notRequired: true, formatIn: (v) => arrayToMultiSelectIn(v), formatOut: (v) => multiSelectToArrayOut(v) },
  user_name: { order: 2, validate: (value) => minLength(3, value), value: '' },
  website: { order: 5, validate: (value) => null, value: '', notRequired: true },
};

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    const storedFields = props.store.has(props.path) ? this._getStoredFields() : null;
    const fields = storedFields || initFields(FIELDS_TEMPLATE, this._nestLocation(props.currentUser));
    if (!storedFields) this._setStoredFields(fields);

    this.state = {
      errors: {},
      fields,
      hasUnsavedChanges: false,
      isBusy: false,
      warnings: {},
      workers: [],
    };

    // Form helpers
    this.getErrorForField = getErrorForField.bind(this);
    this.getFieldValuesAsObject = getFieldValuesAsObject.bind(this);
    this.setIsBusy = setIsBusy.bind(this);
    this.setStateOrError = setStateOrError.bind(this);
    this.validate = validateFields.bind(this);

    this.setStateAndFlagUnsaved = this.setStateAndFlagUnsaved.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Methods
   */
  discardChanges() {
    this.setState({ fields: this._getStoredFields(), errors: {}, warnings: {} });

    this._flagUnsaved(false);
    summonGlobalMessenger({ msg: 'Your changes have been discarded.' });
  }

  handleSubmit() {
    if (!this.state.hasUnsavedChanges) return;
    if (this.validate()) this._submitForm();
  }

  setStateAndFlagUnsaved(errorMsg, key, value) {
    this.setStateOrError(errorMsg, key, value);
    this._flagUnsaved(true);
  }

  /**
   * Helpers
   */
  _flagUnsaved(hasUnsavedChanges) {
    if (this.state.hasUnsavedChanges !== hasUnsavedChanges) {
      this.setState({ hasUnsavedChanges });
      this.props.store.set('showPrompt', hasUnsavedChanges);
    }
  }

  _flattenForSubmission(values) {
    return { ...values, ...values.location, avatar_id: values.avatar.id };
  }

  _getFieldErrorsFromServerError(err) {
    const filteredErrors = filterObject(
      OAuth.processApiRequestError(err),
      /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
      /* eslint-disable-next-line no-prototype-builtins */
      (val, key) => this.state.fields.hasOwnProperty(key),
    );

    return transformObjValues(filteredErrors, getFirstString);
  }

  _getProfilePath() {
    return urlService.url(`/${this.state.fields.user_name.value}`);
  }

  _getStoredFields() {
    return this.props.store.get(this.props.path).fields;
  }

  _isSubmitting() {
    return this.state.workers.includes('submit');
  }

  _nestLocation(values) {
    const { city, country_iso2, state, ...otherValues } = values;

    return { ...otherValues, location: { city, country_iso2, state } };
  }

  _setStoredFields(fields) {
    this.props.store.set(this.props.path, { fields });
  }

  _submitForm() {
    this.setIsBusy(true, 'submit');
    this.setState({ warnings: {} });

    const fields = { ...this.state.fields }; // make a shallow copy - trust that no mutations happen within field objects
    const values = this._flattenForSubmission(this.getFieldValuesAsObject(fields));

    return updateUserProfile(values)
      .then((profile) => {
        this.setIsBusy(false, 'submit');
        this._setStoredFields(fields);
        this._flagUnsaved(false);

        summonGlobalMessenger({ msg: this._getSavedMsg() });
      })
      .catch((err) => {
        this.setIsBusy(false, 'submit');
        const errors = this._getFieldErrorsFromServerError(err);

        if (!isBlank(errors)) {
          // field-specific errors exist, treat it like client-side validation
          this.setState({ errors }, () => scrollToError(errors, this.state.fields));
        } else {
          // Unkown server error, show the default message.
          summonGlobalMessenger({ msg: 'Unable to save changes. Please try again.', type: 'error' });
          errorHandler(err);
        }
      });
  }

  /**
   * Views
   */
  _getSavedMsg() {
    return (
      <span>
        {'Your changes have been saved. '}
        <a href={this._getProfilePath()}>View your profile.</a>
      </span>
    );
  }

  render() {
    const isSubmitting = this._isSubmitting();

    return (
      <div
        className={`${layout.container} ${layout.marginTop60} ${this.props.classNames.root}`}
      >
        <div
          className={`${layout.wrapper960} ${layout.flexRowWrap} ${this.props.classNames.container}`}
        >
          <div className={styles.rowOne}>
            <div id="vfavatar">
              <AvatarUploader
                aspectRatio={1}
                disabled={isSubmitting}
                errors={this.state.errors.avatar}
                imageData={this.state.fields.avatar.value}
                propagateStatus={(status) => this.setIsBusy(status, 'avatar')}
                propagateUpload={(imageData) => this.setStateAndFlagUnsaved(null, 'avatar', imageData)}
                reportError={(error) => this.setState({ warnings: { ...this.state.warnings, avatar: error } })}
                warningText={this.state.warnings.avatar}
              />
            </div>
            <a
              className={`${typography.bodyM} ${typography.bold} ${typography.linkPebble} ${layout.marginTop15}`}
              href={this._getProfilePath()}
            >
              View Profile
            </a>
          </div>
          <div className={styles.rowTwo}>
            <div className={`${formStyles.container} ${layout.marginBottom60}`}>
              <div id="vfname">
                <BasicFormInput
                  disabled={isSubmitting}
                  errors={this.state.errors.name}
                  label="Name"
                  name="name"
                  onChange={(e) => this.setStateAndFlagUnsaved(null, 'name', e.target.value)}
                  value={this.state.fields.name.value}
                />
              </div>
              <div id="vfuser_name">
                <BasicFormInput
                  disabled={isSubmitting}
                  errors={this.state.errors.user_name}
                  helperText="Pick a username. This will be used as your profile url."
                  label="Username"
                  name="user_name"
                  onChange={(e) => this.setStateAndFlagUnsaved(
                    null,
                    'user_name',
                    e.target.value,
                  )}
                  value={this.state.fields.user_name.value}
                />
              </div>
              <div id="vflocation">
                <FormLocationSelect
                  disabled={isSubmitting}
                  errors={this.state.errors.location}
                  label="Location"
                  onChange={(location) => this.setStateAndFlagUnsaved(null, 'location', location)}
                  placeholder="E.g. Seattle, WA, USA"
                  renderDistanceInput={false}
                  value={this.state.fields.location.value}
                />
              </div>
              <div id="vfbio">
                <BasicFormInput
                  charCount={this.state.fields.bio.value.length}
                  classList={{ help: layout.paddingRight45 }}
                  disabled={isSubmitting}
                  element="textarea"
                  errors={this.state.errors.bio}
                  helperText="What do you do for a living? How long have you been developing for? How did you get started in hardware? Do not add links here."
                  label="Bio"
                  maxVal={140}
                  name="bio"
                  onChange={(e) => this.setStateAndFlagUnsaved(
                    maxLength(140, e.target.value),
                    'bio',
                    e.target.value,
                  )}
                  placeholder="Hardware engineer specializing in embedded systems, circuit design, PCB layout, and low-level integration for IoT."
                  value={this.state.fields.bio.value}
                />
              </div>
              {this.props.currentUser.stats.projects >= 1 && (
                <div id="vfwebsite">
                  <BasicFormInput
                    disabled={isSubmitting}
                    errors={this.state.errors.website}
                    helperText="Add a link to your personal website or social profiles."
                    label="Website"
                    name="website"
                    onChange={(e) => this.setStateAndFlagUnsaved(
                      null,
                      'website',
                      e.target.value,
                    )}
                    value={this.state.fields.website.value}
                  />
                </div>
              )}
              <div id="vfskills">
                <FormSelect
                  creatableOpts={{ creatable: true }}
                  disabled={isSubmitting}
                  errors={this.state.errors.skills}
                  helperText="List your top skills and areas of expertise. This helps us connect you with other developers and job opportunities. What languages do you develop in?  Do not add links here."
                  label="Skills"
                  onSelectedChange={(skills) => this.setStateAndFlagUnsaved(null, 'skills', skills)}
                  placeholder="3D Printing"
                  searchOpts={{ rule: 'absolute' }}
                  type="multi"
                  value={this.state.fields.skills.value}
                />
              </div>
              <div id="vfavailable_for_hire">
                <RadioGroup
                  buttons={[
                    { label: 'Available for hire', value: 'true' },
                    { label: 'Not available', value: 'false' },
                  ]}
                  disabled={isSubmitting}
                  errors={this.state.errors.available_for_hire}
                  label="Jobs"
                  name="available_for_hire"
                  onChange={(e) => this.setStateAndFlagUnsaved(
                    null,
                    'available_for_hire',
                    e.target.value,
                  )}
                  value={this.state.fields.available_for_hire.value}
                />
              </div>
              {boolOrNullOut(this.state.fields.available_for_hire.value) && (
                <div id="vfhourly_rate">
                  <DecimalInput
                    disabled={isSubmitting}
                    errors={this.state.errors.hourly_rate}
                    helperText="This is used as a reference point for potential employers. Compensation should be negotiated on a per project basis."
                    icon="$"
                    label="Rate per hour"
                    name="hourly_rate"
                    onChange={(hourly_rate) => this.setStateAndFlagUnsaved(
                      null,
                      'hourly_rate',
                      hourly_rate,
                    )}
                    value={this.state.fields.hourly_rate.value}
                  />
                </div>
              )}
              <ActionsSection
                disabled={this.state.isBusy || !this.state.hasUnsavedChanges}
                isBusy={isSubmitting}
                primaryBtnConfig={{
                  onClick: this.handleSubmit,
                  text: 'Save changes',
                }}
                secondaryBtnConfig={{ onClick: this.discardChanges }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProfileForm.propTypes = {
  classNames: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    available_for_hire: PropTypes.bool,
    avatar: PropTypes.shape({
      file: PropTypes.shape({ url: PropTypes.string }),
      id: PropTypes.number,
    }),
    bio: PropTypes.string,
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    email: PropTypes.string,
    hourly_rate: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    skills: PropTypes.array,
    state: PropTypes.string,
    user_name: PropTypes.string,
    website: PropTypes.string,
  }),
  history: PropTypes.object,
  path: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
};

ProfileForm.defaultProps = {
  classNames: {
    root: '',
    container: '',
  },
  currentUser: {
    avatar: {},
    user_name: '',
  },
  enableMessenger: true,
  history: {},
};

export default ProfileForm;
