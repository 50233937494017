import React from 'react';
import PropTypes from 'prop-types';

import GridList from '../../wrappers/grid_list';

import PlatformMemberCard from '../cards/platform_member_card';

const renderContentPlaceholder = () => (
  <div style={{ fontSize: 18, marginTop: 30, textAlign: 'center', width: '100%' }}>There are no members</div>
);

const PlatformMemberList = ({ isLoading, records }) => (
  <GridList
    ItemComponent={PlatformMemberCard}
    isLoading={isLoading}
    placeholder={renderContentPlaceholder}
    records={records}
  />
);

PlatformMemberList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  records: PropTypes.arrayOf(PropTypes.object),
};

PlatformMemberList.defaultProps = { records: [] };

export default PlatformMemberList;
