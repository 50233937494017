import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GraphQLPaginatedList from '../../../client/wrappers/graph_ql/GraphQLPaginatedList';
import ProjectsList from '../../../client/projects/projects_list';
import DummyProjectsList from '../../../client/projects/projects_list/DummyProjectsList';

import GraphQLProjectsService from '../../../services/graphql/projects_service';
import buttonStyles from '../../../styles/global_ui/buttons.css';
import layoutStyles from '../../../styles/global_ui/layout.css';

class ChannelProjects extends Component {
  constructor(props) {
    super(props);

    this.reportPageView = this.reportPageView.bind(this);
    this.qlService = new GraphQLProjectsService({ history: this.props.history });
  }

  renderNewProjectButton() {
    const { channel } = this.props;

    if (!channel.moderation_level || !channel.base_path || (channel.moderation_level && channel.moderation_level === 'readonly')) return;

    return (
      <div className={layoutStyles.marginBottom15}>
        <a className={buttonStyles.sm} href={`${channel.base_path}/projects/new`}>
          {channel.moderation_level === 'auto' ? 'Add a project' : 'Submit a project'}
        </a>
      </div>
    );
  }

  reportPageView() {
    this.props.seoHandler(this.props.path);
  }

  render() {
    return (
      <div className={layoutStyles.container}>
        <div className={`${layoutStyles.wrapper1170} ${layoutStyles.fullScreenHeight}`}>
          {this.renderNewProjectButton()}
          <GraphQLPaginatedList
            currentHistoryData={this.props.currentHistoryData}
            filters={this.props.filters}
            graphQLArguments={this.props.graphQLArguments}
            graphQLService={this.qlService}
            listComponent={ProjectsList}
            listProps={{ cardBorder: true }}
            loaderComponent={DummyProjectsList}
            reportPageView={this.reportPageView}
          />
        </div>
      </div>
    );
  }
}

ChannelProjects.propTypes = {
  canManage: PropTypes.bool,
  channel: PropTypes.shape({
    base_path: PropTypes.string,
    moderation_level: PropTypes.string,
  }).isRequired,
  currentHistoryData: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  })).isRequired,
  graphQLArguments: PropTypes.shape({ base_channel_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  history: PropTypes.object, // Router history
  path: PropTypes.string.isRequired,
  seoHandler: PropTypes.func.isRequired,
};

ChannelProjects.defaultProps = {
  canManage: false,
  currentHistoryData: null,
  graphQLArguments: {},
  history: {},
};

export default ChannelProjects;
