import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../client/buttons/base';
import LocationSelect, { DEFAULT_DISTANCE_OPT } from '../../../../client/form_components/selects/location_select';
import RingSpinner from '../../../../client/spinners/ring';

import errorHandler from '../../../../services/error_handler';
import getNearestCity from '../../../../services/mapbox/getNearestCity';

import inputStyles from '../../../../styles/global_ui/inputs.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

class LocationFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isBusy: false };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.state.isBusy) return;

    this.setState({ isBusy: true });
    getNearestCity()
      .then((formattedRecord) => {
        const location = {
          ...formattedRecord,
          distance: this.props.filters.distance || DEFAULT_DISTANCE_OPT.value,
        };
        this.props.onChange({ location });
        this.setState({ isBusy: false });
      })
      .catch((err) => {
        this.setState({ isBusy: false });
        errorHandler(err);
      });
  }

  /**
   * helpers
   */
  _getButtonOrSpinner() {
    if (this.state.isBusy) return (<RingSpinner size={16} />);

    return (
      <Button
        colorStyle="blank"
        disabled={this.props.disabled}
        onClick={this.handleClick}
      >
        <span className={`${typography.linkBlue} ${typography.bodyS} ${this.props.disabled ? typography.linkDisabled : ''}`}>
          Near me
        </span>
      </Button>
    );
  }

  render() {
    return (
      <section className={inputStyles.inputWrapper}>
        <header className={`${layout.flexJustifySpaceBetween} ${layout.flexCenterItems} ${inputStyles.label} ${this.props.disabled ? utilStyles.disabled : ''}`}>
          <label className={layout.margin0}>Location</label>
          {this._getButtonOrSpinner()}
        </header>
        <LocationSelect
          disabled={this.props.disabled || this.state.isBusy}
          historyStateEnabled={true}
          onChange={(location) => this.props.onChange({ location })}
          value={{ ...this.props.filters.location, ...this.props.filters.distance }}
        />
      </section>
    );
  }
}

LocationFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.shape({
    date: PropTypes.string,
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    distance: PropTypes.object,
  }),
  label: PropTypes.string,
  onChange: PropTypes.func,
};

LocationFilter.defaultProps = {
  disabled: false,
  filters: {},
  label: 'Location',
  onChange: () => {},
};

export default LocationFilter;
