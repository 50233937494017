// This is a popup, used when we want to actively obtain feedback from users. It is not currently in use. 11/2024
// Use the surveyJson to edit, update, or change questions asked in the survey.
import React, { useCallback, useEffect, useState } from 'react';

import SurveyPopup from '../SurveyPopup';
import { SURVEY_SLUG } from '../SurveyPopup/enums';
import { graphMutateWithoutUser } from '../../../requests/graphql';
import { getCookie, setCookie } from '../../../services/cookies';
import { summonGlobalMessenger } from '../../../utility/dispatchers';
import { json, VERSION } from './surveyJson';

import animation from '../../../styles/global_ui/animation';
import layout from '../../../styles/global_ui/layout';
import typography from '../../../styles/global_ui/typography';
import util from '../../../styles/global_ui/util';
import style from './activeFeedback.css';

const c = {
  body: `${style.body} ${animation.pulse}`,
  container: `${layout.flexColumn} ${layout.gutter10}`,
  questionLabel: `${typography.bodyXS} ${typography.charcoal} ${typography.textCenter}`,
  error: `${typography.error} ${typography.bodyXS} ${typography.textCenter}`,
  hidden: `${util.displayNone}`,
};

const customCss = {
  question: {
    header: c.questionLabel,
    hasError: c.error,
    titleBar: c.hidden, // hide action buttons for questions. eg. "clear", "more", "..."
  },
  completedPage: c.container,
};

const ActiveFeedbackSurvey = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeLapsed, setTimeLapsed] = useState(false);
  const cookie = getCookie('hck_surveys');
  const surveyExpiresAt = cookie?.[SURVEY_SLUG.ACTIVE_GENERAL_FEEDBACK];
  const viewed = surveyExpiresAt === 'viewed';

  const saveSurveyResults = useCallback(({ content }) => {
    const response = {
      content: JSON.stringify(content),
      survey_slug: SURVEY_SLUG.ACTIVE_GENERAL_FEEDBACK,
      version: VERSION,
    };

    graphMutateWithoutUser({ t: 'create_survey_result' }, { result: response })
      .catch((err) => {
        summonGlobalMessenger({ msg: 'Oops, something went wrong. Try again later.', type: 'error' });
        return err;
      });
  }, []);

  const handleOpenChange = (isOpening) => {
    if (!isOpening) {
      const newCookie = { ...cookie, [SURVEY_SLUG.ACTIVE_GENERAL_FEEDBACK]: 'viewed' };
      const currentTime = Date.now();
      const sixMonths = 182 * 24 * 60 * 60 * 1000;
      const expires = currentTime + sixMonths;
      const date = new Date(expires);
      // This cookie used to be set without a domain. ie. the domain was www.hackster.io. We want to use the root domain moving forward ie. *.hackster.io. This means we need to fully remove the old one so that when we update to the new value of "viewed" we are left with only one cookie.
      document.cookie = `hck_surveys=${newCookie}; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;`;
      setCookie('hck_surveys', newCookie, date);
      setIsOpen(false);
    }
  };

  const handleOpen = useCallback(() => setTimeout(() => {
    // dispatch openSurvey event when opening a survey so that other surveys know not to open
    const event = new Event('openSurvey');
    document.dispatchEvent(event);
    setIsOpen(true);
  }, 15000), []);

  useEffect(() => {
    if (viewed) {
      return;
    }
    // get current time in UTC ms
    const currentTime = Date.now();
    const duration = surveyExpiresAt < currentTime ? 0 : surveyExpiresAt - currentTime;

    setTimeout(() => setTimeLapsed(true), duration);
  }, [cookie, surveyExpiresAt, viewed]);

  useEffect(() => {
    if (!timeLapsed) {
      return;
    }
    // Only open if no other survey is open.  If more than one survey is initialized, the data might get tied to the wrong survey model.
    const openSurvey = document.querySelectorAll('#surveyJS');
    if (openSurvey.length) {
      document.addEventListener('closeSurvey', handleOpen);
      return;
    }
    // Only open if sticky footer is closed to prevent overlap.
    const stickyFooter = document.getElementById('sticky-footer');
    if (stickyFooter) {
      document.addEventListener('closeStickyBanner', handleOpen);
      return;
    }

    const openTimeout = handleOpen();

    return () => {
      document.removeEventListener('closeSurvey');
      document.removeEventListener('closeStickyBanner');
      clearTimeout(openTimeout);
    };
  }, [handleOpen, timeLapsed]);

  if (viewed) {
    return null;
  }

  return (
    <SurveyPopup
      asDialog
      onSubmit={saveSurveyResults}
      popup={{
        classList: { container: c.body },
        hasClose: true,
        open: isOpen,
        title: 'A quick feedback survey.',
        onOpenChange: handleOpenChange,
      }}
      surveyCss={customCss}
      surveyJson={json}
    />
  );
};

export default ActiveFeedbackSurvey;
