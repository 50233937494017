/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import { FILTER_WHITELIST } from './filters/buildParamsFromFilters';

const translator = {
  i: 'index',
  index: 'index',
  q: 'query',
  query: 'query',
};

const paramTranslator = {
  p: 'page',
  page: 'page',
};

const sortTranslator = {
  sort: 'sort',
  sort_by: 'sort',
};

// This is defaulting these sort options to the base Algoia index for each content type.  The sorts on the particular
// sort type is redundant since the base index already sorts the results by the types listed below.
// NOTE: The UI has changed recently where we removed some of these options, but they may return and I'll leave them here for now.
const defaultAlgoliaIndexForSort = {
  channels: {
    relevance: true,
    most_members: true,
  },
  contests: { relevance: true },
  events: {
    relevance: true,
    date: true,
  },
  news_articles: { relevance: true },
  projects: {
    relevance: true,
    most_popular: true,
  },
  videos: { relevance: true },
};

function translateSettings(settings) {
  // Fall back to projects just in case.
  if (!settings.index) {
    settings.index = 'projects';
  }

  return Object.keys(settings).reduce((acc, key) => {
    if (sortTranslator.hasOwnProperty(key) && settings[key].length > 0) {
      if (defaultAlgoliaIndexForSort.hasOwnProperty(acc.index) && defaultAlgoliaIndexForSort[acc.index][settings[key]] === undefined) {
        acc.sort = settings[key];
      }
    } else if (key === 'filters' && Object.keys(settings.filters).length > 0) {
      acc.filters = settings.filters;
    }

    return acc;
  }, { index: settings.index });
}

export default function queryTranslator(queryMap) {
  return Object.keys(queryMap).reduce((acc, key, i, list) => {
    if (translator.hasOwnProperty(key)) {
      // Normal first level properties.
      acc[translator[key]] = queryMap[key];
    } else if (sortTranslator.hasOwnProperty(key)) {
      // Sort index.  We do verification for sort indexes in searchIndex.
      acc[sortTranslator[key]] = queryMap[key];
    } else if (paramTranslator.hasOwnProperty(key)) {
      // Algolia params (pagination, etc).
      const params = acc.params || {};
      params[paramTranslator[key]] = queryMap[key];
      acc.params = params;
    } else if (FILTER_WHITELIST.hasOwnProperty(key)) {
      // Filters.
      const filters = acc.filters || {};
      filters[key] = queryMap[key];
      acc.filters = filters;
    } else if (i === list.length - 1 && queryMap.hasOwnProperty('settings')) {
      // Settings.  Make sure this comes last, index, sort, and filters needs precedence.
      acc = { ...acc, ...translateSettings(queryMap.settings) };
    }

    return acc;
  }, {});
}
