import React from 'react';
import PropTypes from 'prop-types';

import Activities from './Activities';
import Info from './Info';
import Interests from './Interests';
import Title, { c as titleClasses } from './Title';
import { InfoPageOptions } from './types';

import layout from '../../styles/global_ui/layout.css';

// TODO: Didn't do at this stage, but prob can restructure props per step to be a bit more organized.
const Step = ({
  currentPath,
  defaultFilter,
  options,
  selectedActivities,
  selectedCommunities,
  selectedCountry,
  selectedIndustry,
  selectedJob,
  selectedPlatforms,
  selectedProducts,
  selectedSkillLevel,
  selectedTopics,
  setSelectedActivities,
  setSelectedCommunities,
  setSelectedCountry,
  setSelectedIndustry,
  setSelectedJob,
  setSelectedPlatforms,
  setSelectedProducts,
  setSelectedSkillLevel,
  setSelectedTopics,
  showGreeting,
  userId,
}) => {
  switch (currentPath) {
    case 'activities':
      return (
        <>
          {showGreeting && (
            <h2 className={titleClasses.subText}>
              👋 Hey there!
              <br className={layout.hiddenSmallUp} />
              {' '}
              A few questions to get you started.
            </h2>
          )}
          <Title header="What would you like to do on Hackster?" subText="(select all that apply)" />
          <Activities
            activities={selectedActivities}
            options={options.activities}
            setSelectedActivities={setSelectedActivities}
          />
        </>
      );
    case 'interests':
      return (
        <>
          <Title header="Tell us what you like!" subText="Select at least 3 areas of interest. This helps us to better recommend content for you." />
          <Interests
            communities={selectedCommunities}
            defaultFilter={defaultFilter}
            options={options.interests}
            platforms={selectedPlatforms}
            products={selectedProducts}
            setSelectedCommunities={setSelectedCommunities}
            setSelectedPlatforms={setSelectedPlatforms}
            setSelectedProducts={setSelectedProducts}
            setSelectedTopics={setSelectedTopics}
            topics={selectedTopics}
            userId={userId}
          />
        </>
      );
    case 'info':
      return (
        <>
          <Title header="Tell us about yourself." subText="Help us get to know you and connect you with other community members." />
          <Info
            country={selectedCountry}
            industry={selectedIndustry}
            job={selectedJob}
            options={options.info}
            setSelectedCountry={setSelectedCountry}
            setSelectedIndustry={setSelectedIndustry}
            setSelectedJob={setSelectedJob}
            setSelectedSkillLevel={setSelectedSkillLevel}
            skillLevel={selectedSkillLevel}
          />
        </>
      );
    default:
      throw new Error('Missing Onboarding Step');
  }
};

Step.propTypes = {
  currentPath: PropTypes.string.isRequired,
  defaultFilter: PropTypes.string,
  options: PropTypes.shape({
    activities: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    interests: PropTypes.shape({
      communities: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      platforms: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      products: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        image_url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      topics: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }).isRequired,
    info: PropTypes.shape(InfoPageOptions).isRequired,
  }).isRequired,
  selectedActivities: PropTypes.arrayOf(PropTypes.string),
  selectedCommunities: PropTypes.arrayOf(PropTypes.number),
  selectedCountry: PropTypes.string,
  selectedIndustry: PropTypes.string,
  selectedJob: PropTypes.string,
  selectedPlatforms: PropTypes.arrayOf(PropTypes.number),
  selectedProducts: PropTypes.arrayOf(PropTypes.number),
  selectedSkillLevel: PropTypes.string,
  selectedTopics: PropTypes.arrayOf(PropTypes.number),
  setSelectedActivities: PropTypes.func.isRequired,
  setSelectedCommunities: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedIndustry: PropTypes.func.isRequired,
  setSelectedJob: PropTypes.func.isRequired,
  setSelectedPlatforms: PropTypes.func.isRequired,
  setSelectedProducts: PropTypes.func.isRequired,
  setSelectedSkillLevel: PropTypes.func.isRequired,
  setSelectedTopics: PropTypes.func.isRequired,
  showGreeting: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

Step.defaultProps = {
  defaultFilter: '',
  selectedActivities: [],
  selectedCommunities: [],
  selectedCountry: null,
  selectedIndustry: null,
  selectedJob: null,
  selectedPlatforms: [],
  selectedProducts: [],
  selectedSkillLevel: null,
  selectedTopics: [],
};

export default Step;
