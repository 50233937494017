import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import createHistory, { cleanPreAndPostSlashes } from '../../client/reusable_components/Router/history';
import { initCurrentPath } from '../../client/reusable_components/Router';

import { InfoPageOptions } from './types';
import { isBlank } from '../../utility/types';

import OnboardingLayout from './OnboardingLayout';

const answeredAllInputsForStep = (vals) => {
  const unansweredInputs = vals.filter((el) => isBlank(el));

  return (unansweredInputs.length === 0);
};

const UserOnboardingPage = ({ currentUser, options, pathHelpers }) => {
  const {
    activities,
    country_iso2,
    channel_ids_by_type,
    id: userId,
    industry,
    is_new_user: isNewUser,
    job,
    onboarding_progress_temp: progress,
    products,
    skill_level,
  } = currentUser;

  // Activities state
  const [selectedActivities, setSelectedActivities] = useState(activities);

  // Interests state
  const [selectedTopics, setSelectedTopics] = useState(channel_ids_by_type.topic_channels);
  const [selectedCommunities, setSelectedCommunities] = useState(channel_ids_by_type.communities);
  const [selectedPlatforms, setSelectedPlatforms] = useState(channel_ids_by_type.platforms);
  const [selectedProducts, setSelectedProducts] = useState(products);

  // Info state
  const [selectedCountry, setSelectedCountry] = useState(country_iso2);
  const [selectedIndustry, setSelectedIndustry] = useState(industry);
  const [selectedJob, setSelectedJob] = useState(job);
  const [selectedSkillLevel, setSelectedSkillLevel] = useState(skill_level);

  // General state
  const [currentPath, setCurrentPath] = useState(initCurrentPath(pathHelpers.fullPath, pathHelpers.basePath));
  const [history] = useState(createHistory(pathHelpers.basePath));
  const [isBusy, setIsBusy] = useState(true);

  const handleLocationChange = async (pathData) => {
    setIsBusy(true);
    const newPath = cleanPreAndPostSlashes(pathData.pathname);
    setCurrentPath(newPath);

    setIsBusy(false);
  };

  // Will need custom fn for interests page. Just care if they have 3 across all types.
  // Recompute continue btn if the currentPath changes or any of our currentUser info changes.
  const canContinue = useMemo(() => {
    switch (currentPath) {
      case 'activities':
        return answeredAllInputsForStep([selectedActivities]);
      case 'interests':
        // Interests does not require the completion of all inputs, just 3 selections total.
        return [selectedCommunities, selectedPlatforms, selectedProducts, selectedTopics].flat().length >= 3;
      case 'info':
        return answeredAllInputsForStep([selectedCountry, selectedIndustry, selectedJob, selectedSkillLevel]);
      default:
        return false;
    }
  }, [currentPath, selectedActivities, selectedCommunities, selectedCountry, selectedIndustry, selectedJob, selectedPlatforms, selectedProducts, selectedSkillLevel, selectedTopics]);

  // Subscribe & Unsubscribe to location event changes.
  useEffect(() => {
    const historyUnlisten = history.listen(handleLocationChange);

    setIsBusy(false);

    return () => {
      historyUnlisten();
    };

  // Note: Disabled for onMount / no actual deps.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingLayout
      // TODO: Restructure props.
      canContinue={canContinue}
      currentPath={currentPath}
      history={history}
      options={options}
      progress={progress}
      selectedActivities={selectedActivities}
      selectedCommunities={selectedCommunities}
      selectedCountry={selectedCountry}
      selectedIndustry={selectedIndustry}
      selectedJob={selectedJob}
      selectedPlatforms={selectedPlatforms}
      selectedProducts={selectedProducts}
      selectedSkillLevel={selectedSkillLevel}
      selectedTopics={selectedTopics}
      setSelectedActivities={setSelectedActivities}
      setSelectedCommunities={setSelectedCommunities}
      setSelectedCountry={setSelectedCountry}
      setSelectedIndustry={setSelectedIndustry}
      setSelectedJob={setSelectedJob}
      setSelectedPlatforms={setSelectedPlatforms}
      setSelectedProducts={setSelectedProducts}
      setSelectedSkillLevel={setSelectedSkillLevel}
      setSelectedTopics={setSelectedTopics}
      showGreeting={isNewUser}
      userId={userId}
    />
  );
};

UserOnboardingPage.propTypes = {
  currentUser: PropTypes.shape({
    activities: PropTypes.arrayOf(PropTypes.string),
    country_iso2: PropTypes.string,
    channel_ids_by_type: PropTypes.shape({
      communities: PropTypes.arrayOf(PropTypes.number).isRequired,
      platforms: PropTypes.arrayOf(PropTypes.number).isRequired,
      topic_channels: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    industry: PropTypes.string,
    job: PropTypes.string,
    onboarding_progress_temp: PropTypes.arrayOf(PropTypes.string).isRequired,
    skill_level: PropTypes.string,
  }).isRequired,
  options: PropTypes.shape({
    activities: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    interests: PropTypes.shape({
      communities: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      platforms: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      products: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        image_url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      topics: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }).isRequired,
    info: PropTypes.shape(InfoPageOptions).isRequired,
  }).isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
  }).isRequired,
};

UserOnboardingPage.defaultProps = {
  currentUser: {
    // activities: [], // TODO: Should not be needed. Should fall back to empty array.
    // channel_ids_by_type // TODO: Also should not be needed. Should fall back to empty array for each type.
    country_iso2: null,
    industry: null,
    job: null,
    skill_level: null,
  },
};

export default UserOnboardingPage;
