import React from 'react';
import PropTypes from 'prop-types';

import HorizontalScrollBox from '../../wrappers/horizontal_scroll_box';
import NavDropdown from './nav_dropdown';
import SocialIcons from '../social_icons';

import keenService from '../../../services/keen/main';

import layoutStyles from '../../../styles/global_ui/layout.css';
import styles from './top_nav.css';

const trackLinkClick = ({ delayRedirect = true, e, location = 'topnav', type = 'main-links', value }) => {
  keenService.recordLinkClick({}, { location, type, value }, delayRedirect, e);
};

const Link = ({ className, link }) => (
  <a
    className={className}
    href={link.url}
    onClick={(e) => trackLinkClick({ e })}
    target={link.target}
  >
    {link.name}
  </a>
);

const Dropdown = ({ link }) => (
  <NavDropdown
    classList={{ link: styles.link, wrapper: styles.linkWrapper }}
    columns={link.columns}
    name={link.name}
    openMenuEventType={`topnav-${link.name}`}
    trackLinkClick={trackLinkClick}
    url={link.url}
  />
);

const _renderLinks = (links) => (
  links.map((link, i) => (
    link.columns
      ? <Dropdown key={i} link={link} />
      : (
        <span key={i} className={styles.linkWrapper}>
          <Link className={styles.link} link={link} />
        </span>
        )
  ))
);

const TopNav = ({ className, mainLinks }) => (
  <div className={className} id="main-links-nav">
    <div className={styles.root}>
      <div className={styles.left}>
        <HorizontalScrollBox classList={{ arrowLeft: styles.arrowLeft, arrowRight: styles.arrowRight }}>
          {_renderLinks(mainLinks)}
        </HorizontalScrollBox>
      </div>

      <div className={`${layoutStyles.flexCenterItems} ${layoutStyles.hiddenMedLargeDown}`}>
        <div className={styles.divider} />
        <SocialIcons className={layoutStyles.flexCenterItems} trackLinkClick={trackLinkClick} />
      </div>
    </div>
  </div>
);

TopNav.propTypes = {
  className: PropTypes.string,
  mainLinks: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({
        header: PropTypes.string.isRequired,
        linkList: PropTypes.shape({
          items: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          })).isRequired,
          viewAllLink: PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }),
        }).isRequired,
      }).isRequired,
      PropTypes.shape({
        header: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
          cover_image_url: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })).isRequired,
      }).isRequired,
    ]).isRequired),
    name: PropTypes.string.isRequired,
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

TopNav.defaultProps = { className: '' };

export default TopNav;
