const CARD_ADDITIONAL_HEIGHT = 54;
const CHAR_PER_LINE = 16;
const LINE_HEIGHT = 22;
const MAX_HEIGHT = 900;

const _estimateCardHeight = (article) => (
  Math.ceil(article.title.length / CHAR_PER_LINE) * LINE_HEIGHT + CARD_ADDITIONAL_HEIGHT
);

const truncateArticlesForCenterColumn = (articles) => {
  let totalHeight = 0;

  for (let i = 0; i < articles.length; i++) {
    const newHeight = totalHeight + _estimateCardHeight(articles[i]);

    // short circuit loop and retun truncated array of articles if adding a card would exceed MAX_HEIGHT
    if (newHeight > MAX_HEIGHT) return articles.slice(0, i);
    totalHeight = newHeight;
  }

  return articles;
};

export default truncateArticlesForCenterColumn;
