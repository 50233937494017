import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../client/nav_components/breadcrumb';

import styles from './idea_page.css';

const Breadcrumbs = ({ basePath, nextID, prevID, onClick }) => (
  <div className={styles.breadcrumbs}>
    {prevID === null
      ? <div />
      : (
        <Breadcrumb
          color="Pebble"
          href={`${basePath}${prevID}`}
          onClick={(e) => onClick(e, prevID)}
          size="S"
          text="Prev submission"
        />
        )}
    {nextID === null
      ? <div />
      : (
        <Breadcrumb
          color="Pebble"
          direction="right"
          href={`${basePath}${nextID}`}
          onClick={(e) => onClick(e, nextID)}
          size="S"
          text="Next submission"
        />
        )}
  </div>
);

Breadcrumbs.propTypes = {
  basePath: PropTypes.string.isRequired,
  nextID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  prevID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Breadcrumbs.defaultProps = {
  nextID: null,
  onClick: () => {},
  prevID: null,
};

export default Breadcrumbs;
