import PropTypes from 'prop-types';
import React from 'react';

import ConfirmEmailMessage from '../../../../client/reusable_components/ConfirmEmailMessage';

import { summonLoginPanel } from '../../../../utility/dispatchers';
import currentUserService from '../../../../services/current_user';
import urlService from '../../../../services/url_service';

function handleClick(e, fetchedCurrentUser, href, summonDialog) {
  if (!fetchedCurrentUser) return;

  if (!currentUserService.has('id')) {
    e.preventDefault();
    summonLoginPanel({
      detail: {
        redirect_to: href,
        simplified: true,
      },
    });
  } else if (currentUserService.get('isConfirmed') === false) {
    e.preventDefault();
    summonDialog(ConfirmEmailMessage, { actionText: 'send a message to another user' });
  }
}

const ContactLink = ({ className, label, fetchedCurrentUser, summonDialog, userId }) => {
  const href = urlService.url(`/messages/new?recipient_id=${userId}`);

  return (
    <a
      className={className}
      href={href}
      onClick={(e) => handleClick(e, fetchedCurrentUser, href, summonDialog)}
    >
      {label}
    </a>
  );
};

ContactLink.propTypes = {
  className: PropTypes.string,
  fetchedCurrentUser: PropTypes.bool.isRequired,
  label: PropTypes.string,
  summonDialog: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

ContactLink.defaultProps = {
  className: '',
  label: 'Contact',
};

export default ContactLink;
