import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../link/AnalyticsLink';
import LazyImage from '../../../reusable_components/LazyImage';

import { NEWS_ARTICLE_CARD } from '../../../../services/keen/events/linkConstants';
import { getInObj } from '../../../../utility/accessors';

import { timestampToPrettyDate, timestampToRelativeTime } from '../../../../utility/time';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../news_card.css';

const _getSubtitle = ({ article, type }) => {
  switch (type) {
    case 'latest':
      return (<span className={typography.pebble} title={timestampToPrettyDate(article.published_at)}>{timestampToRelativeTime(article.published_at)}</span>);
    case 'trending':
      return (<a className={typography.linkPebble} href={article.user.url}>{article.user.name}</a>);
    default:
      return null;
  }
};

const NewsArticleLightCard = ({ article, location, type }) => (
  <div className={`${styles.lightCardWrapper} ${layout.flexStartItems}`}>
    <div className={`${layout.flexColumn}`}>
      <AnalyticsLink
        className={`${typography.bodyM} ${typography.bold} ${typography.linkCharcoal} `}
        item={article}
        linkType={NEWS_ARTICLE_CARD}
        location={location}
      >
        {article.title}
      </AnalyticsLink>
      <p className={`${typography.bodyS} ${typography.charcoal} ${layout.marginTop5}`}>
        {_getSubtitle({ article, type })}
      </p>
    </div>

    <AnalyticsLink
      className={layout.marginLeft15}
      item={article}
      linkType={NEWS_ARTICLE_CARD}
      location={location}
    >
      <LazyImage
        alt={article.title}
        classList={{ root: `${utilStyles.borderRadius} ${styles.imageShadow}` }}
        ratio="1:1"
        src={getInObj(['mobile_image', 'url'], article) || getInObj(['image', 'url'], article)}
        width={70}
      />
    </AnalyticsLink>
  </div>
);

NewsArticleLightCard.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    published_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.string,
  type: PropTypes.oneOf(['latest', 'trending']).isRequired,
};

NewsArticleLightCard.defaultProps = { location: null };

export default NewsArticleLightCard;
