import PropTypes from 'prop-types';
import React, { Component } from 'react';

import GridList from '../../../../client/wrappers/grid_list';
import Icon from '../../../icon';
import MenuColumn from './MenuColumn';
import SmallProjectCard from '../../../cards/small_project_card';

import keenService from '../../../../services/keen/main';

import layoutStyles from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './nav_dropdown.css';

class NavDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);

    this.hovered = false;
    this.openEventFired = false;

    // refs
    this._menuContainer;
  }

  handleMouseLeave(event) {
    if (!this._menuContainer) {
      if (this.hovered) this.hovered = false;

      return;
    }

    const { top, right, bottom, left } = this._menuContainer.getBoundingClientRect();
    const { clientX, clientY } = event;

    if (
      (clientX <= left || clientX >= right)
      || (clientY <= top || clientY >= bottom)
    ) {
      const update = () => this.setState({ showMenu: false });
      setTimeout(() => !this.hovered ? update() : null, 350);
      this.hovered = false;
    }
  }

  handleMouseOver() {
    if (!this.hovered) {
      this.hovered = true;
    }

    setTimeout(() => {
      if (this.hovered) {
        if (!this.openEventFired) {
          this.openEventFired = true;
          keenService.reportEvent({ eventName: 'Opened menu' }, { type: this.props.openMenuEventType });
        }
        this.setState({ showMenu: true });
      }
    }, 500);
  }

  _featuredProjectsView(items, header, i) {
    return (
      <div key={i} className={styles.columnFeatured}>
        <div className={`${typography.h5} ${layoutStyles.marginBottom15}`}>{header}</div>
        <GridList
          ItemComponent={SmallProjectCard}
          className={styles.projectGrid}
          itemKey="project"
          itemProps={{
            imgBorderRadius: true,
            lazy: false,
            location: 'topnav',
          }}
          maxCols={2}
          records={items}
        />
      </div>
    );
  }

  _renderLink(isDesktop) {
    const { classList, name, trackLinkClick, url } = this.props;

    return (
      <a
        className={`${classList.link} ${isDesktop ? layoutStyles.hiddenMedLargeDown : layoutStyles.hiddenMedLargeUp}`}
        href={url}
        onClick={(e) => trackLinkClick({ e })}
        onMouseLeave={isDesktop ? this.handleMouseLeave : null}
        onMouseOver={isDesktop ? this.handleMouseOver : null}
        title={name}
      >
        {name}
        {isDesktop && <Icon className={layoutStyles.marginLeft10} name="arrow-down" size="12" />}
      </a>
    );
  }

  render() {
    const { classList, columns, trackLinkClick, name } = this.props;

    return (
      <span
        className={classList.wrapper}
        id={`top-nav-${name}-wrapper`}
      >
        {this._renderLink(false)}
        {this._renderLink(true)}
        {this.state.showMenu
        && (
          <div className={styles.menuRoot}>
            <div
              ref={(c) => this._menuContainer = c}
              className={styles.menuContainer}
              onMouseLeave={this.handleMouseLeave}
              onMouseOver={this.handleMouseOver}
            >
              {columns.map((column, i) => (
                column.header === 'Featured Projects'
                  ? this._featuredProjectsView(column.items, column.header, i)
                  : <MenuColumn key={i} column={column} trackLinkClick={trackLinkClick} />
              ))}
            </div>
          </div>
        )}
      </span>
    );
  }
}

NavDropdown.propTypes = {
  classList: PropTypes.shape({
    link: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      linkList: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })).isRequired,
        viewAllLink: PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        cover_image_url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
  ])).isRequired,
  name: PropTypes.string.isRequired,
  openMenuEventType: PropTypes.string,
  trackLinkClick: PropTypes.func,
  url: PropTypes.string.isRequired,
};

NavDropdown.defaultProps = {
  classList: {},
  openMenuEventType: 'nav-dropdown',
  trackLinkClick: () => {},
};

export default NavDropdown;
