import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { graphQuery } from '../../../../requests/graphql';
import errorHandler from '../../../../services/error_handler';

import Drafts from './Drafts';
import Loader from '../../../reusable_components/Router/Loader';
import PageHeader from '../../page_header';
import UnreadNotifications from './UnreadNotifications';

import layout from '../../../../styles/global_ui/layout.css';

import { PUBLIC } from '../../../../graphql/projects/enums';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drafts: null,
      user: this.getUpdatedUser(),
    };

    this.getExistingDrafts = this.getExistingDrafts.bind(this);
    this.getUpdatedUser = this.getUpdatedUser.bind(this);
    this.getDrafts = this.getDrafts.bind(this);
  }

  componentDidMount() {
    this.getDrafts();
  }

  getExistingDrafts() {
    const store = this.props.store.get('projects/drafts');
    const drafts = store ? store.projects.drafts : null;

    return drafts;
  }

  getDrafts() {
    const drafts = this.getExistingDrafts();
    if (drafts) return this.setState({ drafts });

    return graphQuery({ t: 'get_projects_by_category_drafts' }, { author_id: this.props.currentUser.id, publication_state: PUBLIC })
      .then((res) => {
        this.setState({ drafts: res.drafts });

        this.props.store.set('projects/drafts', { projects: { drafts: this.state.drafts } });
      })
      .catch((err) => errorHandler(err));
  }

  getUpdatedUser() {
    const profile = this.props.store.get('profile');
    const updates = profile ? { name: profile.fields.name.value, user_name: profile.fields.user_name.value } : {};

    return { ...this.props.currentUser, ...updates };
  }

  render() {
    const { name, user_name } = this.state.user;

    return (
      <div className={layout.container}>
        <div className={layout.wrapper960}>
          <PageHeader type="home" typeArgs={{ name, user_name }} />
          {!this.state.drafts
            ? <Loader />
            : <Drafts currentUser={this.state.user} drafts={this.state.drafts} />}
          <UnreadNotifications currentUser={this.props.currentUser} />
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    stats: PropTypes.shape({ projects: PropTypes.number }),
  }).isRequired,
  isWhitelabel: PropTypes.bool,
  store: PropTypes.object,
};

Home.defaultProps = {
  isWhitelabel: false,
  store: {},
};

export default Home;
