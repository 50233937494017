import React from 'react';
import PropTypes from 'prop-types';

import ArticlesList from '../../../shared/articles_list';

import gridStyles from '../../../../../styles/global_ui/grid.css';
import layout from '../../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../../styles/global_ui/util.css';
import typography from '../../../../../styles/global_ui/typography.css';

const RelatedArticlesBottom = ({ records }) => {
  if (records && records.length === 0) return null;

  return (
    <div className={`${layout.marginTop60} ${layout.marginBottom30} ${layout.fullWidth}`}>
      <h5 className={`${typography.h5} ${layout.paddingBottom15} ${layout.marginBottom22} ${utilStyles.borderBottom}`}>
        Related articles
      </h5>
      <ArticlesList
        articles={{ records: (records || []), metadata: {} }}
        className={`${gridStyles.guttersH30_sm} ${gridStyles.guttersV30_sm}`}
        gutterSize={15}
        isBusy={!records}
        location="related_articles_bottom"
      />
    </div>
  );
};

RelatedArticlesBottom.propTypes = { records: PropTypes.array };

RelatedArticlesBottom.defaultProps = { records: null };

export default RelatedArticlesBottom;
