import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon';

import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './mobile_nav.css';

const BackButton = ({ name, internalTarget, switchPanelOrDropdown }) => (
  <div className={styles.rowCenter}>
    <span
      className={`${typography.link} ${styles.backButton} ${typography.bold} ${utilStyles.cursorPointer}`}
      onClick={(e) => switchPanelOrDropdown(e, 'panel', internalTarget)}
    >
      <Icon className={styles.backArrow} name="arrow-left" size="12" />
      {name}
    </span>
  </div>
);

BackButton.propTypes = {
  internalTarget: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  switchPanelOrDropdown: PropTypes.func.isRequired,
};

export default BackButton;
