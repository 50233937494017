import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../../link/AnalyticsLink';
import OptionalMobileLazyBgImageView from '../../base_card/templates/OptionalMobileLazyBgImageView';

import { NEWS_ARTICLE_CARD } from '../../../../services/keen/events/linkConstants';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from '../news_card.css';

const IMAGE_RATIO_CONFIG = {
  image: {
    breakpoint: layout.hiddenMedDown,
    classList: { root: utilStyles.absolutePlaceholderChild },
    ratio: '16:9',
    width: 555,
  },
  mobileImage: {
    breakpoint: layout.hiddenMedUp,
    classList: { root: utilStyles.absolutePlaceholderChild },
    ratio: '1:1',
    width: 120,
  },
};

const NewsArticleSponsoredCardLarge = ({ article, location }) => (
  <div className={`${styles.sponsoredCard} ${styles.sponsoredCardLg}`}>
    <AnalyticsLink
      className={`${styles.featuredImgWrapperSm} ${layout.fullWidth} ${utilStyles.posRelative}`}
      item={article}
      linkType={NEWS_ARTICLE_CARD}
      location={location}
    >
      <OptionalMobileLazyBgImageView
        config={IMAGE_RATIO_CONFIG}
        item={article}
      />
    </AnalyticsLink>

    <div className={styles.sponsoredDataWrapperLg}>
      <AnalyticsLink
        className={`${typography.bodyL} ${typography.bold}`}
        item={article}
        linkType={NEWS_ARTICLE_CARD}
        location={location}
      >
        {article.title}
      </AnalyticsLink>
      <p className={`${typography.bodyM} ${layout.marginTop5}`}>
        {'Sponsored by '}
        <AnalyticsLink
          className={typography.linkBlue}
          customAttributes={{ rel: 'noopener' }}
          isExternal={true}
          item={{ id: article.sponsor_name, url: article.sponsor_link }}
          linkType={NEWS_ARTICLE_CARD}
          location={location}
        >
          {article.sponsor_name}
        </AnalyticsLink>
      </p>
    </div>
  </div>
);

NewsArticleSponsoredCardLarge.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    sponsor_link: PropTypes.string.isRequired,
    sponsor_name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  isResponsive: PropTypes.bool,
  location: PropTypes.string,
};

NewsArticleSponsoredCardLarge.defaultProps = {
  isResponsive: true,
  location: null,
};

export default NewsArticleSponsoredCardLarge;
