import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { IN_PROGRESS, PRE_REGISTRATION } from '../../constants';
import { MEMBERSHIP_TIERS } from '../../../contests_page/enum';
import { canRegisterForContest } from '../../../../client/cards/contest_card/ActiveContestCard';

import AnchorButton from '../../../../client/buttons/anchor';
import ProBadge from '../../../../client/reusable_components/Badge/ProBadge';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './side_panel.css';

class ContestStatus extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { shouldShowStatusView: false };
  }

  componentDidMount() {
    this.setState({ shouldShowStatusView: true });
  }

  /**
   * HELPERS
   */
  isPreRegistrationOrInProgress(status) {
    return [PRE_REGISTRATION, IN_PROGRESS].includes(status);
  }

  /**
   * VIEWS
   */
  getSingleLineStatus({ text, date }) {
    return (
      <p className={typography.bodyL}>
        {text[0]}
        {' '}
        {date && <b>{date}</b>}
      </p>
    );
  }

  getStatusWithTimeRemaining({ date, text, timeRemaining }) {
    return (
      <span>
        <div className={typography.bodyL}>
          {text[0]}
          {' '}
          <b>{timeRemaining}</b>
        </div>
        <div className={typography.bodyS}>
          {text[1]}
          {' '}
          {date}
        </div>
      </span>
    );
  }

  getStatusView(props) {
    return this.isPreRegistrationOrInProgress(props.status)
      ? this.getStatusWithTimeRemaining(props)
      : this.getSingleLineStatus(props);
  }

  render() {
    const canRegister = canRegisterForContest(this.props.requiredUserTier, this.props.userTier);

    return (
      <div className={styles.rowContainer}>
        <h5
          className={`${styles.rowHeader} ${typography.h5} ${layout.flexCenterItems} ${layout.gutter5} `}
        >
          Contest Status
          {this.props.requiredUserTier === 'PRO' && (
            <ProBadge contentType="contest" size="medium" />
          )}
        </h5>
        {!canRegister && (
          <AnchorButton
            className={`${layout.marginBottom10}`}
            href="/pro"
          >
            Learn more about PRO
          </AnchorButton>
        )}
        {this.state.shouldShowStatusView
          ? (
              this.getStatusView(this.props)
            )
          : (
            <div className={styles.statusDummy} />
            )}
      </div>
    );
  }
}

ContestStatus.propTypes = {
  date: PropTypes.string,
  requiredUserTier: PropTypes.oneOf(MEMBERSHIP_TIERS).isRequired,
  status: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  timeRemaining: PropTypes.string,
  userTier: PropTypes.oneOf(MEMBERSHIP_TIERS).isRequired,
};

ContestStatus.defaultProps = {
  date: null,
  status: null,
  timeRemaining: null,
};

export default ContestStatus;
