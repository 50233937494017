import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ArticlesList from '../../../../scenes/news/shared/articles_list';
import ContentPlaceholder from '../../../../client/reusable_components/ContentPlaceholder';

import errorHandler from '../../../../services/error_handler';
import smoothScroll from '../../../../client/utils/smoothScroll';

import { PUBLISHED_SORT, PUBLISHED_STATUS } from '../../../../graphql/news/enums';

import layout from '../../../../styles/global_ui/layout.css';
import styles from '../profile/profile.css';

export default class Articles extends Component {
  constructor(props) {
    super(props);

    this.state = { isBusy: false };

    this.fetchMoreRecords = this.fetchMoreRecords.bind(this);
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    smoothScroll(0, 300);
  }

  /**
   * Methods
   */
  fetchMoreRecords() {
    this.setState({ isBusy: true });

    return this.props.fetchMoreRecords(this.props.store, 'articles', ['articles'], this._getQueryArgs(), true)
      .then(() => this.setState({ isBusy: false }))
      .catch((err) => {
        this.setState({ isBusy: false });
        errorHandler(err);
      });
  }

  /**
   * Helpers
   */
  _getQueryArgs() {
    return {
      by_user_id: this.props.profile.id,
      by_sponsored: false,
      by_status_type: PUBLISHED_STATUS,
      sort: PUBLISHED_SORT,
    };
  }

  /**
   * Views
   */
  _createList({ articles, fetchMoreRecords, profile, store }) {
    return (
      <ArticlesList
        articles={articles}
        handlePaginatorClick={this.fetchMoreRecords}
        isBusy={this.state.isBusy}
        onUserProfile={true}
      />
    );
  }

  _createPlaceholder({ isProfileOwner, profile }) {
    return (
      <ContentPlaceholder
        classList={{
          container: styles.container,
          labelSegments: { 1: styles.placeholderLabelSegment },
        }}
        isProfileOwner={isProfileOwner}
        label={['Articles ', '0']}
        templateArgs={{ name: profile.name }}
        type="no-published-articles"
      />
    );
  }

  render() {
    const articles = this.props.store.get('articles');

    return (
      <div className={layout.container}>
        <div className={layout.wrapper1170}>
          {articles && articles.records.length > 0
            ? this._createList({ ...this.props, articles })
            : this._createPlaceholder(this.props)}
        </div>
      </div>
    );
  }
}

Articles.propTypes = {
  fetchMoreRecords: PropTypes.func.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  store: PropTypes.object.isRequired,
};
