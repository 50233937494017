import React from 'react';

import animation from '../../../../styles/animation.css';
import layout from '../../../../styles/global_ui/layout.css';
import styles from '../news_card.css';

const NewsArticleLightDummyCard = ({ article, location, type }) => (
  <div className={styles.lightCardWrapper}>
    <div className={animation.loader} style={{ height: 16, width: '100%' }} />
    <div className={`${animation.loader} ${layout.marginTop5}`} style={{ height: 16, width: '50%' }} />
    <div className={`${animation.loader} ${layout.marginTop5}`} style={{ height: 14, width: '25%' }} />
  </div>
);

export default NewsArticleLightDummyCard;
