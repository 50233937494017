import smoothScroll from '../../../../client/utils/smoothScroll';

function checkForDuplicateSelections(selections) {
  if (!selections) {
    return false;
  }

  const dups = {};
  let hasDups = false;

  for (const selection of selections) {
    if (dups[selection.value]) {
      hasDups = true;
      break;
    } else {
      dups[selection.value] = true;
    }
  }

  return hasDups;
}

function generateCreate(tag, rootIndex) {
  const attribute = tag.id ? 'tag_relations_attributes' : 'new_tags_attributes';
  const update = tag.id ? 'tag_id' : 'name';
  const value = tag.id ? tag.id : tag.value;

  if (document && document.createElement) {
    const input = document.createElement('input');
    input.setAttribute('name', `project[${attribute}][${rootIndex}][${update}]`);
    input.setAttribute('value', value);
    input.setAttribute('type', 'hidden');

    return input;
  }
}

function generateDestroy(tag, rootIndex, i) {
  const attribute = 'tag_relations_attributes';
  const update = i > 0 ? 'id' : '_destroy';
  const value = i > 0 ? tag.cue_id : true;

  if (document && document.createElement) {
    const input = document.createElement('input');
    input.setAttribute('name', `project[${attribute}][${rootIndex}][${update}]`);
    input.setAttribute('value', value);
    input.setAttribute('type', 'hidden');

    return input;
  }
}

function generateInputField(tag, rootIndex, type) {
  if (document && document.getElementById) {
    const iterations = type === 'create' ? 1 : 2;
    const rootElement = document.createElement('div');
    const updateFunction = type === 'create' ? generateCreate : generateDestroy;

    for (let i = 0; i < iterations; i++) {
      rootElement.appendChild(updateFunction(tag, rootIndex, i));
    }

    return rootElement;
  }
}

export function appendAdditionalInputs(updates) {
  if (document && document.getElementById) {
    if (document.getElementById('additional-input')) {
      return;
    } else {
      const root = document.getElementById('category-tag-select');
      root.appendChild(handleInputFieldGeneration(updates));

      return false;
    }
  }
}

export function createDiffArray(current, previous, type) {
  const tagNames = Object.keys({ ...previous, ...current });

  return tagNames.reduce((memo, tagName) => {
    const composeMemo = (source, action) => [...memo, { ...source[tagName], type: action }];

    if (current[tagName] && previous[tagName] && previous[tagName].status === 'saved') {
      // if it is in both places and previous.status is saved - do nothing.
      return memo;
    } else if (current[tagName]) {
      // if it is in current - create
      return composeMemo(current, 'create');
    } else if (!current[tagName] && previous[tagName] && previous[tagName].status !== 'pending_creation') {
      // if it is only in previous and isn't pending creation - delete
      return composeMemo(previous, 'delete');
    } else {
      return memo;
    }
  }, []);
}

// New FormGroup shouldn't allow duplicates by design, but keeping as a backup.
export function createValidationErrors(selections, isPublicProject) {
  if (isPublicProject && selections.length === 0) return 'Your project is public, you need at least one tag!';

  return selections && selections.length > 5
    ? 'Remember, five tags at most!'
    : checkForDuplicateSelections(selections)
      ? 'Remember to make sure that each tag is unique!'
      : false;
}

export function handleInputFieldGeneration(tagUpdates) {
  if (document && document.createElement) {
    const additionalInput = document.createElement('div');
    additionalInput.setAttribute('id', 'additional-input');

    tagUpdates.forEach((tag, i) => {
      tag.type && tag.type === 'create'
        ? additionalInput.appendChild(generateInputField(tag, i, 'create'))
        : additionalInput.appendChild(generateInputField(tag, i, 'destroy'));
    });

    return additionalInput;
  }
}

export function preventSubmit(event) {
  event.preventDefault();

  const selectElement = document.getElementById('category-tag-select');
  const centerPosition = selectElement.offsetTop - ((window.innerHeight / 2) - (selectElement.offsetHeight / 2));

  smoothScroll(centerPosition);

  return false;
}
