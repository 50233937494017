import React from 'react';

import EventDetails from '../../../../client/cards/search/sub_components/EventInfo/EventDetails';
import EventDate from '../../../../client/cards/search/sub_components/EventInfo/EventDate';

import { buildLocation } from '../../../../services/country_data';

export default function events({
  city,
  country_iso2,
  event_type,
  link,
  name,
  objectID,
  state,
  start_date,
  title,
}) {
  return {
    body: { component: <EventDetails dateString={start_date} location={buildLocation(city || '', state || '', country_iso2 || '')} /> },
    id: objectID,
    image: {
      component: <EventDate dateString={start_date} orientation="horizontal" />,
      loadBuffer: 100,
      width: 75,
    },
    location: buildLocation(city || '', state || '', country_iso2 || ''),
    stats: [],
    subTitle: [{ text: `${event_type} event`, linkTo: null }],
    title: {
      text: title,
      linkTo: link,
    },
  };
}
