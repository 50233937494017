import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BookmarkListForm from '../../../bookmarks/bookmark_list_form';
import ButtonDropdown from '../../../buttons/button_dropdown';
import DummyList from '../../../wrappers/grid_list/DummyList';
import GridList from '../../../wrappers/grid_list';
import ListCard from '../../../cards/list_card';
import DummyListCard from '../../../cards/list_card/DummyListCard';
import Paginator from '../../../nav_components/paginator';

import GraphQLBookmarkListsService from '../../../../services/graphql/bookmark_lists_service';
import bookmarkStore from '../../../../services/bookmark_store';
import errorHandler from '../../../../services/error_handler';
import urlService from '../../../../services/url_service';
import { summonGlobalMessenger } from '../../../../utility/dispatchers';
import { windowScrollTo } from '../../../../services/window';

import { GENERIC_ERROR } from '../../../../constants/alerts';

import bookmarkStyles from '../../../bookmarks/bookmarks.css';
import buttonStyles from '../../../../styles/global_ui/buttons.css';
import headerStyles from '../../page_header/page_header.css';
import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../styles/global_ui/util.css';

const ANALYTICS_CONSTANTS = { widget_src: 'bookmarks_page' };
const PER_PAGE = 20;

const Loader = () => (
  <DummyList
    ItemComponent={DummyListCard}
    gutterSize={30}
  />
);

/**
 * TODO: pagination adds a lot of code and complexity to this thing.
 * currently, there are 19 users with >20 lists, and only 6 with >30 lists.
 * Is the extra weight worth it?
 */
class Bookmarks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      isBusy: true,
      metadata: {},
      records: [],
    };

    this.qlService = new GraphQLBookmarkListsService({ history: props.history });

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleNewList = this.handleNewList.bind(this);
    this.paginateTo = this.paginateTo.bind(this);

    this._isMounted;
    this.unlisten;

    // refs
    this._buttonDropdown;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._isMounted = true;
    this.unlisten = this.props.history.listen(this.handleLocationChange);
    this._initFromURL();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (typeof this.unlisten === 'function') this.unlisten();
  }

  /**
   * Initializers
   */
  _initFromURL() {
    return this.qlService.initializeFromUrl({})
      .then((currentQuery) => this._resolveGraphQLResponse(currentQuery))
      .catch((err) => this._handleError(err));
  }

  /**
   * Methods
   */
  handleLocationChange(location, action) {
    if (action === 'POP' && location.pathname === `/${this.props.path}`) {
      this.setState({ isBusy: true });
      this._initFromURL();
    }
  }

  handleNewList(newList) {
    if (this._buttonDropdown) this._buttonDropdown.__toggleDropdownHook(false);
    summonGlobalMessenger({ msg: `Created new list '${newList.name}'.`, type: 'success' });
    this._updateStore(newList);

    if (this.state.metadata.current_page === 1) {
      this._prependList(this._createListForDisplay(newList));
    } else {
      this.paginateTo(1); // Presumes api response will include new list (i.e. no cache lag). if not, rethink this
    }
  }

  paginateTo(pageNum) {
    this.setState({ isBusy: true });

    return this.qlService.searchWithFilterString(`page=${pageNum}`)
      .then((currentQuery) => this._resolveGraphQLResponse(currentQuery))
      .catch((err) => this._handleError(err));
  }

  /**
   * Helpers
   */
  _createListForDisplay(list = {}) {
    return {
      ...list,
      projects_count: 0,
      url: urlService.url(`/${this._getCurrentUserName()}/bookmarks/${list.id}`),
    };
  }

  _getCurrentUserName() {
    const profile = this.props.store.get('profile');

    return profile ? profile.fields.user_name.value : this.props.currentUser.user_name;
  }

  _handleError(err) {
    errorHandler(err);
    summonGlobalMessenger({ msg: GENERIC_ERROR, type: 'error' });
    this._setStateIfMounted({ isBusy: false });
  }

  _prependList(newList) {
    const { records, metadata } = this.state;

    // if we are about to push the last item to the next page, make sure forward pagination is enabled
    const next_page = records.length < PER_PAGE ? null : metadata.current_page + 1;

    // prepend new list to page, keeping PER_PAGE records per page
    const newRecords = [newList].concat(records).slice(0, PER_PAGE);

    this._setStateIfMounted({
      metadata: { ...metadata, next_page },
      records: newRecords,
    });
  }

  _resolveGraphQLResponse({ metadata, records }) {
    windowScrollTo(0, 0);
    this._setStateIfMounted({
      metadata,
      records,
      initialized: true,
      isBusy: false,
    });
  }

  _setStateIfMounted(state, cb) {
    if (this._isMounted) this.setState(state, cb);
  }

  _updateStore(list) {
    return bookmarkStore.initialize()
      .then(bookmarkStore.addList(list));
  }

  render() {
    const { initialized, isBusy, metadata, records } = this.state;
    const { current_page, next_page, prev_page } = metadata;

    return (
      <div className={`${layout.container} ${utilStyles.bgWhite}`}>
        <div className={`${layout.wrapper1170} ${layout.marginBottom30}`}>
          <div className={`${headerStyles.headerInner} ${layout.marginBottom30}`}>
            <h2 className={`${typography.h2} ${layout.flex10Auto}`}>
              My bookmarks
            </h2>
            <ButtonDropdown
              ref={(c) => this._buttonDropdown = c}
              alignRight={true}
              classList={{
                button: `${buttonStyles.md} ${typography.whitespaceNowrap}`,
                dropdown: `${bookmarkStyles.dropdown} ${utilStyles.boxShadow2}`,
              }}
              label="Create new list"
            >
              <div className={layout.padding15}>
                <BookmarkListForm analytics={ANALYTICS_CONSTANTS} componentSize="sm" onSubmit={this.handleNewList} />
              </div>
            </ButtonDropdown>
          </div>
          {initialized
            ? (
              <GridList
                ItemComponent={ListCard}
                gutterSize={30}
                isLoading={isBusy}
                itemKey="list"
                itemProps={{ imageURLs: this.props.imageURLs }}
                records={records}
              />
              )
            : <Loader />}
          {!!current_page && !!(prev_page || next_page)
          && (
            <Paginator
              classList={{ root: layout.marginTop30 }}
              currentPage={current_page}
              currentQuery={this.props.history.location.search}
              disabled={isBusy}
              nextPage={next_page}
              onClick={this.paginateTo}
              prevPage={prev_page}
            />
          )}
        </div>
      </div>
    );
  }
}

Bookmarks.propTypes = {
  currentUser: PropTypes.shape({ user_name: PropTypes.string.isRequired }).isRequired,
  history: PropTypes.object.isRequired,
  imageURLs: PropTypes.shape({
    bookmarkCoverDefault: PropTypes.shape({
      x1: PropTypes.string.isRequired,
      x2: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  store: PropTypes.shape({ get: PropTypes.func.isRequired }).isRequired,
};

export default Bookmarks;
