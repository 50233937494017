import React from 'react';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../client/form_components/inputs/radio_group';
import FilterLabel from './FilterLabel';

const IGNORE_VALUE = 'IGNORE_VALUE';

const valueIn = (val) => val || IGNORE_VALUE;
const valueOut = (val) => val === IGNORE_VALUE ? '' : val;

const getButtons = ({ buttons, ignoreOptionLabel }) => (
  buttons.concat([{ label: ignoreOptionLabel, value: IGNORE_VALUE }])
);

const RadioFilter = ({ buttons, disabled, ignoreOptionLabel, label, onChange, value }) => (
  <RadioGroup
    buttons={getButtons({ buttons, ignoreOptionLabel })}
    disabled={disabled}
    label={<FilterLabel disabled={disabled} label={label} />}
    onChange={(e) => onChange(valueOut(e.target.value))}
    value={valueIn(value)}
  />
);

RadioFilter.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.node,
    value: PropTypes.any,
  })).isRequired,
  disabled: PropTypes.bool,
  ignoreOptionLabel: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

RadioFilter.defaultProps = {
  disabled: false,
  label: '',
  ignoreOptionLabel: 'All',
  onChange: () => {},
  value: '',
};

export default RadioFilter;
