import React from 'react';
import PropTypes from 'prop-types';

import DummyUserCard from '../user_card/DummyCard';

import cardStyles from '../cards.css';
import layoutStyles from '../../../styles/global_ui/layout.css';
import styles from './contest_idea_card.css';

const DummyContestIdeaCard = ({ classList }) => (
  <div className={`${cardStyles.wrapper} ${classList.wrapper}`}>
    <div className={cardStyles.cardWithBorder}>
      <div className={styles.body}>
        <div className={styles.dummyTitle} />
        <div className={styles.dummyBody} />
        <div className={styles.dummyBody} />
        <div className={styles.dummyBody} />
        <div className={cardStyles.spacer} />
        <div className={`${layoutStyles.flexCenterItems} ${layoutStyles.marginTop30}`}>
          <DummyUserCard avatarSize={30} titleSize="L" />
        </div>
      </div>
      <div className={styles.project}>
        <div className={styles.dummyTitle} />
        <div className={styles.placeholderOuter}>
          <div className={styles.dummyPlaceholderInner} />
        </div>
      </div>
    </div>
  </div>
);

DummyContestIdeaCard.propTypes = { classList: PropTypes.shape({ wrapper: PropTypes.string }) };

DummyContestIdeaCard.defaultProps = { classList: { wrapper: '' } };

export default DummyContestIdeaCard;
