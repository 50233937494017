import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContentPlaceholder from '../../../client/reusable_components/ContentPlaceholder';
import DummyHorizontalPartCard from '../../../client/parts/horizontal_part_card/DummyHorizontalPartCard';
import HorizontalPartCard from '../../../client/parts/horizontal_part_card';
import NavBar from './NavBar';

import GraphQLPartsService from '../../../services/graphql/parts_service';
import { cancelablePromise } from '../../../utility/promises';
import { isObjectWithLength } from '../../../utility/types';
import errorHandler from '../../../services/error_handler';

import styles from './product.css';
import partCardStyles from '../../../client/parts/horizontal_part_card/horizontal_part_card.css';
import layout from '../../../styles/global_ui/layout.css';

class ProductWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetched: false,
      part: null,
    };

    this.qlService = new GraphQLPartsService({
      history: this.props.history,
      queryString: () => ({ t: 'platform_product' }),
      resolverKey: 'product',
    });
    this._activePromise;
  }

  componentDidMount() {
    this._initializeFromUrl();
  }

  componentWillUnmount() {
    if (this._activePromise) this._activePromise.cancel();
  }

  _initializeFromUrl() {
    return this.qlService.getPartSlugFromWindowLocation()
      .then((slug) => this._getCancelableRequest(slug).promise)
      .then((res) => this._resolvePart(res))
      .catch((err) => {
        if (err.promiseCanceled) return;

        this.setState({ fetched: true });
        errorHandler('initializeFromUrl', err);
      });
  }

  _getCancelableRequest(slug) {
    this._activePromise = cancelablePromise(this.qlService.searchWithNoEffectsForSingleRecord({ slug }, this.props.graphQLArguments));

    return this._activePromise;
  }

  _resolvePart({ part, promiseCanceled }) {
    if (promiseCanceled) return;
    if (!part) return this.setState({ fetched: true });

    this.setState({ fetched: true, part });
    this.props.propagatePart(part);
  }

  /**
   * Views
   */
  _getPartView() {
    if (!this.state.fetched) return (<DummyHorizontalPartCard className={styles.dummyContainer} />);
    if (this.state.fetched && !this.state.part) return (<div>Product not found!</div>);

    return isObjectWithLength(this.state.part)
      ? (
        <HorizontalPartCard
          canManage={this.props.canManage}
          className={partCardStyles.singleCardContainer}
          part={this.state.part}
          platformId={this.props.platformId}
          showLinks={false}
        />
        )
      : <ContentPlaceholder type="no-product" />;
  }

  render() {
    return (
      <div className={`${layout.container}`}>
        <div className={layout.wrapper1170}>
          {this._getPartView()}
          {this.state.fetched && isObjectWithLength(this.state.part)
          && (
            <div className={styles.wrapper}>
              <NavBar currentPath={this.props.path} part={this.state.part} transition={this.props.transition} />
              <div className={styles.childWrapper}>{this.props.children}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProductWrapper.propTypes = {
  canManage: PropTypes.bool,
  graphQLArguments: PropTypes.shape({
    platform_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    platform_user_name: PropTypes.string,
  }),
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  platformId: PropTypes.number.isRequired,
  propagatePart: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};

ProductWrapper.defaultProps = { canManage: false };

export default ProductWrapper;
