import React from 'react';
import PropTypes from 'prop-types';

import AnalyticsLink from '../../link/AnalyticsLink';
import BaseCardVertical from '../base_card/vertical/BaseCardVertical';

import { timestampToPrettyDate, timestampToRelativeTime } from '../../../utility/time';

import { NEWS_ARTICLE_CARD } from '../../../services/keen/events/linkConstants';

import cardStyles from '../cards.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';

const NewsArticleCard = ({ article, location, onUserProfile }) => {
  const linkProps = { item: article, linkType: NEWS_ARTICLE_CARD, location };

  return (
    <BaseCardVertical
      imageAspectRatio="16:9"
      item={article}
      linkProps={linkProps}
      renderBody={() => (
        <div className={`${typography.breakWord} ${layout.flexColumn}`}>
          <AnalyticsLink
            className={`${typography.bodyM} ${typography.bold} ${typography.linkCharcoal}`}
            {...linkProps}
          >
            {article.title}
          </AnalyticsLink>
          {onUserProfile && <div className={cardStyles.spacer} />}
          <p className={typography.bodyS}>
            <a className={typography.link} href={article.user.url}>{article.user.name}</a>
            <span className={`${layout.marginLeft5} ${layout.marginRight5}`}>•</span>
            <span title={timestampToPrettyDate(article.published_at)}>{timestampToRelativeTime(article.published_at)}</span>
          </p>
        </div>
      )}
      responsive={!onUserProfile}
    />
  );
};

NewsArticleCard.propTypes = {
  article: PropTypes.shape({
    image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    mobile_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    published_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.string,
  onUserProfile: PropTypes.bool,
};

NewsArticleCard.defaultProps = {
  itemIndex: null,
  location: null,
  onUserProfile: false,
  overrideTracking: null,
};

export default NewsArticleCard;
